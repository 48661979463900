import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CHRONO_SCREEN } from '../../../config/type';
import TimerContextProvider, { TimerContext } from './contexts/TimerContext';
import TimerChrono from './TimerChrono/TimerChrono';
import TimerConfig from './TimerConfig/TimerConfig';

const TimerScreenSwitch = ({ chronoConfiguration }: any) => {
	const history = useHistory();
	const location = useLocation<any>();

	const [screen, setScreen] = useState(CHRONO_SCREEN.CONFIG);

	useEffect(() => {
		let unlisten = history.listen((location, action) => {
			if (location.pathname === '/timer') {
				setScreen(CHRONO_SCREEN.CONFIG);
			}
		});

		if (location.pathname === '/timer/start' && screen !== CHRONO_SCREEN.APP) {
			history.push('/timer');
		}

		return () => {
			unlisten();
		};
	}, [history, location]);

	return (
		<TimerContextProvider states={{ screen, setScreen }}>
			{screen === CHRONO_SCREEN.CONFIG ? <TimerConfig /> : screen === CHRONO_SCREEN.APP ? <TimerChrono /> : null}
		</TimerContextProvider>
	);
};

export default TimerScreenSwitch;
