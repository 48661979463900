import { useEffect, useState } from 'react';
import useUser from './useUser';

export default (programId) => {
	const { user } = useUser();
	const [isCoach, setIsCoach] = useState(false);

	useEffect(() => {
		setIsCoach(false);

		if (user?.ownedPrograms) {
			const index = user?.ownedPrograms?.findIndex((p) => p?.id === programId);
			if (index !== -1) {
				setIsCoach(true);
			}
		}
	}, [programId, user]);

	return isCoach;
};
