import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW } from '../../../../../config/type';
import ButtonAddStep from '../FormsElements/ButtonAddStep';
import InputShow from '../FormsElements/InputShow';
import Step from '../FormsElements/Step';
import FormHOC from './FormHOC';
import './FormForTime.scss';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';

const FormForTime = () => {
	const { t } = useTranslation();
	const [stepVisible, setStepVisible] = useState(false);
	const { timerConfig } = useContext(TimerConfigDataContext);

	const [configTimeLimit, setConfigTimeLimit] = useState<any>(timerConfig?.timeLimit || 0);
	const [configNumber, setConfigNumber] = useState<any>(timerConfig?.setConfiguration?.number || 0);
	const [configRestTime, setConfigRestTime] = useState<any>(timerConfig?.setConfiguration?.restTime || 0);

	const [config, setConfig] = useState<any>(timerConfig);

	useEffect(() => {
		if (configNumber !== 0 || configRestTime !== 0) setStepVisible(true);
	}, [configNumber, configRestTime]);

	useEffect(() => {
		setConfig({
			type: CHRONO_ACTIVITIES[2].name,
			timeLimit: configTimeLimit,
			setConfiguration: {
				number: configNumber,
				restTime: configRestTime,
			},
		});
	}, [configTimeLimit, configNumber, configRestTime]);

	const closeStep = () => {
		setConfigNumber(0);
		setConfigRestTime(0);
		setStepVisible(false);
	};

	const showStep = () => {
		setStepVisible(true);
		setConfigNumber(2);
	};

	return (
		<FormHOC config={config}>
			<div className="timerFormForTime">
				<InputShow
					type={CHRONO_CONGIG_INPUT_SHOW.TIME}
					value={configTimeLimit}
					name={t('chrono:timeLimit')}
					setConfig={setConfigTimeLimit}
					configName="timeLimit"
				/>

				{config?.timeLimit > 0 && !stepVisible && <ButtonAddStep name={t('chrono:addStep')} onClick={showStep} />}

				{stepVisible && (
					<Step setVisible={closeStep}>
						<div className="stepWrapper">
							<div className="sets">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
									value={configNumber}
									name={t('chrono:sets')}
									setConfig={setConfigNumber}
									configName=""
									min={2}
								/>
							</div>
							<div className="restTime">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.TIME}
									value={configRestTime}
									name={t('chrono:restTime')}
									setConfig={setConfigRestTime}
									configName=""
								/>
							</div>
						</div>
					</Step>
				)}
			</div>
		</FormHOC>
	);
};

export default FormForTime;
