import './ExerciseWodEditor.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxScore from '../../../pages/calendar/calendarSession/CalendarSessionExercices/ExerciseEditor/CheckboxScore';
import { EXPECTED_SCORES } from '../../../../config/type';
import { AlignLeft, Image } from '../../../main/Icons';
import Button from '../../../common/Button';
import { resizeFile } from '../../../../utils/helpers';
import { useHistory, useParams } from 'react-router-dom';

import {
	SessionExerciseBlockType,
	useAddWodBookEntryBlockMutation,
	useSetWodBookEntryExpectedScoresMutation,
	useUpdateWodBookEntryBlocksOrderMutation,
} from '../../../../generated/gql';
import Loader from '../../../main/Loader';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import BlockWodEdit from './BlockWodEdit';

const ExerciseWodEditor = ({ exercise }: any) => {
	const { t } = useTranslation();
	let history = useHistory();
	const { id } = useParams<any>();
	const [exerciseState, setExerciseState] = useState<any>();
	const [scoresSelected, setScoresSelected] = useState<any>([]);
	const [rerender, setRerender] = useState<any>(false);
	const [lastHeight, setLastHeight] = useState<any>();
	const [addWodBookEntryBlock, { error, loading }] = useAddWodBookEntryBlockMutation();
	useErrorModal({ error });
	const [updateWodBookEntryBlocksOrder, { error: error2 }] = useUpdateWodBookEntryBlocksOrderMutation();
	const [setWodBookEntryExpectedScores, { error: error3 }] = useSetWodBookEntryExpectedScoresMutation();

	//Copy data inside state
	useEffect(() => {
		if (exercise) {
			setExerciseState(exercise);
		}
	}, [exercise]);

	useEffect(() => {
		setRerender(false);
	}, [rerender]);

	//Checkbox Scores expected
	useEffect(() => {
		const scoresSelectedArray = [];
		for (const [key, value] of Object.entries(EXPECTED_SCORES)) {
			scoresSelectedArray.push({
				name: value,
				value: !!exercise?.expectedScores?.find((x: any) => x.type === value),
			});
		}
		setScoresSelected(scoresSelectedArray);
	}, [exercise, setScoresSelected]);

	const onScoresSelected = useCallback(
		(index, value) => {
			const expectedScores = scoresSelected
				?.filter((score: any) => score?.value)
				?.map((score: any) => {
					if (score?.value) return { type: score?.name };
				});

			setWodBookEntryExpectedScores({
				variables: {
					entryId: id,
					expectedScores,
				},
			}).then((r) => {
				console.log('r setWodBookEntryExpectedScores', r);
			});
		},
		[scoresSelected, setWodBookEntryExpectedScores, id]
	);

	//Fix height bug when change position of block
	useEffect(() => {
		if (!rerender) {
			let height = document.querySelector('.exercisesListWrapper')?.scrollHeight || 0;
			setLastHeight(height);
		}
	}, [exerciseState, rerender]);

	const addText = () => {
		let exerciseStateUpdate = {
			...exerciseState,
			blocks: [...exerciseState.blocks],
		};

		exerciseStateUpdate.blocks.push({
			content: '',
			order: exerciseStateUpdate.blocks.length + 1,
			type: 'TEXT',
		});

		setExerciseState(exerciseStateUpdate);
	};

	const addImage = (image: any) => {
		return addWodBookEntryBlock({
			variables: {
				entryId: id,
				image,
				type: SessionExerciseBlockType.Image,
			},
		}).then((r: any) => {
			console.log('r addWodBookEntryBlock', r);
		});
	};

	const selectFile = async (e: any) => {
		try {
			const image = await resizeFile(e.target.files[0]);
			await addImage(image);
		} catch (e) {
			console.error('resize error', e);
		}
	};

	const handleBlockPosition = (direction: any, blockId: any) => {
		const blocksArray = exercise?.blocks.map((block: any, key: any) => {
			return {
				blockId: block?.id,
				order: block?.order,
			};
		});

		//find blockId index in blocksArray
		const indexOfBlockToMove = blocksArray?.findIndex((block: any) => block?.blockId === blockId);
		//update order index
		if (direction === 0) {
			blocksArray[indexOfBlockToMove].order += 1;
			blocksArray[indexOfBlockToMove + 1].order -= 1;
		} else {
			blocksArray[indexOfBlockToMove].order -= 1;
			blocksArray[indexOfBlockToMove - 1].order += 1;
		}

		updateWodBookEntryBlocksOrder({
			variables: {
				entryId: id,
				blockOrders: blocksArray,
			},
		}).then((r) => {
			console.log('r updateWodBookEntryBlocksOrder', r);
		});
	};

	return (
		<div className="exerciseEditor">
			<div className="editContainer">
				<div className="exercisesList textSmall weightMedium" style={{ minHeight: lastHeight + 'px' }}>
					{!rerender && (
						<div className="exercisesListWrapper">
							<h1 className="titleL weightSemiBold">{t('calendar:writeExercice')}</h1>
							{exerciseState?.blocks?.map((block: any, index: number) => {
								return (
									<BlockWodEdit
										key={index}
										index={index}
										exerciseState={exerciseState}
										setExerciseState={setExerciseState}
										setRerender={setRerender}
										blockId={block?.id}
										sessionId={id}
										exerciseId={exercise?.id}
										handleBlockPosition={handleBlockPosition}
									/>
								);
							})}
						</div>
					)}
				</div>
				{loading ? (
					<div className="actions textNormal weightMedium noselect">
						<Loader size={80} />
					</div>
				) : (
					<div className="actions textNormal weightMedium noselect">
						<button onClick={addText}>
							<AlignLeft />
							{t('calendar:addText')}
						</button>
						<label htmlFor="imgUpload" className="imgUploadLabel">
							<Image />
							{t('calendar:addImage')}
							<input id="imgUpload" type="file" accept="image/!*" onChange={selectFile} />
						</label>
					</div>
				)}
				<div className="scoreEdit">
					<h1 className="titleL weightMedium">{t('calendar:expectedScores')}</h1>
					<div className="checkboxs">
						{scoresSelected.map((data: any, index: any) => (
							<CheckboxScore
								key={index}
								index={index}
								name={t('calendar:' + data.name)}
								value={data.value}
								onScoresSelected={onScoresSelected}
								setScoresSelected={setScoresSelected}
							/>
						))}
					</div>
				</div>
			</div>
			<div className="actions">
				<div className="left" />
				<div className="right">
					<Button
						buttonClass="btnGreen textNormal weightMedium"
						buttonText={t('commons:validate')}
						onClick={() => {
							history.push(`/wodbook/${id}`);
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default ExerciseWodEditor;
