import { useTranslation } from 'react-i18next';
import { CheckCircle } from '../main/Icons';

import './RegisterTexts.scss';

const RegisterTexts = () => {
	const { t } = useTranslation();

	return (
		<div className="registerTexts">
			<div className="registerTextsWrapper">
				<div className="up">
					<h1 className="title titleXXL weightBold">
						{t('auth:registerRightTitle')}
					</h1>
					<p className="paragraphe titleL weightMedium">
						{t('auth:registerRightText1')}
					</p>
					<div className="benefitsLists">
						<div className="benefitsList">
							<h2 className="titleXL weightMedium">
								{t('auth:registerRightText2')}
							</h2>
							<div className="benefit">
								<CheckCircle />
								<p className="textNormal weightMedium">
									{t('auth:registerRightText3')}
								</p>
							</div>
							<div className="benefit">
								<CheckCircle />
								<p className="textNormal weightMedium">
									{t('auth:registerRightText4')}
								</p>
							</div>
						</div>
						<div className="benefitsList">
							<h2 className="titleXL weightMedium">
								{t('auth:registerRightText5')}
							</h2>

							<div className="benefit">
								<CheckCircle />
								<p className="textNormal weightMedium">
									{t('auth:registerRightText6')}
								</p>
							</div>
							<div className="benefit">
								<CheckCircle />
								<p className="textNormal weightMedium">
									{t('auth:registerRightText7')}
								</p>
							</div>
							<div className="benefit">
								<CheckCircle />
								<p className="textNormal weightMedium">
									{t('auth:registerRightText8')}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="down">
					<p className="textNormal weightMedium">
						{t('auth:registerRightBottom1')}{' '}
						<a href="https://play.google.com/store/apps/details?id=com.branddna.bound2">
							{t('auth:registerRightBottom2')}
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default RegisterTexts;
