import './CalendarSessionExercices.scss';
import React, { useContext, useState } from 'react';
import { CalendarSessionContext } from '../../contexts/CalendarSessionContext';
import Exercice from './Exercice';
import Button from '../../../../common/Button';
import { useTranslation } from 'react-i18next';
import { useCreateSessionExerciseMutation } from '../../../../../generated/gql';
import { useErrorModal } from '../../../../../hooks/useErrorModal';
import { useHistory, useParams } from 'react-router-dom';

const CalendarSessionExercices = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { slug, idSession } = useParams<any>();
	const { dataSession, ownerSession, editMode } = useContext(CalendarSessionContext);

	const [createSessionExercise, { error }] = useCreateSessionExerciseMutation();
	useErrorModal({ error });

	const onClick = () => {
		createSessionExercise({
			variables: {
				sessionId: dataSession?.showProgramSession?.id,
			},
		})
			.then((response) => {
				console.log('response response', response);
				// @ts-ignore
				history.push(
					'/program/' + slug + '/session/' + idSession + '/edit/' + response?.data?.createSessionExercise?.id
				);
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className="calendarSessionExercices">
			{dataSession?.showProgramSession?.exercises?.map((exercice: any) => (
				<Exercice key={exercice.id} data={exercice} />
			))}

			{dataSession?.showProgramSession?.exercises?.length > 0 && ownerSession && editMode && (
				<Button
					onClick={onClick}
					buttonClass="btnGreen btnAddExercise titleL weightMedium"
					buttonText={t('calendar:addExerciceBtn')}
				/>
			)}

			{dataSession?.showProgramSession?.exercises?.length === 0 && (
				<div className="noExercises">
					<div className="noExercisesWrapper">
						<h1 className="titleXL weightBold">{t('calendar:sessionNoExercises')}</h1>

						{ownerSession && (
							<div className="actions">
								<Button
									buttonClass="btnGreen textNormal weightMedium"
									buttonText={t('calendar:addExercise')}
									onClick={onClick}
								/>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default CalendarSessionExercices;
