import { X } from '../../../../main/Icons'
import './Step.scss'

const Step = ({children, setVisible} : any) => {
	return (
		<div className="timerFormStep">

			<button className="closeButton" onClick={() => setVisible(false)}><X /></button>

			{children}


		</div>
	)
}

export default Step
