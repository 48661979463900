import './CalendarPage.scss';
import CalendarApp from './calendar/CalendarApp';

export const CalendarPage = () => {
	return (
		<div id="calendarPage">
			<div className="calendarApp">
				<CalendarApp />
			</div>
		</div>
	);
};
