import './ChatMainSidebar.scss';
import { useListChatChannelsQuery } from '../../../generated/gql';
import Loader from '../../main/Loader';
import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ChannelsList from './ChatInfosSidebar/ChannelsList';
import SearchChat from './ChatInfosSidebar/SearchChat';
import SearchChatResults from './ChatInfosSidebar/SearchChatResults';
import { useDispatch } from 'react-redux';
import { setChatChannelId } from '../../../store/actions';
import { useParams } from 'react-router-dom';
import { useErrorModal } from '../../../hooks/useErrorModal';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const ChatMainSidebar = ({ onChannelSelected, visible, setVisible, channel }: any) => {
	const { error, loading, refetch, data, fetchMore }: any = useListChatChannelsQuery();
	useErrorModal({ error });
	const dispatch = useDispatch();
	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const classNameSidebar = state === VISIBLE ? '' : 'chatMainSidebarOut';

	const [searchInput, setSearchInput] = useState<string>('');
	const [firstime, setFirstime] = useState(true);
	const { slug } = useParams<any>();

	//CLICK OUTSIDE GESTION
	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
			window.addEventListener('click', handleClickOutsideSidebar);
			return () => {
				window.removeEventListener('click', handleClickOutsideSidebar);
			};
		}
	}, [visible, isMedium]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			document.body.offsetHeight;
			setState(VISIBLE);
		}
	}, [state]);

	useEffect(() => {
		if (isMedium) {
			setVisible(true);
		}
	}, [isMedium, setVisible]);

	const handleClickOutsideSidebar = (e: any) => {
		let searchElements = false;
		let endLoop = false;
		let element = e.target;
		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (element.classList[0] === 'chatMainSidebar') {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}
		if (!searchElements && !isMedium) {
			setVisible(false);
		}
	};

	const channels = useMemo(() => {
		if (!data) return [];

		return [...data?.listChatChannels?.result].sort((a, b) => {
			if (!b?.latestMessage?.createdAt) return -1;
			if (!a?.latestMessage?.createdAt) return 1;

			const c: any = new Date(b?.latestMessage?.createdAt);
			const d: any = new Date(a?.latestMessage?.createdAt);

			return c - d;
		});
	}, [data]);

	//Select first channel by default
	useEffect(() => {
		//select first channel by default
		if (data?.listChatChannels?.result[0] && firstime && !slug) {
			onChannelSelected(data?.listChatChannels?.result[0]);
			setFirstime(false);
			dispatch(setChatChannelId(data?.listChatChannels?.result[0]?.id));
		}
	}, [data, onChannelSelected, firstime]);

	if (state === HIDDEN) return null;
	if (loading) return <Loader size={80} />;

	return (
		<div
			className={`
				chatMainSidebar scrollbarThin
				${classNameSidebar}
			`}
		>
			<div className="chatMainSidebarWrapper ">
				<SearchChat searchInput={searchInput} setSearchInput={setSearchInput} />

				{searchInput && searchInput.length > 0 ? (
					<SearchChatResults
						searchInput={searchInput}
						onChannelSelected={onChannelSelected}
						setSearchInput={setSearchInput}
					/>
				) : (
					<ChannelsList
						channels={channels}
						channelSelected={channel}
						onChannelSelected={onChannelSelected}
						setSidebarVisible={setVisible}
					/>
				)}
			</div>
		</div>
	);
};

export default ChatMainSidebar;
