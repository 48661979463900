export const Home = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
			<path d="M9 22V12h6v10" />
		</svg>
	);
};

export const Calendar = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
			<path d="M16 2v4M8 2v4M3 10h18" />
		</svg>
	);
};

export const MessageCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M21 11.5a8.4 8.4 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.4 8.4 0 01-3.8-.9L3 21l1.9-5.7a8.4 8.4 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.4 8.4 0 013.8-.9h.5a8.5 8.5 0 018 8v.5z" />
		</svg>
	);
};

export const Clock = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M12 6v6l4 2" />
		</svg>
	);
};

export const Book = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M4 19.5A2.5 2.5 0 016.5 17H20" />
			<path d="M6.5 2H20v20H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2z" />
		</svg>
	);
};

export const Activity = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M22 12h-4l-3 9L9 3l-3 9H2" />
		</svg>
	);
};

export const User = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
			<circle cx="12" cy="7" r="4" />
		</svg>
	);
};

export const Settings = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<circle cx="12" cy="12" r="3" />
			<path d="M19.4 15a1.65 1.65 0 00.33 1.82l.06.06a2 2 0 010 2.83 2 2 0 01-2.83 0l-.06-.06a1.65 1.65 0 00-1.82-.33 1.65 1.65 0 00-1 1.51V21a2 2 0 01-2 2 2 2 0 01-2-2v-.09A1.65 1.65 0 009 19.4a1.65 1.65 0 00-1.82.33l-.06.06a2 2 0 01-2.83 0 2 2 0 010-2.83l.06-.06a1.65 1.65 0 00.33-1.82 1.65 1.65 0 00-1.51-1H3a2 2 0 01-2-2 2 2 0 012-2h.09A1.65 1.65 0 004.6 9a1.65 1.65 0 00-.33-1.82l-.06-.06a2 2 0 010-2.83 2 2 0 012.83 0l.06.06a1.65 1.65 0 001.82.33H9a1.65 1.65 0 001-1.51V3a2 2 0 012-2 2 2 0 012 2v.09a1.65 1.65 0 001 1.51 1.65 1.65 0 001.82-.33l.06-.06a2 2 0 012.83 0 2 2 0 010 2.83l-.06.06a1.65 1.65 0 00-.33 1.82V9a1.65 1.65 0 001.51 1H21a2 2 0 012 2 2 2 0 01-2 2h-.09a1.65 1.65 0 00-1.51 1z" />
		</svg>
	);
};

export const Pen = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M17 3a2.8 2.8 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
		</svg>
	);
};

export const ArrowRight = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M5 12h14M12 5l7 7-7 7" />
		</svg>
	);
};

export const Add = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M12 5v14M5 12h14" />
		</svg>
	);
};

export const Users = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
			<circle cx="9" cy="7" r="4" />
			<path d="M23 21v-2a4 4 0 00-3-3.87M16 3.13a4 4 0 010 7.75" />
		</svg>
	);
};

export const MapPin = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
			<circle cx="12" cy="10" r="3" />
		</svg>
	);
};

export const BookOpen = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M2 3h6a4 4 0 014 4v14a3 3 0 00-3-3H2zM22 3h-6a4 4 0 00-4 4v14a3 3 0 013-3h7z" />
		</svg>
	);
};

export const Star = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<path d="M12 2l3 6.3 7 1-5 4.8 1.2 7-6.2-3.3L5.8 21 7 14.1 2 9.3l7-1L12 2z" />
		</svg>
	);
};

export const ZoomIn = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<circle cx="11" cy="11" r="8" />
			<path d="M21 21l-4-4M11 8v6M8 11h6" />
		</svg>
	);
};

export const Lock = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svgIcon">
			<rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
			<path d="M7 11V7a5 5 0 0110 0v4" />
		</svg>
	);
};

export const Send = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
		</svg>
	);
};

export const Play = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M5 3l14 9-14 9V3z" />
		</svg>
	);
};

export const AtSign = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="4" />
			<path d="M16 8v5a3 3 0 006 0v-1a10 10 0 10-4 8" />
		</svg>
	);
};

export const EyeOff = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22" />
		</svg>
	);
};

export const Eye = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
			<circle cx="12" cy="12" r="3" />
		</svg>
	);
};

export const Mail = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M4 4h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6c0-1.1.9-2 2-2z" />
			<path d="M22 6l-10 7L2 6" />
		</svg>
	);
};

export const Check = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M20 6L9 17l-5-5" />
		</svg>
	);
};

export const CheckCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M22 11v1a10 10 0 11-6-9.1" />
			<path d="M22 4L12 14l-3-3" />
		</svg>
	);
};

export const CheckCircleEmpty = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 16 16">
			<path d="M8 14.667A6.667 6.667 0 108 1.333a6.667 6.667 0 000 13.334z" />
		</svg>
	);
};

export const Search = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="11" cy="11" r="8" />
			<path d="M21 21l-4-4" />
		</svg>
	);
};

export const FileText = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z" />
			<path d="M14 2v6h6M16 13H8M16 17H8M10 9H8" />
		</svg>
	);
};

export const Headphones = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M3 18v-6a9 9 0 0118 0v6" />
			<path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z" />
		</svg>
	);
};

export const CreditCard = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<rect x="1" y="4" width="22" height="16" rx="2" ry="2" />
			<path d="M1 10h22" />
		</svg>
	);
};

export const HelpCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M9 9a3 3 0 016 1c0 2-3 3-3 3M12 17h0" />
		</svg>
	);
};

export const ChevronDown = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M6 9l6 6 6-6" />
		</svg>
	);
};

export const ChevronUp = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="m18 15-6-6-6 6" />
		</svg>
	);
};

export const ChevronRight = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M9 18l6-6-6-6" />
		</svg>
	);
};

export const ChevronLeft = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M15 18l-6-6 6-6" />
		</svg>
	);
};

export const LogOut = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9" />
		</svg>
	);
};

export const Frown = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M16 16s-1-2-4-2-4 2-4 2M9 9h0M15 9h0" />
		</svg>
	);
};

export const Trash2 = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
		</svg>
	);
};

export const Instagram = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
			<path d="M16 11a4 4 0 11-3-3 4 4 0 013 3zM18 7h0" />
		</svg>
	);
};

export const X = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M18 6L6 18M6 6l12 12" />
		</svg>
	);
};

export const AlertCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M12 8v4M12 16h0" />
		</svg>
	);
};

export const MenuDots = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 287.8 287.8">
			<path d="M144 230a29 29 0 110 58 29 29 0 010-58zm0-115a29 29 0 110 58 29 29 0 010-58zm0-115a29 29 0 110 58 29 29 0 010-58z" />
		</svg>
	);
};

export const BellOff = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M13.7 21a2 2 0 01-3.4 0M18.6 13a17.9 17.9 0 01-.6-5M6.3 6.3A5.9 5.9 0 006 8c0 7-3 9-3 9h14M18 8a6 6 0 00-9.3-5M1 1l22 22" />
		</svg>
	);
};

export const MoreHorizontal = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="1" />
			<circle cx="19" cy="12" r="1" />
			<circle cx="5" cy="12" r="1" />
		</svg>
	);
};

export const Flag = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7" />
		</svg>
	);
};

export const Award = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="8" r="7" />
			<path d="M8.2 13.9L7 23l5-3 5 3-1.2-9.1" />{' '}
		</svg>
	);
};

export const Edit = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
			<path d="M18.5 2.5a2.1 2.1 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
		</svg>
	);
};

export const Copy = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
			<path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
		</svg>
	);
};

export const AlignLeft = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M17 10H3M21 6H3M21 14H3M17 18H3" />
		</svg>
	);
};

export const Image = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
			<circle cx="8.5" cy="8.5" r="1.5" />
			<path d="m21 15-5-5L5 21" />
		</svg>
	);
};

export const Heart = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M20.8 4.6a5.5 5.5 0 0 0-7.7 0l-1.1 1-1-1a5.5 5.5 0 0 0-7.8 7.8l1 1 7.8 7.8 7.8-7.7 1-1.1a5.5 5.5 0 0 0 0-7.8z" />
		</svg>
	);
};

export const Plus = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M12 5v14M5 12h14" />
		</svg>
	);
};

export const Save = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
			<path d="M17 21v-8H7v8M7 3v5h8" />
		</svg>
	);
};

export const StopCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M9 9h6v6H9z" />
		</svg>
	);
};

export const PauseCircle = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<circle cx="12" cy="12" r="10" />
			<path d="M10 15V9M14 15V9" />
		</svg>
	);
};

export const Maximize = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M15 3h6v6M9 21H3v-6M21 3l-7 7M3 21l7-7" />
		</svg>
	);
};

export const Price = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			className="feather feather-dollar-sign"
		>
			<line x1="12" y1="1" x2="12" y2="23"></line>
			<path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
		</svg>
	);
};

export const Warning = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
			className="feather feather-alert-triangle"
		>
			<path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
			<line x1="12" y1="9" x2="12" y2="13"></line>
			<line x1="12" y1="17" x2="12.01" y2="17"></line>
		</svg>
	);
};

export const Clipboard = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className="svgIcon" viewBox="0 0 24 24">
			<path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
			<rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
		</svg>
	);
};
