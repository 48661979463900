import './SelectedWodTemplate.scss';
import moment from 'moment';
import { SessionExerciseBlockType, useUpdateWodBookEntryMutation } from '../../../../generated/gql';
import Modal from '../../../common/Modal';
import { useContext, useEffect, useState } from 'react';
import Scores from './Scores';
import { Check, Edit, MenuDots, Pen, Trash2, X } from '../../../main/Icons';
import Dropdown from '../../../common/Dropdown';
import { useTranslation } from 'react-i18next';
import { WodContext } from '../Context';
import { WODTYPE } from '../../../../config/type';
import { useHistory } from 'react-router-dom';
import ModalDeleteWod from './ModalDeleteWod';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const SelectedWodTemplate = ({ createdAt, name, exercises, scores, scoreExpected, type, wodId, publicWod }: any) => {
	const history = useHistory();
	const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
	const { selectedWod, wodEditMode, setWodEditMode } = useContext(WodContext);

	const [wodNameEdit, setWodNameEdit] = useState(false);
	const [wodName, setWodName] = useState<string>(name);
	const [wodNameInput, setWodNameInput] = useState<string>(name);

	const [modalDeleteWod, setModalDeleteWod] = useState(false);
	const [updateWodBookEntry, { error, loading }]: any = useUpdateWodBookEntryMutation();
	useErrorModal({ error });
	const { t } = useTranslation();

	useEffect(() => {
		setWodEditMode(false);
		setWodNameEdit(false);
	}, [selectedWod, setWodEditMode]);

	const onClickUpdateName = () => {
		if (loading) return;

		updateWodBookEntry({
			variables: {
				exerciseName: wodNameInput,
				entryId: wodId,
			},
		})
			.then((r: any) => {
				console.log('updateWodBookEntry', r);
				setWodNameEdit(false);
			})
			.catch((e: any) => console.log('e', e));
	};

	return (
		<div className="selectedWodTemplate">
			<header>
				<div className="left">
					<h2 className="titleL weightMedium">{moment(createdAt).format('L')}</h2>

					<h1 className="titleXXL weightBold">
						<div className="wodName">
							{!wodNameEdit ? (
								name
							) : (
								<form>
									<input
										type="text"
										className="titleXL weightMedium"
										value={wodNameInput}
										onChange={(e: any) => setWodNameInput(e.target.value)}
									/>
								</form>
							)}

							{wodEditMode && (
								<div className="actions">
									{!wodNameEdit ? (
										<button
											className="btnDropdownWarning"
											onClick={() => {
												setWodNameEdit(!wodNameEdit);
												setWodName(name);
												setWodNameInput(name);
											}}
										>
											<Pen />
										</button>
									) : (
										<>
											<button className="btnDropdownGreen" onClick={onClickUpdateName}>
												<Check />
											</button>
											<button className="btnDropdownRed" onClick={() => setWodNameEdit(!wodNameEdit)}>
												<X />
											</button>
										</>
									)}
								</div>
							)}
						</div>
					</h1>
				</div>
				{type === WODTYPE.PRIVATE && (
					<div className="right">
						<button className="btn btnCentered" onClick={() => setMenuDropdownVisible(true)}>
							<MenuDots />
						</button>
						<Dropdown
							visible={menuDropdownVisible}
							setVisible={setMenuDropdownVisible}
							style={{
								top: '36px',
								right: '0px',
							}}
						>
							<div className="actions textMedium weightMedium">
								<button
									className="delete btnDropdown btnDropdownWarning"
									onClick={() => {
										setWodEditMode((prev: any) => !prev);
										setMenuDropdownVisible(false);
									}}
								>
									<Edit />
									{t('commons:edit')}
								</button>
								<button className="delete btnDropdown btnDropdownRed" onClick={() => setModalDeleteWod(true)}>
									<Trash2 />
									{t('commons:delete')}
								</button>
							</div>
						</Dropdown>

						<ModalDeleteWod visible={modalDeleteWod} setVisible={setModalDeleteWod} wodId={wodId} />
					</div>
				)}
			</header>

			<div className="exercisesListWrapper">
				<div className="exercisesList">
					{exercises?.map((exercise: any) =>
						exercise.type === SessionExerciseBlockType.Text ? (
							<div key={exercise.id} className="text textMedium weightMedium contentBloc">
								{exercise.content}
							</div>
						) : exercise.type === SessionExerciseBlockType.Image ? (
							<ImageBloc key={exercise.id} url={exercise.image.url} />
						) : null
					)}
				</div>
				<div className="menu">
					{wodEditMode && (
						<button className="btnDropdownWarning" onClick={() => history.push('/wodbook/edit/' + selectedWod)}>
							<Edit />
						</button>
					)}
				</div>
			</div>
			{scoreExpected?.length > 0 && (
				<div className="scores">
					<Scores scores={scores} scoreExpected={scoreExpected} wodId={wodId} publicWod={publicWod} />
				</div>
			)}
		</div>
	);
};

const ImageBloc = ({ url }: any) => {
	const [imgModal, setImgModal] = useState<boolean>(false);

	return (
		<div
			className="imageExercise contentBloc"
			onClick={() => {
				setImgModal(true);
			}}
		>
			<img src={url} alt="" />
			<Modal visible={imgModal} setVisible={setImgModal}>
				<div className="imgModal noselect">
					<img src={url} alt="" />
				</div>
			</Modal>
		</div>
	);
};

export default SelectedWodTemplate;
