import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CHRONO_ACTIVITIES } from '../../../../config/type';
import { secToArr } from '../../../../utils/chrono';
import { TimerConfigContext } from '../contexts/TimerConfigContext';
import { TimerConfigDataContext } from '../contexts/TimerConfigDataContext';

import './Navbar.scss';

const Navbar = () => {
	const { t } = useTranslation();
	const [navbarPhrase, setNavbarPhrase] = useState('');
	const { chronoType, setChronoType } = useContext(TimerConfigContext);
	const { timerConfig } = useContext(TimerConfigDataContext);


	//Position on timer save
	useEffect(() => {
		if (timerConfig?.type) {
			setChronoType({ name: timerConfig?.type });
		}
	}, []);

	const timeArrToPhrase = (arr: any) => {
		let timePhrase = '';
		if (arr[0] !== '00') {
			timePhrase += arr[0] + t('chrono:minutesValue');
		}
		if (arr[1] !== '00') {
			if (timePhrase.length > 0) timePhrase += ' ';
			timePhrase += arr[1] + t('chrono:secondsValue');
		}
		return timePhrase;
	};

	useEffect(() => {
		//Generate navbarPhrase
		let phrase: string = '';
		//AMRAP
		if (timerConfig?.type === CHRONO_ACTIVITIES[0]?.name) {
			const roundTimeArr: any = secToArr(timerConfig.roundConfiguration.timeLimit);
			phrase = t('chrono:AMRAPPhrase1') + ' ' + timeArrToPhrase(roundTimeArr) + '.';

			//If set
			if (timerConfig.setConfiguration.number > 0) {
				phrase += ' ' + t('chrono:AMRAPPhrase2');
			}
			//If rest
			if (timerConfig.setConfiguration.restTime > 0) {
				phrase +=
					' ' + t('chrono:AMRAPPhrase3') + ' ' + timeArrToPhrase(secToArr(timerConfig.setConfiguration.restTime));
				phrase += ' ' + t('chrono:AMRAPPhrase4');
			}
			phrase += '.';
		}
		//EMOM
		else if (timerConfig?.type === CHRONO_ACTIVITIES[1].name) {
			phrase =
				t('chrono:EMOMPhrase1') +
				' ' +
				timeArrToPhrase(secToArr(timerConfig.emoms[0].everyTime)) +
				' ' +
				t('chrono:EMOMPhrase2') +
				' ' +
				timerConfig.emoms[0].roundsNumber +
				' ' +
				t('chrono:EMOMPhrase3');
		}
		//FOR TIME
		else if (timerConfig?.type === CHRONO_ACTIVITIES[2]?.name) {
			phrase += t('chrono:FORTIMEPhrase1');

			if (timerConfig?.timeLimit > 0) {
				phrase += ' ' + t('chrono:FORTIMEPhrase2') + ' ' + timeArrToPhrase(secToArr(timerConfig?.timeLimit));

				if (timerConfig?.setConfiguration?.number > 0) {
					phrase +=
						' ' +
						t('chrono:FORTIMEPhrase3') +
						' ' +
						timerConfig?.setConfiguration?.number +
						' ' +
						t('chrono:FORTIMEPhrase4');
				}

				if (timerConfig?.setConfiguration?.restTime) {
					phrase +=
						' ' +
						t('chrono:FORTIMEPhrase5') +
						' ' +
						timeArrToPhrase(secToArr(timerConfig?.setConfiguration?.restTime)) +
						' ' +
						t('chrono:FORTIMEPhrase6');
				}
			}

			phrase += '.';
		}
		//TABATA
		else if (timerConfig?.type === CHRONO_ACTIVITIES[3]?.name) {
			phrase +=
				timeArrToPhrase(secToArr(timerConfig?.roundConfiguration?.timeLimit)) +
				' ' +
				t('chrono:TABATAPhrase1') +
				' ' +
				timeArrToPhrase(secToArr(timerConfig?.roundConfiguration?.restTime)) +
				' ' +
				t('chrono:TABATAPhrase2') +
				' ' +
				timerConfig?.roundConfiguration?.number +
				' ' +
				t('chrono:TABATAPhrase3');

			if (timerConfig?.setConfiguration?.number > 0) {
				phrase +=
					' ' +
					t('chrono:TABATAPhrase4') +
					' ' +
					timerConfig?.setConfiguration?.number +
					' ' +
					t('chrono:TABATAPhrase5');

				if (timerConfig?.setConfiguration?.restTime > 0) {
					phrase +=
						' ' +
						t('chrono:TABATAPhrase6') +
						' ' +
						timeArrToPhrase(secToArr(timerConfig?.setConfiguration?.restTime)) +
						' ' +
						t('chrono:TABATAPhrase7');
				}

				phrase += '.';
			}
		}
		//AHAP
		else if (timerConfig?.type === CHRONO_ACTIVITIES[4]?.name) {
			phrase += t('chrono:AHAPPhrase1') + ' ' + timeArrToPhrase(secToArr(timerConfig?.ahaps[0]?.timeLimit));
			phrase += '.';
		}

		setNavbarPhrase(phrase);
	}, [timerConfig, t]);

	return (
		<div className="timerConfigNavbar">
			<nav className="menuActivities">
				{CHRONO_ACTIVITIES.map((activity: any, key: number) => (
					<button
						key={key}
						className="titleXL noselect"
						style={{
							borderBottom: activity.name === chronoType?.name ? '2px solid #05DBC3' : 'unset',
							paddingBottom: activity.name === chronoType?.name ? 'unset' : '2px',
						}}
						onClick={() => setChronoType(activity)}
					>
						{activity.name}
					</button>
				))}
			</nav>

			<div className="description textNormal">{navbarPhrase}</div>
		</div>
	);
};

export default Navbar;
