import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { focusNavbarSearch } from '../../../../store/actions';

import { Play, ArrowRight, Book, User, Mail, Send } from '../../../main/Icons';
import Button from '../../../common/Button';
import ButtonBig from '../../../common/ButtonBig';
import WidgetBase from '../../../common/WidgetBase';

import './Welcome.scss';
import { UserIcon } from '../../../main/UserIcon';
import React from 'react';
import { useShowUserLazyQuery, useShowUserQuery } from '../../../../generated/gql';

const Welcome = ({ data, dataEmpty }: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className="welcomeWrapper">
			<h1 className="titleXXXL weightBold">{t('home:welcome')}</h1>
			{dataEmpty && (
				<div className="welcomeWidgetWrapper">
					<WidgetBase className="welcomeMessage" padding={false}>
						<div className={`welcomeMessageWidgetWrapper ${dataEmpty ? 'backgroundGreen' : 'backgroundBlue'}`}>
							<h1 className="titleXXL weightBold">
								{dataEmpty ? t('home:startOnHustleUpTitle') : t('home:welcomeTitle')}
							</h1>
							<p className="titleL weightNormal">{dataEmpty ? t('home:startOnHustleUpBody') : t('home:welcomeBody')}</p>

							{dataEmpty ? (
								<Button
									buttonText={t('home:findProgram')}
									buttonClass="btnDuo btnReversed btnGreenLight textNormal weightNormal"
									ButtonIcon={ArrowRight}
									onClick={() => {
										dispatch(focusNavbarSearch(true));
									}}
								/>
							) : (
								<div />
								/*<Button
                                    buttonText={t('home:doExercicesButton')}
                                    buttonClass="btnDuo btnReversed btnBlueLight textNormal weightNormal"
                                    ButtonIcon={Play}
                                    onClick={() => {}}
                                />*/
							)}
						</div>
					</WidgetBase>

					<div className="welcomeActions">
						{dataEmpty ? (
							<WidgetBase className="welcomeActionsDataEmpty">
								<div className="welcomeActionsWrapper">
									<h1 className="titleXL weightBold">{t('home:becomeCoach')}</h1>
									<p className="textSmall weightMedium">{t('home:becomeCoachBody')}</p>
									<Button
										buttonText={t('home:buttonProgramCreate')}
										buttonClass="btnDuo btnReversed btnGreenLight textNormal weightNormal"
										ButtonIcon={ArrowRight}
										onClick={() => history.push('/program/add')}
									/>
								</div>
							</WidgetBase>
						) : (
							<div className="welcomeActionsButtons">
								<HustleUpOfficial />
								{/*<ButtonBig Icon={Book} iconBackground="#FFAF14" text={t('home:buttonWODBook')} />
							<ButtonBig
								Icon={User}
								iconBackground="#ABB5CD"
								text={t('home:buttonYourProfil')}
								onClick={() => history.push('/profile')}
							/>*/}
							</div>
						)}
					</div>
				</div>
			)}
			<div className="welcomeWidgetWrapper">
				<WidgetBase className="welcomeMessage" padding={false}>
					<div className={`welcomeMessageWidgetWrapper ${'backgroundBlue'}`}>
						<h1 className="titleXXL weightBold">{t('home:welcomeTitle')}</h1>
						<p className="titleL weightNormal">{t('home:welcomeBody')}</p>
					</div>
				</WidgetBase>

				<div className="welcomeActions">
					<div className="welcomeActionsButtons">
						<HustleUpOfficial />
					</div>
				</div>
			</div>
		</div>
	);
};

const HustleUpOfficial = () => {
	const hustleUpId = process.env.REACT_APP_HUSTLE_UP_OFFI_ID;
	const { t } = useTranslation();
	const history = useHistory();
	const { data } = useShowUserQuery({
		variables: {
			id: hustleUpId,
		},
	});

	if (!data?.showUser) return null;

	return (
		<div className="coach">
			<div className="coachInfosWrapper">
				<div className="avatarCoach">
					<UserIcon
						url={data?.showUser?.profile_picture?.url}
						text={
							// @ts-ignore
							data?.showUser?.firstName?.charAt(0) + data?.showUser?.firstName?.charAt(1)
						}
						size="80"
					/>
				</div>

				<div className="infosCoach">
					{/*<div className="coachDeco textNormal weightSemiBold">{t('programs:details:coach')}</div>*/}
					<h1 className="titleXL weightBold">
						{/*data?.showUser?.lastName + ' ' + data?.showUser?.firstName}*/ t('commons:customerService')}
					</h1>
					<h2 className="textMedium weightMedium">@{data?.showUser?.nickname}</h2>
				</div>
			</div>

			<ButtonBig
				Icon={Send}
				iconBackground="#ABB5CD"
				text={t('home:contact')}
				onClick={() => history.push('/chat/' + hustleUpId)}
			/>
		</div>
	);
};

export default Welcome;
