import Button2 from '../common/Button';

import './WidgetTitle.scss';

const WidgetTitle = ({
	title,
	buttonParams = {},
	buttonClass,
	buttonText,
	ButtonIcon,
	onClick,
}: any) => {
	return (
		<div
			className="widgetTitle"
			style={{
				transform: buttonParams?.Icon ? 'translateY(-9px)' : 'translateY(0)',
			}}
		>
			<h1 className="title titleXL weightBold">{title}</h1>
			{ButtonIcon && (
				<div className="buttonAction">
					<Button2
						buttonClass={buttonClass}
						buttonText={buttonText}
						ButtonIcon={ButtonIcon}
						onClick={onClick}
					/>
				</div>
			)}
		</div>
	);
};

export default WidgetTitle;
