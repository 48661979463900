import './FormAhap.scss'
import { useContext, useEffect, useState } from "react";
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW, CHRONO_SETTYPE } from "../../../../../config/type";
import FormHOC from "./FormHOC"
import InputShow from '../FormsElements/InputShow';
import { useTranslation } from 'react-i18next';
import ButtonAddStep from '../FormsElements/ButtonAddStep';
import Step from '../FormsElements/Step';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';

const FormAhap = () => {
	const { t } = useTranslation();
	const {timerConfig} : {timerConfig:any} = useContext(TimerConfigDataContext)

	const [timeLimit, setTimeLimit] = useState(timerConfig?.ahaps?.map((timerConfigU : any) => timerConfigU.timeLimit) || [300])
	const [restTime, setRestTime] = useState(timerConfig?.ahaps?.map((timerConfigU : any) => timerConfigU.restTime) || [0])

	const [config, setConfig] = useState<any>(timerConfig);

	useEffect(() => {
		if (timeLimit.length === restTime.length) {
			const ahaps = timeLimit.map((ahap: any, i: number) => ({
				timeLimit: timeLimit[i],
				restTime: restTime[i]
			}));

			setConfig({
				type: CHRONO_ACTIVITIES[4].name,
				ahaps,
			});
			// document.querySelector('.timerApp')?.scrollTo(0, 10000)
		}
	}, [timeLimit, restTime]);


	const handleClickAddAhap = () => {
		setTimeLimit((o : any) => [...o, 300]);
		setRestTime((o : any) => [...o, 0]);
	};


	const closeStep = (i: number) => {
		setTimeLimit((o: any) => {
			const update = [...o];
			update.splice(i, 1);
			return update;
		});
		setRestTime((o: any) => {
			const update = [...o];
			update.splice(i, 1);
			return update;
		});
	};



	return (
		<FormHOC config={config}>
			<div className="timerFormAhap">

				<InputShow
					type={CHRONO_CONGIG_INPUT_SHOW.TIME}
					value={timeLimit[0]}
					name={t('chrono:roundTime')}
					setConfig={setTimeLimit}
					setType={CHRONO_SETTYPE.MULTIPLE}
					index={0}
					configName=""
					min={1}
				/>

				{config?.ahaps?.length === 1 && <ButtonAddStep name={t('chrono:addStep')} onClick={() => handleClickAddAhap()} />}


				{config?.ahaps?.length > 1 &&
					config?.ahaps?.map((ahap: any, i: number) => {
						if (i > 0) {
							return (
								<div key={i}>
									<Step
										setVisible={() => {
											closeStep(i);
										}}
									>
										<div className="stepLine">
											<div className="stepRoundTime">
												<InputShow
													type={CHRONO_CONGIG_INPUT_SHOW.TIME}
													value={ahap.timeLimit}
													name={t('chrono:roundTime')}
													setConfig={setTimeLimit}
													setType={CHRONO_SETTYPE.MULTIPLE}
													index={i}
													configName=""
													min={1}
												/>
											</div>

											<div className="stepRestTime">
												<InputShow
													type={CHRONO_CONGIG_INPUT_SHOW.TIME}
													value={ahap.restTime}
													name={t('chrono:restTime')}
													setConfig={setRestTime}
													setType={CHRONO_SETTYPE.MULTIPLE}
													index={i}
													configName=""
												/>
											</div>
										</div>
									</Step>
									{i === config.ahaps.length - 1 && (
										<ButtonAddStep name={t('chrono:addStep')} onClick={() => handleClickAddAhap()} />
									)}
								</div>
							);
						} else {
							return null;
						}
					})}
			</div>
		</FormHOC>
	)
}

export default FormAhap
