import './ChatInfosSidebar.scss';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../../main/UserIcon';
import { ProgIcon } from '../../main/ProgIcon';
import Button from '../../common/Button';
import { BellOff, LogOut, MenuDots, MoreHorizontal, Send, Trash2 } from '../../main/Icons';
import UserCard from '../../common/UserCard';
import { useEffect, useState } from 'react';
import useUser from '../../../hooks/user/useUser';
import Dropdown from '../../common/Dropdown';
import { useHistory } from 'react-router-dom';
import {
	ListChatChannelMembersDocument,
	useListChatChannelMembersLazyQuery,
	useRemoveChatMemberMutation,
} from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import update from 'immutability-helper';
import { useMediaQuery } from 'react-responsive';


const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;


const ChatInfosSidebar = ({ channel, leaveModalVisible, setLeaveModalVisible, visible, setVisible }: any) => {
	const { t } = useTranslation();
	const history = useHistory();

	const isDesktop = useMediaQuery({ query: '(min-width: 1500px)' });
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const classNameSidebar = state === VISIBLE ? '' : 'chatInfoSidebarOut';

	const [coach, setCoach] = useState<any>();
	const [coachMe, setCoachMe] = useState<any>(false);
	const [memberList, setMemberList] = useState<any>([]);
	const [menuGroupVisible, setMenuGroupVisible] = useState(false);
	const userProfil = useUser();
	//fix for joinChannel querry who order member in a different order
	const [isUserFirstMember] = useState<any>(channel?.members[0]?.id === userProfil?.user?.id);

	const [listChatChannelMembers, { error, data }]: any = useListChatChannelMembersLazyQuery();
	useErrorModal({ error });

	useEffect(() => {
		if (channel?.type === 'PROGRAM') {
			listChatChannelMembers({
				variables: {
					channelId: channel?.id,
				},
			});
		}
	}, [channel, listChatChannelMembers]);

	// Member list merge // Coach detection
	useEffect(() => {
		if (channel?.type === 'PROGRAM') {
			// Coach is me ?

			if (channel.coach === userProfil?.user?.id) {
				setCoach(userProfil.user);
				setMemberList(channel.members);
				setCoachMe(true);
			} else {
				if (data?.listChatChannelMembers?.result) {
					const dataMemberList = [...data.listChatChannelMembers.result];
					const coachIndex = dataMemberList.findIndex((e: any) => e.isCoach);
					dataMemberList.splice(coachIndex, 1);
					setMemberList(dataMemberList);
					setCoach(data?.listChatChannelMembers.result[coachIndex]);
					setCoachMe(false);
				}
			}
		}
	}, [channel, data?.listChatChannelMembers, userProfil.user]);



	// ANIMATION MOBILE
	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
			window.addEventListener('click', handleClickOutsideSidebar);
			return () => {
				window.removeEventListener('click', handleClickOutsideSidebar);
			};
		}
	}, [visible, isDesktop]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			document.body.offsetHeight;
			setState(VISIBLE);
		}
	}, [state]);

	useEffect(() => {
		if (isDesktop) {
			setVisible(false);
		}
	}, [isDesktop, setVisible]);

	const handleClickOutsideSidebar = (e: any) => {
		let searchElements = false;
		let endLoop = false;
		let element = e.target;
		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (element.classList[0] === 'chatInfosSidebar') {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}
		if (!searchElements && !isDesktop) {
			setVisible(false);
		}
	};



	if (!channel) return <div className="chatInfosSidebar" />;

	return (
		<div 
			className={`
			chatInfosSidebar scrollbarThin
				${classNameSidebar}
			`}
		>
			<div className="top">
				{channel?.type === 'PROGRAM' && (
					<div className="actionsProgram">
						<Button
							buttonClass="btnGreyContrast textNormal weightMedium"
							buttonText={t('chat:viewProgram')}
							onClick={() => history.push('/program/')}
						/>
						<Button
							ButtonIcon={MoreHorizontal}
							buttonClass="btnGreyContrast btnCentered"
							onClick={() => {
								setMenuGroupVisible(!menuGroupVisible);
							}}
						/>

						<Dropdown
							visible={menuGroupVisible}
							setVisible={setMenuGroupVisible}
							style={{
								right: 0,
								top: '40px',
							}}
						>
							<button className="textSmall weightMedium">
								<LogOut />
								{t('chat:leaveConversation')}
							</button>
						</Dropdown>
					</div>
				)}

				<div
					className="infos"
					style={{
						marginTop: channel?.type === 'DUO' ? '8vh' : 'unset',
					}}
				>
					{channel?.type === 'DUO' ? (
						<UserIcon
							url={channel?.avatar?.url}
							text={
								channel?.members[isUserFirstMember ? 1 : 0]?.firstName.charAt(0) +
								channel?.members[isUserFirstMember ? 1 : 0]?.lastName.charAt(0)
							}
							size={100}
						/>
					) : channel?.type === 'PROGRAM' ? (
						<div className="progIcon">
							<ProgIcon url={channel?.avatar?.url} />
						</div>
					) : null}

					<h1 className="titleXL weightBold">{channel?.name}</h1>

					{channel?.members && (
						<h2 className="textSmall weightMedium">@{channel?.members[isUserFirstMember ? 1 : 0]?.nickname}</h2>
					)}
				</div>

				{channel?.type === 'DUO' && (
					<div className="actions">
						<Button
							onClick={() => history.push('/profile/' + channel?.members[isUserFirstMember ? 1 : 0]?.id)}
							buttonClass="btnGreyContrast textNormal weightMedium"
							buttonText={t('chat:viewProfile')}
						/>
					</div>
				)}

				{channel?.type === 'PROGRAM' && (
					<>
						<div className="memberListSeparator" />
						<div className="memberList scrollbarThin">
							{coach && (
								<div className="coach">
									<MemberGroup member={coach} coach={true} coachMe={coachMe} channel={channel} />
								</div>
							)}
							{memberList &&
								memberList.map((member: any) => (
									<MemberGroup key={member.id} member={member} coachMe={coachMe} channel={channel} />
								))}
						</div>
					</>
				)}
			</div>

			{channel?.type === 'DUO' && (
				<div className="bottom">
					<div className="actions textNormal weightMedium">
						{/*<button className="chatMenuItem">
							<BellOff />
							{t('chat:hideNotification')}
						</button>*/}

						<button className="chatMenuItem redItem" onClick={() => setLeaveModalVisible(true)}>
							<Trash2 />
							{t('chat:leaveConversation')}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

const MemberGroup = ({ member, coach, coachMe, channel }: any) => {
	const [visible, setVisible] = useState(false);

	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div className="chatMemberGroup">
			<UserCard
				data={member}
				imgSize={38}
				classNameName="textMedium weightMedium"
				classNameNickname="textSmall weightMedium"
				coach={coach}
				onClick={() => {}}
			/>

			<div className="actions">
				<div
					onClick={() => {
						setVisible(!visible);
					}}
				>
					<MenuDots />
				</div>

				<Dropdown
					visible={visible}
					setVisible={setVisible}
					style={{
						right: 0,
						top: '20px',
						zIndex: 44000000000,
					}}
				>
					<div className="dropdownActions textSmall weightMedium">
						<button onClick={() => history.push('/chat/' + member?.id)}>
							<Send />
							{t('chat:sendMessage')}
						</button>

						{/*{coachMe && <RemoveUserFromChatButton memberId={member?.id} channelId={channel?.id} />}*/}
					</div>
				</Dropdown>
			</div>
		</div>
	);
};

const RemoveUserFromChatButton = ({ memberId, channelId }: any) => {
	const { t } = useTranslation();
	const [removeChatMember, { error }] = useRemoveChatMemberMutation({
		update(cache, { data }) {
			// If the message is the latest one, update
			const dataQuery = cache.readQuery({
				query: ListChatChannelMembersDocument,
				variables: {
					channelId,
				},
			});

			if (!dataQuery) return;

			// @ts-ignore
			const { listChatChannelMembers } = dataQuery;
			const userDeleteIndex = listChatChannelMembers.result.findIndex((c: any) => c.id === data?.removeChatMember);

			const newListChatChannelMembers = update(listChatChannelMembers, {
				result: {
					$splice: [[userDeleteIndex, 1]],
				},
				total: {
					$apply: (x: number) => x - 1,
				},
			});

			console.log('newListChatChannelMembers', newListChatChannelMembers);

			cache.writeQuery({
				query: ListChatChannelMembersDocument,
				variables: {
					channelId,
				},
				data: {
					listChatChannelMembers: newListChatChannelMembers,
				},
			});
		},
	});
	useErrorModal({ error });

	const onclick = () => {
		removeChatMember({
			variables: {
				channelId,
				memberId,
			},
		}).then((r) => console.log('r', r));
	};

	return (
		<button onClick={onclick}>
			<Trash2 />
			{t('chat:removeFromChat')}
		</button>
	);
};

export default ChatInfosSidebar;
