import './Calendar.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CalendarDatesContext } from '../contexts/CalendarDatesContext';
import moment from 'moment';
import { CalendarMainSidebarContext } from '../contexts/CalendarMainSidebarContext';
import { CalendarContext } from '../contexts/CalendarContext';
import { CalendarMainSidebarVisible } from '../_types';
import { DateShowFormat } from '../_types';
import CalendarModalSessionMove from './CalendarModalSessionMove';
import Day from './Day';
import { useUpdateProgramSessionMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { capitalizeFirstLetter } from '../../../../utils/helpers';

const Calendar = () => {
	const { me, dateSelected, dateShowFormat, refetch, setFirstime } = useContext(CalendarContext);
	const { datesArrSessions } = useContext(CalendarDatesContext);
	const { calendarMainSidebarVisible } = useContext(CalendarMainSidebarContext);
	const [updateProgramSession, { error }] = useUpdateProgramSessionMutation();
	useErrorModal({ error });

	const [sessionDragging, setSessionDragging] = useState<any>();
	const [weekIndex, setWeekIndex] = useState<number>(0);
	const [sessionMoveModal, setSessionMoveModal] = useState<boolean>(false);
	const [sessionMoveTargetDate, setSessionMoveTargetDate] = useState<any>();

	const classNameCalendarView =
		calendarMainSidebarVisible === CalendarMainSidebarVisible.VISIBLE
			? 'calendar noselect'
			: 'calendar noselect calendarViewFull';

	//Find index of the week corresponding to the dateSelected inside datesArrSessions
	useEffect(() => {
		datesArrSessions.forEach((week: any, index: number) => {
			week.days.forEach((day: any) => {
				if (moment(day.dateMoment).format('DD/MM/YYYY') === moment(dateSelected).format('DD/MM/YYYY')) {
					setWeekIndex(index);
				}
			});
		});
	}, [datesArrSessions, dateSelected]);

	const drop = useCallback(
		(e: Event) => {
			//Detect where session is dropped
			let element: any = e.target;
			let valideMove = false;
			let endloop = false;
			while (!endloop) {
				if (element?.parentElement) {
					if (element.classList[0] === 'dayCellule' /*&&
					sessionMoveTargetDate !== sessionMoveTargetDate*/) {
						let dateIn = extractDateFromID(element.id);
						if (moment(dateIn).format('L') !== moment(sessionDragging?.plannedAt).format('L')) {
							//Validate session move
							setSessionMoveModal(true);
							setSessionMoveTargetDate(dateIn);
							//Save destination
							valideMove = true;
						}
					}
					element = element.parentElement;
				} else {
					endloop = true;
				}
			}

			if (!valideMove) setSessionDragging(null);
		},
		[sessionDragging, sessionMoveTargetDate]
	);

	useEffect(() => {
		window.addEventListener('drop', drop);
		return () => {
			window.removeEventListener('drop', drop);
		};
	}, [sessionDragging, drop]);

	//Modal move session actions
	const handleClickSessionMove = (sessionMove: boolean) => {
		if (sessionMove) {
			//sessionDateUpdate(sessionMoveTargetDate);

			updateProgramSession({
				variables: {
					id: sessionDragging.id,
					plannedAt: sessionMoveTargetDate,
				},
			})
				.then(async (r) => {
					//window?.location?.reload();
					await setFirstime(true);
					refetch();
				})
				.catch((e) => console.log(e));
		}
		setSessionMoveTargetDate(null);
		setSessionMoveModal(false);
	};

	return (
		<>
			<div className={classNameCalendarView}>
				<table className="table">
					<thead>
						<tr className="titles">
							{moment.weekdays(true).map((dayTitle: string, index: number) => (
								<th
									key={index}
									className={`
									titleColumn calendarColumn textMedium weightMedium
									${dateShowFormat === DateShowFormat.WEEK && 'titleColumnWeek'}
								`}
								>
									{dateShowFormat === DateShowFormat.WEEK && (
										<h1 className="titleXXL weightMedium">
											{moment(datesArrSessions[weekIndex]?.days[index]?.dateMoment).format('DD')}
										</h1>
									)}

									<h2 className="textMedium weightMedium">{capitalizeFirstLetter(dayTitle)}</h2>
								</th>
							))}
						</tr>
					</thead>
					<tbody
						className={`
						${dateShowFormat === DateShowFormat.WEEK && 'tbodyWeek'}
					`}
					>
						{dateShowFormat === DateShowFormat.MONTH ? (
							datesArrSessions.map((weekDates: any, index: number) => (
								<tr key={index} className="weekRow">
									{weekDates.days.map((dateUnique: any, index2: number) => (
										<Day key={index2} setSessionDragging={setSessionDragging} dateUnique={dateUnique} me={me} />
									))}
								</tr>
							))
						) : (
							<tr className="weekRow">
								{datesArrSessions[weekIndex]?.days.map((dateUnique: any, index2: number) => (
									<Day key={index2} setSessionDragging={setSessionDragging} dateUnique={dateUnique} me={me} />
								))}
							</tr>
						)}
					</tbody>
				</table>
			</div>
			<CalendarModalSessionMove
				visible={sessionMoveModal}
				setVisible={setSessionMoveModal}
				action={handleClickSessionMove}
				sessionDragging={sessionDragging}
				sessionMoveTargetDate={sessionMoveTargetDate}
			/>
		</>
	);
};

export default Calendar;

const extractDateFromID = (id: string): moment.Moment => {
	let dateExtract = id.slice(3);
	let dateObject = new Date(
		+dateExtract.substring(4, 8),
		+dateExtract.substring(2, 4) - 1,
		+dateExtract.substring(0, 2)
	);
	return moment(dateObject);
};
