import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import './ModalDeleteWod.scss';
import { ListWodBookEntriesDocument, useDeleteWodBookEntryMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import update from 'immutability-helper';
import { useContext } from 'react';
import { WodContext } from '../Context';

const ModalDeleteWod = ({ visible, setVisible, wodId }: any) => {
	const { t } = useTranslation();
	const { setSelectedWod } = useContext(WodContext);
	const [deleteWodBookEntry, { loading, error }] = useDeleteWodBookEntryMutation({
		update(cache, { data }) {
			const dataQuery = cache.readQuery({
				query: ListWodBookEntriesDocument,
			});

			if (!dataQuery) return;

			// @ts-ignore
			const { listWodBookEntries } = dataQuery;
			const wodIndex = listWodBookEntries.result.findIndex((c: any) => c.id === data?.deleteWodBookEntry);

			cache.writeQuery({
				query: ListWodBookEntriesDocument,
				data: {
					listWodBookEntries: update(listWodBookEntries, {
						result: {
							$splice: [[wodIndex, 1]],
						},
						total: {
							$apply: (x: number) => x - 1,
						},
					}),
				},
			});
			setSelectedWod(undefined);
		},
	});
	useErrorModal({ error });

	const onClickDelete = () => {
		if (loading) return;

		deleteWodBookEntry({
			variables: {
				id: wodId,
			},
		}).then((r: any) => {
			console.log('r deleteWodBookEntry', r);
		});
		setVisible(false);
	};

	return (
		<Modal visible={visible} setVisible={setVisible}>
			<div className="modalValidation">
				<h1 className="titleXL weightMedium">{t('wodbook:deleteWod')}</h1>
				<h2 className="textMedium weightMedium">{t('wodbook:deleteWodConfirmation')}</h2>

				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setVisible(false);
						}}
					/>
					<Button
						buttonClass="btnDropdownRed textNormal weightMedium"
						buttonText={t('commons:delete')}
						onClick={onClickDelete}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteWod;
