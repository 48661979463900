import { useState, useEffect } from 'react';

import { User, Add, Home, MapPin, BookOpen, Lock } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import { calculateAge } from '../../../../utils/calculateAge';

import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import InformationsItem from './InformationsItem';
import Button from '../../../common/Button';
import EmptyMessage from '../../../common/EmptyMessage';

import './Informations.scss';
import { useHistory } from 'react-router-dom';

const Informations = ({ className, data, isSelfProfile }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [ageCalculated, setAgeCalculated] = useState<Number>();
	const [locationString, setLocationString] = useState<string>();
	const [bodyString, setBodyString] = useState<string>();

	useEffect(() => {
		if (data) {
			//Calculate age from date
			if (data.birthDate?.hasOwnProperty('value')) {
				setAgeCalculated(calculateAge(data?.birthDate.value));
			}

			//Formatting string for location
			if (data.location?.city || data?.location?.country) {
				let city = data?.location?.city?.value?.length > 0 && data?.location?.city?.value;
				let country = data?.location?.country?.value?.length > 0 && data?.location?.country?.value;
				if (city && country) setLocationString(`${city}, ${country}`);
				else {
					setLocationString(`${city ? city : ''}${country ? country : ''}`);
				}
			}

			//Formatting string for body
			if (data?.height?.value || data?.weight?.value) {
				let height = data.height?.value && data.height.value;
				let weight = data.weight?.value && data.weight.value;
				if (height && weight) setBodyString(`${height} cm, ${weight} kg`);
				else {
					setBodyString(`${height ? height + ' cm' : ''}${weight ? weight + ' kg' : ''}`);
				}
			}
		}
	}, [data]);

	return (
		<WidgetBase className={className}>
			<div className="informationsWidgetWrapper">
				<WidgetTitle title={t('profile:widgetTitle:informations')} />

				<hr />
				<div className="informationseWidgetInfos">
					{ageCalculated && (
						<InformationsItem
							title={t('profile:widgetInformation:age')}
							Icon={User}
							data={`${ageCalculated} ${t('profile:widgetInformation:yearsOld')}`}
						/>
					)}

					{locationString && (
						<InformationsItem title={t('profile:widgetInformation:location')} Icon={MapPin} data={locationString} />
					)}

					{data?.sportsRoom_name?.value && (
						<InformationsItem
							title={t('profile:widgetInformation:sportsRoomName')}
							Icon={Home}
							data={data?.sportsRoom_name?.value}
						/>
					)}

					{bodyString && (
						<InformationsItem title={t('profile:widgetInformation:body')} Icon={BookOpen} data={bodyString} />
					)}
				</div>

				{!locationString && !data?.sportsRoom_name?.value && !bodyString && (
					<div className="informationseWidgetNoData">
						{/* Aucune data à afficher */}

						{isSelfProfile ? (
							<Button
								buttonText={t('profile:widgetAction:addInformations')}
								buttonClass="btnGreyContrast textNormal weightMedium btnDuo"
								ButtonIcon={Add}
								onClick={() => history.push('/settings', ['PROFILE'])}
							/>
						) : (
							<EmptyMessage message={t('profile:widgetInformation:noData')} Icon={Lock} />
						)}
					</div>
				)}
			</div>
		</WidgetBase>
	);
};

export default Informations;
