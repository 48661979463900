import './ChatMobileNavBar.scss';
import React, { useState } from 'react';
import { BellOff, MenuDots, MessageCircle, Trash2, User } from '../../main/Icons';
import Dropdown from '../../common/Dropdown';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../../main/UserIcon';
import { ProgIcon } from '../../main/ProgIcon';
import { useHistory } from 'react-router-dom';

const ChatMobileNavBar = ({
	sidebarVisible,
	setSidebarVisible,
	channel,
	setLeaveModalVisible,
	leaveModalVisible,
	setVisibleInfoSidebar
}: any) => {
	const { t } = useTranslation();
	const [dropdownVisibility, setDropdownVisibility] = useState(false);
	const history = useHistory();

	return (
		<div className="chatMobileNavbar">
			<div
				className="left sidebarAction"
				onClick={() => {
					setSidebarVisible(true);
				}}
			>
				<MessageCircle />
			</div>

			<div className="center titleL weightBold noselect">
				{channel?.type === 'DUO' ? (
					<UserIcon
						url={channel?.avatar?.url}
						text={channel?.members[0]?.firstName.charAt(0) + channel?.members[0]?.lastName.charAt(0)}
						size={28}
					/>
				) : (
					channel?.type === 'PROGRAM' && (
						<div className="progIcon">
							<ProgIcon url={channel?.avatar?.url} />
						</div>
					)
				)}
				<div className="name">{channel?.name}</div>
			</div>
		
				<div className="right textNormal weightMedium noselect">
					<div
						className="iconContainer"
						onClick={() => {
							// setDropdownVisibility(true);
							setVisibleInfoSidebar(true)
						}}
					>
						<MenuDots />
					</div>

					{/* <Dropdown visible={dropdownVisibility} setVisible={setDropdownVisibility}>
						<button className="chatMobileMenuItem" onClick={() => history.push('/profile/' + channel?.members[0]?.id)}>
							<User />
							{t('chat:viewProfile')}
						</button>

						<button className="chatMobileMenuItem">
							<BellOff />
							{t('chat:hideNotification')}
						</button>

						<button
							className="chatMobileMenuItem redItem"
							onClick={() => {
								setLeaveModalVisible(!leaveModalVisible);
							}}
						>
							<Trash2 />
							{t('chat:leaveConversation')}
						</button>
					</Dropdown> */}
				</div>
			
		</div>
	);
};

export default ChatMobileNavBar;
