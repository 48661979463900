import './Modal.scss';
import React, { useState, useEffect } from 'react';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIOS } from 'react-device-detect';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const options: BodyScrollOptions = {
	reserveScrollBarGap: true,
};

const Modal = ({ visible, setVisible, children }: any) => {
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const classNameBackground = state === VISIBLE ? 'modal' : 'modal modalOut';
	const classNameModalWindow = state === VISIBLE ? 'modalWindow' : 'modalWindow modalWindowOut';

	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
			window.addEventListener('click', handleClickOutside);
			return () => {
				window.removeEventListener('click', handleClickOutside);
			};
		}
	}, [visible]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			let repaint = document.body.offsetHeight;
			setState(VISIBLE);
		}

		//Body scroll disable
		let scrollElem: any = document.querySelector('.modalWindow');
		if (state === HIDDEN) {
			// enableBodyScroll(scrollElem)
			clearAllBodyScrollLocks();
		} else {
			if (!isIOS) disableBodyScroll(scrollElem, options);
		}
	}, [state]);

	//GESTION CLICK EXTERIEUR
	useEffect(() => {
		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	//Detection du click en dehors de la barre de recherche et de la fenêtre
	const handleClickOutside = (e: any) => {
		let searchElements = false;

		let endLoop = false;
		let element = e.target;

		if (element.parentElement === null) {
			searchElements = true;
		} else {
			while (!endLoop) {
				if (element.parentElement) {
					element = element.parentElement;
					if (element.classList) {
						if (element.classList[0] === 'modalWindow' || element.classList[0] === 'options') {
							searchElements = true;
							break;
						}
					}
				} else {
					endLoop = true;
				}
			}
		}

		if (!searchElements) {
			setVisible(false);
			// document.body.offsetHeight
		}
	};

	if (state === HIDDEN) {
		return null;
	}

	return (
		<div className={classNameBackground}>
			<div className={classNameModalWindow}>
				<div className="modalWindowWrapper scrollbarThin">{children}</div>
			</div>
		</div>
	);
};

export default Modal;
