import './HomePage.scss';

import Loader from '../../components/main/Loader';
import { useMediaQuery } from 'react-responsive';
import Welcome from '../../components/pages/homepage/Welcome/Welcome';
import Calendar from '../../components/pages/homepage/Calendar/Calendar';
import News from '../../components/pages/homepage/News/News';
import useUser from '../../hooks/user/useUser';

export const HomePage = () => {
	const { user, loading } = useUser();

	const isMedium = useMediaQuery({ query: '(min-width: 860px)' });

	if (loading) {
		return <Loader />;
	}

	const noProg =
		user?.ownedPrograms?.length === 0 && user?.followedPrograms?.length === 0;

	return (
		<div id="homePage">
			<div className="column column1">
				<Welcome dataEmpty={noProg} />
				{!isMedium && (
					<>
						<hr />
						<Calendar noProg={noProg} />
					</>
				)}
				<News />
			</div>

			<div className="column column2">
				{isMedium && <Calendar noProg={noProg} />}
			</div>
		</div>
	);
};
