import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW, CHRONO_SETTYPE } from '../../../../../config/type';
import { secToArr } from '../../../../../utils/chrono';
import Modal from '../../../../common/Modal';
// import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';
import { TimerConfigContext } from '../../contexts/TimerConfigContext';
import './InputShow.scss';
import ModalInputSet from './ModalInputSet';

const InputShow = ({ type, value, name, setConfig, configName, setType, index, min, max }: any) => {
	const [valueInit, setValueInit] = useState<any>(value);
	const [valueParsed, setValueParsed] = useState<any>();
	const [modalInputVisible, setModalInputVisible] = useState<any>(false);
	const { chronoType } = useContext<any>(TimerConfigContext);
	const { t } = useTranslation();

	//Type of input
	useEffect(() => {
		if (typeof value === 'number') {
			if (type === CHRONO_CONGIG_INPUT_SHOW.TIME) {
				const valueArrText = secToArr(valueInit);
				setValueParsed(valueArrText[0] + ':' + valueArrText[1]);
			} else if (type === CHRONO_CONGIG_INPUT_SHOW.NUMBER) {
				let number: any = parseInt(valueInit);
				number = number < 10 ? '0' + valueInit : number.toString();
				setValueParsed(number);
			}
		} else {
			setValueParsed(value);
		}
	}, [valueInit, type, value]);

	useEffect(() => {
		setValueInit(value)
	}, [value])

	//Set data
	useEffect(() => {
		if(setType === CHRONO_SETTYPE.MULTIPLE){
			setConfig((o : any) => {
				const updateState = [...o]
				updateState[index] = valueInit
				return updateState
			})
		} else {
			setConfig(valueInit);
		}
		
	}, [valueInit, setConfig]);

	return (
		<>
			<div className="chronoConfigInputShow textNormal weightMedium" onClick={() => setModalInputVisible(true)}>
				{name}
				<div className="value textMedium weightMedium">
					{valueInit === 0 && configName === 'timeLimit' && chronoType.name === CHRONO_ACTIVITIES[2].name
						? t('chrono:noTimeLimit')
						: valueParsed}
				</div>
			</div>

			<Modal visible={modalInputVisible} setVisible={setModalInputVisible}>
				<ModalInputSet
					name={name}
					type={type}
					valueInit={valueInit}
					setValueInit={setValueInit}
					setVisible={setModalInputVisible}
					min={min}
					max={max}
				/>
			</Modal>
		</>
	);
};

export default InputShow;
