import './IconRound.scss'

const IconRound = ({Icon} : any) => {
	return (
		<div className="iconRound">
			<Icon />
		</div>
	)
}

export default IconRound
