import React, { useState } from 'react';

import './CguPage.scss';
import { useTranslation } from 'react-i18next';
import hustleUp from '../../assets/img/hustle_up/hustle_green.png';

export const CguPage = () => {
	return (
		<div className="cguPage">
			<CGU />
		</div>
	);
};

export const CGU = () => {
	const { t } = useTranslation();

	return (
		<div className="cgu">
			<img src={hustleUp} alt={'Hustle Up icon'} />
			<h2>{t('cgu:bodyMainTitle')}</h2>
			<div>
				<article>
					<p>{t('cgu:bodyText1')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle1')}</h3> <p>{t('cgu:bodyText2')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle2')}</h3>
					<h4>{t('cgu:bodySubTitle1')}</h4>
					<p>{t('cgu:bodyText3')}</p> <h4>{t('cgu:bodySubTitle2')}</h4> <p>{t('cgu:bodyText4')}</p>{' '}
					<h4>{t('cgu:bodySubTitle3')}</h4> <p>{t('cgu:bodyText5')}</p> <h4>{t('cgu:bodySubTitle4')}</h4>{' '}
					<p>{t('cgu:bodyText6')}</p> <h4>{t('cgu:bodySubTitle5')}</h4> <p>{t('cgu:bodyText7')}</p>{' '}
					<h4>{t('cgu:bodySubTitle6')}</h4> <p>{t('cgu:bodyText8')}</p> <h4>{t('cgu:bodySubTitle7')}</h4>{' '}
					<p>{t('cgu:bodyText9')}</p> <h4>{t('cgu:bodySubTitle8')}</h4> <p>{t('cgu:bodyText10')}</p>{' '}
					<h4>{t('cgu:bodySubTitle9')}</h4> <p>{t('cgu:bodyText11')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle3')}</h3> <p>{t('cgu:bodyText12')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle4')}</h3> <p>{t('cgu:bodyText13')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle5')}</h3> <p>{t('cgu:bodyText14')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle6')}</h3> <p>{t('cgu:bodyText15')}</p>
				</article>
				<article>
					<h3>{t('cgu:bodyTitle7')}</h3> <p>{t('cgu:bodyText16')}</p>
				</article>
			</div>
		</div>
	);
};
