import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Save } from '../../../../main/Icons';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';
import { useAddTabataChronoConfigurationMutation } from '../../../../../generated/gql';
import { useErrorModal } from '../../../../../hooks/useErrorModal';
import { useSelector } from 'react-redux';
import { SystemState } from '../../../../../store/SystemReducer';
import { TimerConfigContext } from '../../contexts/TimerConfigContext';

const SaveTabataButton = () => {
	const { t } = useTranslation();
	const { timerConfig } = useContext(TimerConfigDataContext);
	const { setModalVisible } = useContext(TimerConfigContext);
	const [addTabataChronoConfiguration, { error, loading }] = useAddTabataChronoConfigurationMutation();
	useErrorModal({ error });
	const saveChronoConfigData = useSelector((state: SystemState) => state.saveChronoConfigData);

	const onClick = () => {
		if (loading || !saveChronoConfigData) return;

		addTabataChronoConfiguration({
			variables: {
				sessionId: saveChronoConfigData?.sessionId,
				exerciseId: saveChronoConfigData?.exerciseId,
				roundConfiguration: timerConfig?.roundConfiguration,
				setConfiguration: timerConfig?.setConfiguration,
			},
		})
			.then((r) => {
				console.log('addTabataChronoConfiguration', r);
				setModalVisible(false);
			})
			.catch((e) => console.log('e', e));
	};

	return (
		<button className="timerSaveConfigButton titleXXL weightMedium" onClick={onClick}>
			<Save /> {t('commons:save')} TABATA
		</button>
	);
};

export default SaveTabataButton;
