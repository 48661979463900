import { useState } from 'react';
import { formatSpans, PostFormatter } from '../../../../api/prismic/tipsAndTricks/formatter';
import { ArrowRight } from '../../../main/Icons';
import Modal from '../../../common/Modal';

import './NewsCard.scss';
import { useTranslation } from 'react-i18next';

const NewsCard = ({ data }: any) => {
	/*const introBlocks = formatSpans(data?.node?.intro?.[0]);*/
	const { t } = useTranslation();
	const [modalView, setModalView] = useState(false);

	const handleClick = () => {
		setModalView(!modalView);
	};

	return (
		<>
			<div className="newsCard" onClick={handleClick}>
				<div className="photo">
					<img src={data?.node?.image?.url} alt={data?.node?.title?.[0]?.text} />
				</div>

				<h1 className="textMedium weightMedium">{data?.node?.title?.[0]?.text}</h1>

				<p className="textNormal weightMedium">
					{t('commons:discover')}
					<ArrowRight />
				</p>
			</div>
			<Modal visible={modalView} setVisible={setModalView}>
				<div className="newsModalContent">
					<div className="illu noselect">
						<img src={data?.node?.image?.url} alt={data?.node?.title?.[0]?.text} />
					</div>
					<div className="contentNewsModal">
						<div className="texts">
							<h1 className="titleXXL weightBold">{data?.node?.title?.[0]?.text}</h1>
							<PostFormatter bodyBlocks={data?.node?.body} />
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default NewsCard;
