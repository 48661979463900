import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import TextInput from '../common/Form/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import hustleGreen from '../../assets/img/hustle_up/hustle_green.png';
import Button from '../common/Button';
import { FIRSTNAME_REGEX, LASTNAME_REGEX, NICKNAME_REGEX } from '../../config/regex';
import { useCreateUserMutation } from '../../generated/gql';

import './RegisterData.scss';

type Inputs = {
	firstname: string;
	lastname: string;
	nickname: string;
};

export default function RegisterData({ email, setStep }: any) {
	const { t } = useTranslation();
	const [createUser] = useCreateUserMutation();

	const validationSchema = Yup.object().shape({
		firstname: Yup.string()
			.trim()
			.lowercase()
			.required(t('formik:requiredField'))
			.matches(FIRSTNAME_REGEX, t('formik:firstNameValidation')),
		lastname: Yup.string()
			.trim()
			.lowercase()
			.required(t('formik:requiredField'))
			.matches(LASTNAME_REGEX, t('formik:lastNameValidation')),
		nickname: Yup.string()
			.trim()
			.lowercase()
			.required(t('formik:requiredField'))
			.matches(NICKNAME_REGEX, t('formik:pseudoValidation')),
	});
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = useCallback(
		async (data: { firstname: string; lastname: string; nickname: string }) => {
			await createUser({
				variables: {
					firstName: data?.firstname,
					lastName: data?.lastname,
					nickname: data?.nickname,
					lang: 'fr-FR',
					email: email,
				},
			})
				.then((val) => {
					setStep(3);
				})
				.catch((e) => {
					console.error(JSON.stringify(e));
					if (e?.message === 'NICKNAME_ALREADY_EXISTS') {
						setError(
							'nickname',
							{
								type: 'validation',
								message: t([`errors:${e}`, 'errors:nicknameAlreadyExists']),
							},
							{ shouldFocus: true }
						);
					}
				});
		},
		[createUser, email, setError, setStep, t]
	);

	return (
		<div className="registerData loginLeftContainer">
			<header className="headerHustleUp">
				<img src={hustleGreen} alt="Hustle Up logo" />

				<h1 className="titleXXXL weightBold">{t('auth:registerDataTitle')}</h1>
				<p>{t('auth:registerDataText')}</p>
			</header>

			<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="nickname"
					placeholder={t('commons:nickname')}
					type="text"
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="firstname"
					placeholder={t('commons:firstname')}
					type="text"
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="lastname"
					placeholder={t('commons:lastname')}
					type="text"
				/>
				<div className="formValidationClassic">
					<Button
						buttonText={t('auth:joinCommunity')}
						buttonClass="btnGreen textMedium weightBold"
						onClick={() => {}}
					/>
				</div>
			</form>
		</div>
	);
}
