import './Support.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Instagram, Mail } from '../../../main/Icons';
import config from '../../../../aws-config';

const Support = () => {
	const { t } = useTranslation();

	return (
		<div className="settingsSupport pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:support')}</h1>

			<div className="settingsSupportWrapper settingsWrappers">
				<h1 className="titleL weightBold">{t('settings:support:title')}</h1>
				<p className="textMedium weightMedium">{t('settings:support:p1')}</p>
				<p className="textMedium weightMedium">{t('settings:support:p2')}</p>
				<p className="textMedium weightMedium">{t('settings:support:p3')}</p>
				<p className="textSmall weightMedium">{t('settings:support:version') + process.env.REACT_APP_CODE_VERSION}</p>
				<div className="contactInfo">
					<div className="info textSmall weightMedium">
						<Instagram />
						@hustleup.app
					</div>

					<div className="info textSmall weightMedium">
						<Mail />
						contact@hustleup-app.com
					</div>
				</div>
			</div>
		</div>
	);
};

export default Support;
