import { useEffect, useState } from 'react';
import useUser from './useUser';

export default (programId) => {
	const { user } = useUser();
	const [isMember, setIsMember] = useState(false);

	useEffect(() => {
		setIsMember(false);

		if (user?.followedPrograms) {
			const index = user?.followedPrograms?.findIndex(
				(p) => p?.id === programId
			);
			if (index !== -1) {
				console.log('index', index);
				setIsMember(true);
			}
		}
	}, [programId, user]);

	return isMember;
};
