import './SearchChat.scss'
import React from 'react'
import Button from '../../../common/Button'
import TextInput from '../../../common/Form/TextInput'
import { useTranslation } from 'react-i18next';
import { Add } from '../../../main/Icons';


const SearchChat = ({setSearchInput, searchInput} : any) => {
	const { t } = useTranslation();

	return (
		<div className="searchSidebarChat">
			<input 
				type="text"
				className="textNormal weightMedium"
				placeholder={t('commons:search')}
				onChange={(e) => {setSearchInput(e.target.value)}}
				value={searchInput}
			/>
			{/* <Button
				ButtonIcon={Add}
				buttonClass='btnGreyContrast btnLarge btnCentered'
			/> */}
		</div>
	)
}

export default SearchChat
