import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { use100vh } from 'react-div-100vh';

import RegisterForm from '../../components/auth/RegisterForm';
import RegisterData from '../../components/auth/RegisterData';
import RegisterTexts from '../../components/auth/RegisterTexts';

import './RegisterPage.scss';
import RegisterConfirmation from '../../components/auth/RegisterConfirmation';

export const RegisterPage = () => {
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState(1);

	const height = use100vh();
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	return (
		<div
			id="registerPage"
			className="loginLayoutWrapper"
			style={{
				minHeight: height ? height : '100vh',
			}}
		>
			<div className="leftLoginWrapper">
				{step === 1 ? (
					<RegisterForm setStep={setStep} setEmail={setEmail} />
				) : step === 2 ? (
					<RegisterData email={email} setStep={setStep} />
				) : (
					step === 3 && <RegisterConfirmation />
				)}
			</div>
			{isDesktop && (
				<div className="rightLoginWrapper">
					<RegisterTexts />
				</div>
			)}
		</div>
	);
};
