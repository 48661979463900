import './ButtonBig.scss'

const ButtonBig = ({Icon, iconBackground, text, onClick} : any) => {
	return (
		<button className="buttonBig" onClick={onClick}>
			<div
				className="icon"
				style={{
					background: iconBackground
				}}
			>
				<Icon />
			</div>
			
			<h1 className="textNormal weightMedium">{text}</h1>
		</button>
	)
}

export default ButtonBig
