import { useContext, useEffect } from "react"
import { TimerConfigDataContext } from "../../contexts/TimerConfigDataContext"

const FormHOC = ({children, config} : any) => {

	const {setTimerConfig} = useContext(TimerConfigDataContext)

	useEffect(() => {
		// console.log(config)
		setTimerConfig(config)
	}, [config])

	return (
		<div className="noselect">
			{children}
		</div>
	)
}

export default FormHOC
