import './WodLine.scss';
import { formatScore } from '../../../../utils/helpers';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { WodContext } from '../Context';

const WodLine = ({ wod, wodCat }: any) => {
	const [isFocusWod, setIsFocusWod] = useState<any>();
	const { selectedWod, setSelectedWod } = useContext(WodContext);
	const [wodData, setWodData] = useState<any>();
	const haveScore = wodData?.scores?.length > 0;

	useEffect(() => {
		if (selectedWod === wod?.id) setIsFocusWod(true);
		else setIsFocusWod(false);
	}, [selectedWod, setIsFocusWod, wod]);

	useEffect(() => {
		setWodData({
			id: wod?.id,
			date: wodCat === 'public' ? wod?.latestUserScores?.result[0]?.date : wod?.scoreEntries[0]?.date,
			name: wodCat === 'public' ? wod?.exercise?.nameTranslations[0]?.name : wod?.exercise?.name,
			scores: wodCat === 'public' ? wod?.latestUserScores?.result[0]?.scores : wod?.scoreEntries[0]?.scores,
		});
	}, [setWodData, wodCat, wod]);

	return (
		<div className={isFocusWod ? 'focusWod wodLine' : 'wodLine'} onClick={() => setSelectedWod(wodData?.id)}>
			{haveScore && <h2 className="textMedium weightMedium">{moment(wodData?.date).format('LL')}</h2>}

			<h1 className="titleL weightMedium">{wodData?.name}</h1>

			{haveScore && (
				<div className="scoresList">
					{wodData?.scores?.map((score: any, key: any) => (
						<p key={key} className="score textSmall weightMedium">
							{formatScore(score?.type, score?.value)}
						</p>
					))}
				</div>
			)}
		</div>
	);
};

export default WodLine;
