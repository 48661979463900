import { UserIcon } from '../main/UserIcon';
import { capitalizeName } from '../../utils/string';

import './UserCard.scss';
import { useHistory } from 'react-router-dom';

const UserCard = ({
	data,
	imgSize,
	classNameName,
	classNameNickname,
	coach,
	onClick,
}: any) => {
	const history = useHistory();

	return (
		<div
			className="userCard"
			onClick={
				onClick ? () => onClick() : () => history.push('/profile/' + data?.id)
			}
		>
			<UserIcon
				url={data?.profile_picture?.url}
				text={data?.firstName?.charAt(0) + data?.lastName?.charAt(0)}
				size={imgSize}
				coach={coach}
			/>

			<div
				className="infos"
				style={{
					transform: coach ? 'translateX(-8px)' : 'unset',
				}}
			>
				<h1 className={`${classNameName}`}>
					{capitalizeName(data?.firstName as string) +
						' ' +
						capitalizeName(data?.lastName as string)}
				</h1>
				{data?.nickname && (
					<h2 className={`${classNameNickname}`}>@{data.nickname}</h2>
				)}
			</div>
		</div>
	);
};

export default UserCard;
