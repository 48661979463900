import Loader from '../main/Loader';

const Button = ({ buttonClass, ButtonIcon, buttonText, loading, onClick }: any) => {
	return (
		<button className={`btn ${buttonClass}`} onClick={loading ? () => {} : onClick}>
			{loading && (
				<div className="loading">
					<Loader size={25} />
				</div>
			)}
			{ButtonIcon && !loading && (
				<div className="btnIcon">
					<ButtonIcon />
				</div>
			)}
			{buttonText && <div className="btnText">{buttonText}</div>}
		</button>
	);
};

export default Button;
