import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from '../components/Layout/Sidebar/Sidebar';
import Navbar from '../components/Layout/Navbar/Navbar';

import './Main.scss';

import { MainLayoutContext } from './_contexts';
import PubNubChatListner from '../components/Layout/PubNubChatListner.js';

const Main = ({ children, breadcrumb }: { children: React.Component; breadcrumb: [] }) => {
	const location = useLocation();

	//Background website color
	useEffect(() => {
		let backgroundColor = '';
		if (location.pathname.substring(0, 8) === '/profile') {
			backgroundColor = '#F9FBFF';
		} else {
			backgroundColor = '#FFF';
		}

		document.body.style.background = backgroundColor;
	}, [location]);

	//set global layout here
	return (
		<MainLayoutContext.Provider
			value={{
				breadcrumb,
			}}
		>
			<main id="globalLayout">
				<PubNubChatListner />
				<Sidebar />
				<div className="app">
					<Navbar breadcrumb={breadcrumb} />
					<section className="content">{children}</section>
				</div>
			</main>
		</MainLayoutContext.Provider>
	);
};

export default Main;
