import './SearchChatResults.scss';
import React, { useEffect } from 'react';
import SearchListUser from '../../../Layout/Navbar/SearchListUser';
import { useChatCreation } from '../../../../hooks/chat/useChatCreation';
import { setChatChannelId } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

const SearchChatResults = ({ searchInput, onChannelSelected, setSearchInput }: any) => {
	const { joinChatChannel, data } = useChatCreation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (data?.createChatChannel) {
			onChannelSelected(data?.createChatChannel);
			dispatch(setChatChannelId(data?.createChatChannel?.id));
			setSearchInput('');
		}
	}, [data]);

	return (
		<div className="searchChatResults">
			<SearchListUser searchQuery={searchInput} onPress={(id: any) => joinChatChannel(id)} />
		</div>
	);
};

export default SearchChatResults;
