import { Add } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import Button from '../../../common/Button';
import ProgramLine from '../programs/ProgramLine';

import './ProgramsCreated.scss';

const ProgramsCreated = ({ className, data }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<WidgetBase className={!data ? 'widgetBackgroundGrey ' + className : className}>
			{data?.length > 0 ? (
				<WidgetTitle
					title={t('programs:programsCreated:title')}
					buttonClass="btnGreen btnCentered btnLarge"
					ButtonIcon={Add}
					onClick={() => {
						history.push('/program/add');
					}}
				/>
			) : (
				<WidgetTitle
					title={t('programs:programsCreated:titleDataEmpty')}
					onClick={() => {
						history.push('/program/add');
					}}
				/>
			)}

			{data?.length > 0 ? (
				<div className="programsList">
					{data.map((program: any, index: number) => (
						<ProgramLine key={index} data={program} />
					))}
				</div>
			) : (
				<div className="programsDataEmpty">
					<p className="textSmall weightMedium">{t('programs:programsCreated:paragraphNoPrograms')}</p>
					<Button
						buttonText={t('home:buttonProgramCreate')}
						buttonClass="btnGreenLight textNormal weightMedium"
						onClick={() => history.push('/program/add')}
					/>
				</div>
			)}
		</WidgetBase>
	);
};

export default ProgramsCreated;
