import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const useGaTracker = () => {
	const location = useLocation();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		try {
			ReactGA.initialize('G-4J2VHXY9Q1', {});
			setInitialized(true);
		} catch (e) {
			console.error(e);
		}
	}, []);

	useEffect(() => {
		if (initialized) {
			ReactGA.send({
				hitType: 'pageview',
				page: location.pathname + location.search,
			});
		}
	}, [initialized, location]);
};

export default useGaTracker;
