import { ProgIcon } from '../../../main/ProgIcon';
import './SessionLine.scss';
import { useHistory } from 'react-router-dom';

const SessionLine = ({ data }: any) => {
	const history = useHistory();
	
	return (
		<div
			className="sessionLine"
			onClick={() =>
				history.push('/program/' + data?.program?.id + '/session/' + data?.id)
			}
		>
			<div className="left">
				<div className="image">
					<ProgIcon url={data?.program?.avatar?.url} />
				</div>

				<div className="texts">
					<h1 className="titleL weightSemiBold">{data?.name}</h1>
					<h2 className="textNormal weightMedium">{data?.program?.name}</h2>
				</div>
			</div>

			<div className="right">
				<h1 className="textMedium weightMedium">
					@{data?.program?.creator?.nickname}
				</h1>
			</div>
		</div>
	);
};

export default SessionLine;
