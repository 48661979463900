import './UserIcon.scss';

export const UserIcon = ({ url, text, size, coach }: any) => {
	// icon for user
	return (
		<div 
			className="userIconWrapper"
			style={{
				transform: coach ? 'translateX(-4px)' : 'unset'
			}}
		>
			<div
				className="userIcon"
				style={{
					height: size + 'px',
					width: size + 'px',
					boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
					border: coach ? '4px solid #05DBC3' : 'unset',
					// transform: coach ? 'translateX(-4px)' : 'unset'
				}}
			>
				{
					url ? 
					<img src={url} alt={'user icon'} /> 
					: text ?
					<h1 style={{color : '#4C5771', fontSize: size / 2.5 + 'px'}}>{text?.toUpperCase()}</h1>
					: ''
				}


			</div>

			{
				coach &&
				<div className="coachSigle">
					coach
				</div>
			}
		</div>
	)

}

