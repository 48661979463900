import './ProgramPresentation.scss';
import React from 'react';
import WidgetBase from '../../../common/WidgetBase';
import { ProgIcon } from '../../../main/ProgIcon';
import { Star } from '../../../main/Icons';
import { computeProgramNote } from '../../../../utils/helpers';

const ProgramPresentation = ({ data }: any) => {
	/*const stars = computeProgramNote(data?.showProgram?.points, data?.showProgram?.notesNumber);*/
	return (
		<WidgetBase className="programPresentation" padding={false}>
			<div className="programPresentationWidgetWrapper">
				<div className="programIcon">
					<ProgIcon url={data?.showProgram?.avatar?.url} />
				</div>

				{/*<div className="infoBar">
					<div className="rate textNormal weightSemiBold">
						<Star />
						{stars ? stars : '-'}
					</div>
				</div>*/}

				<div className="texts">
					<h1 className="titleXXL weightBold">{data?.showProgram?.name}</h1>
					<p className="textMedium weightMedium">{data?.showProgram?.description}</p>
				</div>
			</div>
		</WidgetBase>
	);
};

export default ProgramPresentation;
