import { useTranslation } from 'react-i18next';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

import './SocialButtons.scss'

const SocialButtons = ({onPressSocialButton} : any) => {
	const { t } = useTranslation();
	
	return (
		<div className="socialButtons">
			
			<div className="separation">
				<div className="lineGrey"></div>
				<div className="text textSmall weightMedium">{t('commons:or')}</div>
				<div className="lineGrey"></div>
			</div>

			<div className="socialConnexion">

				<button 
					className="socialButton"
					onClick={(e: any) => {
						e.preventDefault();
						onPressSocialButton(CognitoHostedUIIdentityProvider.Google);
					}}
				>
					<p className="titleL weightMedium">{t('auth:signinWithGoogle')}</p>
					<div className="logo">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path d="M472 214H282c-9 0-15 7-15 15v61c0 8 6 15 15 15h107c-12 31-34 56-61 72l45 80a229 229 0 00114-231c-1-7-7-12-15-12z" fill="#167ee6"/>
							<path d="M257 397c-53 0-99-29-123-71l-80 45a233 233 0 00319 86l-45-80c-21 13-46 20-71 20z" fill="#12b347"/>
							<path d="M373 457l-46-80c-20 12-45 20-70 20v91c42 0 82-11 116-31z" fill="#0f993e"/>
							<path d="M114 255c0-26 8-51 20-71l-80-46a231 231 0 000 233l80-45c-13-21-20-46-20-71z" fill="#ffd500"/>
							<path d="M257 112c34 0 65 13 90 33 6 5 15 4 20-1l43-43c6-6 6-17-1-23a232 232 0 00-355 60l80 46c24-43 70-72 123-72z" fill="#ff4b26"/>
							<path d="M347 145c6 5 15 4 20-1l43-43c6-6 6-17-1-23-41-35-94-57-152-57v91c34 0 65 13 90 33z" fill="#d93f21"/>
						</svg>
					</div>
				</button>

				<button 
					className="socialButton"
					onClick={(e: any) => {
						e.preventDefault();
						onPressSocialButton(CognitoHostedUIIdentityProvider.Facebook);
					}}
				>
					<p className="titleL weightMedium">{t('auth:signinWithFacebook')}</p>
					<div className="logo">
						<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M16 3.98h2.19V.17a28.3 28.3 0 00-3.2-.17C8.07 0 9.96 7.85 9.68 9H6.2v4.27h3.48V24h4.28V13.27h3.34L17.82 9h-3.87c.18-2.82-.77-5.02 2.05-5.02z" fill="#3b5999"/>
						</svg>
					</div>
				</button>

				<button 
					className="socialButton"
					onClick={(e: any) => {
						e.preventDefault();
						onPressSocialButton(CognitoHostedUIIdentityProvider.Apple);
					}}
				>
					<p className="titleL weightMedium">{t('auth:signinWithApple')}</p>
					<div className="logo">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 502.7 502.7">
							<path d="M389 282c-14-70 52-111 52-111s-25-37-69-46c-43-10-65 2-86 11-22 9-33 9-33 9-31 0-54-33-113-18-41 10-84 58-92 114s9 128 42 184c32 56 65 77 88 78 24 0 47-17 75-21 29-5 46 10 74 18 27 7 37 0 69-27s60-104 60-104-53-18-67-87zM328 80c30-32 20-80 20-80s-43 8-72 38-24 77-24 77 45-4 76-35z"/>
						</svg>
					</div>
				</button>

			</div>
			
		</div>
	)
}

export default SocialButtons


