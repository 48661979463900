import { Pen, Send } from '../../components/main/Icons';
import { useState, useEffect } from 'react';
import { useMeLazyQuery, useShowUserLazyQuery } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import { useTranslation } from 'react-i18next';
import Loader from '../../components/main/Loader';
import { useMediaQuery } from 'react-responsive';
import Profile from '../../components/pages/profile/Profile/Profile';
import Informations from '../../components/pages/profile/Informations/Informations';
import ButtonWidget from '../../components/common/ButtonWidget';
import ProgramsCreated from '../../components/pages/profile/ProgramsCreated/ProgramsCreated';
import ProgramsFollow from '../../components/pages/profile/ProgramsFollow/ProgramsFollow';
import PersonalRecord from '../../components/pages/profile/PersonalRecord/PersonalRecord';
import { useHistory, useLocation } from 'react-router-dom';

import './ProfilePage.scss';
import { useParams } from 'react-router-dom';

export const ProfilePage = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { slug } = useParams<any>();
	const [dataState, setDataState] = useState<any>();
	const isMedium = useMediaQuery({ query: '(min-width: 860px)' });
	const isDesktopXL = useMediaQuery({ query: '(min-width: 1400px)' });
	const location = useLocation();

	const [getUserData, { data: userData }] = useMeLazyQuery({
		fetchPolicy: 'cache-only',
	});

	const [showUser, { data, error, loading, refetch }] = useShowUserLazyQuery();
	// @ts-ignore
	useErrorModal({ error, onRetry: () => refetch() });

	useEffect(() => {
		if (slug) {
			showUser({
				variables: {
					id: slug,
				},
			});
		} else {
			getUserData();
		}
	}, [slug, getUserData, showUser]);

	useEffect(() => {
		if (data && slug) {
			setDataState(data?.showUser);
		} else if (userData?.me) {
			setDataState(userData?.me);
		}
	}, [data, userData, location, slug]);

	if (loading || !dataState) {
		return <Loader />;
	}

	return (
		<div id="profilePage">
			<div className="column column1">
				<Profile className="profileWidget" data={dataState} />
				<Informations className="informationsWidget" data={dataState} isSelfProfile={!slug} />

				<ButtonWidget
					className="editProfilButtonWidget"
					onClick={slug ? () => history.push('/chat/' + slug) : () => history.push('/settings', ['PROFILE'])}
				>
					{slug ? <Send /> : <Pen />}

					<h1 className="textNormal">
						{slug ? t('profile:widgetAction:sendMessage') : t('profile:widgetAction:edit')}
					</h1>
				</ButtonWidget>

				{isMedium && !isDesktopXL && (
					<PersonalRecord className="personalRecordWidget" userId={dataState?.id} isSelfProfile={!slug} />
				)}
			</div>

			<div className="column column2">
				{dataState?.ownedPrograms?.length > 0 && (
					<ProgramsCreated className="programsCreatedWidget" data={dataState} isSelfProfile={!slug} />
				)}
				<ProgramsFollow className="programsFollowWidget" data={dataState} isSelfProfile={!slug} />
			</div>

			<div className="column column3">
				{(isDesktopXL || !isMedium) && (
					<PersonalRecord className="personalRecordWidget" userId={dataState?.id} isSelfProfile={!slug} />
				)}
			</div>
		</div>
	);
};
