import WidgetTitle from '../../../common/WidgetTitle';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Calendar as CalendarIcon } from '../../../main/Icons';
import { useMediaQuery } from 'react-responsive';
import './Calendar.scss';

import EmptyMessage from '../../../common/EmptyMessage';
import SessionLine from './SessionLine';
import { useListProgramSessionsDayQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import Loader from '../../../main/Loader';
import { useHistory } from 'react-router-dom';

const Calendar = ({ noProg }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const isMedium = useMediaQuery({ query: '(min-width: 860px)' });

	const day = new Date();
	const { data, error, loading, refetch }: any = useListProgramSessionsDayQuery({
		variables: {
			month: day.getMonth() + 1,
			year: day.getFullYear(),
			day: day.getDate(),
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	if (loading || !data) {
		return <Loader />;
	}

	return (
		<div className="calendarWrapper">
			<WidgetTitle
				title={t('home:calendarTitle')}
				buttonText={!isMedium && t('home:buttonSeeCalendar')}
				buttonClass={!isMedium ? 'btnGreen btnDuo textNormal weightNormal' : 'btnGreen btnCentered btnLarge'}
				ButtonIcon={CalendarIcon}
				onClick={() => history.push('/calendar')}
			/>

			{data?.listProgramSessions?.result?.length > 0 ? (
				<div className="calendarContent">
					<div className="date">
						<h2 className="textNormal weightMedium">{moment(day).format('DD MMMM YYYY')}</h2>
						<h1 className="titleXL weightMedium">{t('home:calendarSessionDay')}</h1>
					</div>

					<div className="sessionList">
						{data?.listProgramSessions?.result.map((session: any) => (
							<SessionLine key={session.id} data={session} />
						))}
					</div>
				</div>
			) : (
				<EmptyMessage message={noProg ? t('home:calendarNodata') : t('home:noExerciceToday')} />
			)}
		</div>
	);
};

export default Calendar;
