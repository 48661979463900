import { useTranslation } from 'react-i18next';
import { SEARCH_TYPE } from '../../../config/type';

import './NavbarSearchOptions.scss';

const NavbarSearchOptions = ({ setSearchType, searchType }: any) => {
	const { t } = useTranslation();

	return (
		<div className="navbarSearchOptions textMedium weightMedium noselect">
			<button 
				onClick={() => setSearchType(SEARCH_TYPE.PROGRAM)}
				className={searchType === SEARCH_TYPE.PROGRAM ? 'active' : ''}
			>
				{t('commons:program')}
			</button>
			<button 
				onClick={() => setSearchType(SEARCH_TYPE.USER)}
				className={searchType === SEARCH_TYPE.USER ? 'active' : ''}
			>
				{t('commons:athlete')}
			</button>
		</div>
	);
};

export default NavbarSearchOptions;
