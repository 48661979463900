import React, { useCallback, useEffect, useState } from 'react';
import { LoadingFullScreen } from './components/main/LoadingFulllScreen';
import { initI18n } from './i18n/i18n';
import { Auth } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { Router } from './router/Router';
import ApolloClient from './api/apollo/ApolloClient';
import ErrorModal from './components/main/ErrorModal';
import { useMeLazyQuery } from './generated/gql';
import { useErrorModal } from './hooks/useErrorModal';
import PrismicApolloProvider from './api/prismic/PrismicApolloProvider';
import PrismicClient from './api/prismic/client';
import { PubNubProvider } from 'pubnub-react';
import { PubNubClient, setUserPubNubData } from './api/PubNub/PubNubClient';

import 'normalize.css/normalize.css';
import './assets/scss/index.scss';

//load i18n module
initI18n();

function App() {
	const [isAuthenticated, setAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);
	const [client, setClient] = useState<any>(undefined);
	const [prismicClient, setPrismicClient] = useState<any>(undefined);

	useEffect(() => {
		setClient(ApolloClient);
		setPrismicClient(PrismicClient);

		Auth.currentAuthenticatedUser()
			.then(() => {
				setAuthenticated(true);
			})
			.catch((err) => {
				console.error(err);
				setAuthenticated(false);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <LoadingFullScreen />;
	}

	if (client && prismicClient) {
		return (
			<div className="App">
				<ApolloProvider client={client}>
					<PrismicApolloProvider client={prismicClient}>
						<PubNubProvider client={PubNubClient}>
							<AppContainer isAuthenticated={isAuthenticated} />
						</PubNubProvider>
					</PrismicApolloProvider>
				</ApolloProvider>
			</div>
		);
	}
	return null;
}

const AppContainer = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
	const [me, { data, error, refetch }] = useMeLazyQuery();
	useErrorModal({ error, onRetry: () => refetch });

	useEffect(() => {
		if (isAuthenticated) {
			me();
		}
	}, [isAuthenticated, me]);

	useEffect(() => {
		if (data?.me) {
			// console.log('data me', data?.me);
			//set pubnub client data
			setUserPubNubData(data.me?.pubnub_uuid);
		}
	}, [data]);

	return (
		<div>
			<ErrorModal />
			<BrowserRouter>
				<Router isAuthenticated={isAuthenticated} />
			</BrowserRouter>
		</div>
	);
};

export default App;
