import './EmptyMessage.scss'

const EmptyMessage = ({message, Icon}: any) => {
	return (
		<div className="emptyMessage">
			{
				Icon &&
				<Icon />
			}
			<h1
				style={{
					marginLeft: Icon ? '8px' : 'unset'
				}}
				className="textSmall weightMedium"
			>
				{message}
			</h1>
		</div>
	)
}

export default EmptyMessage
