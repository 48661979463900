import React, { useState } from 'react';

export const WodContext = React.createContext({} as any)

export const WodContextProvider = ({children} : any) => {
	const [selectedWod, setSelectedWod] = useState<any>();
	const [isPublicWod, setIsPublicWod] = useState(true);
	const [wodEditMode, setWodEditMode] = useState(false)

	return (
		<WodContext.Provider
			value={{
				selectedWod,
				setSelectedWod,
				isPublicWod,
				setIsPublicWod,
				wodEditMode,
				setWodEditMode
			}}
		>
			{children}
		</WodContext.Provider>
	)
}

