import { Home, Calendar, MessageCircle, Clock, Book, Activity, User, Settings } from '../../main/Icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSidebar } from '../../../store/actions';
import { SystemState } from '../../../store/SystemReducer';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

import Loader from '../../main/Loader';
import UserCard from '../../common/UserCard';
import SidebarButton from './SidebarButton';

import './Sidebar.scss';
import useUser from '../../../hooks/user/useUser';
import { ListChatChannelsDocument, useListChatChannelsQuery } from '../../../generated/gql';

const Sidebar = () => {
	const [sidebarDisplay, setSidebarDisplay] = useState(-245);
	const [counterUnreadMessage, setCounterUnreadMessage] = useState(0);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	const sidebarToggled = useSelector((state: SystemState) => state.sidebarToggled);

	const { user, loading } = useUser();

	//DISPLAY SIDEBAR MOBILE/DESKTOP
	useEffect(() => {
		if (isDesktop) {
			setSidebarDisplay(0);
		} else {
			dispatch(toggleSidebar(false));
			setSidebarDisplay(-245);
		}
	}, [isDesktop]); //eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!isDesktop) setSidebarDisplay(sidebarToggled ? 0 : -245);
	}, [sidebarToggled]); //eslint-disable-line react-hooks/exhaustive-deps

	//CLICK OUTSIDE GESTION
	useEffect(() => {
		if (!isDesktop) {
			window.addEventListener('click', handleClickOutsideSidebar);

			return () => {
				window.removeEventListener('click', handleClickOutsideSidebar);
			};
		}
	}, [sidebarToggled, isDesktop]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleClickOutsideSidebar = (e: any) => {
		if (sidebarToggled && !isDesktop) {
			let sidebarElem = false;

			let endLoop = false;
			let element = e.target;

			while (!endLoop) {
				if (element.parentElement) {
					element = element.parentElement;

					if (element.id && element.id === 'sidebar') {
						sidebarElem = true;
					}
				} else {
					endLoop = true;
				}
			}

			if (!sidebarElem) {
				dispatch(toggleSidebar(false));
			}
		}
	};

	//CLOSE SIDEBAR WHEN CHANGE PAGE
	const location = useLocation();

	useEffect(() => {
		if (sidebarToggled) dispatch(toggleSidebar(false));
	}, [location]); // eslint-disable-line

	const { error, data }: any = useListChatChannelsQuery({
		fetchPolicy: 'cache-and-network',
		nextFetchPolicy: 'cache-first',
	});

	useEffect(() => {
		if (data) {
			let count = 0;
			for (const channel of data.listChatChannels.result) {
				if (channel.unreadMessagesCount > 0) count += channel.unreadMessagesCount;
			}
			setCounterUnreadMessage(count);
		}
	}, [data]);

	if (loading || !user) {
		return null;
	}

	return (
		<aside
			id="sidebar"
			className="scrollbarThin"
			style={{
				left: sidebarDisplay + 'px',
			}}
		>
			<div className="top">
				{!isDesktop && (
					<div className="modalMenu">
						<svg
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 18 18"
							onClick={() => dispatch(toggleSidebar(false))}
						>
							<path d="M13.5 4.5l-9 9M4.5 4.5l9 9" strokeLinecap="round" strokeLinejoin="round" />
						</svg>
					</div>
				)}

				{isDesktop && (
					<UserCard
						data={user}
						imgSize={54}
						classNameName="weightBold titleL"
						classNameNickname="weightNormal textNormal"
						onClick={() => {}}
					/>
				)}

				<nav className="menu">
					<SidebarButton name={t('commons:home')} Icon={Home} route={'/'} />
					<SidebarButton name={t('commons:prog')} Icon={Activity} route={'/program'} />
					<SidebarButton name={t('commons:calendar')} Icon={Calendar} route={'/calendar'} />
					<SidebarButton name={t('commons:wodbook')} Icon={Book} route={'/wodbook'} />
					<SidebarButton name={t('commons:timer')} Icon={Clock} route={'/timer'} />
					<SidebarButton
						name={t('commons:chat')}
						Icon={MessageCircle}
						route={'/chat'}
						counterUnreadMessage={counterUnreadMessage}
					/>
				</nav>
			</div>

			<div className="bottom">
				<nav className="menu">
					<SidebarButton name={t('commons:profil')} Icon={User} route={'/profile'} />
					<SidebarButton name={t('commons:settings')} Icon={Settings} route={'/settings'} />
				</nav>
			</div>
		</aside>
	);
};

export default Sidebar;
