import React, { useState } from 'react';
import { CheckCircle, CheckCircleEmpty } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import { French, English } from '../../../main/IconsFlag';

import './Language.scss';
import { switchLang } from '../../../../i18n/i18n';

const Language = () => {
	const lng = localStorage.getItem('language') || 'en';
	const [lang, setLang] = useState(lng);
	const { t } = useTranslation();

	return (
		<div className="settingsLanguage pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:lang')}</h1>

			<div className="settingsPageContent settingsWrappers">
				<div
					className="langueItem"
					onClick={() => {
						setLang('en');
						switchLang('en').catch((e) => console.error(e));
					}}
				>
					<div className="left">
						<div className={`icon ${lang === 'fr' && 'uncheck'}`}>
							{lang === 'en' ? <CheckCircle /> : <CheckCircleEmpty />}
						</div>
						<div className="text textMedium weightMedium noselect">
							{t('settings:lang:en')}
						</div>
					</div>

					<div className="right">
						<div className="lineDeco" />
						<div className="flagIcon">
							<English />
						</div>
					</div>
				</div>
				<div
					className="langueItem"
					onClick={() => {
						setLang('fr');
						switchLang('fr').catch((e) => console.error(e));
					}}
				>
					<div className="left">
						<div className={`icon ${lang === 'en' && 'uncheck'}`}>
							{lang === 'fr' ? <CheckCircle /> : <CheckCircleEmpty />}
						</div>
						<div className="text textMedium weightMedium noselect">
							{t('settings:lang:fr')}
						</div>
					</div>

					<div className="right">
						<div className="lineDeco" />
						<div className="flagIcon">
							<French />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Language;
