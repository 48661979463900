import './FormEmom.scss';
import { useContext, useEffect, useState } from 'react';
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW, CHRONO_SETTYPE } from '../../../../../config/type';
import FormHOC from './FormHOC';
import InputShow from '../FormsElements/InputShow';
import { useTranslation } from 'react-i18next';
import ButtonAddStep from '../FormsElements/ButtonAddStep';
import Step from '../FormsElements/Step';
import {TimerConfigDataContext} from '../../contexts/TimerConfigDataContext';

const FormEmom = () => {
	const { t } = useTranslation();
	const {timerConfig} : {timerConfig:any} = useContext(TimerConfigDataContext)

	const [everyTime, setEveryTime] = useState<any>(timerConfig?.emoms?.map((timerConfigU : any) => timerConfigU.everyTime) || [60])
	const [restTime, setRestTime] = useState<any>(timerConfig?.emoms?.map((timerConfigU : any) => timerConfigU.restTime) || [0]);
	const [roundsNumber, setRoundsNumber] = useState<any>(timerConfig?.emoms?.map((timerConfigU : any) => timerConfigU.roundsNumber) || [10]);

	const [config, setConfig] = useState<any>(timerConfig);

	useEffect(() => {
		if (everyTime.length === restTime.length && everyTime.length === roundsNumber.length) {
			const emoms = everyTime.map((emom: any, i: number) => ({
				everyTime: everyTime[i],
				restTime: restTime[i],
				roundsNumber: roundsNumber[i],
			}));

			setConfig({
				type: CHRONO_ACTIVITIES[1].name,
				emoms,
			});
			// document.querySelector('.timerApp')?.scrollTo(0, 10000)
		}
	}, [everyTime, restTime, roundsNumber]);

	const handleClickAddEMOM = () => {
		setEveryTime((o : any) => [...o, 60]);
		setRestTime((o : any) => [...o, 0]);
		setRoundsNumber((o : any) => [...o, 1]);
	};

	const closeStep = (i: number) => {
		setEveryTime((o: any) => {
			const update = [...o];
			update.splice(i, 1);
			return update;
		});
		setRestTime((o: any) => {
			const update = [...o];
			update.splice(i, 1);
			return update;
		});
		setRoundsNumber((o: any) => {
			const update = [...o];
			update.splice(i, 1);
			return update;
		});
	};

	return (
		<FormHOC config={config}>
			<div className="timerFormEmom">
				<div className="emomOne">
					<div className="inputEvery">
						<InputShow
							type={CHRONO_CONGIG_INPUT_SHOW.TIME}
							value={everyTime[0]}
							name={t('chrono:every')}
							setConfig={setEveryTime}
							setType={CHRONO_SETTYPE.MULTIPLE}
							index={0}
							configName=""
						/>
					</div>

					<div className="inputRounds">
						<InputShow
							type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
							value={roundsNumber[0]}
							name={t('chrono:rounds')}
							setConfig={setRoundsNumber}
							setType={CHRONO_SETTYPE.MULTIPLE}
							index={0}
							configName=""
							min={1}
						/>
					</div>
				</div>

				{config?.emoms?.length === 1 && <ButtonAddStep name={t('chrono:addEmom')} onClick={() => handleClickAddEMOM()} />}

				{config?.emoms?.length > 1 &&
					config?.emoms?.map((emom: any, i: number) => {
						if (i > 0) {
							return (
								<div key={i}>
									<Step
										setVisible={() => {
											closeStep(i);
										}}
									>
										<div className="stepWrapper">
											<InputShow
												type={CHRONO_CONGIG_INPUT_SHOW.TIME}
												value={emom.restTime}
												name={t('chrono:restTime')}
												setConfig={setRestTime}
												setType={CHRONO_SETTYPE.MULTIPLE}
												index={i}
												configName=""
											/>
										</div>

										<div className="stepWrapperEveryRound">
											<div className="everyRounds">
												<InputShow
													type={CHRONO_CONGIG_INPUT_SHOW.TIME}
													value={emom.everyTime}
													name={t('chrono:every')}
													setConfig={setEveryTime}
													setType={CHRONO_SETTYPE.MULTIPLE}
													index={i}
													configName=""
												/>
											</div>

											<div className="roundsNumber">
												<InputShow
													type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
													value={emom.roundsNumber}
													name={t('chrono:rounds')}
													setConfig={setRoundsNumber}
													setType={CHRONO_SETTYPE.MULTIPLE}
													index={i}
													configName=""
												/>
											</div>
										</div>
									</Step>
									{i === config.emoms.length - 1 && (
										<ButtonAddStep name={t('chrono:addEmom')} onClick={() => handleClickAddEMOM()} />
									)}
								</div>
							);
						} else {
							return null;
						}
					})}
			</div>
		</FormHOC>
	);
};

export default FormEmom;
