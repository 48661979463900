import './Chat.scss';
import {
	ListChatChannelsDocument,
	useListChatMembersAndMessagesQuery,
	useSetChannelLatestReceiptMutation,
} from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import InputArea from './Chat/InputArea';
import ChatArea from './Chat/ChatArea';
import { useCallback, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';

const Chat = ({ channel }: any) => {
	const discussionZoneWrapper = useRef<any>();
	const [loadingEarlier, setLoadingEarlier] = useState(false);

	const { error, loading, refetch, data, fetchMore, updateQuery } = useListChatMembersAndMessagesQuery({
		variables: {
			channelId: channel?.id,
		},
		/*fetchPolicy: 'cache-and-network',*/
		nextFetchPolicy: 'cache-first',
	});
	// @ts-ignore
	useErrorModal({ error, retry: () => refetch() });

	const [setChannelLatestReceipt]: any = useSetChannelLatestReceiptMutation({
		update(cache) {
			const data: any = cache.readQuery({
				query: ListChatChannelsDocument,
			});
			const { listChatChannels } = data;

			const channelIndex = listChatChannels.result.findIndex((c: any) => c.id === channel?.id);

			if (channelIndex === -1) return;

			cache.writeQuery({
				query: ListChatChannelsDocument,
				data: {
					listChatChannels: update(listChatChannels, {
						result: {
							[channelIndex]: {
								unreadMessagesCount: {
									$set: 0,
								},
							},
						},
					}),
				},
			});
		},
	});

	useEffect(() => {
		if (channel?.unreadMessagesCount > 0) {
			setChannelLatestReceipt({
				variables: {
					channelId: channel?.id,
					receivedAt: channel?.latestMessage?.createdAt,
				},
			});
		}
	}, [channel, data, setChannelLatestReceipt]);

	//Scroll Top Trigger
	const handleScrollTop = (e: any) => {
		if (e.target.scrollTop === 0) {
			//The scroll has reached the top
			fetchMoreData();
		}
	};

	const fetchMoreData = useCallback(() => {
		if (!fetchMore || loading) return;
		setLoadingEarlier(true);

		const offset = data?.listChatChannelMessages?.offset || 0;
		const limit = data?.listChatChannelMessages?.limit || 20;
		const newOffset = offset + limit;
		const chatWrapperHeight = discussionZoneWrapper.current.scrollHeight;

		fetchMore({
			variables: {
				channelId: channel?.id,
				messagesOffset: newOffset,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;

				// @ts-ignore
				const { listChatChannelMembers, listChatChannelMessages } = fetchMoreResult;

				const newData = {
					listChatChannelMembers,
					listChatChannelMessages: {
						...listChatChannelMessages,
						result: [
							// @ts-ignore
							...prev.listChatChannelMessages.result,
							...listChatChannelMessages.result,
						],
					},
				};

				return newData;
			},
		})
			.catch((e: any) => console.log('e', e))
			.finally(() => {
				setLoadingEarlier(false);
				discussionZoneWrapper.current.scrollTop = discussionZoneWrapper.current.scrollHeight - chatWrapperHeight;
			});
	}, [fetchMore, channel?.id, data, loading]);

	if (!data) return null;

	return (
		<div className="chatDiscussion">
			<div className="discussionZone">
				<div className="discussionZoneWrapper scrollbarThin" onScroll={handleScrollTop} ref={discussionZoneWrapper}>
					<ChatArea
						data={data}
						channel={channel}
						discussionZoneWrapper={discussionZoneWrapper}
						fetchMoreMessage={loadingEarlier}
					/>
				</div>
			</div>

			<div className="inputZone">
				<InputArea channel={channel} updateQuery={updateQuery} discussionZoneWrapper={discussionZoneWrapper} />
			</div>
		</div>
	);
};

export default Chat;
