import './SearchMemberWidget.scss';
import {
	useCreateProgramInvitationMutation,
	useListInvitableProgramUsersLazyQuery,
} from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserCard from '../../../common/UserCard';
import Button from '../../../common/Button';
import { Add } from '../../../main/Icons';
import Loader from 'react-loader-spinner';

const SearchMemberWidget = ({ programId, full }: any) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState(''); // faire varier avec l'input
	const [searchModalView, setSearchModalView] = useState(false);

	const [
		listInvitableProgramUsers,
		{ data, error, loading, refetch },
	] = useListInvitableProgramUsersLazyQuery({
		fetchPolicy: 'network-only',
	});
	/*		useErrorModal({ error, onRetry: () => refetch() });*/

	useEffect(() => {
		getUser().catch((e) => console.error(e));
	}, [searchQuery]);

	const getUser = async () => {
		await listInvitableProgramUsers({
			variables: {
				id: programId,
				name: searchQuery.trim(),
			},
		});
	};

	//GESTION CLICK EXTERIEUR
	useEffect(() => {
		window.addEventListener('click', handleClickOutsideSearch);

		return () => {
			window.removeEventListener('click', handleClickOutsideSearch);
		};
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	//Detection du click en dehors de la barre de recherche et de la fenêtre
	const handleClickOutsideSearch = (e: any) => {
		let searchElements = false;

		let endLoop = false;
		let element = e.target;

		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (
						element.classList[0] === 'searchMemberModal' ||
						element.classList[0] === 'searchMember'
					) {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}

		if (!searchElements) {
			setSearchModalView(false);
		}
	};

	return (
		<div className="searchMember">
			<h1 className="titleXL weightBold">{t('programs:inviteMember')}</h1>
			<input
				className="searchMemberInput textMedium weightMedium"
				type="text"
				onChange={(e) => {
					setSearchQuery(e.target.value);
				}}
				onClick={() => {
					setSearchModalView(true);
				}}
				placeholder={t('commons:findAthlete')}
			/>

			{searchModalView && (
				<SearchMemberModal
					data={data}
					loading={loading}
					programId={programId}
				/>
			)}
		</div>
	);
};

const SearchMemberModal = ({ data, loading, programId }: any) => {
	const { t } = useTranslation();
	const [style, setStyle] = useState({
		opacity: 0,
		transform: 'translateY(-30px)',
	});

	useEffect(() => {
		setStyle({
			opacity: 1,
			transform: 'translateY(0px)',
		});
	}, []);

	if (loading) {
		return (
			<div className="searchMemberModal" style={style}>
				<Loader type="Rings" height={40} width={40} />
			</div>
		);
	}

	return (
		<div className="searchMemberModal" style={style}>
			{data?.listInvitableProgramUsers?.result.length > 0 ? (
				data?.listInvitableProgramUsers.result.map(
					(athlete: any, key: number) => (
						<div className="resultLine" key={key}>
							<div className="left">
								<UserCard
									key={athlete.id}
									data={athlete}
									imgSize={42}
									classNameName="textMedium weightMedium"
									classNameNickname="textSmall weightMedium"
								/>
							</div>

							<div className="right">
								<ButtonInvite programId={programId} userId={athlete.id} />
							</div>
						</div>
					)
				)
			) : (
				<p>{t('programs:noUserFind')}</p>
			)}
		</div>
	);
};

const ButtonInvite = ({ programId, userId }: any) => {
	const [isInvited, setIsInvited] = useState(false);
	const { t } = useTranslation();

	const [
		createProgramInvitation,
		{ data, error, loading },
	] = useCreateProgramInvitationMutation();
	const onInvitation = () => {
		createProgramInvitation({
			variables: {
				program: programId,
				recipient: userId,
			},
		})
			.then(() => setIsInvited(true))
			.catch((e) => console.error(e));
	};

	return (
		<>
			{isInvited ? (
				<Button
					buttonText={t('commons:sended')}
					buttonClass="btnGreyContrast textMedium weightNormal"
				/>
			) : (
				<Button
					ButtonIcon={Add}
					buttonClass="btnGreen btnCentered"
					onClick={() => onInvitation()}
				/>
			)}
		</>
	);
};

export default SearchMemberWidget;
