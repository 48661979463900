import { Users, Star, Price } from '../main/Icons';

import './ProgCard.scss';
import { ProgIcon } from '../main/ProgIcon';
import { computeProgramNote } from '../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProgCard = ({ data, sizeTitle, sizeInfos, imgSize, onClick, rightDecoration }: any) => {
	const history = useHistory();
	const { t } = useTranslation();
	const stars = computeProgramNote(data.points, data.notesNumber);

	return (
		<div className="progCard">
			<div className="left" onClick={onClick ? onClick() : () => history.push('/program/' + data?.id)}>
				<div
					className="logo"
					style={{
						height: imgSize + 'px',
						width: imgSize + 'px',
					}}
				>
					<ProgIcon url={data.avatar?.url} />
				</div>
				<div className="infos">
					<h1 className={sizeTitle}>{data.name}</h1>
					<div className="infosDetails">
						<div className="infosDetail">
							<Users />
							<h2 className={sizeInfos}>{data.users}</h2>
						</div>

						<div className="infosDetail">
							<Star />
							<h2 className={sizeInfos}>{stars ? stars : '-'}</h2>
						</div>
						{data?.price > 0 && (
							<div className="infosDetail">
								{/*<Price />*/}
								<h2 className={sizeInfos}>
									{data?.price}€/{t('commons:month')}
								</h2>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="right">
				{rightDecoration ? rightDecoration : <p className={`pseudo ${sizeInfos}`}>@{data?.creator?.nickname}</p>}
			</div>
		</div>
	);
};

export default ProgCard;
