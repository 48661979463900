import './CalendarSession.scss';
import React, { useEffect, useState } from 'react';
import CalendarSessionNavbar from '../../../components/pages/calendar/calendarSession/CalendarSessionNavbar';
import { useShowProgramSessionQuery } from '../../../generated/gql';
import { useParams } from 'react-router-dom';
import { CalendarSessionTab } from '../../../components/pages/calendar/_types';
import CalendarSessionContextProvider from '../../../components/pages/calendar/contexts/CalendarSessionContext';
import CalendarSessionExercices from '../../../components/pages/calendar/calendarSession/CalendarSessionExercices/CalendarSessionExercices';
import CalendarSessionComments from '../../../components/pages/calendar/calendarSession/CalendarSessionComment/CalendarSessionComments';
import { useErrorModal } from '../../../hooks/useErrorModal';
import { useCheckProgramAcces } from '../../../hooks/useCheckProgramAcces';

// import { useMediaQuery } from 'react-responsive';

const CalendarSession = () => {
	// const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const [tab, setTab] = useState(CalendarSessionTab.EXERCICES);
	const { idSession, slug } = useParams<any>();

	//protect program from acces via url
	const progAcces = useCheckProgramAcces(slug);
	let progAccesError = {};
	if (!progAcces) progAccesError = { error: { message: 'NOT_AUTHORIZED' } };
	useErrorModal(progAccesError);

	const { data, error, loading } = useShowProgramSessionQuery({
		variables: {
			id: idSession,
		},
	});
	useErrorModal({ error });

	if (loading || error || !progAcces) return null;

	return (
		<CalendarSessionContextProvider tab={tab} setTab={setTab} data={data}>
			<div className="calendarSessionPage">
				<CalendarSessionNavbar />
				{tab === CalendarSessionTab.EXERCICES ? (
					<CalendarSessionExercices />
				) : tab === CalendarSessionTab.COMMENTS ? (
					<CalendarSessionComments />
				) : (
					<div className="row row1">
						<div className="column column1">
							<CalendarSessionExercices />
						</div>
						<div className="column column2">
							<CalendarSessionComments />
						</div>
					</div>
				)}
			</div>
		</CalendarSessionContextProvider>
	);
};

export default CalendarSession;
