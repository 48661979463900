import './WodBookEdit.scss';
import { useShowWodBookEntryQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import { useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import React from 'react';
import ExerciseWodEditor from '../../../components/pages/wodbook/ExerciseWodEditor/ExerciseWodEditor';

const WodBookEdit = () => {
	const { id } = useParams<any>();
	const { data, error, loading }: any = useShowWodBookEntryQuery({
		variables: {
			entryId: id,
		},
	});
	useErrorModal({ error });

	if (!data?.showWodBookEntry?.exercise || loading)
		return <Loader type="TailSpin" color="#50B4EE" height={12} width={12} />;

	return (
		<div className="wodBookEdit">
			<div className="exerciseEditorWrapper">
				<ExerciseWodEditor exercise={data?.showWodBookEntry?.exercise} />
			</div>
		</div>
	);
};

export default WodBookEdit;
