import './ButtonWidget.scss';

const ButtonWidget = ({ children, className, onClick }: any) => {
	return (
		<button className={`buttonProfileWidget ${className} weightMedium`} onClick={onClick}>
			{children}
		</button>
	);
};

export default ButtonWidget;
