import { useContext } from 'react';
import { CHRONO_ACTIVITIES } from '../../../../config/type';
import { TimerConfigContext } from '../contexts/TimerConfigContext';
import FormAhap from './Forms/FormAhap';
import FormAmrap from './Forms/FormAmrap';
import FormEmom from './Forms/FormEmom';
import FormForTime from './Forms/FormForTime';
import FormTabata from './Forms/FormTabata';
import './Form.scss'
// import {TimerConfigDataContext} from '../contexts/TimerConfigDataContext';

const Form = () => {
	const { chronoType } = useContext(TimerConfigContext);



	const FormSelector = () => {
		switch (chronoType.name) {
			case CHRONO_ACTIVITIES[0].name:
				return <FormAmrap />;
			case CHRONO_ACTIVITIES[1].name:
				return <FormEmom />;
			case CHRONO_ACTIVITIES[2].name:
				return <FormForTime />;
			case CHRONO_ACTIVITIES[3].name:
				return <FormTabata />;
			case CHRONO_ACTIVITIES[4].name:
				return <FormAhap />;
			default:
				return null;
		}
	};

	return (
		<div className="chronoConfigFormContainer">
			<FormSelector />
		</div>
		
	)
};

export default Form;
