import { useTranslation } from 'react-i18next';
import { useListWodBookEntriesQuery } from '../../../../generated/gql';
import Loader from '../../../main/Loader';
import WodLine from './WodLine';
import EmptyMessage from '../../../common/EmptyMessage';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WodContext } from '../Context';
import './MyWod.scss';

export const MyWod = () => {
	const { t } = useTranslation();
	const { slug } = useParams<any>();
	const { setSelectedWod } = useContext(WodContext);
	const [firstime, setFirstime] = useState(true);
	const { data, error, loading }: any = useListWodBookEntriesQuery({
		fetchPolicy: 'cache-first',
		nextFetchPolicy: 'cache-only',
	});
	useErrorModal({ error });

	//use to come back to the good wod after editing it
	useEffect(() => {
		if (slug && data?.listWodBookEntries?.result?.length > 0 && firstime) {
			const indexOfWod = data?.listWodBookEntries?.result?.findIndex((wod: any) => wod?.id === slug);

			if (indexOfWod !== -1) {
				setFirstime(false);
				setSelectedWod(slug);
			}
		}
	}, [slug, data?.listWodBookEntries]);

	if (loading) return <Loader size={80} />;

	return (
		<div className="MyWod">
			{data?.listWodBookEntries?.result?.length > 0 ? (
				data?.listWodBookEntries?.result?.map((wod: any, key: any) => <WodLine key={key} wod={wod} wodCat="private" />)
			) : (
				<EmptyMessage message={t('wodbook:nowodList')} />
			)}
		</div>
	);
};
