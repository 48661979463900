import './Images.scss'
import React, { useState } from 'react'
import Modal from '../../../../../common/Modal'

interface ImagesProps {
	url: string;
}

const Images = ({url} : ImagesProps) => {
	const [imgModal, setImgModal] = useState<boolean>(false)

	return (
		<div className="imageExercise contentBloc" onClick={() => {setImgModal(true)}}>
			<img src={url} alt="" />
			{/* {block.image.url} */}

			
			<Modal visible={imgModal} setVisible={setImgModal}>
				<div className="imgModal noselect">
					<img src={url} alt="" />
				</div>
			</Modal>
		</div>
	)
}

export default Images
