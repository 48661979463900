import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import ProgramsCreated from '../../components/pages/programs/ProgramsCreated/ProgramsCreated';
import ProgramsFollowed from '../../components/pages/programs/ProgramsFollowed/ProgramsFollowed';
import ProgramsPending from '../../components/pages/programs/ProgramsPending/ProgramsPending';

import './ProgramPage.scss';
import useUser from '../../hooks/user/useUser';
import Loader from '../../components/main/Loader';

export const ProgramPage = () => {
	const { t } = useTranslation();
	const { user, loading, refetch } = useUser();

	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1400px)' });

	if (loading) return <Loader size={80} />;

	return (
		<div id="programPage">
			<div className="programPageWrapper">
				<h1 className="pageTitle titleXXL weightBold">{t('programs:page:title')}</h1>
				<div className="programColumnsWrapper">
					<div className="programsColumnsContainer programsColumnsContainer1">
						<ProgramsCreated data={user?.ownedPrograms} className="programsCreated" />
						{isMedium && !isDesktopXXL && (
							<ProgramsPending
								dataRequests={user?.programJoinRequests}
								dataInvitations={user?.programInvitations}
								refetchUser={refetch}
								className="programsPending"
							/>
						)}
					</div>
					<div className="programsColumnsContainer programsColumnsContainer2">
						<ProgramsFollowed data={user?.followedPrograms} className="programsFollowed" />
					</div>
					<div className="programsColumnsContainer programsColumnsContainer3">
						{(!isMedium || isDesktopXXL) && (
							<ProgramsPending
								dataRequests={user?.programJoinRequests}
								dataInvitations={user?.programInvitations}
								refetchUser={refetch}
								className="programsPending"
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
