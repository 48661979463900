import './CalendarNavbar.scss';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarContext } from './contexts/CalendarContext';
import { ChevronLeft, ChevronRight } from '../../main/Icons';
import { DateShowFormat } from './_types';
import moment, { unitOfTime } from 'moment';
import Button from '../../common/Button';
import { useMediaQuery } from 'react-responsive';

const CalendarNavbar = () => {
	const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const { t } = useTranslation();
	const {
		dateSelected,
		setDateSelected,
		dateShowFormat,
		setDateShowFormat,
		fetchMoreCalendarData,
	} = useContext(CalendarContext);

	const dateNav = (option: boolean) => {

		let duration: any = 'week';

		if (dateShowFormat === DateShowFormat.MONTH) {
			duration = 'M';
		} else {
			duration = 'week';
		}

		if (!isTablet) {
			duration = 'M';
		}

		const newDate = option
			? moment(dateSelected).add(1, duration)
			: moment(dateSelected).subtract(1, duration);
		setDateSelected(newDate);
		fetchMoreCalendarData(moment(newDate).month());
	};

	return (
		<div className="calendarNavbar">
			{isTablet && (
				<div className="title">
					<h1 className="titleXXL weightBold">{t('commons:calendar')}</h1>
				</div>
			)}

			<div className="navigation noselect">
				<button
					onClick={() => {
						dateNav(false);
					}}
				>
					<ChevronLeft />
				</button>

				<div className="dateSelectShow">
					{dateShowFormat === DateShowFormat.MONTH || !isTablet ? (
						<div className="dateSelectMonth titleXL weightMedium">
							{moment(dateSelected).format('DD MMMM YYYY')}
						</div>
					) : (
						<div className="dateSelectWeek">
							<h2 className="textSmall weightMedium">
								{moment(dateSelected).format('DD MMMM YYYY')}
							</h2>
							<h1 className="titleXL weightMedium">
								{t('calendar:week') + ' ' + moment(dateSelected).format('ww')}
							</h1>
						</div>
					)}
				</div>

				<button
					onClick={() => {
						dateNav(true);
					}}
				>
					<ChevronRight />
				</button>
			</div>

			{isTablet && (
				<div className="options noselect">
					<Button
						buttonClass={`
								btnGreyContrast textNormal weightMedium
							`}
						buttonText={t('calendar:today')}
						onClick={() => {
							setDateSelected(moment());
						}}
					/>

					<div className="calendarViewSwitch">
						<Button
							buttonClass={`
									textNormal weightMedium
									${
										dateShowFormat === DateShowFormat.MONTH
											? 'btnBlueExtraLight'
											: 'btnBlueDisable'
									}
								`}
							buttonText={t('calendar:week')}
							disabled={DateShowFormat.WEEK}
							onClick={() => {
								setDateShowFormat(DateShowFormat.WEEK);
							}}
						/>
						<Button
							buttonClass={`
									textNormal weightMedium
									${
										dateShowFormat === DateShowFormat.WEEK
											? 'btnBlueExtraLight'
											: 'btnBlueDisable'
									}
								`}
							buttonText={t('calendar:month')}
							disabled={DateShowFormat.MONTH}
							onClick={() => {
								setDateShowFormat(DateShowFormat.MONTH);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default React.memo(CalendarNavbar);
