import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import NavbarSearchOptions from './NavbarSearchOptions';

import './NavbarSearchModal.scss';
import { SEARCH_TYPE } from '../../../config/type';
import SearchListUser from './SearchListUser';
import SearchListPrograms from './SearchListPrograms';

const NavbarSearchModal = ({
	searchType,
	setSearchType,
	searchInputValue
}: any) => {
	const [style, setStyle] = useState({
		opacity: 0,
		transform: 'translateY(-30px)',
	});
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	useEffect(() => {
		setStyle({
			opacity: 1,
			transform: 'translateY(0px)',
		});
	}, []);

	return (
		<div className="navbarSearchModal" style={style}>
	
			<div className="navbarSearchModalOptions">
				<NavbarSearchOptions
					searchType={searchType}
					setSearchType={setSearchType}
				/>
			</div>
		

			<div className="contentSimu scrollbarThin">
				{searchType === SEARCH_TYPE.USER && (
					<SearchListUser searchQuery={searchInputValue} />
				)}
				{searchType === SEARCH_TYPE.PROGRAM && (
					<SearchListPrograms searchQuery={searchInputValue} />
				)}
			</div>
		</div>
	);
};

export default NavbarSearchModal;
