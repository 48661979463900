import './Dropdown.scss';
import React, { useEffect, useState } from 'react';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const Dropdown = ({ children, visible, setVisible, style, direction }: any) => {
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);

	let classNameDropdown: string;

	if (state === VISIBLE) {
		classNameDropdown = 'dropdownMenu';
	} else {
		if (direction === 'top') {
			classNameDropdown = 'dropdownMenu dropdownMenuOutTop';
		} else {
			classNameDropdown = 'dropdownMenu dropdownMenuOut';
		}
	}

	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
			window.addEventListener('click', handleClickOutside);
			return () => {
				window.removeEventListener('click', handleClickOutside);
			};
		}
	}, [visible]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			document.body.offsetHeight;
			setState(VISIBLE);

			// setTimeout(() => {
			// 	setVisible(false)
			// },3000)
		}
	}, [state]);

	//GESTION CLICK EXTERIEUR
	useEffect(() => {
		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	//Detection du click en dehors de la barre de recherche et de la fenêtre
	const handleClickOutside = (e: any) => {
		let searchElements = false;

		let endLoop = false;
		let element = e.target;

		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (element.classList[0] === 'dropdownMenu') {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}
		if (!searchElements) {
			setVisible(false);
			document.body.offsetHeight;
		}
	};

	if (state === HIDDEN) {
		return null;
	}

	return (
		<div className={classNameDropdown} style={style}>
			<div className="dropdownMenuWrapper">{children}</div>
		</div>
	);
};

export default Dropdown;
