import './ProgramPromotion.scss';
import React, { useState } from 'react';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import IconRound from '../../../common/IconRound';
import { Check, Frown } from '../../../main/Icons';
import {
	useCreateProgramCheckoutSessionMutation,
	useCreateProgramJoinRequestMutation,
} from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import Modal from '../../../common/Modal';
import Loader from '../../../main/Loader';

const ProgramPromotion = ({ dataProgram }: any) => {
	const { t } = useTranslation();
	const [step, setStep] = useState(1);
	const [visible, setVisible] = useState<any>(false);

	const [createProgramJoinRequest, { error, loading }]: any = useCreateProgramJoinRequestMutation();
	useErrorModal({ error });

	const handleClick = () => {
		createProgramJoinRequest({
			variables: {
				program: dataProgram?.id,
			},
		}).then(() => {
			setStep(2);
		});
	};

	//Program full
	if (dataProgram?.users >= dataProgram?.usersLimit && dataProgram?.usersLimit !== 0) {
		return (
			<WidgetBase className="programPromotion">
				<div className="programFull">
					<IconRound Icon={Frown} />
					<h1 className="titleXXL weightBold">{t('programs:programPromotion:programFull')}</h1>
					<p className="textMedium weightMedium">{t('programs:programPromotion:programFullBody')}</p>
				</div>
			</WidgetBase>
		);
	}

	if (dataProgram?.price > 0) {
		return (
			<WidgetBase className="programPromotion">
				<Modal visible={visible} setVisible={setVisible}>
					<div className="modalSession">
						<h1 className="weightBold">
							{t('programs:programPromotion:modalSubscribeTitle', { price: dataProgram?.price })}
						</h1>
						<p className="textMedium weightMedium bodyText1">
							{t('programs:programPromotion:modalSubscribeBody1', { price: dataProgram?.price })}
						</p>
						<p className="textMedium weightMedium">{t('programs:programPromotion:modalSubscribeBody2')}</p>
						<div className="actions">
							<RedirectStripeButton programId={dataProgram?.id} />
						</div>
					</div>
				</Modal>
				<WidgetTitle title={t('programs:programPromotion:title2')} />
				<div className="textPresentation">
					<p className="textMedium weightMedium">{t('programs:programPromotion:paid1')}</p>
					<p className="textMedium weightMedium">{t('programs:programPromotion:paid2')}</p>
					<p className="textMedium weightBold">{t('programs:programPromotion:price') + dataProgram?.price + ',00 €'}</p>
				</div>
				<div className="actions">
					<Button
						loading={loading}
						buttonClass="btnGreen textNormal weightMedium"
						buttonText={t('programs:programPromotion:subscribe')}
						onClick={() => setVisible(true)}
					/>
				</div>
			</WidgetBase>
		);
	}

	return (
		<WidgetBase className="programPromotion">
			{step === 1 && (
				<>
					<WidgetTitle title={t('programs:programPromotion:title')} />
					<div className="textPresentation">
						<p className="textMedium weightMedium">{t('programs:programPromotion:p1')}</p>
						<p className="textMedium weightMedium">{t('programs:programPromotion:p2')}</p>
					</div>
					<div className="actions">
						<Button
							loading={loading}
							buttonClass="btnGreen textNormal weightMedium"
							buttonText={t('programs:programPromotion:askJoin')}
							onClick={handleClick}
						/>
					</div>
				</>
			)}
			{step === 2 && (
				<div className="requestSent">
					<IconRound Icon={Check} />
					<h1 className="titleXXL weightBold">{t('programs:programPromotion:requestSent')}</h1>
					<p className="textMedium weightMedium">{t('programs:programPromotion:requestSentBody')}</p>
				</div>
			)}
		</WidgetBase>
	);
};

const RedirectStripeButton = ({ programId }: any) => {
	const { t } = useTranslation();
	const [createProgramCheckoutSession, { error, loading }]: any = useCreateProgramCheckoutSessionMutation();
	const [loadingUrl, setLoadingUrl] = useState(false);
	useErrorModal({ error });

	const onClick = () => {
		setLoadingUrl(true);
		createProgramCheckoutSession({
			variables: {
				programId,
				cancelUrl: `${process.env.REACT_APP_DOMAIN_NAME}program/${programId}`,
				returnUrl: `${process.env.REACT_APP_DOMAIN_NAME}program/${programId}`,
				successUrl: `${process.env.REACT_APP_DOMAIN_NAME}program/${programId}/success`,
			},
		})
			.then((r: any) => {
				console.log('r createProgramCheckoutSession', r);
				if (r?.data?.createProgramCheckoutSession?.url)
					window.location.replace(r?.data?.createProgramCheckoutSession?.url);
			})
			.catch((e: any) => console.log(e));
	};

	return (
		<>
			{loadingUrl ? (
				<Loader size={60} />
			) : (
				<Button
					buttonText={t('programs:programPromotion:modalSubscribeButton')}
					buttonClass="btnGreen textNormal weightMedium"
					onClick={onClick}
					loading={loading}
				/>
			)}
		</>
	);
};

export default ProgramPromotion;
