import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useUser from '../../../../hooks/user/useUser';
import { CalendarSessionTab } from '../_types';

export const CalendarSessionContext = React.createContext({} as any);

const CalendarSessionContextProvider = ({ children, tab, setTab, data }: any) => {
	const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1600px)' });
	const me = useUser();

	const [dataSession, setDataSession] = useState({ ...data });
	const [ownerSession, setOwnerSession] = useState(false);
	const [editMode, setEditMode] = useState(false);

	useEffect(() => {
		setOwnerSession(data?.showProgramSession?.program?.creator?.id === me?.user?.id);
	}, [data, me]);

	useEffect(() => {
		setDataSession({ ...data });
	}, [data]);

	useEffect(() => {
		if (isTablet) {
			setTab(CalendarSessionTab.ALL);
		} else {
			setTab(CalendarSessionTab.EXERCICES);
		}
	}, [isTablet, setTab]);

	return (
		<CalendarSessionContext.Provider
			value={{
				tab,
				setTab,
				dataSession,
				setDataSession,
				ownerSession,
				setOwnerSession,
				editMode,
				setEditMode,
			}}
		>
			{children}
		</CalendarSessionContext.Provider>
	);
};

export default CalendarSessionContextProvider;
