import { useTranslation } from 'react-i18next';
import { UserIcon } from '../../../main/UserIcon';
import { Pen } from '../../../main/Icons';
import TextInput from '../../../common/Form/TextInput';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectInput from '../../../common/Form/SelectInput';
import Button from '../../../common/Button';
import { capitalizeName } from '../../../../utils/string';
import { calculateAge } from '../../../../utils/calculateAge';
import './Profile.scss';
import React from 'react';
import { MeDocument, useAddUserProfilePictureMutation, useUpdateUserMutation } from '../../../../generated/gql';
import { resizeFile } from '../../../../utils/helpers';
import update from 'immutability-helper';

type Inputs = {
	firstname: string;
	lastname: string;
	genre: object;
	location: string;
	room: string;
	age: number;
	height: number;
	weight: number;
};

const Profile = ({ user }: any) => {
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		firstname: Yup.string(),
		lastname: Yup.string(),
		genre: Yup.object().required(t('validation:requiredField')),
		location: Yup.string(),
		room: Yup.string(),
		age: Yup.number(),
		height: Yup.number(),
		weight: Yup.number(),
	});

	const {
		register,
		handleSubmit,
		setError,
		control,
		formState: { errors },
		watch,
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			firstname: capitalizeName(user?.firstName) as string,
			lastname: capitalizeName(user?.lastName) as string,
			location: user?.location?.city?.value
				? user?.location?.city?.value + ' ' + user?.location?.country?.value
				: undefined,
			room: user?.sportsRoom_name?.value,
			age: calculateAge(user?.birthDate?.value),
			height: user?.height?.value,
			weight: user?.weight?.value,
			genre:
				user?.gender === 'FEMALE'
					? {
							value: 'woman',
							label: t('commons:woman'),
					  }
					: {
							value: 'man',
							label: t('commons:man'),
					  },
		},
	});

	const [
		updateUser,
		{ data, error, loading }, // eslint-disable-line
	] = useUpdateUserMutation(/*{
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: MeDocument,
			});

			if (!data) {
				console.error('QUERY ME NO DATA');
				return;
			}

			cache.writeQuery({
				query: MeDocument,
				data: update(queryData?.me, {
					me: {
						$set: [data?.updateUser],
					},
				}),
			});
		},
	}*/);

	const onSubmit = async (values: any, e: any) => {
		try {
			const val = validationSchema.cast(values);
			const userData = {
				// @ts-ignore
				...(val.firstname && { firstName: val.firstname }),
				// @ts-ignore
				...(val.lastname && { lastName: val.lastname }),
				...(Number(values.weight) && {
					weight: Number(values.weight),
				}),
				...(Number(values.height) && {
					height: Number(values.height),
				}),
				...(values.room && { sportsRoom_name: values.room }),
				/*                ...(birthDate && {birthDate: birthDate}),
                ...(city && {city: city}),
                ...(country && {country: country}),*/
				/*...(values.gender && {gender: isMen ? 'MALE' : 'FEMALE'}),*/
			};
			// @ts-ignore
			const user = await updateUser({ variables: userData });
		} catch (e) {
			console.error('handleSubmit ', JSON.stringify(e));
		}
	};

	return (
		<div className="settingsProfile pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:profile')}</h1>
			<div className="settingsProfileWrapper settingsWrappers">
				<UserAvatar user={user} />
				<form className="profileForm" onSubmit={handleSubmit(onSubmit)}>
					<div className="doubleInput">
						<TextInput
							// required
							errors={errors}
							register={register}
							name="firstname"
							placeholder={t('commons:firstname')}
							type="text"
						/>
						<TextInput
							// required
							errors={errors}
							register={register}
							name="lastname"
							placeholder={t('commons:lastname')}
							type="text"
						/>
					</div>
					<SelectInput
						errors={errors}
						name="genre"
						control={control}
						watch={watch}
						options={[
							{
								value: 'man',
								label: t('commons:man'),
							},
							{
								value: 'woman',
								label: t('commons:woman'),
							},
						]}
					/>
					<div className="doubleInput">
						<TextInput
							errors={errors}
							register={register}
							name="location"
							placeholder={t('commons:location')}
							type="text"
						/>

						<TextInput errors={errors} register={register} name="room" placeholder={t('commons:room')} type="text" />
					</div>
					{/*
					<TextInput
						errors={errors}
						register={register}
						name="age"
						placeholder={t('commons:age')}
						type="text"
						textIcon={t('commons:years').toLowerCase()}
					/>*/}

					<div className="doubleInput">
						<TextInput
							errors={errors}
							register={register}
							name="height"
							placeholder={t('commons:height')}
							type="text"
							textIcon="cm"
						/>

						<TextInput
							errors={errors}
							register={register}
							name="weight"
							placeholder={t('commons:weight')}
							type="text"
							textIcon="kg"
						/>
					</div>
					<div className="actions">
						<Button
							buttonText={t('commons:save')}
							buttonClass="textNormal weightSemiBold btnGreen"
							onClick={() => {}}
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

const UserAvatar = ({ user }: any) => {
	const { t } = useTranslation();
	const [
		addUserProfilePicture,
		{ data, error, loading }, // eslint-disable-line
	] = useAddUserProfilePictureMutation({
		update(cache, { data }) {
			if (data?.addUserProfilePicture?.url) {
				const queryData: any = cache.readQuery({
					query: MeDocument,
				});

				if (!data) {
					console.error('QUERY ME NO DATA');
					return;
				}

				cache.writeQuery({
					query: MeDocument,
					data: {
						me: update(queryData?.me, {
							profile_picture: {
								url: {
									$set: data?.addUserProfilePicture?.url,
								},
							},
						}),
					},
				});
			}
		},
	});

	const selectFile = async (e: any) => {
		try {
			const image = await resizeFile(e.target.files[0]);

			addUserProfilePicture({
				variables: {
					file: image,
				},
			})
				.then((r) => console.log('r', r))
				.catch((e) => console.log('e', e));
		} catch (e) {
			console.error('resize error', e);
		}
	};

	return (
		<div className="avatarSettings">
			<div className="avatar">
				<UserIcon
					url={user?.profile_picture?.url}
					text={user?.firstName?.charAt(0) + user?.firstName?.charAt(1)}
					size={80}
				/>
			</div>
			<div className="actions textNormal weightMedium">
				<label htmlFor="imgUpload" className="imgUploadLabel">
					<Pen />
					{t('programs:programAdd:updateLogo')}
					<input id="imgUpload" type="file" accept="image/!*" onChange={selectFile} />
				</label>
			</div>
		</div>
	);
};

export default Profile;
