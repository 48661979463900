import './FormTabata.scss';
import { useContext, useEffect, useState } from 'react';
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW } from '../../../../../config/type';
import FormHOC from './FormHOC';
import InputShow from '../FormsElements/InputShow';
import { useTranslation } from 'react-i18next';
import ButtonAddStep from '../FormsElements/ButtonAddStep';
import Step from '../FormsElements/Step';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';

const FormTabata = () => {
	const { t } = useTranslation();

	const [stepVisible, setStepVisible] = useState(false);
	const { timerConfig } = useContext(TimerConfigDataContext);

	const [timeLimit, setTimeLimit] = useState(timerConfig?.roundConfiguration?.timeLimit || 20);
	const [restTimeRound, setRestTimeRound] = useState(timerConfig?.roundConfiguration?.restTime || 10);
	const [numberRound, setNumberRound] = useState(timerConfig?.roundConfiguration?.number || 8);
	const [numberSet, setNumberSet] = useState(timerConfig?.setConfiguration?.number || 0);
	const [restTimeSet, setRestTimeSet] = useState(timerConfig?.setConfiguration?.restTime || 0);

	const [config, setConfig] = useState<any>(timerConfig);

	useEffect(() => {
		if (numberSet !== 0 || restTimeSet !== 0) setStepVisible(true);
	}, [numberSet, restTimeSet]);

	useEffect(() => {
		setConfig({
			type: CHRONO_ACTIVITIES[3].name,
			roundConfiguration: {
				timeLimit,
				restTime: restTimeRound,
				number: numberRound,
			},
			setConfiguration: {
				number: numberSet,
				restTime: restTimeSet,
			},
		});
	}, [timeLimit, restTimeRound, numberRound, numberSet, restTimeSet]);

	const closeStep = () => {
		setNumberSet(0);
		setRestTimeSet(0);
		setStepVisible(false);
	};

	const showStep = () => {
		setStepVisible(true);
		setNumberSet(2);
	};

	return (
		<FormHOC config={config}>
			<div className="timerFormTabata">
				<InputShow
					type={CHRONO_CONGIG_INPUT_SHOW.TIME}
					value={timeLimit}
					name={t('chrono:roundTime')}
					setConfig={setTimeLimit}
					configName=""
					min={1}
				/>

				<div className="roundsRestTime">
					<div className="inputNumberRound">
						<InputShow
							type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
							value={numberRound}
							name={t('chrono:rounds')}
							setConfig={setNumberRound}
							configName=""
							min={1}
						/>
					</div>

					<div className="inputRestTimeRound">
						<InputShow
							type={CHRONO_CONGIG_INPUT_SHOW.TIME}
							value={restTimeRound}
							name={t('chrono:restTime')}
							setConfig={setRestTimeRound}
							configName=""
							min={0.1}
						/>
					</div>
				</div>

				{!stepVisible && <ButtonAddStep name={t('chrono:addStep')} onClick={() => showStep()} />}

				{stepVisible && (
					<Step setVisible={closeStep}>
						<div className="stepWrapper">
							<div className="inputNumberSet">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
									value={numberSet}
									name={t('chrono:sets')}
									setConfig={setNumberSet}
									configName=""
									min={2}
								/>
							</div>

							<div className="inputRestTimeSet">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.TIME}
									value={restTimeSet}
									name={t('chrono:restTime')}
									setConfig={setRestTimeSet}
									configName=""
								/>
							</div>
						</div>
					</Step>
				)}
			</div>
		</FormHOC>
	);
};

export default FormTabata;
