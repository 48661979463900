import TimerConfigContextProvider from './contexts/TimerConfigContext';
import TimerConfigDataContextProvider from './contexts/TimerConfigDataContext';
import './Timer.scss';
import TimerScreenSwitch from './TimerScreenSwitch';

const Timer = ({ chronoConfiguration, chronoConfigurationData, setModalVisible }: any) => {

	

	return (
		<TimerConfigContextProvider states={{ setModalVisible }}>
			<TimerConfigDataContextProvider states={{ chronoConfiguration, chronoConfigurationData }}>
				<div className="timerApp">
					<TimerScreenSwitch />
				</div>
			</TimerConfigDataContextProvider>
		</TimerConfigContextProvider>
	);
};

export default Timer;
