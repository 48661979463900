import { useContext } from "react";

import SaveAmrapButton from "./SaveAmrapButton";
import './index.scss'
import { TimerConfigContext } from "../../contexts/TimerConfigContext";
import { CHRONO_ACTIVITIES } from "../../../../../config/type";
import SaveEmomButton from "./SaveEmomButton";
import SaveForTimeButton from "./SaveForTimeButton";
import SaveTabataButton from "./SaveTabataButton";
import SaveAhapButton from "./SaveAhapButton";


const SaveButtons = () => {
	const { chronoType } = useContext(TimerConfigContext);
	
	switch (chronoType.name) {
		case CHRONO_ACTIVITIES[0].name:
			return <SaveAmrapButton />;
		case CHRONO_ACTIVITIES[1].name:
			return <SaveEmomButton />;
		case CHRONO_ACTIVITIES[2].name:
			return <SaveForTimeButton />;
		case CHRONO_ACTIVITIES[3].name:
			return <SaveTabataButton />;
		case CHRONO_ACTIVITIES[4].name:
			return <SaveAhapButton />;
		default:
			return null;
	}
}

export default SaveButtons
