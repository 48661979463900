import './BlockEdit.scss';
import React, { useContext, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, Copy, Pen, Trash2 } from '../../../../../main/Icons';
import { CalendarSessionContext } from '../../../contexts/CalendarSessionContext';
import autosize from 'autosize';
import { resizeFile } from '../../../../../../utils/helpers';
import {
	SessionExerciseBlockType,
	useAddSessionExerciseBlockMutation,
	useDeleteSessionExerciseBlockMutation,
	useUpdateSessionExerciseBlockMutation,
} from '../../../../../../generated/gql';
import Loader from '../../../../../main/Loader';

enum Direction {
	'DOWN',
	'UP',
}

interface BlockEditProps {
	index: number;
	exerciseState: any;
	setExerciseState: React.Dispatch<any>;
	setRerender: any;
	exerciseId: any;
	sessionId: any;
	blockId: any;
	handleBlockPosition: any;
}

const BlockEdit = ({
	index,
	exerciseState,
	setExerciseState,
	setRerender,
	exerciseId,
	sessionId,
	blockId,
	handleBlockPosition,
}: BlockEditProps) => {
	// const { exerciseEditor } = useContext(CalendarSessionContext);
	const textarea = useRef<any>();
	const [addSessionExerciseBlock, { error }] = useAddSessionExerciseBlockMutation();
	const [updateSessionExerciseBlock, { error: error2, loading }] = useUpdateSessionExerciseBlockMutation();
	const [deleteSessionExerciseBlock, { error: error3 }] = useDeleteSessionExerciseBlockMutation();

	useEffect(() => {
		if (textarea) {
			autosize(textarea.current);
		}
	}, [exerciseState]);

	const handleChangeText = (e: any) => {
		setExerciseState((oldState: any) => {
			const updateState = { ...oldState, blocks: [] };

			oldState.blocks.forEach((block: any, i: number) => {
				updateState.blocks.push({ ...block });
			});

			updateState.blocks[index].content = e.target.value;
			return updateState;
		});
	};

	const handleDelete = () => {
		if (!blockId) return;

		deleteSessionExerciseBlock({
			variables: {
				blockId,
				exerciseId,
				sessionId,
			},
		}).then((r) => {
			console.log('r', r);
			setExerciseState((oldState: any) => {
				const updateState = { ...oldState, blocks: [] };

				oldState.blocks.forEach((block: any, i: number) => {
					updateState.blocks.push({ ...block });
				});

				updateState.blocks.splice(index, 1);

				updateState.blocks.forEach((block: any, i: number) => {
					updateState.blocks[i].order = i + 1;
				});

				return updateState;
			});
		});
	};

	const selectFile = async (e: any) => {
		try {
			const image = await resizeFile(e.target.files[0]);
			await updateImage(image);
		} catch (e) {
			console.error('resize error', e);
		}
	};

	const updateImage = (image: any) => {
		if (blockId) {
			return updateSessionExerciseBlock({
				variables: {
					exerciseId,
					sessionId,
					blockId,
					image,
					type: SessionExerciseBlockType.Image,
				},
			}).then((r: any) => {
				console.log('r', r);
			});
		}
	};

	const onBlurTextArea = () => {
		const content = exerciseState.blocks[index].content.trim();
		if (!content && content === '') return;

		if (content && blockId) {
			return updateSessionExerciseBlock({
				variables: {
					exerciseId,
					sessionId,
					blockId,
					content,
					type: SessionExerciseBlockType.Text,
				},
			}).then((r: any) => {
				console.log('r', r);
			});
		}

		return addSessionExerciseBlock({
			variables: {
				exerciseId,
				sessionId,
				content: content,
				type: SessionExerciseBlockType.Text,
			},
		}).then((r: any) => {
			console.log('r', r);
		});
	};

	return (
		<div className="blockEdit">
			<div className="positionEdit noselect">
				{index > 0 && (
					<button
						onClick={() => {
							handleBlockPosition(Direction.UP, blockId);
						}}
					>
						<ChevronUp />
					</button>
				)}
				{index < exerciseState?.blocks?.length - 1 && (
					<button
						onClick={() => {
							handleBlockPosition(Direction.DOWN, blockId);
						}}
					>
						<ChevronDown />
					</button>
				)}
			</div>
			<div className="content">
				{exerciseState.blocks[index].type === 'TEXT' && (
					<div className="contentTextContainer">
						<textarea
							ref={textarea}
							className="scrollbarThin"
							onChange={handleChangeText}
							value={exerciseState.blocks[index].content}
							onBlur={onBlurTextArea}
						/>
					</div>
				)}
				{exerciseState.blocks[index].type === 'IMAGE' && (
					<div className="contentImageContainer">
						{typeof exerciseState.blocks[index].image.url === 'string' ? (
							<img src={exerciseState.blocks[index]?.image?.url} alt="" />
						) : (
							<img src={URL.createObjectURL(exerciseState.blocks[index]?.image?.url)} alt="" />
						)}
					</div>
				)}
			</div>
			<div className="actions noselect">
				{exerciseState.blocks[index].type === 'IMAGE' && (
					<>
						{loading ? (
							<div className="imgUploadLabel">
								<Loader size={40} />
							</div>
						) : (
							<label htmlFor={`imgUploadUpdate-${index}`} className="imgUploadLabel">
								<Pen />
								<input id={`imgUploadUpdate-${index}`} type="file" accept="image/!*" onChange={selectFile} />
							</label>
						)}
					</>
				)}
				{!loading && (
					<button onClick={handleDelete}>
						<Trash2 />
					</button>
				)}
			</div>
		</div>
	);
};

export default BlockEdit;
