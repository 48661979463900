import './RegisterConfirmation.scss';
import React, { useCallback } from 'react';
import { Check } from '../main/Icons';
import IconRound from '../common/IconRound';
import Button from '../common/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/user/useUser';
import Loader from '../main/Loader';

const RegisterConfirmation = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { loading, user } = useUser();

	const onRegister = useCallback(() => {
		history.go(0);
	}, [history]);

	return (
		<div className="forgetPasswordStep3 loginLeftContainer">
			<div className="headerRoundIcon">
				<IconRound Icon={Check} />
				<h1 className="formTitle titleXXL weightBold">{t('auth:createdAccount')}</h1>
			</div>
			{loading ? (
				<div className="formValidationClassic">
					<Loader type="Rings" color="#319795" height={80} width={80} />
				</div>
			) : (
				<div className="formValidationClassic">
					<Button
						buttonText={t('auth:returnMainPage')}
						buttonClass="btnGreyContrast textMedium weightNormal"
						onClick={() => {
							onRegister();
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default RegisterConfirmation;
