import './ChatPage.scss';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ChatMainSidebar from '../../components/pages/chat/ChatMainSidebar';
import ChatInfosSidebar from '../../components/pages/chat/ChatInfosSidebar';
import Chat from '../../components/pages/chat/Chat';
import ChatMobileNavBar from '../../components/pages/chat/ChatMobileNavBar';
import Modal from '../../components/common/Modal';
import { useDispatch } from 'react-redux';
import Button from '../../components/common/Button';
import { useTranslation } from 'react-i18next';
import { ListChatChannelsDocument, useLeaveChatChannelMutation } from '../../generated/gql';
import update from 'immutability-helper';
import { setChatChannelId } from '../../store/actions';
import { useParams } from 'react-router-dom';
import { useChatCreation } from '../../hooks/chat/useChatCreation';

export const ChatPage = () => {
	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXL = useMediaQuery({ query: '(min-width: 1200px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1500px)' });
	const [channel, setChannel] = useState<any>({});
	const [sidebarVisible, setSidebarVisible] = useState(true);
	const [sidebarInfoVisible, setSidebarInfoVisible] = useState(false);
	const [leaveModalVisible, setLeaveModalVisible] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { slug } = useParams<any>();
	const { joinChatChannel, data } = useChatCreation();

	useEffect(() => {
		if (slug) {
			joinChatChannel(slug).catch((e) => console.error(e));
		}
	}, [slug]);

	useEffect(() => {
		if (data?.createChatChannel) {
			setChannel({
				...data?.createChatChannel,
				type: data?.createChatChannel?.type || 'DUO',
			});
			dispatch(setChatChannelId(data?.createChatChannel?.id));
		}
	}, [data?.createChatChannel]);

	const [leaveChatChannel] = useLeaveChatChannelMutation({
		update(cache, { data: dataDeleteChatMessage }) {
			// If the message is the latest one, update
			const data = cache.readQuery({
				query: ListChatChannelsDocument,
			});

			if (!data) return;
			const { listChatChannels } : any = data;
			const channelIndex = listChatChannels.result.findIndex((c: any) => c.id === channel?.id);

			cache.writeQuery({
				query: ListChatChannelsDocument,
				data: {
					listChatChannels: update(listChatChannels, {
						result: {
							$splice: [[channelIndex, 1]],
						},
					}),
				},
			});
		},
	});

	const onClickLeaveChatRoom = () => {
		leaveChatChannel({
			variables: {
				channelId: channel?.id,
			},
		})
			.then((r: any) => {
				setLeaveModalVisible(false);

				setTimeout(() => {
					setChannel({});
					dispatch(setChatChannelId(false));
				}, 500);
			})
			.catch((e) => console.error(e));
	};

	return (
		<div id="chatPage">
			{!isMedium && (
				<ChatMobileNavBar
					sidebarVisible={sidebarVisible}
					setSidebarVisible={setSidebarVisible}
					channel={channel}
					leaveModalVisible={leaveModalVisible}
					setLeaveModalVisible={setLeaveModalVisible}
					setVisibleInfoSidebar={setSidebarInfoVisible}
				/>
			)}

			<div className="chatApp">
				<div className="column1 column">
					<ChatMainSidebar
						onChannelSelected={setChannel}
						visible={sidebarVisible}
						setVisible={setSidebarVisible}
						channel={channel}
					/>
				</div>

				<div className="column2 column">
					{isMedium && !isDesktopXXL && (
						<ChatMobileNavBar
							sidebarVisible={sidebarVisible}
							setSidebarVisible={setSidebarVisible}
							channel={channel}
							leaveModalVisible={leaveModalVisible}
							setLeaveModalVisible={setLeaveModalVisible}
							setVisibleInfoSidebar={setSidebarInfoVisible}
						/>
					)}

					{channel?.id && <Chat channel={channel} />}
				</div>

				{channel?.id && (
					<div className="column3 column">
						<ChatInfosSidebar
							channel={channel}
							leaveModalVisible={leaveModalVisible}
							setLeaveModalVisible={setLeaveModalVisible}
							visible={sidebarInfoVisible}
							setVisible={setSidebarInfoVisible}
						/>
					</div>
				)}
			</div>

			<Modal visible={leaveModalVisible} setVisible={setLeaveModalVisible}>
				<div className="modalValidation">
					<h1 className="titleXL weightMedium">{t('chat:wouldLeaveConversation')}</h1>
					<h2 className="textMedium weightMedium">{t('chat:wouldLeaveConversationBody')}</h2>
					<div className="actions">
						<Button
							buttonClass="btnGreyNoBack textNormal weightMedium"
							buttonText={t('commons:cancel')}
							onClick={() => {
								setLeaveModalVisible(false);
							}}
						/>
						<Button
							buttonClass="btnTextRed textNormal weightMedium"
							buttonText={t('chat:yesLeave')}
							onClick={onClickLeaveChatRoom}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};
