import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CalendarMainSidebarVisible } from '../_types';

export const CalendarMainSidebarContext = React.createContext({} as any);

const CalendarMainSidebarContextProvider = ({ children }: any) => {
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1600px)' });

	const [
		calendarMainSidebarVisible,
		setCalendarMainSidebarVisible,
	] = useState<number>(
		isDesktopXXL
			? CalendarMainSidebarVisible.VISIBLE
			: CalendarMainSidebarVisible.HIDDEN
	);

	return (
		<CalendarMainSidebarContext.Provider
			value={{
				calendarMainSidebarVisible,
				setCalendarMainSidebarVisible,
			}}
		>
			{children}
		</CalendarMainSidebarContext.Provider>
	);
};

export default CalendarMainSidebarContextProvider;
