import './TimerChronoAppExerciseModal.scss';
import { isIOS } from 'react-device-detect';
import React, { useContext, useEffect, useState } from 'react';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';
import Modal from '../../../../common/Modal';
import { X } from '../../../../main/Icons';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const TimerChronoAppExerciseModal = ({ visible, setVisible }: any) => {
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const { chronoConfigurationData } = useContext(TimerConfigDataContext);

	const classNameBackground =
		state === VISIBLE ? 'timeChronoAppExerciseModal' : 'timeChronoAppExerciseModal timeChronoAppExerciseModalOut';

	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
		}
	}, [visible]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			let repaint = document.body.offsetHeight;
			setState(VISIBLE);
		}
	}, [state]);

	return (
		<div className={classNameBackground}>
			<div className="modalContent scrollbarThin">
				{chronoConfigurationData.blocks.map((block: any, index: number) => (
					<Exercice key={index} block={block} />
				))}

				<div
					className="closeButton"
					onClick={() => {
						setVisible(false);
					}}
				>
					<X />
				</div>
			</div>
		</div>
	);
};

const Exercice = ({ block }: any) => {
	if (block.type === 'TEXT') {
		return (
			<div key={block.id} className="text titleXL  contentBloc">
				{block.content}
			</div>
		);
	} else if (block.type === 'IMAGE') {
		return <ExerciceIMG key={block.id} imageUrl={block.image.url} />;
	}

	return null;
};

const ExerciceIMG = ({ imageUrl }: any) => {
	const [imgModal, setImgModal] = useState<boolean>(false);

	return (
		<div
			className="imageExercise contentBloc"
			onClick={() => {
				setImgModal(true);
			}}
		>
			<img src={imageUrl} alt="" />
			{/* {block.image.url} */}

			<Modal visible={imgModal} setVisible={setImgModal}>
				<div className="imgModal noselect">
					<img src={imageUrl} alt="" />
				</div>
			</Modal>
		</div>
	);
};

export default TimerChronoAppExerciseModal;
