import './MenuItem.scss';

const MenuItem = ({ data, setMenuIndex, setMenuDisplay, menuIndex }: any) => {
	const handleClick = () => {
		setMenuIndex(data.id);

		if (setMenuDisplay) {
			setMenuDisplay(false);
		}
	};

	return (
		<div
			className={`
				menuItem titleL weightMedium noselect
				${menuIndex === data?.id ? 'selectedItem' : ''}
			`}
			onClick={handleClick}
		>
			<data.Icon />
			{data.text}
		</div>
	);
};

export default MenuItem;
