import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { use100vh } from 'react-div-100vh';

import './ForgetPasswordPage.scss';

import Step1ResetPassword from '../../components/auth/StepResetPassword/Step1ResetPassword';
import Step2ResetPassword from '../../components/auth/StepResetPassword/Step2ResetPassword';
import Step3ResetPassword from '../../components/auth/StepResetPassword/Step3ResetPassword';

export const ForgetPasswordPage = () => {
	const height = use100vh();
	const [step, setStep] = useState(1);
	const [email, setEmail] = useState(null);

	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	return (
		<div
			id="forgetPasswordPage"
			className="loginLayoutWrapper"
			style={{
				minHeight: height ? height : '100vh',
			}}
		>
			<div className="leftLoginWrapper">
				{step === 1 ? (
					<Step1ResetPassword setStep={setStep} setEmail={setEmail} />
				) : step === 2 ? (
					<Step2ResetPassword setStep={setStep} email={email} />
				) : step === 3 ? (
					<Step3ResetPassword />
				) : (
					''
				)}
			</div>

			{isDesktop && <div className="rightLoginWrapper" />}
		</div>
	);
};
