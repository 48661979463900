import validation from './validation.json';
import auth from './auth.json';
import commons from './commons.json';
import errors from './errors.json';
import home from './home.json';
import formik from './formik.json';
import profile from './profile.json';
import programs from './programs.json';
import settings from './settings.json';
import chat from './chat.json';
import calendar from './calendar.json';
import session from './session.json';
import wodbook from './wodbook.json';
import chrono from './chrono.json';
import cgu from './cgu.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	validation,
	auth,
	commons,
	errors,
	home,
	formik,
	profile,
	programs,
	settings,
	chat,
	calendar,
	session,
	wodbook,
	chrono,
	cgu
};
