import './Connect.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import Loader from '../../../main/Loader';
import useUser from '../../../../hooks/user/useUser';

const Connect = () => {
	const { user } = useUser();
	const { t } = useTranslation();

	return (
		<div className="settingsConnect pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:connect')}</h1>
			{user?.validated_stripe_connect_account ? (
				<div className="settingsPaymentWrapper settingsWrappers">
					<p className="textMedium weightMedium">{t('settings:connect:connectText')}</p>
					<Button
						buttonText={t('settings:connect:openStipeConnect')}
						buttonClass="btnGreen textNormal weightMedium"
						onClick={() => window.open('https://connect.stripe.com/express_login/', '_blank', 'noopener noreferrer')}
					/>
				</div>
			) : (
				<div className="settingsPaymentWrapper settingsWrappers">
					<p>{t('settings:connect:noStripeAccount')}</p>
				</div>
			)}
		</div>
	);
};

export default Connect;
