import React, { useEffect, useState } from 'react';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIOS } from 'react-device-detect';
import './TimerChronoAppPauseModal.scss'
import { useTranslation } from 'react-i18next';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const options: BodyScrollOptions = {
	reserveScrollBarGap: true,
};

const TimerChronoAppPauseModal = ({ visible, setVisible, setChronoEnd}: any) => {
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const classNameBackground = state === VISIBLE ? 'timeChronoAppPauseModal' : 'timeChronoAppPauseModal timeChronoAppPauseModalOut';

	const {t} = useTranslation()

	useEffect(() => {
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
		}
	}, [visible]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			let repaint = document.body.offsetHeight;
			setState(VISIBLE);
		}

		//Body scroll disable
		let scrollElem: any = document.querySelector('.timeChronoAppPauseModal');
		if (state === HIDDEN) {
			// enableBodyScroll(scrollElem)
			clearAllBodyScrollLocks();
		} else {
			if (!isIOS) disableBodyScroll(scrollElem, options);
		}
	}, [state]);

	const handleClickEnd = () => {
		setChronoEnd(true);
		setVisible(false);
	}
	

	if (state === HIDDEN) {
		return null;
	}

	return (
		<div className={classNameBackground}>

			<div className="modalContent">
				<div className="text weightBold">
					{t('chrono:PAUSE')}
				</div>

				<div className="actions">
					<button className="btnTimerModal weightSemiBold" onClick={() => setVisible(false)}>{t('chrono:resume')}</button>
					<button className="btnTimerModal red weightSemiBold" onClick={handleClickEnd}>{t('chrono:exit')}</button>
				</div>
			</div>

		</div>
	)
};

export default TimerChronoAppPauseModal;
