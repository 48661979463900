import {useState, useEffect} from 'react'

import './WidgetBase.scss'

const WidgetBase = ({ children, className = "", shadow = false, sizeMax = "large", padding = true }: any) => {
	const [style, setStyle] = useState({
		boxShadow: 'unset' 
	})

	const [smallWidth, setSmallWidth] = useState('')

	useEffect(() => {
		setStyle({
			boxShadow : shadow ? '0px 15px 40px rgba(76, 87, 113, 0.12)' : 'unset',
		})

		setSmallWidth(sizeMax === 'small' ? 'widgetProfileSmall' : '')
	}, [shadow, sizeMax])

	return (
		<div 
			className={`widgetProfile ${className} ${smallWidth} ${!padding && 'widgetPaddingOff'}`}
			style={style}
		>
			{children}
		</div>
	)
}

export default WidgetBase
