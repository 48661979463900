export function secToArr (sec){
	let minutes
	let secondes

	if(sec > 3599){
		minutes = "59"
		secondes = "59"
	}
	else{
		let modulo = sec % 60
		secondes = modulo.toString()
		minutes = ((sec - modulo) / 60).toString()

		if(secondes.length === 1) secondes = "0" + secondes
		if(minutes.length === 1) minutes = "0" + minutes
	}

	return [minutes, secondes]
}



export const secToString = sec => {
	let tempSec = sec
	let hours
	let minutes
	let seconds

	if(tempSec >= 3600){
		let hoursModulo = tempSec % 3600
		hours = (tempSec - hoursModulo) / 3600
		tempSec -= hours * 3600

		if(hours < 10) hours = '0' + hours
	}

	if(tempSec >= 60){
		let minutesModulo = tempSec % 60
		minutes = (tempSec - minutesModulo) / 60
		tempSec -= minutes * 60

		if(minutes < 10) minutes = '0' + minutes
	} else {
		minutes = '00'
	}

	seconds = tempSec < 10 ? '0' + tempSec :  tempSec

	if(hours){
		return `${hours}:${minutes}:${seconds}`
	} else {
		return `${minutes}:${seconds}`
	}

}


export function arrToSec(arr){
	return parseInt(arr[0]) * 60 + parseInt(arr[1])

}