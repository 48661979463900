import gql from 'graphql-tag';

export const ALL_TIPS_AND_TRICKS = gql`
    query allTipsAndTrickss(
        $lang: String
        $tags_in: [String!]
        $last: Int
        $before: String
        $sortBy: SortTipsandtricksy
    ) {
        allTipsandtrickss(
            lang: $lang
            tags_in: $tags_in
            last: $last
            before: $before
            sortBy: $sortBy
        ) {
            edges {
                node {
                    _meta {
                        uid
                        lastPublicationDate
                    }
                    intro
                    title
                    image
                    body {
                        ... on TipsandtricksBodyPostslice {
                            type
                            fields {
                                primary_content
                                secondary_content
                            }
                        }
                    }
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
            }
        }
    }
`;

export const TIPS_AND_TRICKS = gql`
    query tipsAndTricks($uid: String!, $lang: String!) {
        tipsandtricks(uid: $uid, lang: $lang) {
            _meta {
                uid
            }
            intro
            title
            image
            body {
                ... on TipsandtricksBodyPostslice {
                    type
                    fields {
                        primary_content
                        secondary_content
                    }
                }
            }
        }
    }
`;
