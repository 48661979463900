import './Comment.scss';
import React, { useEffect, useState } from 'react';
import { UserIcon } from '../../../../main/UserIcon';

import moment from 'moment';
import { Heart, MessageCircle } from '../../../../main/Icons';
import { capitalizeName } from '../../../../../utils/string';
import Modal from '../../../../common/Modal';
import { useTranslation } from 'react-i18next';
import {
	CommentType,
	useShowCommentLazyQuery,
	useLikeProgramSessionCommentMutation,
	useRemoveProgramSessionCommentLikeMutation,
} from '../../../../../generated/gql';

import CommentReplyInput from './CommentReplyInput';

interface CommentProps {
	comment: any;
	setAnswerInputId: React.Dispatch<React.SetStateAction<string | undefined>>;
	answerInputId: string | undefined;
	idSession: any;
}

const Comment = ({
	comment,
	setAnswerInputId,
	answerInputId,
	idSession,
}: CommentProps) => {
	const [numberVisibleRequest, setNumberVisibleRequest] = useState<number>(1);
	const [commentsReplies, setCommentsReplies] = useState<any>([]);
	const { t } = useTranslation();

	const [
		showComment,
		{ data, error, loading, refetch },
	] = useShowCommentLazyQuery({});

	useEffect(() => {
		setCommentsReplies(comment?.replies?.result);
	}, [comment]);

	// LOAD MORE ANSWERS
	const loadAnswers = () => {
		showComment({
			variables: {
				id: comment?.id,
				type: CommentType.Session,
			},
		});
	};

	useEffect(() => {
		if (data) {
			setCommentsReplies((o: any) => {
				const u: any[] = [];
				data?.showComment?.replies?.result?.forEach(
					(answer: any, i: number) => {
						u.push(answer);
					}
				);
				return u;
			});
		}
	}, [data]);

	// if(!data) return null

	return (
		<div className="comment">
			<CommentTemplate
				comment={comment}
				setAnswerInputId={setAnswerInputId}
				answerInputId={answerInputId}
				idSession={idSession}
			/>

			{comment?.replies?.result?.length > 0 && (
				<div className="replies">
					{commentsReplies?.map((commentReply: any, index: number) => (
						<CommentTemplate
							key={index}
							comment={commentReply}
							setAnswerInputId={setAnswerInputId}
							answerInputId={answerInputId}
							idSession={idSession}
						/>
					))}

					{commentsReplies.length < comment?.replies?.total && (
						<button
							onClick={loadAnswers}
							className="moreAnswers textMedium weightBold"
						>
							{t('calendar:showMoreReply')}
						</button>
					)}
				</div>
			)}
		</div>
	);
};

const CommentTemplate = ({
	comment,
	setAnswerInputId,
	answerInputId,
	idSession,
}: CommentProps) => {
	const [imgModal, setImgModal] = useState(false);
	const { t } = useTranslation();

	// console.log('comment', comment);

	return (
		<div className="commentTemplate">
			<div className="left">
				<UserIcon
					url={comment?.author?.profile_picture?.url}
					text={
						comment?.author?.firstName?.charAt(0) +
						comment?.author?.lastName?.charAt(0)
					}
					size={24}
				/>
			</div>

			<div className="right">
				<header className="infos ">
					<div className="left">
						<h1 className="textSmall weightMedium">
							{capitalizeName(comment?.author?.firstName) +
								' ' +
								capitalizeName(comment?.author?.lastName)}
						</h1>
						<h2 className="textSmall weightMedium">
							&#8226; {moment(comment?.createdAt).fromNow()}
						</h2>
					</div>
					<div className="right">
						<div className="likes textSmall weightMedium">
							{comment?.likes}
							<Heart />
						</div>
					</div>
				</header>

				<article className="content">
					{comment?.attachment?.url && (
						<div className="contentImage">
							<img
								src={comment?.attachment?.url}
								alt=""
								onClick={() => {
									setImgModal(true);
								}}
							/>

							<Modal visible={imgModal} setVisible={setImgModal}>
								<div className="imgModal">
									<img src={comment?.attachment?.url} alt="" />
								</div>
							</Modal>
						</div>
					)}

					{comment?.content && (
						<div
							className="contentText textSmall weightMedium"
							style={{
								marginTop: comment?.attachment?.url ? '14px' : 'unset',
							}}
						>
							{comment?.content}
						</div>
					)}
				</article>

				<div className="actions textSmall weightMedium">
					{comment?.canLike ? (
						<CommentLikeButton commentId={comment?.id} />
					) : (
						<CommentUnlikeButton commentId={comment?.id} />
					)}
					{comment.replyable && (
						<button
							onClick={() => {
								setAnswerInputId(comment?.id);
							}}
						>
							<MessageCircle />
							{t('calendar:answer')}
						</button>
					)}
				</div>

				{answerInputId === comment?.id && (
					<CommentReplyInput commentId={comment?.id} idSession={idSession} />
				)}
			</div>
		</div>
	);
};

const CommentLikeButton = ({ commentId }: any) => {
	const { t } = useTranslation();
	const [
		likeProgramSessionComment,
		{ error, loading },
	]: any = useLikeProgramSessionCommentMutation();

	const handleClick = () => {
		if (loading) return;

		likeProgramSessionComment({
			variables: {
				commentId: commentId,
			},
		});
	};

	return (
		<button onClick={handleClick}>
			<Heart />
			{t('calendar:like')}
		</button>
	);
};

const CommentUnlikeButton = ({ commentId }: any) => {
	const { t } = useTranslation();
	const [
		removeProgramSessionCommentLike,
		{ error, loading },
	]: any = useRemoveProgramSessionCommentLikeMutation();

	const handleClick = () => {
		if (loading) return;

		removeProgramSessionCommentLike({
			variables: {
				commentId,
			},
		});
	};

	return (
		<button className="liked" onClick={handleClick}>
			<Heart />
			{t('calendar:unlike')}
		</button>
	);
};

export default Comment;
