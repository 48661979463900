import './FormAmrap.scss';
import { useContext, useEffect, useState } from 'react';
import { CHRONO_ACTIVITIES, CHRONO_CONGIG_INPUT_SHOW } from '../../../../../config/type';
import FormHOC from './FormHOC';
import InputShow from '../FormsElements/InputShow';
import { useTranslation } from 'react-i18next';
import ButtonAddStep from '../FormsElements/ButtonAddStep';
import Step from '../FormsElements/Step';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';

const FormAmrap = () => {
	const { t } = useTranslation();
	const [stepVisible, setStepVisible] = useState(false);
	const {timerConfig} = useContext(TimerConfigDataContext)

	//Inputs states
	const [timeLimit, setTimelimit] = useState(timerConfig?.roundConfiguration?.timeLimit || 600);
	const [number, setNumber] = useState(timerConfig?.setConfiguration?.number || 0);
	const [restTime, setRestTime] = useState(timerConfig?.setConfiguration?.restTime || 0);

	const [config, setConfig] = useState<any>(timerConfig);

	useEffect(() => {
		if(number !== 0 || restTime !== 0) setStepVisible(true)
	}, [number, restTime])

	useEffect(() => {
		setConfig({
			type : CHRONO_ACTIVITIES[0].name,
			roundConfiguration: {
				timeLimit: timeLimit,
			},
			setConfiguration: {
				number: number,
				restTime: restTime,
			},
		});
	}, [timeLimit, number, restTime]);

	const closeStep = () => {
		setNumber(0);
		setRestTime(0);
		setStepVisible(false);
	};

	const showStep = () => {
		setStepVisible(true)
		setNumber(2)
	}

	return (
		<FormHOC config={config}>
			<div className="timerFormAmrap">
				<InputShow
					type={CHRONO_CONGIG_INPUT_SHOW.TIME}
					value={timeLimit}
					name={t('chrono:asManyRoundsIn')}
					setConfig={setTimelimit}
					configName="timeLimit"
					// min={1}
				/>

				{!stepVisible && <ButtonAddStep name={t('chrono:addStep')} onClick={showStep} />}

				{stepVisible && (
					<Step setVisible={closeStep}>
						<div className="stepWrapper">
							<div className="sets">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.NUMBER}
									value={number}
									name={t('chrono:sets')}
									setConfig={setNumber}
									configName=""
									min={2}
								/>
							</div>
							<div className="restTime">
								<InputShow
									type={CHRONO_CONGIG_INPUT_SHOW.TIME}
									value={restTime}
									name={t('chrono:restTime')}
									setConfig={setRestTime}
									configName=""
								/>
							</div>
						</div>
					</Step>
				)}
			</div>
		</FormHOC>
	);
};

export default FormAmrap;
