import React, { useMemo } from 'react';

import './formatter.scss';

export const PostFormatter = ({ bodyBlocks }) => {
	if (!bodyBlocks) return <div />;

	return (
		<div>
			{bodyBlocks.map((block, key) => (
				<PostBlock block={block} key={key} />
			))}
		</div>
	);
};

const PostBlock = ({ block }) => {
	return (
		<div>
			{block.fields?.map((field, key) => (
				<div key={key}>
					{field.primary_content && <PrimaryContentParagraphs paragraphs={field.primary_content} />}
					{field.secondary_content && <SecondaryContentParagraphs paragraphs={field.secondary_content} />}
				</div>
			))}
		</div>
	);
};

const PrimaryContentParagraphs = ({ paragraphs }) => {
	return (
		<div>
			{paragraphs.map((paragraph, key) => (
				<PrimaryContentParagraph paragraph={paragraph} key={key} />
			))}
		</div>
	);
};

const PrimaryContentParagraph = ({ paragraph }) => {
	const textBlocks = useMemo(() => formatSpans(paragraph), [paragraph]);

	const props = useMemo(
		() =>
			mapTypeProps(paragraph.type) && {
				[mapTypeProps(paragraph.type)]: true,
			},
		[paragraph]
	);

	return (
		<div className="paragraphe titleL weightNormal" {...props}>
			{textBlocks.map((textBlock, key) => {
				if (textBlock.type === 'strong') {
					return <b key={key}>{textBlock.content}</b>;
				}
				return textBlock.content;
			})}
		</div>
	);
};

const SecondaryContentParagraph = ({ paragraph }) => {
	const textBlocks = useMemo(() => formatSpans(paragraph), [paragraph]);
	const props = useMemo(
		() => (mapTypeProps(paragraph.type) ? { [mapTypeProps(paragraph.type)]: true } : { p2: true }),
		[paragraph]
	);

	return (
		<div className="paragraphe titleL weightNormal">
			{textBlocks.map((textBlock, key) => {
				if (textBlock.type === 'strong') {
					return (
						<b {...props} key={key}>
							{textBlock.content}
						</b>
					);
				}
				return (
					<p key={key} className={'weightMedium textGrey'}>
						{textBlock.content}
					</p>
				);
			})}
		</div>
	);
};

const SecondaryContentParagraphs = ({ paragraphs }) => {
	return (
		<div>
			{paragraphs.map((paragraph, key) => (
				<SecondaryContentParagraph paragraph={paragraph} key={key} />
			))}
		</div>
	);
};

export const formatSpans = (paragraph) => {
	let blocks = [];

	if (paragraph?.spans) {
		let previousCursor = 0;
		for (const span of paragraph.spans) {
			blocks.push({
				content: paragraph?.text.slice(previousCursor, span.start),
				type: 'normal',
			});
			blocks.push({
				content: paragraph?.text.slice(span.start, span.end),
				type: span.type,
			});
			previousCursor = span.end;
		}
		blocks.push({
			content: paragraph?.text.slice(previousCursor, paragraph?.text.length),
			type: 'normal',
		});
		return blocks;
	}
	return [
		{
			content: paragraph?.text,
			type: 'normal',
		},
	];
};

export const mapTypeProps = (level) => {
	const mapping = {
		heading1: 'h1',
		heading2: 'h2',
		heading3: 'h3',
		heading4: 'h4',
		heading5: 'h5',
		heading6: 'h6',
		strong: 'bold',
	};

	return mapping[level];
};
