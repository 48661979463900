import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import './ProgramHomePage.scss';

import { useErrorModal } from '../../../hooks/useErrorModal';
import { useShowProgramQuery } from '../../../generated/gql';
import { useParams } from 'react-router-dom';
import ProgramNavbar from '../../../components/pages/programs/programs/ProgramNavbar';
import { PROG_NAVBAR } from '../../../config/type';
import ProgramPresentation from '../../../components/pages/programs/ProgramPresentation/ProgramPresentation';
import ProgramHomeDetails from '../../../components/pages/programs/ProgramHomeDetails/ProgramHomeDetails';
import ProgramSessions from '../../../components/pages/programs/ProgramSessions/ProgramSessions';
import useIsCoach from '../../../hooks/user/useIsCoach';
import useIsMember from '../../../hooks/user/useIsMember';
import Loader from '../../../components/main/Loader';
import ProgramPromotion from '../../../components/pages/programs/ProgramPromotion/ProgramPromotion';
import Modal from '../../../components/common/Modal';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/common/Button';

export const ProgramHomePage = () => {
	const { t } = useTranslation();
	const { slug, status } = useParams<any>();
	const [visible, setVisible] = useState<any>(false);

	// member, coach, guest,
	const [userRole, setUserRole] = useState<string>('guest');

	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1400px)' });
	const isCoach = useIsCoach(slug);
	const isMember = useIsMember(slug);

	const { data, error, loading } = useShowProgramQuery({
		variables: {
			id: slug,
		},
	});
	useErrorModal({ error });

	useEffect(() => {
		if (isCoach) setUserRole('coach');
		else if (isMember) setUserRole('member');
		else setUserRole('guest');
	}, [isCoach, isMember, slug]);

	useEffect(() => {
		if (status === 'success') setVisible(true);
	}, [status]);

	if (loading || error) return <Loader size={80} />;

	return (
		<div id="programHomePage">
			<ProgramNavbar slug={slug} focus={PROG_NAVBAR.HOME} name={data?.showProgram?.name} userRole={userRole} />
			<div className="programHomePageWrapper">
				<div className="column column1">
					<ProgramPresentation data={data} />
					<ProgramHomeDetails data={data} />
				</div>
				{isDesktopXXL && <div className="separator" />}
				<div className="column column2">
					{userRole === 'guest' ? (
						<ProgramPromotion dataProgram={data?.showProgram} />
					) : (
						<ProgramSessions dataProgram={data?.showProgram} />
					)}
				</div>
			</div>
			{status && (
				<Modal visible={visible} setVisible={setVisible}>
					<div className="modalSession">
						<h1 className="weightBold">{t('programs:congratulations')}</h1>
						<p className="textMedium weightMedium bodyText1">
							{t('programs:successModalBody1', { programName: data?.showProgram?.name })}
						</p>
						<p className="textMedium weightMedium bodyText1">{t('programs:successModalBody2')}</p>
						<div className="actions">
							<Button
								buttonText={t('programs:successModalBtn')}
								buttonClass="btnGreen textNormal weightMedium"
								onClick={() => setVisible(false)}
								loading={loading}
							/>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};
