import WidgetBase from '../../../common/WidgetBase';
import UserCard from '../../../common/UserCard';

import './Profile.scss';

const Profile = ({ className, data }: any) => {
	return (
		<WidgetBase shadow={true} className={className}>
			<div className="profileWidgetWrapper">
				<UserCard
					onClick={() => {}}
					data={data}
					imgSize={84}
					classNameName="weightBold titleXL"
					classNameNickname="weightNormal textNormal"
				/>
			</div>
		</WidgetBase>
	);
};

export default Profile;
