import React, { useEffect, useState } from 'react';
import { CHRONO_ACTIVITIES } from '../../../../config/type';

export const TimerConfigContext = React.createContext({} as any);

const TimerConfigContextProvider = ({ children, states }: any) => {
	const [chronoType, setChronoType] = useState<any>(CHRONO_ACTIVITIES[2]);

	return (
		<TimerConfigContext.Provider
			value={{
				...states,
				chronoType,
				setChronoType,
			}}
		>
			{children}
		</TimerConfigContext.Provider>
	);
};

export default TimerConfigContextProvider;
