import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SidebarButton.scss';

const SidebarButton = ({ name, Icon, route, counterUnreadMessage }: any) => {
	const location = useLocation();
	const [active, setActive] = useState<boolean>();

	useEffect(() => {
		if (route === '/') {
			if (location.pathname === '/') {
				setActive(true);
			} else {
				setActive(false);
			}
		} else {
			if (location.pathname.substring(0, route.length) === route) {
				setActive(true);
			} else {
				setActive(false);
			}
		}
	}, [route, location]);

	return (
		<Link
			to={route}
			className={`sidebarButton noselect  
			${active && 'sidebarButtonActive'}`}
		>
			<Icon />
			<div className="text textNormal weightMedium">{name}</div>
			<div className="infos">
				<div className="notificationNbWrapper">
					{counterUnreadMessage > 0 && <div className="notificationNb">{counterUnreadMessage}</div>}
				</div>
			</div>
		</Link>
	);
};

export default SidebarButton;
