import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../common/Form/TextInput';
import Modal from '../../../common/Modal';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './AddWodScoreModal.scss';
import { timeToString } from '../../../../utils/helpers';
import moment from 'moment';
import {
	ScoreValueType,
	ShowPublicWodDocument,
	useAddPublicWodScoresMutation,
	useAddWodBookEntryScoresMutation,
} from '../../../../generated/gql';
import Button from '../../../common/Button';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import update from 'immutability-helper';

interface AddScoreModalProps {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	scoreExpected: any;
	wodId: any;
	publicWod: boolean;
}

const AddWodScoreModal = ({ visible, setVisible, scoreExpected, wodId, publicWod }: AddScoreModalProps) => {
	const { t } = useTranslation();
	const [scoresExpectedFormat, setScoresExpectedFormat] = useState<any[]>();
	const [shouldSubmit, setShouldSubmit] = useState<any>(false);
	const [yupObjectDynamique, setYupObjectDynamique] = useState<any>({});
	const [addWodBookEntryScores, { error, loading }]: any = useAddWodBookEntryScoresMutation();
	useErrorModal({ error });
	const [addPublicWodScores, { error: error2, loading: loading2 }]: any = useAddPublicWodScoresMutation({
		update(cache, { data }) {
			const dataQuery: any = cache.readQuery({
				query: ShowPublicWodDocument,
				variables: {
					wodId,
				},
			});

			if (!dataQuery) return;

			const { showPublicWod } = dataQuery;

			cache.writeQuery({
				query: ShowPublicWodDocument,
				variables: {
					wodId,
				},
				data: {
					showPublicWod: update(showPublicWod, {
						latestUserScores: {
							result: {
								$unshift: [data?.addPublicWodScores],
							},
							total: {
								$apply: (x: any) => x + 1,
							},
						},
					}),
				},
			});
		},
	});
	// @ts-ignore
	useErrorModal({ error2 });

	useEffect(() => {
		const scoresExpectedFormatTemp: any = [];
		let yupObjectDynamique: any = {};
		let timeSkip = false;

		if (scoreExpected) {
			for (let i = 0; i < scoreExpected.length; i++) {
				let name: string | null = null;
				let yupValidationName: string | null = null;
				let yupValidationMethods: any = null;

				if (scoreExpected[i].type === ScoreValueType.Calories) {
					name = t('wodbook:addScoreCalories');
					yupValidationName = ScoreValueType.Calories;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Distance) {
					name = t('wodbook:addScoreDistance');
					yupValidationName = ScoreValueType.Distance;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Duration && !timeSkip) {
					name = t('wodbook:addScoreMinutes');
					yupValidationName = 'minutes';
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
					i--;
					timeSkip = true;
				} else if (scoreExpected[i].type === ScoreValueType.Duration) {
					name = t('wodbook:addScoreSeconds');
					yupValidationName = 'seconds';
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Load) {
					name = t('wodbook:addScoreWeight');
					yupValidationName = ScoreValueType.Load;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Repetitions) {
					name = t('wodbook:addScoreRepetitions');
					yupValidationName = ScoreValueType.Repetitions;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				}

				const scoreLineFormat = {
					name,
					yupValidationName,
					yupValidationMethods,
				};

				scoresExpectedFormatTemp.push(scoreLineFormat);

				if (yupValidationName && yupValidationMethods) {
					yupObjectDynamique = {
						...yupObjectDynamique,
						[yupValidationName]: yupValidationMethods,
					};
				}
			}
		}

		setScoresExpectedFormat(scoresExpectedFormatTemp);
		setYupObjectDynamique(yupObjectDynamique);
	}, [scoreExpected, setScoresExpectedFormat, t]);

	const validationSchema = Yup.object().shape({
		date: Yup.string().required(t('formik:requiredField')),
		...yupObjectDynamique,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: timeToString(moment().format()),
		},
	});

	const onSubmit = (values: any) => {
		if (!shouldSubmit || loading) return;

		const val = validationSchema.cast(values);

		if (val.minutes) {
			// @ts-ignore
			val.DURATION = (parseInt(val.minutes) * 60 + parseInt(val.seconds)).toString();
			delete val.minutes;
			delete val.seconds;
		}

		const scores = Object.entries(values)
			.filter((x) => x[0] !== 'date')
			.map((value) => {
				return {
					type: value[0],
					// @ts-ignore
					value: parseFloat(value[1].toString().replace(/,/g, '.')),
				};
			});

		if (publicWod) {
			addPublicWodScores({
				variables: {
					wodId,
					date: val?.date,
					scores,
				},
			}).then((r: any) => {
				console.log('r', r);
				setShouldSubmit(false);
				setVisible(false);
			});
		} else {
			addWodBookEntryScores({
				variables: {
					id: wodId,
					date: val?.date,
					scores,
				},
			}).then((r: any) => {
				console.log('r', r);
				setShouldSubmit(false);
				setVisible(false);
			});
		}
	};

	return (
		<div className="AddWodScoreModal modalValidation">
			<h1 className="titleXL weightMedium">{t('wodbook:addYourScores')}</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				{scoresExpectedFormat?.map(
					(scoreExpected: any, i: number) =>
						scoreExpected.yupValidationName !== 'minutes' &&
						scoreExpected.yupValidationName !== 'seconds' && (
							<TextInput
								key={i}
								required
								errors={errors}
								register={register}
								name={scoreExpected.yupValidationName}
								label={scoreExpected.name}
							/>
						)
				)}
				<div className="timeInputs">
					{scoresExpectedFormat?.map((scoreExpected: any, i: number) => {
						if (scoreExpected.yupValidationName === 'minutes' || scoreExpected.yupValidationName === 'seconds') {
							return (
								<TextInput
									key={i}
									required
									errors={errors}
									register={register}
									name={scoreExpected.yupValidationName}
									label={scoreExpected.name}
								/>
							);
						}
						return null;
					})}
				</div>
				<TextInput required errors={errors} register={register} type="date" name="date" label={t('calendar:date')} />
				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setVisible(false);
							setShouldSubmit(false);
						}}
					/>
					<Button
						buttonClass="btnDropdownGreen textNormal weightMedium"
						buttonText={t('commons:add')}
						onClick={() => {
							setShouldSubmit(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default AddWodScoreModal;
