import { Add } from '../../../main/Icons';
import { useState, useEffect, useMemo } from 'react';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import { useTranslation } from 'react-i18next';
import CategorySelector from './CategorySelector';
import Record from './Record';
import './PersonalRecord.scss';
import { useListPersonalRecordsLazyQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import moment from 'moment';
import EmptyMessage from '../../../common/EmptyMessage';
import Modal from '../../../common/Modal';
import AddPersonnalRecordModal from './AddPersonnalRecordModal';

const categories = [
	{
		title: 'Cardio',
		key: 'CARDIO',
	},
	{
		title: 'Gym',
		key: 'GYM',
	},
	{
		title: 'Barbell',
		key: 'BARBELL',
	},
];

const PersonalRecord = ({ className, userId, isSelfProfile }: any) => {
	const [addScoreModalVisible, setAddScoreModalVisible] = useState(false);
	const [categorySelect, setCategorySelect] = useState<any>(categories[0]);
	const [recordYear, setRecordYear] = useState<Array<number>>([]);
	const { t } = useTranslation();

	const [listPersonalRecords, { data, error, refetch }] = useListPersonalRecordsLazyQuery();
	useErrorModal({ error });

	const sortedData = useMemo(() => {
		if (!data?.listPersonalRecords) return;
		// @ts-ignore
		return [...data?.listPersonalRecords?.result].sort((a, b): any => {
			return (
				// @ts-ignore
				new Date(b.scoreEntries[0].date) - new Date(a.scoreEntries[0].date)
			);
		});
	}, [data]);

	//Display by year system
	useEffect(() => {
		if (sortedData && sortedData.length > 0) {
			let yearArr: Array<any> = [];
			let yearActual: any;

			sortedData.forEach((elem: any) => {
				let dateYear = moment(elem.scoreEntries[0]?.date)?.year();
				if (yearActual !== dateYear) {
					yearActual = dateYear;
					yearArr.push(dateYear);
				}
			});

			yearArr.sort((a: any, b: any) => {
				if (a < b) return 1;
				if (a > b) return -1;
				return 0;
			});

			setRecordYear(yearArr);
		}
	}, [sortedData]);

	useEffect(() => {
		if (categorySelect) {
			listPersonalRecords({
				variables: {
					category: categorySelect?.key,
					user: userId,
				},
			});
		}
	}, [categorySelect, listPersonalRecords, userId]);

	return (
		<WidgetBase className={className}>
			<div className="personalRecordWrapper">
				<WidgetTitle
					title={t('profile:widgetTitle:personalRecord')}
					ButtonIcon={isSelfProfile && Add}
					buttonClass="btnLarge btnGreen btnCentered"
					onClick={() => setAddScoreModalVisible(true)}
				/>
				<div className="selectContainer">
					<CategorySelector selectDefault={categorySelect} options={categories} setCategorySelect={setCategorySelect} />
				</div>
				<div className="personalRecordList">
					{recordYear?.map((year, index1) => {
						return (
							<div key={index1} className="year">
								<div className="yearText textMedium weightMedium">{year}</div>
								{data?.listPersonalRecords?.result?.map((record: any, index2) => {
									if (moment(record.scoreEntries[0]?.date)?.year() === year) {
										return <Record key={index2} data={record} />;
									}

									return null;
								})}
							</div>
						);
					})}
				</div>
				{
					// @ts-ignore
					data?.listPersonalRecords?.total <= 0 && <EmptyMessage message={t('profile:widgetPersonalRecord:noData')} />
				}
				<Modal visible={addScoreModalVisible} setVisible={setAddScoreModalVisible}>
					<AddPersonnalRecordModal
						visible={addScoreModalVisible}
						setVisible={setAddScoreModalVisible}
						category={categorySelect}
						refetch={refetch}
					/>
				</Modal>
			</div>
		</WidgetBase>
	);
};

export default PersonalRecord;
