import { useContext, useEffect } from 'react';
import SelectedWod from './SelectedWod';
import SelectedWodPublic from './SelectedWodPublic';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks, BodyScrollOptions } from 'body-scroll-lock';
import { useMediaQuery } from 'react-responsive';
import './WodbookShow.scss';
import { X } from '../../../main/Icons';
import { WodContext } from '../Context';

const options: BodyScrollOptions = {
	reserveScrollBarGap: true,
};

const WodbookShow = () => {
	const { isPublicWod, selectedWod, setSelectedWod } = useContext(WodContext);
	const isLg = useMediaQuery({ query: '(min-width: 800px)' });

	useEffect(() => {
		let scrollElem: any = document.querySelector('.wodbookShow');

		if (!isLg) {
			if (selectedWod === undefined) {
				enableBodyScroll(scrollElem);
			} else {
				disableBodyScroll(scrollElem, options);
			}
		} else {
			clearAllBodyScrollLocks();
		}

		return () => {
			clearAllBodyScrollLocks();
		};
	}, [selectedWod, isLg]);

	if (!selectedWod) return null;

	return (
		<div className="wodbookShow">
			{!isLg && (
				<button className="closeButton" onClick={() => setSelectedWod(undefined)}>
					<X />
				</button>
			)}
			{isPublicWod ? <SelectedWodPublic wodId={selectedWod} /> : <SelectedWod wodId={selectedWod} />}
		</div>
	);
};

export default WodbookShow;
