import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScoreValueType } from '../../../../generated/gql';
import { formatScore } from '../../../../utils/helpers';
import { Trash2 } from '../../../main/Icons';
import { WodContext } from '../Context';
import ModalDeleteScore from './ModalDeleteScore';
import './Score.scss';

const Score = ({ score, wodId }: any) => {
	const { t } = useTranslation();
	const [modalDeleteScore, setModalDeleteScore] = useState(false);
	const { wodEditMode } = useContext(WodContext);

	return (
		<div className="wodScore">
			<div className="wodScoreWrapped">
				<div className="date textMedium weightBold">{moment(score.date).format('L')}</div>

				<div className="scoreList">
					{score.scores.map((scoreData: any, i: number) => (
						<div key={i} className="scoreData">
							<div className="left">
								<p className="score textMedium weightMedium">{formatScore(scoreData?.type, scoreData?.value)}</p>
							</div>
							<div className="right">
								<div className="name textMedium weightBold">
									{scoreData?.type === ScoreValueType.Duration
										? t('session:duration')
										: scoreData?.type === ScoreValueType.Calories
										? t('session:calories')
										: scoreData?.type === ScoreValueType.Load
										? t('session:load')
										: scoreData?.type === ScoreValueType.Repetitions
										? t('session:repetitions')
										: scoreData?.type === ScoreValueType.Distance
										? t('session:distance')
										: null}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="actions">
				{wodEditMode && (
					<>
						<button className="btnDropdownRed" onClick={() => setModalDeleteScore(true)}>
							<Trash2 />
						</button>

						<ModalDeleteScore
							visible={modalDeleteScore}
							setVisible={setModalDeleteScore}
							wodId={wodId}
							scoreId={score?.id}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default Score;
