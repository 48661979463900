import './WodBookPage.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WodbookList from '../../components/pages/wodbook/WodbookList/WodbookList';
import WodbookShow from '../../components/pages/wodbook/WodbookShow/WodbookShow';
import { WodContext, WodContextProvider } from '../../components/pages/wodbook/Context';
import { Add } from '../../components/main/Icons';
import Modal from '../../components/common/Modal';
import Button from '../../components/common/Button';
import TextInput from '../../components/common/Form/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ListWodBookEntriesDocument, useCreateWodBookEntryMutation } from '../../generated/gql';
import { useErrorModal } from '../../hooks/useErrorModal';
import update from 'immutability-helper';
import { useParams } from 'react-router-dom';

export const WodBookPage = () => {
	return (
		<WodContextProvider>
			<div className="wodbookPage">
				<WodBookPageTitle />
				<div className="content">
					<div className="column1 column">
						<WodbookList />
					</div>
					<div className="column2 column">
						<WodbookShow />
					</div>
				</div>
			</div>
		</WodContextProvider>
	);
};

const WodBookPageTitle = () => {
	const { slug } = useParams<any>();
	const { t } = useTranslation();
	const [modalAddWodbook, setAddWodbook] = useState(false);
	const { setSelectedWod, setIsPublicWod } = useContext(WodContext);
	const [createWodBookEntry, { loading, error }] = useCreateWodBookEntryMutation({
		update(cache, { data }) {
			const dataQuery = cache.readQuery({
				query: ListWodBookEntriesDocument,
			});

			if (!dataQuery) return;

			// @ts-ignore
			const { listWodBookEntries } = dataQuery;

			cache.writeQuery({
				query: ListWodBookEntriesDocument,
				data: {
					listWodBookEntries: update(listWodBookEntries, {
						result: {
							$push: [data?.createWodBookEntry],
						},
						total: {
							$apply: (x: number) => x + 1,
						},
					}),
				},
			});
			setSelectedWod(data?.createWodBookEntry?.id);
		},
	});
	useErrorModal({ error });
	const { isPublicWod } = useContext(WodContext);

	const validationSchema = Yup.object().shape({
		wodName: Yup.string().trim().required(t('formik:requiredField')),
	});

	//use to come back to the good wod after editing it
	useEffect(() => {
		setIsPublicWod(false);
	}, [slug, setIsPublicWod]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = (values: any) => {
		if (loading) return;

		const val = validationSchema.cast(values);

		createWodBookEntry({
			variables: {
				exerciseName: val?.wodName || '',
			},
		}).then((r: any) => console.log('r createWodBookEntry', r));
	};

	return (
		<h1 className="titleXXL weightBold">
			{t('wodbook:wodbook')}
			{!isPublicWod && (
				<button className="btn btnCentered btnGreen" onClick={() => setAddWodbook(true)}>
					<Add />
				</button>
			)}
			<Modal visible={modalAddWodbook} setVisible={setAddWodbook}>
				<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
					<div className="modalValidation">
						<h1 className="titleXL weightMedium">{t('wodbook:addWod')}</h1>
						<TextInput required errors={errors} register={register} name="wodName" label={t('wodbook:wodName')} />
						<div className="actions">
							<div />
							<Button
								buttonClass="btnDropdownGreen textNormal weightMedium"
								buttonText={t('commons:add')}
								onClick={() => {
									setAddWodbook(false);
								}}
							/>
						</div>
					</div>
				</form>
			</Modal>
		</h1>
	);
};
