import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TimerConfigDataContext } from '../contexts/TimerConfigDataContext'
import './TimerChrono.scss'
import TimerChronoApp from './TimerChronoApp/TimerChronoApp'
import TimerPreStart from './TimerPreStart'

const TimerChrono = () => {
	const [preStart, setPreStart] = useState(true)
	const {timerConfig} = useContext(TimerConfigDataContext)
	const history = useHistory();

	useEffect(() => {
		history.push(`/timer/start`);
	}, [])

	return (
		<div className="timerChronoApp">

			{
				preStart ?
					<TimerPreStart setPreStart={setPreStart} />
				:
					<TimerChronoApp />
			}
			
		</div>
	)
}

export default TimerChrono
