import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { secToString } from '../../../../../utils/chrono';
import { useMediaQuery } from 'react-responsive';
import './TimerEndScreen.scss';

const TimerEndScreen = ({ timerTotalValue, laps, nameCat }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const isMobile = useMediaQuery({ query: '(min-width: 1200px)' });

	const handleClickExit = () => {
		history.goBack();
	};

	return (
		<div className="timerEndScreenModal">
			<div className="timeEndScreenModalWrapper">
				<div className="left">
					<div className="content">
						<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98">
							<g clipPath="url(#a)">
								<path d="M89.3 53.73a44.27 44.27 0 1 1-88.54 0 44.27 44.27 0 0 1 88.55 0Z" fill="#937DE2" />
								<path
									d="M45.04 9.46c-2.34 0-4.64.18-6.88.53a44.28 44.28 0 0 1 0 87.48 44.27 44.27 0 1 0 6.88-88Z"
									fill="#7570D6"
								/>
								<path
									d="M94.84 50.46 69.56 67.2a8.61 8.61 0 0 1-6.12 1.32 8.64 8.64 0 0 0-6.13 1.32l-3.38 2.25-18.95-27.93 4.65-20.08a21 21 0 0 0-2.95-16.3A5 5 0 0 1 38.1.83a5 5 0 0 1 6.25.58l3.01 2.92a32.19 32.19 0 0 1 7.77 11.9l1.63 4.4 16.82-11.1a5.73 5.73 0 0 1 8.03 1.75 5.8 5.8 0 0 1-1.8 7.87c-.03.02-.03.06-.02.08.01.03.05.05.08.03a5.7 5.7 0 0 1 6.84 2.2 5.71 5.71 0 0 1-1.56 7.9.12.12 0 0 0-.04.17c.04.06.12.07.17.04a6.04 6.04 0 0 1 4.63.77 5.73 5.73 0 0 1 .06 9.59l-.2.13c-.04.03-.05.07-.03.1 0 .02.01.03.02.03.03.02.07.02.11 0a5.61 5.61 0 0 1 4.87.62c.66.43 1.24 1 1.7 1.7a5.74 5.74 0 0 1-1.6 7.95Z"
									fill="#FDD7BD"
								/>
								<path
									d="M54.17 77.39 43.5 84.45l-15.4 10.2A44.32 44.32 0 0 1 1.19 59.82l17.87-11.84 10.88-7.2a2.72 2.72 0 0 1 3.77.77L54.94 73.6a2.72 2.72 0 0 1-.77 3.78Z"
									fill="#87DBFF"
								/>
								<path
									d="M54.17 77.39 43.5 84.45 19.05 47.98l10.88-7.2a2.72 2.72 0 0 1 3.77.77L54.94 73.6a2.72 2.72 0 0 1-.77 3.78Z"
									fill="#6FC7FF"
								/>
								<path
									d="m85.04 19.8-8.4 4.5c-1.2.64-2.68.3-3.48-.78l6.63-4.29c.01.03.04.05.08.03a5.7 5.7 0 0 1 5.17.54ZM89.9 30.34l-8.22 4.4c-1.2.64-2.68.3-3.48-.78l6.63-4.3.29-.13c.03.06.1.07.16.04a6.04 6.04 0 0 1 4.63.77ZM94.74 40.81l-7.25 3.88c-1.2.63-2.67.3-3.48-.78l5.75-3.72a.1.1 0 0 0 .11 0 5.62 5.62 0 0 1 4.87.62Z"
									fill="#FAC5AA"
								/>
							</g>
							<defs>
								<clipPath id="a">
									<path fill="#fff" d="M0 0h98v98H0z" />
								</clipPath>
							</defs>
						</svg>

						{nameCat && <h1 className="catName weightSemiBold">{nameCat}</h1>}

						{timerTotalValue && (
							<h1 className="weightSemiBold">
								{t('chrono:totalTime')} : {secToString(timerTotalValue)}
							</h1>
						)}

						{isMobile && 
							<button className="btn btnGreen weightSemiBold" onClick={handleClickExit}>
								Ok
							</button>
						}
					</div>
				</div>

				<div className="right">
					{laps.length > 0 && (
						<div className="laps">
							{laps.map((lap: any, index: number) => (
								<div key={index} className="lap weightSemiBold">
									<div className="lapNumber">{index + 1}</div>
									<div className="lapStart">
										{t('chrono:startLap') + ' : '}
										{secToString(lap.start)}
									</div>
									<div className="lapTime">
										{t('programs:details:duration') + ' : '}
										{secToString(lap.time)}
									</div>
								</div>
							))}
						</div>
					)}

					<div className="actions">
						{!isMobile &&
							<button className="btn btnGreen weightSemiBold" onClick={handleClickExit}>
								Ok
							</button>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimerEndScreen;
