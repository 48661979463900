import { useListProgramUsersQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import EmptyMessage from '../../../common/EmptyMessage';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ProgListMemberLine from './ProgListMemberLine';

import './ProgListMember.scss';

const ProgListMember = ({ programId, userRole, dataProgram }: any) => {
	const { t } = useTranslation();

	const { data, error, loading, refetch }: any = useListProgramUsersQuery({
		variables: {
			id: programId,
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	return (
		<div className="progListMember">
			<h1 className="titleXL weightBold">{t('programs:currentMembers')}</h1>

			<div className="memberTable">
				{data?.listProgramUsers?.result?.length > 0 ? (
					data?.listProgramUsers?.result?.map((user: any, key: any) => {
						return (
							<ProgListMemberLine
								userData={user}
								programId={programId}
								userRole={userRole}
								key={key}
								dataProgram={dataProgram}
							/>
						);
					})
				) : (
					<EmptyMessage message={t('programs:noMember')} />
				)}
			</div>
		</div>
	);
};

export default ProgListMember;
