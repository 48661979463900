import './CalendarMobile.scss';
import React, { useContext, useEffect } from 'react';
import { CalendarContext } from '../contexts/CalendarContext';
import CalendarSelector from '../calendarMainSidebar/CalendarSelector';
import CalendarMobileSessionsList from './CalendarMobileSessionsList';
import { DateShowFormat } from '../_types';

const CalendarMobile = () => {
	const {setDateShowFormat } = useContext(CalendarContext);

	useEffect(() => {
		setDateShowFormat(DateShowFormat.WEEK);
	}, [setDateShowFormat]);

	return (
		<div className="calendarMobile">
			<div className="calendarSeletorWrapper">
				<CalendarSelector />
			</div>

			<div className="calendarMobileSessionsListWrapper">
				<CalendarMobileSessionsList />
			</div>
		</div>
	);
};

export default CalendarMobile;
