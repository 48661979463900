import './Payment.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import { useCreateCustomerPortalSessionMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import Loader from '../../../main/Loader';
import useUser from '../../../../hooks/user/useUser';

const Payment = () => {
	const { user } = useUser();
	const { t } = useTranslation();
	const [loadingUrl, setLoadingUrl] = useState(false);
	const [createCustomerPortalSession, { error, loading }]: any = useCreateCustomerPortalSessionMutation();
	useErrorModal({ error });

	const onClick = () => {
		setLoadingUrl(true);
		createCustomerPortalSession({
			variables: {
				returnUrl: `${process.env.REACT_APP_DOMAIN_NAME}settings`,
			},
		})
			.then((r: any) => {
				console.log('r createCustomerPortalSession', r);
				if (r?.data?.createCustomerPortalSession?.url)
					window.location.replace(r?.data?.createCustomerPortalSession?.url);
			})
			.catch((e: any) => {
				setLoadingUrl(false);
				console.log(e);
			});
	};

	return (
		<div className="settingsPayment pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:payment')}</h1>
			{user?.stripe_customer_id ? (
				<div className="settingsPaymentWrapper settingsWrappers">
					<p className="textMedium weightMedium">{t('settings:billing:billingText')}</p>
					<p className="textMedium weightMedium">{t('settings:billing:billingText2')}</p>
					{loadingUrl ? (
						<Loader size={60} />
					) : (
						<Button
							loading={loading}
							buttonText={t('settings:billing:openStipeBilling')}
							buttonClass="btnGreen textNormal weightMedium"
							onClick={onClick}
						/>
					)}
				</div>
			) : (
				<div className="settingsPaymentWrapper settingsWrappers">
					<p>{t('settings:billing:noStripeAccount')}</p>
				</div>
			)}
		</div>
	);
};

export default Payment;
