import { useCallback, useEffect, useState } from 'react';
import { useListUsersLazyQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import Loader from '../../main/Loader';
import { useTranslation } from 'react-i18next';
import UserCard from '../../common/UserCard';

import './SearchListUser.scss';

const SearchListUser = ({ searchQuery, onPress }: any) => {
	const [loadingMore, setLoadingMore] = useState(false);
	const { t } = useTranslation();
	const [getUsersList, { data, error, loading, refetch, fetchMore }] = useListUsersLazyQuery({
		fetchPolicy: 'network-only',
	});
	useErrorModal({ error, onRetry: () => refetch });

	/*const fetchMoreUsers = useCallback(() => {
		if (data?.listUsers?.result?.length === data?.listUsers?.total) {
			return;
		}
		setLoadingMore(true);
		if (fetchMore) {
			fetchMore({
				variables: {
					offset: data?.listUsers?.result?.length,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					return {
						listUsers: {
							...fetchMoreResult?.listUsers,
							result: [
								// @ts-ignore
								...prev?.listUsers?.result,
								// @ts-ignore
								...fetchMoreResult?.listUsers?.result,
							],
						},
					};
				},
			}).finally(() => {
				setLoadingMore(false);
			});
		}
		// @ts-ignore
	}, [data?.listUsers?.result?.length, data.listUsers?.total, fetchMore]);*/

	useEffect(() => {
		getUsersList();
	}, [getUsersList]);

	const getUser = useCallback(async () => {
		await getUsersList({
			variables: {
				name: searchQuery.trim(),
			},
		});
	}, [getUsersList, searchQuery]);

	useEffect(() => {
		if (searchQuery !== '') getUser().catch((e) => console.error(e));
		else getUsersList();
	}, [getUser, getUsersList, searchQuery]);

	if (loading) return <Loader type="Rings" color="#319795" height={80} width={80} />;

	return (
		<div className="searchListUser">
			{
				// @ts-ignore
				data?.listUsers?.total > 0 ? (
					// @ts-ignore
					data?.listUsers?.result.map((user: any) => {
						return (
							<UserCard
								key={user.id}
								data={user}
								imgSize={42}
								classNameName="textMedium weightMedium"
								classNameNickname="textSmall weightMedium"
								onClick={onPress ? () => onPress(user.id) : null}
							/>
						);
					})
				) : (
					<div>
						<p>{t('errors:noProfilFound')}</p>
					</div>
				)
			}
		</div>
	);
};

export default SearchListUser;
