import { useListProgramJoinRequestsQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import EmptyMessage from '../../../common/EmptyMessage';
import { useTranslation } from 'react-i18next';
import ProgAcceptNewMemberLine from './ProgAcceptNewMemberLine';

import './ProgAcceptNewMember.scss';

const ProgAcceptNewMember = ({ programId }: any) => {
	const { t } = useTranslation();

	const {
		data,
		error,
		loading,
		refetch,
	}: any = useListProgramJoinRequestsQuery({
		fetchPolicy: 'network-only',
		variables: {
			id: programId,
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	const onUpdate = () => {
		refetch();
	};

	return (
		<div className="progAcceptNewMember">
			<h1 className="titleXL weightBold">{t('programs:requestForAthletes')}</h1>

			<div className="memberTable">
				{data?.listProgramJoinRequests?.result?.length > 0 ? (
					data?.listProgramJoinRequests?.result?.map((user: any, key: any) => {
						return (
							<ProgAcceptNewMemberLine
								key={key}
								userData={user}
								programId={programId}
								onUpdate={onUpdate}
							/>
						);
					})
				) : (
					<EmptyMessage message={t('programs:noUserWaiting')} />
				)}
			</div>
		</div>
	);
};

export default ProgAcceptNewMember;
