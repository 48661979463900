import './CalendarSessionNavbar.scss';
import { useMediaQuery } from 'react-responsive';
import React, { useState, useContext, useEffect } from 'react';
import { CalendarSessionContext } from '../contexts/CalendarSessionContext';
import { CalendarSessionTab } from '../_types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Check, ChevronLeft, Edit, Pen, Trash2, X } from '../../../main/Icons';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import { useDeleteProgramSessionMutation, useUpdateProgramSessionMutation } from '../../../../generated/gql';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const CalendarSessionNavbar = ({ data }: any) => {
	const { t } = useTranslation();
	const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const { dataSession, ownerSession, setEditMode, editMode } = useContext(CalendarSessionContext);
	const history = useHistory();
	const { slug } = useParams<any>();
	const [sessionNameEdit, setSessionNameEdit] = useState(false);
	const [sessionName, setSessionName] = useState<string>(dataSession?.showProgramSession?.name);
	const [name, setName] = useState<string>(dataSession?.showProgramSession?.name);
	const [modalDelete, setModalDelete] = useState<boolean>(false);
	const [updateProgramSession, { data: dataUpdateProgramSEssion, error }] = useUpdateProgramSessionMutation();
	useErrorModal({ error });

	const onClickUpdateName = () => {
		if (sessionName.trim() === '') return;

		updateProgramSession({
			variables: {
				id: dataSession?.showProgramSession?.id,
				// @ts-ignore
				name: sessionName.trim(),
			},
		}).then((r) => {
			console.log('r', r);
			setSessionNameEdit(false);
			// @ts-ignore
			setSessionName(r?.data?.updateProgramSession?.name);
			// @ts-ignore
			setName(r?.data?.updateProgramSession?.name);
		});
	};

	return (
		<>
			<div className="planningButton">
				<Button
					buttonText={t('calendar:returnToCalendar')}
					buttonClass="btnGreyNoBack textNormal weightMedium btnDuo"
					ButtonIcon={ChevronLeft}
					onClick={() => {
						history.push(`/program/${slug}/calendar`, {
							date: dataSession?.showProgramSession?.plannedAt,
						});
					}}
				/>
			</div>

			<div className="calendarSessionNavbar">
				<div className="left">
					<div className="left">
						<div className="titles">
							<>
								<h2 className="titleL weightMedium">
									{moment(dataSession?.showProgramSession?.plannedAt).format('L')}
								</h2>
								<h2 className="titleXL weightMedium">{dataSession.showProgramSession?.program?.name}</h2>
								<h1 className="titleXXL weightBold">
									<div className="sessionName">
										{!sessionNameEdit ? (
											name
										) : (
											<form>
												<input
													type="text"
													className="titleXL weightMedium"
													value={sessionName}
													onChange={(e: any) => setSessionName(e.target.value)}
												/>
											</form>
										)}
									</div>
									{ownerSession && editMode && (
										<div className="actionsSession">
											{sessionNameEdit ? (
												<>
													<button className="buttonCheck" onClick={onClickUpdateName}>
														<Check />
													</button>
													<button className="buttonCancel" onClick={() => setSessionNameEdit(!sessionNameEdit)}>
														<X />
													</button>
												</>
											) : (
												<button
													onClick={() => {
														setSessionNameEdit(!sessionNameEdit);
														setSessionName(name);
													}}
												>
													<Pen />
												</button>
											)}
										</div>
									)}
								</h1>
							</>
						</div>
						{!isTablet && (
							<nav className="menu noselect">
								<MenuItem name={t('commons:exercices')} type={CalendarSessionTab.EXERCICES} disable={false} />
								<MenuItem
									name={t('commons:comments')}
									type={CalendarSessionTab.COMMENTS}
									disable={dataSession.showProgramSession.exercises.length === 0}
								/>
							</nav>
						)}
					</div>
					<div className="right">
						{ownerSession && editMode && (
							<>
								<button
									className="deleteButton editsBtn"
									onClick={() => {
										setModalDelete(true);
									}}
								>
									<Trash2 />
								</button>
								<DeleteModal
									modalDelete={modalDelete}
									setModalDelete={setModalDelete}
									sessionId={dataSession?.showProgramSession?.id}
								/>
							</>
						)}
						{ownerSession && !editMode && (
							<button
								className="editButton editsBtn"
								onClick={() => {
									setEditMode(!editMode);
								}}
							>
								<Edit />
							</button>
						)}
					</div>
				</div>
				<div className="right titleXXL weightBold noselect">{t('commons:comments')}</div>
			</div>
		</>
	);
};

interface MenuItemProps {
	name: string;
	type: CalendarSessionTab;
	disable: boolean;
}

const MenuItem = ({ name, type, disable }: MenuItemProps) => {
	const { tab, setTab } = useContext(CalendarSessionContext);

	const handleClick = () => {
		if (!disable) {
			setTab(type);
		}
	};

	return (
		<div
			className="menuItem titleL weightBold"
			style={{
				borderBottom: tab === type ? '2px solid #4C5771' : 'unset',
				cursor: disable ? 'unset' : 'pointer',
				color: disable ? '#ABB5CD' : '#4C5771',
			}}
			onClick={handleClick}
		>
			{name}
		</div>
	);
};

const DeleteModal = ({ modalDelete, setModalDelete, sessionId }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { slug } = useParams<any>();

	const [deleteProgramSession, { data, error, loading }] = useDeleteProgramSessionMutation();

	const onDeleteClick = () => {
		deleteProgramSession({
			variables: {
				id: sessionId,
			},
		}).then((r) => {
			console.log('r', r);
			history.push('/program/' + slug + '/calendar');
		});
	};

	return (
		<Modal visible={modalDelete} setVisible={setModalDelete}>
			<div className="deleteModale">
				<h1 className="titleXL weightMedium">{t('calendar:deleteSeance')}</h1>
				<h2 className="textMedium weightMedium">{t('calendar:deleteSeanceBody')}</h2>
				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setModalDelete(false);
						}}
					/>
					<Button
						onClick={onDeleteClick}
						buttonClass="btnTextRed textNormal weightMedium"
						buttonText={t('commons:delete')}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default CalendarSessionNavbar;
