import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';
import { composeWithDevTools } from 'redux-devtools-extension';
import amplifyConfig from './aws-config';
import reducer from './store/SystemReducer';
const store = createStore(reducer, composeWithDevTools());

Amplify.configure(amplifyConfig);

//remove log for prod
const PROD_MOD = process.env.REACT_APP_DOMAIN_NAME === 'https://web.hustleup-app.com/';

if (PROD_MOD) {
	console.log = function () {};
	console.error = function () {};
}

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
