import './SessionLine.scss';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { ProgIcon } from '../../../main/ProgIcon';
import moment from 'moment';
import { CalendarContext } from '../contexts/CalendarContext';
import { DateShowFormat } from '../_types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SessionLineProps {
	data: any;
	me: any;
	setSessionDragging: React.Dispatch<any> | undefined;
}

const SessionLine = ({ data, me, setSessionDragging = undefined }: SessionLineProps) => {
	const { dateShowFormat, dateSelected, programPage } = useContext(CalendarContext);
	const [ownerSession, setOwnerSession] = useState<boolean>();
	const [pastSession, setPastSession] = useState<boolean>();
	const [classNameDragging, setClassNameDragging] = useState('');
	const sessionLineCalendar = useRef<HTMLDivElement>(null);
	let history = useHistory();

	//Style
	useEffect(() => {
		if (me && data.program.creator) {
			let classNameSessionTemp: string;
			let dateSessionFormat = moment(data.plannedAt).format('YYYY-MM-DD');
			let dateToday = moment().format('YYYY-MM-DD');

			setPastSession(moment(dateSessionFormat).isBefore(dateToday));
			setOwnerSession(me?.user?.id === data?.program?.creator?.id);
		}
	}, [data, me, dateSelected]);

	// Drag and drop
	useEffect(() => {
		const sessionLine = sessionLineCalendar.current;

		const dragstart = (e: Event) => {
			if (setSessionDragging) {
				setClassNameDragging('dragging');
				setSessionDragging(data);
			}
		};

		const dragend = (e: Event) => setClassNameDragging('');

		sessionLine?.addEventListener('dragstart', dragstart);
		sessionLine?.addEventListener('dragend', dragend);
		return () => {
			sessionLine?.removeEventListener('dragstart', dragstart);
			sessionLine?.removeEventListener('dragend', dragend);
		};
	}, [data, setSessionDragging, programPage]);

	//Click on session
	const handleClick = () => {
		history.push('/program/' + data.program.id + '/session/' + data.id);
	};

	return (
		<div
			className={`
				sessionLineCalendar
				${classNameDragging}
			`}
			onClick={handleClick}
			draggable={programPage !== undefined}
			ref={sessionLineCalendar}
		>
			{dateShowFormat === DateShowFormat.MONTH ? (
				<SessionLineMonth data={data} ownerSession={ownerSession} pastSession={pastSession} />
			) : (
				<SessionLineWeek data={data} ownerSession={ownerSession} pastSession={pastSession} />
			)}
		</div>
	);
};

interface SessionLineTemplateProps {
	data: any;
	ownerSession: boolean | undefined;
	pastSession: boolean | undefined;
}

const SessionLineMonth = ({ data, ownerSession, pastSession }: SessionLineTemplateProps) => {
	let classSessionLineMonth: string;

	if (ownerSession) {
		classSessionLineMonth = pastSession
			? 'sessionLineCalendarGreen sessionLineCalendarGreenOld'
			: 'sessionLineCalendarGreen';
	} else {
		classSessionLineMonth = pastSession
			? 'sessionLineCalendarBlue sessionLineCalendarBlueOld'
			: 'sessionLineCalendarBlue';
	}

	return (
		<div
			className={`
				sessionLineCalendarMonth
				${classSessionLineMonth}
			`}
		>
			<div className="iconPro">
				<ProgIcon url={data?.program?.avatar?.url} />
			</div>
			<h1>{data?.name}</h1>
		</div>
	);
};

const SessionLineWeek = ({ data, ownerSession, pastSession }: SessionLineTemplateProps) => {
	const { t } = useTranslation();
	let classSessionLineWeekTitle: string;
	let classSessionLineWeekBody: string;

	if (ownerSession) {
		classSessionLineWeekTitle = 'sessionLineWeekGreenTitle';
		classSessionLineWeekBody = pastSession ? 'sessionLineCalendarGreenOld' : 'sessionLineWeekGreenBody';
	} else {
		classSessionLineWeekTitle = 'sessionLineWeekBlueTitle';
		classSessionLineWeekBody = pastSession ? 'sessionLineCalendarBlueOld' : 'sessionLineWeekBlueBody';
	}

	return (
		<div className="sessionLineCalendarWeek">
			<div
				className={`
					titleBar
					${classSessionLineWeekTitle}
				`}
			>
				{data.name}
			</div>

			<div
				className={`
					programInfos
					${classSessionLineWeekBody}
				`}
			>
				<div className="infos">
					<div className="image">
						<ProgIcon url={data?.program?.avatar?.url} />
					</div>
					<div className="texts weightMedium">
						<h1>{data?.program?.name}</h1>
						<h2>@{data?.program?.creator?.nickname}</h2>
					</div>
				</div>
				<div
					className="exercicesNumber weightMedium"
					style={{
						color: ownerSession ? '#21B7A6' : '#3391C8',
					}}
				>
					{data?.exercises?.length === 0
						? t('calendar:noExercises')
						: data?.exercises?.length === 1
						? '1 ' + t('calendar:exercise')
						: data?.exercises?.length + ' ' + t('calendar:exercise')}
				</div>
			</div>
		</div>
	);
};

export default React.memo(SessionLine);
