import './ProgramHomeDetails.scss';

import React from 'react';
import { UserIcon } from '../../../main/UserIcon';
import { Users, ChevronRight, Clock, Star } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { computeProgramNote } from '../../../../utils/helpers';

const ProgramHomeDetails = ({ data }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const stars = computeProgramNote(data?.showProgram?.points, data?.showProgram?.notesNumber);

	return (
		<div className="programHomeDetails">
			<div className="presentation">
				<div className="coach detailContainer">
					<div className="avatarCoach">
						<UserIcon
							url={data?.showProgram?.creator?.profile_picture?.url}
							text={data?.showProgram?.creator?.firstName?.charAt(0) + data?.showProgram?.creator?.firstName?.charAt(1)}
							size="80"
						/>
					</div>
					<div className="infosCoach">
						<div className="coachDeco textNormal weightSemiBold">{t('programs:details:coach')}</div>
						<h1 className="titleXL weightBold">
							{data?.showProgram?.creator?.lastName + ' ' + data?.showProgram?.creator?.firstName}
						</h1>
						<h2 className="textMedium weightMedium">@{data?.showProgram?.creator?.nickname}</h2>
					</div>
				</div>
			</div>

			<div className="infos">
				<div className="members detailContainer">
					<div className="roundIcon">
						<Users />
					</div>

					{/*<div className="infosProgram" onClick={() => history.push('/program/' + data?.showProgram?.id + '/member')}>*/}
					<div className="infosProgram">
						<div className="left">
							<h1 className="textMedium weightMedium">{t('programs:details:members')}</h1>
							<h2
								className="titleXXXL weightMedium"
								style={{
									color: data?.showProgram?.users === 0 ? '#ABB5CD' : '#4C5771',
								}}
							>
								{data?.showProgram?.users}
								{data?.showProgram?.usersLimit > 0 && (
									<span className="textMedium">{' / ' + data?.showProgram?.usersLimit}</span>
								)}
							</h2>
						</div>
						{/*	<div className="right">
							<ChevronRight />
						</div>*/}
					</div>
				</div>

				<div className="duration detailContainer">
					<div className="roundIcon">
						<Star />
					</div>

					{/*					<div className="infosProgram">
						<div className="left">
							<h1 className="textMedium weightMedium">{t('programs:details:duration')}</h1>
							<h2 className="titleXXXL weightMedium">{data?.showProgram?.duration}</h2>
						</div>
						<div className="right">
							<ChevronRight />
						</div>
					</div>*/}
					<div className="infosProgram">
						<div className="left">
							<h1 className="textMedium weightMedium">{t('programs:details:note')}</h1>
							<h2 className="titleXXXL weightMedium">
								{stars ? (
									<>
										{stars} <span className="textMedium">{' / ' + 5}</span>
									</>
								) : (
									'-'
								)}
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProgramHomeDetails;
