import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Layout from '../../layout';

export default function UnauthenticatedRoute({ component: C, appProps, layout, path, ...rest }: any) {
	return (
		<Route
			{...rest}
			render={(props) =>
				!appProps.isAuthenticated || path === '/cgu' ? (
					<Layout type={layout} breadcrumb={[]}>
						<C {...props} {...appProps} />
					</Layout>
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
}
