import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import './SelectInput.scss';

const SelectInput = ({ options, errors, control, required, name, watch }: any) => {
	// const [selectedOption, setSelectedOption] = useState<any>(null);

	// const handleChange = (selectedOption: any) => {
	// 	setSelectedOption(selectedOption);
	// };
	const optionsInitial = [...options];
	const [optionsState, setOptionsState] = useState<any>(options);

	const customStyles = {
		container: (provided: any, state: any) => ({
			...provided,
			border: 'none',
			outline: 'none',
		}),
		control: (provided: any, state: any) => {
			let borderColor = '1px solid #E6EAF4';
			if (errors[name]) {
				borderColor = '1px solid #FF5925';
			}
			return {
				...provided,
				border: state.isFocused && state.menuIsOpen ? '1px solid #4C5771' : borderColor,
				borderBottom: state.isFocused && state.menuIsOpen ? 'none' : borderColor,
				borderBottomLeftRadius: state.isFocused ? '0' : '4px',
				borderBottomRightRadius: state.isFocused ? '0' : '4px',
				boxShadow: 'none',
				'&:hover': {
					borderTop: '1px solid #4C5771',
					borderLeft: '1px solid #4C5771',
					borderRight: '1px solid #4C5771',
					borderBottom: state.menuIsOpen ? 'none' : '1px solid #4C5771',
					borderBottomLeftRadius: state.menuIsOpen ? '0' : '4px',
					borderBottomRightRadius: state.menuIsOpen ? '0' : '4px',
				},
				padding: '4px 6px',
			};
		},
		dropdownIndicator: (provided: any, state: any) => ({
			...provided,
			borderLeft: 'unset',
			borderLeftColor: 'unset',
			outline: 'unset',
		}),
		placeholder: (provided: any, state: any) => ({
			color: '#ABB5CD',
		}),
		menu: (provided: any, state: any) => ({
			top: '0px',
			marginTop: '-1px',
			minHeight: '0px',
			height: 'auto',
			borderBottom: '1px solid #4C5771',
			borderLeft: '1px solid #4C5771',
			borderRight: '1px solid #4C5771',
			borderTop: 'none',
			borderBottomLeftRadius: '4px',
			borderBottomRightRadius: '4px',
			overflow: 'hidden',
			background: 'white',
			padding: 0,
		}),
		menuPortal: (base: any) => ({
			...base,
			marginTop: '-1px',
		}),
		menuList: (provided: any, state: any) => ({
			margin: 0,
			padding: 0,
		}),
		option: (provided: any, state: any) => ({
			top: 0,
			marginTop: 0,
			padding: '13px 16px',
			fontSize: '14px',
			fontWeight: 400,
			display: 'flex',
			'&:hover': {
				background: '#F2F5FB',
				cursor: 'pointer',
			},
		}),
	};

	// Modifie les options en fonctions de la selection
	useEffect(() => {
		const stateUpdate = [...optionsInitial];
		const selected = watch(name);

		if (selected) {
			const newState: any = [];
			stateUpdate.forEach((option) => {
				if (option.value !== selected.value) newState.push(option);
			});
			setOptionsState(newState);
		}
	}, [watch(name)]);

	return (
		<div className="selectInput">
			<div style={{ position: 'absolute', width: '100%' }}>
				<Controller
					control={control}
					name={name}
					render={({ field: { onChange, onBlur, value, name, ref } }) => (
						<Select
							value={value}
							styles={customStyles}
							onChange={onChange}
							options={optionsState}
							className="textMedium weightMedium"
							isSearchable={false}
						/>
					)}
				/>

				{errors[name] && <p className="errorMessage textSmall weightMedium">{errors[name] && errors[name].message}</p>}
			</div>
		</div>
	);
};

export default SelectInput;
