import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import './ModalDeleteScore.scss';
import { useDeleteWodBookEntryScoresMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const ModalDeleteScore = ({ visible, setVisible, wodId, scoreId }: any) => {
	const { t } = useTranslation();
	const [deleteWodBookEntryScores, { error, loading }] = useDeleteWodBookEntryScoresMutation();
	useErrorModal({ error });

	const deleteClick = () => {
		if (loading) return;

		deleteWodBookEntryScores({
			variables: {
				scoreId,
				entryId: wodId,
			},
		})
			.then((r) => {
				console.log('deleteWodBookEntryScores', r);
			})
			.catch((e) => console.log('e', e))
			.finally(() => setVisible(false));
	};

	return (
		<Modal visible={visible} setVisible={setVisible}>
			<div className="modalValidation">
				<h1 className="titleXL weightMedium">{t('wodbook:deleteScore')}</h1>
				<h2 className="textMedium weightMedium">{t('wodbook:deleteScoreConfirmation')}</h2>

				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setVisible(false);
						}}
					/>
					<Button
						buttonClass="btnDropdownRed textNormal weightMedium"
						buttonText={t('commons:delete')}
						onClick={deleteClick}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteScore;
