import './CalendarMainSidebar.scss';
import React, { useContext, useState, useEffect } from 'react';
import { CalendarMainSidebarContext } from './contexts/CalendarMainSidebarContext';
import { CalendarMainSidebarVisible as VisibleEnum } from './_types';
import { useMediaQuery } from 'react-responsive';
import { ArrowRight } from '../../main/Icons';
import clickOutside from '../../../utils/clickOutside';
import CalendarSelector from '../calendar/calendarMainSidebar/CalendarSelector';
import CalendarProgramFilter from './calendarMainSidebar/CalendarProgramFilter';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const CalendarMainSidebar = () => {
	const { t } = useTranslation();
	const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1600px)' });
	const { calendarMainSidebarVisible, setCalendarMainSidebarVisible } = useContext(CalendarMainSidebarContext);
	const [visibleLock, setVisibleLock] = useState<boolean>(false);

	const classNameMainSidebar =
		calendarMainSidebarVisible === VisibleEnum.VISIBLE
			? 'calendarMainSidebar'
			: 'calendarMainSidebar calendarMainSidebarOut';
	const classNameMainSidebarButton =
		calendarMainSidebarVisible === VisibleEnum.HIDDEN
			? 'calendarMainSidebarButtonVisible'
			: 'calendarMainSidebarButtonVisible calendarMainSidebarButtonVisibleHidden';

	let { slug } = useParams<any>();

	//VISIBILITY OF SIDEBAR
	useEffect(() => {
		if (!visibleLock) setCalendarMainSidebarVisible(isDesktopXXL ? VisibleEnum.VISIBLE : VisibleEnum.HIDDEN);
	}, [isDesktopXXL, setCalendarMainSidebarVisible, visibleLock]);

	const handleClickVisible = (visible: boolean) => {
		setCalendarMainSidebarVisible(visible ? VisibleEnum.VISIBLE : VisibleEnum.HIDDEN);
		setVisibleLock(true);
	};

	//CLICK OUTSIDE GESTION
	useEffect(() => {
		const handleClickOutsideSidebar = (e: any) => {
			if (calendarMainSidebarVisible === VisibleEnum.VISIBLE && !isDesktopXXL) {
				if (clickOutside(e, ['calendarMainSidebar', 'calendarMainSidebar calendarMainSidebarOut'])) {
					setCalendarMainSidebarVisible(VisibleEnum.HIDDEN);
				}
			}
		};

		if (!isDesktopXXL) {
			window.addEventListener('click', handleClickOutsideSidebar);
			return () => {
				window.removeEventListener('click', handleClickOutsideSidebar);
			};
		}
	}, [isDesktopXXL, calendarMainSidebarVisible, setCalendarMainSidebarVisible]);

	return (
		<>
			<div className={classNameMainSidebar}>
				<div className="top">
					{isTablet && <CalendarSelector />}

					{!slug && <CalendarProgramFilter />}
				</div>

				<div className="bottom">
					<div className="calendarHideMainSidebar textMedium weightMedium" onClick={() => handleClickVisible(false)}>
						<ArrowRight />
						{t('commons:reduce')}
					</div>
				</div>
			</div>

			<div
				className={classNameMainSidebarButton}
				onClick={() => {
					handleClickVisible(true);
				}}
			>
				<ArrowRight />
			</div>
		</>
	);
};

export default React.memo(CalendarMainSidebar);
