import { Add } from '../../../../main/Icons'
import './ButtonAddStep.scss'

const ButtonAddStep = ({name, onClick} : any) => {
	return (
		<button className="timerButtonAddStep textMedium weightNormal" onClick={onClick}>
			<Add />
			{name}
		</button>
	)
}

export default ButtonAddStep
