import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown, LogOut } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';

import './ProgramNavbarMenu.scss';

const ProgramNavbarMenu = ({ userRole, slug, handleClickModalExit, progData }: any) => {
	const { t } = useTranslation();
	const [menuDisplay, setMenuDisplay] = useState(false);

	const menuContainer = useRef<HTMLDivElement>(null);

	// MOBILE MENU
	//CLICK OUTSIDE GESTION
	useEffect(() => {
		// Animation open menu
		if (menuContainer.current) {
			menuContainer.current.style.transition = 'all 200ms ease-out';
			setTimeout(() => {
				if (menuContainer.current) {
					menuContainer.current.style.transform = 'translateY(0)';
					menuContainer.current.style.opacity = '1';
				}
			}, 1);
		}

		window.addEventListener('click', handleClickOutsideSidebar);
		return () => {
			window.removeEventListener('click', handleClickOutsideSidebar);
		};
	}, [menuDisplay]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleClickOutsideSidebar = (e: any) => {
		if (menuDisplay) {
			let menuElem = false;
			let endLoop = false;
			let element = e.target;

			while (!endLoop) {
				if (element.parentElement) {
					element = element.parentElement;
					if (element.classList[0] === 'menuContainer') {
						menuElem = true;
					}
				} else {
					endLoop = true;
				}
			}

			if (!menuElem) {
				setMenuDisplay(false);
			}
		}
	};

	const handleClick = () => {
		setMenuDisplay(!menuDisplay);
	};

	return (
		<div className="mobileMenu">
			<div className="selected textMedium weightSemiBold" onClick={handleClick}>
				{t('commons:menu')}
				<ChevronDown />
			</div>

			{menuDisplay && (
				<div className="menuContainer textMedium weightSemiBold" ref={menuContainer}>
					{userRole !== 'guest' && (
						<>
							<Link to={'/program/' + slug + '/calendar'}>{t('commons:planning')}</Link>
							<Link to={'/program/' + slug + '/member'}>{t('commons:members')}</Link>
						</>
					)}

					{userRole === 'coach' && <Link to={'/program/' + slug + '/settings'}>{t('commons:settings')}</Link>}

					{userRole === 'member' && progData?.price === 0 && (
						<button className="logoutLink" onClick={handleClickModalExit}>
							{t('commons:leaveProgram')}
							<LogOut />
						</button>
					)}
				</div>
			)}
		</div>
	);
};

export default ProgramNavbarMenu;
