import './ChannelList.scss';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../../../main/UserIcon';
import { ProgIcon } from '../../../main/ProgIcon';
import { useMediaQuery } from 'react-responsive';

import noConversation from '../../../../assets/img/chat/noConversation.svg';
import Button from '../../../common/Button';
import { setChatChannelId } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

const ChannelsList = ({ channels, onChannelSelected, channelSelected, setSidebarVisible }: any) => {
	const { t } = useTranslation();
	return (
		<div className="channelsList">
			{channels.length > 0 ? (
				channels?.map((channel: any, key: any) => (
					<Channel
						key={key}
						channel={channel}
						channelSelected={channelSelected}
						onChannelSelected={onChannelSelected}
						setSidebarVisible={setSidebarVisible}
					/>
				))
			) : (
				<div className="channelsListEmpty">
					<img src={noConversation} alt="" />

					<h1 className="titleXXL weightMedium">{t('chat:noConversation')}</h1>
					<p className="textSmall weightMedium">{t('chat:noConversationBody')}</p>
				</div>
			)}
		</div>
	);
};

const Channel = ({ channel, onChannelSelected, channelSelected, setSidebarVisible }: any) => {
	const dispatch = useDispatch();
	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const { t } = useTranslation();

	const formatDate = (date: any) => {
		if (moment(date).isSame(moment(), 'day'))
			// if date is today
			return moment(date).format('LT');

		if (moment(date).isSame(moment().subtract(1, 'days').startOf('day'), 'day')) return t('commons:yesterday');

		return moment(date).format('L');
	};

	const handleClick = () => {
		onChannelSelected(channel);
		dispatch(setChatChannelId(channel?.id));

		if (!isMedium) {
			setSidebarVisible(false);
		}
	};

	return (
		<div
			className="channel noselect"
			onClick={handleClick}
			style={{
				background: channel?.id === channelSelected?.id ? '#f9fbff' : 'white',
			}}
		>
			<div className="avatar">
				{channel?.type === 'DUO' ? (
					<UserIcon
						url={channel?.avatar?.url}
						text={channel?.members[0]?.firstName.charAt(0) + channel?.members[0]?.lastName.charAt(0)}
						size={44}
					/>
				) : channel?.type === 'PROGRAM' ? (
					<div className="progIcon">
						<ProgIcon url={channel?.avatar?.url} />
					</div>
				) : null}
			</div>

			<div className="texts">
				<h1
					className={`
						textMedium
						${channel?.unreadMessagesCount > 0 ? 'weightBold' : 'weightMedium'}
					`}
				>
					{channel?.name}
				</h1>
				{channel.latestMessage?.deleted ? (
					<p
						className="textNormal weightMedium"
						style={{
							color: channel?.unreadMessagesCount > 0 ? '#4C5771' : '#ABB5CD',
						}}
					>
						{t('chat:messageDeletedShord')}
					</p>
				) : (
					<p
						className="textNormal weightMedium"
						style={{
							color: channel?.unreadMessagesCount > 0 ? '#4C5771' : '#ABB5CD',
						}}
					>
						{(channel.latestMessage?.content && !channel?.latestMessage?.deleted && channel.latestMessage?.content) ||
							(channel.latestMessage?.attachment?.type === 'IMAGE' && '[image]')}
					</p>
				)}
			</div>

			<div className="infos">
				<div
					className={`
						dateNewMessage textSmall
						${channel?.unreadMessagesCount > 0 ? 'weightBold' : 'weightMedium'}
					`}
				>
					{formatDate(channel.latestMessage?.createdAt)}
				</div>
				<div className="notificationNbWrapper">
					{channel?.unreadMessagesCount > 0 && <div className="notificationNb">{channel?.unreadMessagesCount}</div>}
				</div>
			</div>
		</div>
	);
};

export default ChannelsList;
