import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemState } from '../../store/SystemReducer';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { useTranslation } from 'react-i18next';
import './ErrorModal.scss';

const ErrorModal = () => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState<any>(false);
	const error = useSelector((store: SystemState) => store.error);

	useEffect(() => {
		if (error?.title) {
			//let generatedMessage = t([`errors:${error?.message}`, 'errors:default']);

			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [error]);

	const onCancel = useCallback(() => {
		setVisible(false);
		if (error?.onClose) error.onClose();
	}, [error]);

	/*	const onRetryPress = useCallback(() => {
		setVisible(false);
		if (error?.onRetry) {
			error?.onRetry();
		}
	}, [error]);*/

	if (!error) return null;

	//const rightButtonName = error?.onRetry ? t('commons:retry') : 'Ok';

	//const leftButtonName = error?.onRetry ? t('commons:cancel') : false;

	return (
		<Modal visible={visible} setVisible={setVisible}>
			<div className="errorModal">
				<h1 className="titleXL weightMedium">{error.title}</h1>
				<h2 className="textMedium weightMedium body">{error.body}</h2>
				<div className="actions noselect">
					{/*{error?.onRetry ? (
						<>
							<div />
							<Button
								buttonText={t('commons:ok')}
								buttonClass="btnGreyNoBack textNormal weightMedium"
								onClick={onCancel}
							/>

							<Button
								buttonText={t('commons:add')}
								buttonClass="btnGreenNoBack textNormal weightMedium"
								onClick={() => {}}
							/>
						</>
					) : (*/}
					<>
						<div />
						<Button buttonText={'Ok'} buttonClass="btnGreyNoBack textNormal weightMedium" onClick={onCancel} />
					</>
					{/*	)}*/}
				</div>
			</div>
		</Modal>
	);
};

export default ErrorModal;
