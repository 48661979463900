import React from 'react';

import './Textarea.scss'

const Textarea = ({
	name,
	errors,
	register,
	placeholder,
	required,
	label,
	noLabel = false,
}: any) => {
	return (
		<div className="textarea">
			{!noLabel && (
				<label htmlFor={name} className="textNormal weightMedium">
					{label || placeholder}
				</label>
			)}

			<div className="inputZone">
				<textarea
					className={`
						textMedium weightMedium
						${errors[name] && 'inputError'}
					`}
					placeholder={placeholder}
					{...register(name, { required })}
				></textarea>
			</div>

			<p className="errorMessage textSmall weightMedium">
				{errors[name] && errors[name].message}
			</p>
		</div>
	);
};

export default Textarea;
