import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../common/Form/TextInput';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './AddPersonnalRecordModal.scss';
import { getUnitForScoreType, timeToString } from '../../../../utils/helpers';
import moment from 'moment';
import { useCreatePersonalRecordMutation } from '../../../../generated/gql';
import Button from '../../../common/Button';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import CategorySelector from './CategorySelector';

const RecordTypes = [
	{
		i18nKey: 'LOAD',
		value: 'LOAD',
	},
	{
		i18nKey: 'DURATION',
		value: 'DURATION',
	},
	{
		i18nKey: 'DISTANCE',
		value: 'DISTANCE',
	},
	{
		i18nKey: 'CALORIES',
		value: 'CALORIES',
	},
	{
		i18nKey: 'REPETITIONS',
		value: 'REPETITIONS',
	},
];

interface AddPersonnalRecordModalProps {
	visible: boolean;
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	category: any;
	refetch: any;
}

const AddPersonnalRecordModal = ({ visible, setVisible, category, refetch }: AddPersonnalRecordModalProps) => {
	const { t } = useTranslation();
	const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
	const [categorySelect, setCategorySelect] = useState<any>({
		key: RecordTypes[0].value,
		title:
			t(`commons:scoreType.${RecordTypes[0].i18nKey}`) +
			(RecordTypes[0].value !== 'DURATION' ? ` (${getUnitForScoreType(RecordTypes[0].i18nKey)})` : ''),
	});
	const [createPersonalRecord, { error, loading }]: any = useCreatePersonalRecordMutation();
	useErrorModal({ error });

	useEffect(() => {
		if (categorySelect?.key === 'DURATION') {
			setValidationSchema(
				Yup.object().shape({
					exercise: Yup.string()
						.required(t('formik:requiredField'))
						.trim()
						.lowercase()
						.min(3, t('formik:sessionNameValidation'))
						.max(40, t('formik:sessionNameValidation')),
					minutes: Yup.number().required(t('formik:requiredField')),
					seconds: Yup.number()
						.required(t('formik:requiredField'))
						.max(59, t('formik:maxValueIs', { max: 59 })),
				})
			);
		} else {
			setValidationSchema(
				Yup.object().shape({
					exercise: Yup.string()
						.required(t('formik:requiredField'))
						.trim()
						.lowercase()
						.min(3, t('formik:sessionNameValidation'))
						.max(40, t('formik:sessionNameValidation')),

					value: Yup.number().required(t('formik:requiredField')),
				})
			);
		}
	}, [categorySelect]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: timeToString(moment().format()),
		},
	});

	const onSubmit = (values: any) => {
		if (loading) return;

		const val = validationSchema.cast(values);

		const data = {
			category: category?.key,
			exercise: val?.exercise,
			type: categorySelect?.key,
			date: moment(val?.date),
			value: categorySelect?.key === 'DURATION' ? values.minutes * 60 + values.seconds : values.value,
		};

		createPersonalRecord({
			variables: data,
		}).then((r: any) => {
			console.log('r createPersonalRecord', r);
			setVisible(false);
			refetch();
		});
	};

	const options = useMemo(() => {
		return RecordTypes?.map((type) => {
			return {
				key: type?.value,
				title:
					t(`commons:scoreType.${type.i18nKey}`) +
					(type.value !== 'DURATION' ? ` (${getUnitForScoreType(type.i18nKey)})` : ''),
			};
		});
	}, [t]);

	return (
		<div className="AddPersonnalRecordModal modalValidation">
			<h1 className="titleXL weightMedium">{t('profile:addNewPR')}</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					type="text"
					name="exercise"
					label={t('commons:exercice')}
				/>
				<CategorySelector selectDefault={categorySelect} options={options} setCategorySelect={setCategorySelect} />
				<div className="inputs">
					{categorySelect?.key !== 'DURATION' ? (
						<TextInput
							required
							errors={errors}
							register={register}
							type="text"
							name={'value'}
							label={categorySelect?.title}
						/>
					) : (
						<div className="inputsTime">
							<TextInput
								required
								errors={errors}
								register={register}
								type="text"
								name="minutes"
								label={t('wodbook:addScoreMinutes')}
							/>
							<TextInput
								required
								errors={errors}
								register={register}
								type="text"
								name="seconds"
								label={t('wodbook:addScoreSeconds')}
							/>
						</div>
					)}
				</div>
				<TextInput required errors={errors} register={register} type="date" name="date" label={t('calendar:date')} />
				<div className="actions">
					<Button
						buttonClass="btnDropdownGreen textNormal weightMedium"
						buttonText={t('commons:add')}
						onClick={() => {}}
					/>
				</div>
			</form>
		</div>
	);
};

export default AddPersonnalRecordModal;
