import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListPublicWodCategoriesQuery } from '../../../../generated/gql';
import Loader from '../../../main/Loader';
import { isMobile } from 'react-device-detect';
import './WodbookList.scss';
import BenchmarkWod from './BenchmarkWod';
import { MyWod } from './MyWod';
import { WodContext } from '../Context';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const WodbookList = () => {
	const { t } = useTranslation();
	const { isPublicWod, setIsPublicWod, setSelectedWod } = useContext(WodContext);
	const [categoryId, setCategoryId] = useState<any>();

	const { data, error, loading }: any = useListPublicWodCategoriesQuery();
	useErrorModal({ error });
	useEffect(() => {
		setCategoryId(data?.listPublicWodCategories?.result[0]?.id);
	}, [data]);

	if (loading) return <Loader size={80} />;

	return (
		<div className="wodbookList noselect">
			<div className="wodCat textMedium weightMedium">
				<button
					style={{
						borderBottom: isPublicWod ? '2px solid #ABB5CD' : 'unset',
						paddingBottom: isPublicWod ? '12px' : '14px',
					}}
					onClick={() => {
						setIsPublicWod(true);
						setSelectedWod(undefined);
					}}
				>
					{t('wodbook:benchmarkWod')}
				</button>

				<button
					style={{
						borderBottom: !isPublicWod ? '2px solid #ABB5CD' : 'unset',
						paddingBottom: !isPublicWod ? '12px' : '14px',
					}}
					onClick={() => {
						setIsPublicWod(false);
						setSelectedWod(undefined);
					}}
				>
					{t('wodbook:myWod')}
				</button>
			</div>

			{isPublicWod && (
				<div
					className="wodPublicCat scrollbarThin"
					style={{
						scrollbarWidth: isMobile ? 'none' : 'thin',
					}}
				>
					{data?.listPublicWodCategories?.result?.map((category: any, i: number) => (
						<button
							key={i}
							className="btn btnGreyContrast textNormal weightMedium"
							style={{
								color: categoryId === category.id ? '#05DBC3' : '#4C5771',
							}}
							onClick={() => {
								setCategoryId(category.id);
							}}
						>
							{category.translations[0].name}
						</button>
					))}
				</div>
			)}

			<div className="wodLineListWrapper">
				{isPublicWod && categoryId && <BenchmarkWod categoryId={categoryId} />}
				{!isPublicWod && <MyWod />}
			</div>
		</div>
	);
};

export default WodbookList;
