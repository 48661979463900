import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import { Add } from '../../../main/Icons';
import './Scores.scss';
import AddWodScoreModal from './AddWodScoreModal';
import Score from './Score';
import { useMemo, useState } from 'react';
import Modal from '../../../common/Modal';

const Scores = ({ scores, scoreExpected, wodId, publicWod }: any) => {
	const { t } = useTranslation();
	const [addScoreModalVisible, setAddScoreModalVisible] = useState(false);

	const sortedData = useMemo(() => {
		if (!scores) return;

		return [...scores].sort((a, b): any => {
			return (
				// @ts-ignore
				new Date(b?.date) - new Date(a?.date)
			);
		});
	}, [scores]);

	return (
		<div className="wodScores">
			<div className="addScore">
				<h1 className="titleL weightBold">{t('wodbook:scores')}</h1>
				<Button buttonClass="btnCentered btnGreen" ButtonIcon={Add} onClick={() => setAddScoreModalVisible(true)} />
				<Modal visible={addScoreModalVisible} setVisible={setAddScoreModalVisible}>
					<AddWodScoreModal
						visible={addScoreModalVisible}
						setVisible={setAddScoreModalVisible}
						scoreExpected={scoreExpected}
						wodId={wodId}
						publicWod={publicWod}
					/>
				</Modal>
			</div>
			<div className="scoresList">
				{sortedData?.map((score: any) => {
					return <Score key={score.id} score={score} wodId={wodId} />;
				})}
			</div>
		</div>
	);
};

export default Scores;
