import './CalendarSelector.scss';
import { useEffect, useState, useContext } from 'react';
import { CalendarContext } from '../contexts/CalendarContext';
import { CalendarDatesContext } from '../contexts/CalendarDatesContext';
import moment from 'moment';

const CalendarSelector = () => {
	const { datesArrSessions } = useContext(CalendarDatesContext);

	return (
		<div className="calendarSelector">
			<table className="calendarSelectorTable">
				<thead>
					<tr className="titles">
						{moment.weekdays(true).map((dayTitle: string, index: number) => (
							<th
								key={index}
								className="titleColumn calendarColumn textMedium weightBold"
							>
								{dayTitle[0]}
							</th>
						))}
					</tr>
				</thead>

				<tbody>
					<tr className="separator">
						<td></td>
					</tr>

					{datesArrSessions.map((weekDates: any, index: number) => (
						<tr key={index} className="weekRow">
							{weekDates.days.map((dateUnique: any, index2: number) => (
								<DaySelector key={index2} dateUnique={dateUnique} />
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

const DaySelector = ({ dateUnique }: any) => {
	const { setDateSelected, me } = useContext(CalendarContext);
	const { datesArrSessions, programList } = useContext(CalendarDatesContext);
	const [activeColor, setActiveColor] = useState<string>('#4C5771');
	const [sessionDots, setSessionDots] = useState<boolean>(false);

	//Notification detection
	useEffect(() => {
		let dotVisible = false

		dateUnique?.sessions?.forEach((session : any) => {
			programList.forEach((program : any) => {
				if (program.id === session.program.id) {
					if (program.visible) {
						dotVisible = true
					}
				}
			})
		})

		setSessionDots(dotVisible)
		
	}, [dateUnique, me, datesArrSessions, programList]);

	useEffect(() => {
		setActiveColor(dateUnique.state.selected ? '#50B4EE' : '#4C5771');
	}, [setActiveColor, dateUnique]);

	const handleClick = () => {
		setDateSelected(dateUnique.dateMoment);
	};

	return (
		<td onClick={handleClick}>
			<div
				className="number textSmall weightMedium"
				style={{
					color: dateUnique.state.active ? activeColor : '#909fc4',
					fontWeight: moment(dateUnique.dateMoment).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY') ? 700 : 500
				}}
			>
				{moment(dateUnique.dateMoment).format('DD')}
			</div>
			<div className="dots">
				{sessionDots && <div className="dotGreen dot"></div>}
			</div>
		</td>
	);
};

export default CalendarSelector;
