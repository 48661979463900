import './WodBookAdd.scss'

const WodbookAdd = () => {
	return (
		<div className="wodBookAdd">
			{/* <ExerciseEditor exercise={} /> */}
		</div>
	)
}

export default WodbookAdd
