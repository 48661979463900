import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
// import { useTranslation } from 'react-i18next';
// import { useListProgramSessionsDayQuery } from '../../../generated/gql';

import './ProgramCalendarPage.scss';

import { useParams } from 'react-router-dom';
import ProgramNavbar from '../../../components/pages/programs/programs/ProgramNavbar';
import { PROG_NAVBAR } from '../../../config/type';
import CalendarApp from '../calendar/CalendarApp';
import { useShowProgramQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import Loader from '../../../components/main/Loader';
import useIsCoach from '../../../hooks/user/useIsCoach';
import useIsMember from '../../../hooks/user/useIsMember';

export const ProgramCalendarPage = () => {
	const { slug } = useParams<any>();

	// member, coach, guest,
	const [userRole, setUserRole] = useState('guest');

	const isCoach = useIsCoach(slug);
	const isMember = useIsMember(slug);
	useEffect(() => {
		if (isCoach) setUserRole('coach');
		else if (isMember) setUserRole('member');
		else setUserRole('guest');
	}, [isCoach, isMember, slug]);

	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1400px)' });

	const { data, error, loading, refetch } = useShowProgramQuery({
		variables: {
			id: slug,
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	if (!data) return <Loader />;

	return (
		<div id="programCalendarPage">
			<div className="programNavbarContainer">
				<ProgramNavbar slug={slug} focus={PROG_NAVBAR.CALENDAR} name="test" userRole={userRole} />
			</div>

			<div className="programCalendarPageWrapper">
				<CalendarApp programData={data} />
			</div>
		</div>
	);
};
