import './CalendarMobileSessionsList.scss';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarDatesContext } from '../contexts/CalendarDatesContext';
import { CalendarContext } from '../contexts/CalendarContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SessionLine from '../calendar/SessionLine';
import EmptyMessage from '../../../common/EmptyMessage';
import { useParams } from 'react-router-dom';

const CalendarMobileSessionsList = () => {
	const { slug } = useParams<any>();
	const { me, dateSelected } = useContext(CalendarContext);
	const { datesArrSessions, programList } = useContext(CalendarDatesContext);
	const [sessionsList, setSessionsList] = useState<any>({
		owner: [],
		follow: [],
	});
	const { t } = useTranslation();

	// Look session for day selected
	useEffect(() => {
		const sessionsListUpdate: any = {
			owner: [],
			follow: [],
		};

		datesArrSessions.forEach((datesWeek: any) => {
			datesWeek.days.forEach((dateDay: any) => {
				if (moment(dateDay.dateMoment).format('DD/MM/YYYY') === moment(dateSelected).format('DD/MM/YYYY')) {
					dateDay.sessions.forEach((session: any) => {
						let sessionOwner = false;

						if (session.program.creator.id === me?.user?.id) {
							sessionOwner = true;
						}

						programList.forEach((program: any) => {
							if (program.id === session.program.id && program.visible === true) {
								if (sessionOwner) {
									sessionsListUpdate.owner.push(session);
								} else {
									sessionsListUpdate.follow.push(session);
								}
							}
						});
					});
				}
			});
		});
		setSessionsList(sessionsListUpdate);
	}, [dateSelected, datesArrSessions, me, programList]);

	return (
		<div className="calendarMobileSessionsList">
			{sessionsList.owner.length > 0 || sessionsList.follow.length > 0 ? (
				<>
					{slug ? (
						<SessionsProgram owner={true} sessionsList={sessionsList} slug={true} />
					) : (
						<>
							<SessionsProgram owner={true} sessionsList={sessionsList} slug={false} />
							<SessionsProgram owner={false} sessionsList={sessionsList} slug={false} />
						</>
					)}
				</>
			) : (
				<EmptyMessage message={t('calendar:noSessionToday')} />
			)}
		</div>
	);
};

interface SessionsProgramProps {
	owner: boolean;
	sessionsList: any;
	slug: any;
}

const SessionsProgram = ({ owner, sessionsList, slug }: SessionsProgramProps) => {
	const { t } = useTranslation();
	const { me } = useContext(CalendarContext);

	let sessionsListDatas = owner ? sessionsList.owner : sessionsList.follow;

	if (slug) {
		sessionsListDatas = [...sessionsList.owner, ...sessionsList.follow];
		return (
			<div
				className={`
				sessionsProgram
				sessionsProgramOwner
			`}
			>
				<h1 className="titleXL weightMedium">{t('calendar:dailySession')}</h1>

				<div className="sessionsList">
					{sessionsListDatas.length > 0 ? (
						sessionsListDatas.map((session: any, index: number) => (
							<SessionLine key={index} data={session} me={me} setSessionDragging={undefined} />
						))
					) : (
						<EmptyMessage message={t('calendar:noSessionToday')} />
					)}
				</div>
			</div>
		);
	}

	return (
		<div
			className={`
				sessionsProgram
				${owner ? 'sessionsProgramOwner' : 'sessionsProgramFollow'}
			`}
		>
			<h1 className="titleXL weightMedium">
				{owner ? t('profile:widgetTitle:programsCreated') : t('profile:widgetTitle:programsFollowed')}
			</h1>

			<div className="sessionsList">
				{sessionsListDatas.length > 0 ? (
					sessionsListDatas.map((session: any, index: number) => (
						<SessionLine key={index} data={session} me={me} setSessionDragging={undefined} />
					))
				) : (
					<EmptyMessage message={t('calendar:noSessionToday')} />
				)}
			</div>
		</div>
	);
};

export default CalendarMobileSessionsList;
