import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateChatChannelMutation } from '../../generated/gql';

export const useChatCreation = () => {
	const [createChatChannel, { error, loading, data }]: any = useCreateChatChannelMutation();

	const joinChatChannel = async (id: any) => {
		//console.log('joinChatChannel id', id);
		await createChatChannel({
			variables: {
				membersIds: [id],
			},
		});
	};

	return {
		joinChatChannel,
		data,
		error,
		loading,
	};
};
