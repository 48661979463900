import { useState, useEffect, useRef, useCallback } from 'react';
import MenuItem from '../MenuItem';
import { ChevronDown } from '../../../main/Icons';

import './MobileMenu.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const MobileMenu = ({ menuList, setMenuIndex, menuIndex }: any) => {
	const [menuDisplay, setMenuDisplay] = useState(false);
	const { t } = useTranslation();

	const menuContainer = useRef<HTMLDivElement>(null);

	const history = useHistory();

	const onLogout = useCallback(() => {
		Auth.signOut().then(() => {
			history.go(0);
		});
	}, [history]);


	//CLICK OUTSIDE GESTION
	useEffect(() => {
		// Animation open menu
		if (menuContainer.current) {
			menuContainer.current.style.transition = 'all 200ms ease-out';
			setTimeout(() => {
				if (menuContainer.current) {
					menuContainer.current.style.transform = 'translateY(0)';
					menuContainer.current.style.opacity = '1';
				}
			}, 1);
		}

		window.addEventListener('click', handleClickOutsideSidebar);

		return () => {
			window.removeEventListener('click', handleClickOutsideSidebar);
		};
	}, [menuDisplay]); //eslint-disable-line react-hooks/exhaustive-deps

	const handleClickOutsideSidebar = (e: any) => {
		if (menuDisplay) {
			let menuElem = false;

			let endLoop = false;
			let element = e.target;

			while (!endLoop) {
				if (element.parentElement) {
					element = element.parentElement;

					if (element.classList[0] === 'settingMobileMenu') {
						menuElem = true;
					}
				} else {
					endLoop = true;
				}
			}

			if (!menuElem) {
				setMenuDisplay(false);
			}
		}
	};

	const handleClick = () => {
		setMenuDisplay(!menuDisplay);
	};

	return (
		<div className="settingMobileMenu">
			<div className="selected titleL weightMedium" onClick={handleClick}>
				{t('commons:menu')}
				<ChevronDown />
			</div>

			{menuDisplay && (
				<div className="menuContainer" ref={menuContainer}>
					{menuList.map((menuItem: any) => (
						<MenuItem
							key={menuItem.id}
							data={menuItem}
							menuIndex={menuIndex}
							setMenuIndex={setMenuIndex}
							setMenuDisplay={setMenuDisplay}
						/>
					))}

					<div className="separator" />

					<div className="disconnect titleL weightMedium noselect">
						<button onClick={() => onLogout()}>{t('commons:disconnection')}</button>
					</div>
				</div>
			)}


		</div>
	);
};

export default MobileMenu;
