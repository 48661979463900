import { EyeOff, ArrowRight, AtSign, Eye } from '../main/Icons';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import hustleGreen from '../../assets/img/hustle_up/hustle_green.png';

import Button from '../common/Button';
import TextInput from '../common/Form/TextInput';

import './RegisterForm.scss';
import SocialButtons from './SocialButtons';

type Inputs = {
	email: string;
	password: string;
	cgu: boolean;
};

export default function RegisterForm({ onRegister, setStep, setEmail }: any) {
	const [loading, setLoading] = useState(false);
	const [cguError, setCguError] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const { t } = useTranslation();
	const history = useHistory();
	const validationSchema = Yup.object().shape({
		email: Yup.string().trim().lowercase().required(t('validation:requiredField')).email(t('validation:emailWrong')),
		password: Yup.string()
			.required(t('formik:requiredField'))
			.min(6, t('formik:passwordMinLength'))
			.max(20, t('formik:passwordMaxLength'))
			.matches(/^(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9]).*$/, t('formik:passwordMustContain')),
		confirmPassword: Yup.string()
			.required(t('formik:requiredField'))
			.oneOf([Yup.ref('password'), null], t('formik:passwordsMustMatch')),
	});
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = useCallback(
		async (data: { email: string; password: string; cgu: boolean }) => {
			if (!data?.cgu) return setCguError(true);

			setLoading(true);
			await Auth.signUp({
				username: data?.email,
				password: data?.password,
			})
				.then(async (_) => {
					await Auth.signIn(data?.email, data?.password)
						.then(() => {
							setStep(2);
							setEmail(data?.email);
						})
						.catch((e) => console.error('Auth.signIn', e));
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[setLoading]
	);

	const onPressSocialButton = useCallback(
		(provider: CognitoHostedUIIdentityProvider) => {
			Auth.federatedSignIn({ provider })
				.catch((e) => console.error('[AUTH] SOCIAL REGISTER ERROR', e))
				.then(() => {
					onRegister();
					console.log('[AUTH] SOCIAL REGISTER SUCCESS');
				});
		},
		[onRegister]
	);

	return (
		<div className="registerForm loginLeftContainer">
			<header className="headerHustleUp">
				<img src={hustleGreen} alt="Hustle Up logo" />

				<h1 className="titleXXXL weightBold">{t('auth:niceToMeetYou')}</h1>
			</header>

			<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="email"
					placeholder={t('commons:email')}
					type="text"
					Icon={AtSign}
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="password"
					type={showPass ? 'text' : 'password'}
					placeholder={t('formik:password')}
					Icon={showPass ? Eye : EyeOff}
					onIconPress={() => setShowPass(!showPass)}
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="confirmPassword"
					type={showPass ? 'text' : 'password'}
					placeholder={t('formik:confirmPassword')}
				/>

				<div className={`formCheckbox ${cguError && 'cguError'}`}>
					<input {...register('cgu', { required: true })} id="registerFormCGU" type="checkbox" />
					<label htmlFor="registerFormCGU" className={`textNormal weightSemiBold`}>
						{t('auth:agreeWithCGU')}
						<span onClick={() => history.push('cgu')}>
							{t('auth:termsandConditions')}
							{/*<a href="https://web.hustleup-app.com/cgu/">{t('auth:termsandConditions')}</a>*/}
						</span>
					</label>
				</div>

				<div className="actions">
					<div className="formValidationFrontPage">
						<div className="left">
							<Button
								buttonText={t('commons:signup')}
								buttonClass="btnGreen textMedium weightBold"
								onClick={() => {}}
							/>
						</div>

						<div className="right">
							<p className="textSmall weightMedium">{t('auth:alreadyMember')}</p>
							<Link to="/login">
								<p className="textSmall weightMedium">{t('commons:login')}</p>
								<ArrowRight />
							</Link>
						</div>
					</div>
				</div>
			</form>

			<SocialButtons onPressSocialButton={onPressSocialButton} />
		</div>
	);
}
