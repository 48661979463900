import React from 'react';
import Main from './Main';
import Login from './Login';

export enum LayoutName {
	MAIN = 'main',
	LOGIN = 'login',
}

const layoutMap = {
	main: Main,
	login: Login,
};

const Layout = ({
	type,
	children,
	breadcrumb,
}: {
	type: LayoutName;
	children?: any;
	breadcrumb: [];
}) => {
	const Layout = layoutMap[type] || layoutMap.main;
	// @ts-ignore
	return <Layout breadcrumb={breadcrumb}>{children}</Layout>;
};

export default Layout;
