import './Exercice.scss';
import React, { useContext, useState } from 'react';
import { CalendarSessionContext } from '../../contexts/CalendarSessionContext';
import { Award, Book, Check, Edit, Play, Trash2 } from '../../../../main/Icons';
import Images from './Blocs/Images';
import { Link, useHistory, useParams } from 'react-router-dom';
import CalendarSessionLeaderboard from '../CalendarSessionLeaderboard/CalendarSessionLeaderboard';
import {
	ListChatChannelsDocument,
	ShowProgramSessionDocument,
	useCreateSessionExerciseMutation,
	useDeleteSessionExerciseMutation,
	useSaveSessionExerciseToWodBookMutation,
} from '../../../../../generated/gql';
import Modal from '../../../../common/Modal';
import Button from '../../../../common/Button';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../common/Form/TextInput';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import update from 'immutability-helper';

const Exercice = ({ data }: any) => {
	const { slug, idSession } = useParams<any>();
	const { ownerSession, editMode } = useContext(CalendarSessionContext);
	const [exerciseRemoveModal, setExerciseRemoveModal] = useState(false);
	const [leaderboardVisible, setLeaderboardVisible] = useState<boolean>(false);
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div className="calendarSessionExercice">
			<div className="row">
				<div className="blocs">
					{data?.blocks?.map((block: any) => {
						if (block.type === 'TEXT') {
							return (
								<div key={block.id} className="text textNormal weightMedium contentBloc">
									{block.content}
								</div>
							);
						} else if (block.type === 'IMAGE') {
							return <Images key={block.id} url={block.image.url} />;
						} else {
							return null;
						}
					})}
				</div>

				<div className="menu">
					<div className="menuMember">
						{editMode && ownerSession ? (
							<>
								<Link to={`/program/${slug}/session/${idSession}/edit/${data.id}`} className="btnEdit btnExercice">
									<Edit />
								</Link>

								<button className="btnDelete btnExercice" onClick={() => setExerciseRemoveModal(true)}>
									<Trash2 />
								</button>

								<Modal visible={exerciseRemoveModal} setVisible={setExerciseRemoveModal}>
									<div className="modalValidation">
										<h1 className="titleXL weightMedium">{t('calendar:deleteExercise')}</h1>
										<h2 className="textMedium weightMedium">{t('calendar:deleteExerciseBody')}</h2>
										<div className="actions">
											<Button
												buttonClass="btnGreyNoBack textNormal weightMedium"
												buttonText={t('commons:cancel')}
												onClick={() => {
													setExerciseRemoveModal(false);
												}}
											/>
											<DeleteSessionExerciseButton
												setExerciseRemoveModal={setExerciseRemoveModal}
												exerciseId={data?.id}
												idSession={idSession}
											/>
										</div>
									</div>
								</Modal>
							</>
						) : (
							<>
								{data.chronoConfiguration && (
									<button className="btnChrono btnGreen" onClick={() => history.push('/timer', [data])}>
										{/*<button className="btnChrono btnGreen">*/}
										<Play />
									</button>
								)}
								{data.expectedScores.length > 0 && (
									<button
										onClick={() => {
											setLeaderboardVisible(true);
										}}
										className="btnExercice btnGreyContrast"
									>
										<Award />
										{data.userScores.length > 0 && (
											<div className="check">
												<Check />
											</div>
										)}
									</button>
								)}
								{data?.blocks.length > 0 && <SaveToWodBookButton data={data} idSession={idSession} />}
							</>
						)}
					</div>

					<div className="menuOwner" />
				</div>
			</div>
			{data.expectedScores.length > 0 && (
				<CalendarSessionLeaderboard
					dataExercise={data}
					sessionId={idSession}
					leaderboardVisible={leaderboardVisible}
					setLeaderboardVisible={setLeaderboardVisible}
				/>
			)}
		</div>
	);
};

const DeleteSessionExerciseButton = ({ setExerciseRemoveModal, exerciseId, idSession }: any) => {
	const [deleteSessionExercise, { error }] = useDeleteSessionExerciseMutation({
		update(cache, { data }) {
			// If the message is the latest one, update
			const dataQuery = cache.readQuery({
				query: ShowProgramSessionDocument,
				variables: {
					id: idSession,
				},
			});

			if (!dataQuery) return;

			// @ts-ignore
			const { showProgramSession } = dataQuery;
			const exerciceIndex = showProgramSession.exercises.findIndex((c: any) => c.id === data?.deleteSessionExercise);
			// @ts-ignore
			const showProgramSessionCommentsTotal = dataQuery?.showProgramSessionCommentsTotal;

			cache.writeQuery({
				query: ShowProgramSessionDocument,
				data: {
					showProgramSession: update(showProgramSession, {
						exercises: {
							$splice: [[exerciceIndex, 1]],
						},
					}),
					showProgramSessionCommentsTotal,
				},
			});
		},
	});
	const { t } = useTranslation();

	const deleteClick = () => {
		setExerciseRemoveModal(false);

		deleteSessionExercise({
			variables: {
				sessionId: idSession,
				exerciseId,
			},
		})
			.then((r) => {
				console.log('deleteSessionExercise', r);
			})
			.catch((e) => console.log('e', e));
	};

	return (
		<Button buttonClass="btnTextRed textNormal weightMedium" buttonText={t('commons:delete')} onClick={deleteClick} />
	);
};

const SaveToWodBookButton = ({ data, idSession }: any) => {
	const [saveWodModal, setSaveWodModal] = useState(false);
	const [saveSessionExerciseToWodBook, { error }] = useSaveSessionExerciseToWodBookMutation();
	const [wodSaved, setWodSaved] = useState(data.savedToWodBook);

	const { t } = useTranslation();
	const saveWodBook = (title: string) => {
		saveSessionExerciseToWodBook({
			variables: {
				exerciseId: data?.id,
				sessionId: idSession,
				title,
			},
		})
			.then((r) => {
				setSaveWodModal(false);
				setWodSaved(true);
			})
			.catch((e) => console.log('e', e));
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().trim().lowercase().required(t('validation:requiredField')),
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = (data: any) => {
		saveWodBook(data.name);
	};

	return (
		<>
			<button
				className="btnExercice btnGreyContrast"
				onClick={data.savedToWodBook ? () => {} : () => setSaveWodModal(true)}
			>
				<Book />
				{wodSaved && (
					<div className="check">
						<Check />
					</div>
				)}
			</button>

			<Modal visible={saveWodModal} setVisible={setSaveWodModal}>
				<div className="modalValidation">
					<h1 className="titleXL weightMedium">{t('calendar:addWodBook')}</h1>
					{/* <h2 className="textMedium weightMedium">{t('chat:reportMessageConfirmation')}</h2> */}
					<TextInput errors={errors} register={register} name="name" label={t('calendar:nameWod')} type="text" />
					<div className="actions">
						<Button
							buttonClass="btnGreyNoBack textNormal weightMedium"
							buttonText={t('commons:cancel')}
							onClick={() => {
								setSaveWodModal(false);
							}}
						/>
						<Button
							buttonClass="btnTextGreen textNormal weightMedium"
							buttonText={t('commons:add')}
							onClick={handleSubmit(onSubmit)}
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default Exercice;
