import './ProgramSessions.scss';
import { useMediaQuery } from 'react-responsive';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, ChevronRight, Calendar } from '../../../main/Icons';
import moment from 'moment';
import 'moment/locale/fr';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import EmptyMessage from '../../../common/EmptyMessage';
import { useListProgramSessionsQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { useHistory } from 'react-router-dom';

const ProgramSessions = ({ dataProgram }: any) => {
	const { t } = useTranslation();
	const isMediumPlus = useMediaQuery({ query: '(min-width: 1200px)' });
	const history = useHistory();
	const [todaySession, setTodaySession] = useState([]);
	const [nextSession, setNextSession] = useState([]);
	let sessionDate: string | null = null;
	const day = new Date();
	const { data, error }: any = useListProgramSessionsQuery({
		variables: {
			month: day.getMonth() + 1,
			year: day.getFullYear(),
			programId: dataProgram?.id,
		},
	});
	useErrorModal({ error });

	useEffect(() => {
		if (data?.listProgramSessions) {
			let tmpTodaySession: any[] = [];
			let tmpNextSession: any[] = [];
			data?.listProgramSessions.result?.forEach((session: any) => {
				if (moment(session.plannedAt).get('date') === day.getDate()) tmpTodaySession.push(session);

				if (moment(session.plannedAt).get('date') > day.getDate()) tmpNextSession.push(session);

				// @ts-ignore
				setTodaySession(tmpTodaySession);
				// @ts-ignore
				setNextSession(tmpNextSession);
			});
		}
	}, [data?.listProgramSessions]);

	return (
		<WidgetBase className="programSessions">
			<WidgetTitle
				title={t('programs:programSessions:sessionsDay')}
				buttonClass={`
						btnGreen textNormal weightNormal
						${isMediumPlus ? 'btnDuo' : 'btnCentered btnLarge'}
					`}
				buttonText={isMediumPlus && t('commons:calendar')}
				ButtonIcon={Calendar}
				onClick={() => history.push('/program/' + dataProgram?.id + '/calendar')}
			/>
			<div className="todaySessions">
				<h2 className="sessionListeDate textMedium weightMedium">{moment(day).format('LL')}</h2>

				<div className="sessionsList">
					{todaySession?.length > 0 ? (
						todaySession?.map((session, key) => {
							return <SessionLine key={key} session={session} programId={dataProgram?.id} />;
						})
					) : (
						<EmptyMessage message={t('programs:noSession')} />
					)}
				</div>
			</div>
			<div className="futurSessions">
				<div className="decoLine" />
				<h1 className="titleXL weightMedium">{t('programs:programSessions:toCome')}</h1>

				{nextSession?.length > 0 ? (
					nextSession?.map((session, key) => {
						// @ts-ignore
						let newSessionDate: null = moment(session?.plannedAt).format('LL');
						if (newSessionDate !== sessionDate) {
							sessionDate = newSessionDate;
						} else {
							newSessionDate = null;
						}
						return (
							<div key={key}>
								{newSessionDate && (
									<h2 className="sessionListeDate dateBlock textMedium weightMedium">{newSessionDate}</h2>
								)}
								<div className="sessionsList">
									<SessionLine session={session} programId={dataProgram?.id} />
								</div>
							</div>
						);
					})
				) : (
					<EmptyMessage message={t('programs:noSession')} />
				)}
			</div>
		</WidgetBase>
	);
};

const SessionLine = ({ session, programId }: any) => {
	const history = useHistory();

	return (
		<div
			className="sessionLineProgramHome"
			onClick={() => history.push('/program/' + programId + '/session/' + session?.id)}
		>
			<div className="sessionLineLeft">
				<h1 className="titleL weightSemiBold">{session?.name}</h1>
				<h2 className="textNormal weightMedium">{session?.exercises?.length} Block</h2>
			</div>
			<div className="sessionLineRight">
				<ChevronRight />
			</div>
		</div>
	);
};

export default ProgramSessions;
