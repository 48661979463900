import './CalendarSessionLeaderboard.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import clickOutside from '../../../../../utils/clickOutside';
import CalendarSessionLeaderboardTemplate from './CalendarSessionLeaderboardTemplate';

interface CalendarSessionLeaderboardProps {
	dataExercise: any;
	sessionId: any;
	leaderboardVisible: boolean;
	setLeaderboardVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

enum STEP {
	'VISIBLE',
	'HIDDEN',
	'ENTERING',
	'LEAVING',
}

const CalendarSessionLeaderboard = ({
	dataExercise,
	leaderboardVisible,
	setLeaderboardVisible,
	sessionId,
}: CalendarSessionLeaderboardProps) => {
	const [step, setStep] = useState(leaderboardVisible ? STEP.VISIBLE : STEP.HIDDEN);
	const classNameLeaderboard =
		step === STEP.VISIBLE ? 'calendarSessionLeaderboard' : 'calendarSessionLeaderboard calendarSessionLeaderboardOut';
	const isDesktopXXL = useMediaQuery({ query: '(min-width: 1600px)' });
	const calendarSessionLeaderboard = useRef<any>();

	useEffect(() => {
		if (!leaderboardVisible) {
			setStep(STEP.LEAVING);
		} else {
			setStep((s) => (s === STEP.HIDDEN ? STEP.ENTERING : STEP.VISIBLE));
		}
	}, [leaderboardVisible]);

	useEffect(() => {
		if (step === STEP.LEAVING) {
			calendarSessionLeaderboard.current.style.transition = 'left 200ms ease-out, opacity 200ms ease-out';

			const timer = setTimeout(() => {
				setStep(STEP.HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (step === STEP.ENTERING) {
			// document.body.offsetHeight
			setStep(STEP.VISIBLE);

			setTimeout(() => {
				calendarSessionLeaderboard.current.style.transition = 'unset';
			}, 200);
		}
	}, [step]);

	// //CLICK OUTSIDE GESTION
	useEffect(() => {
		const handleClickOutsideSidebar = (e: any) => {
			if (step === STEP.VISIBLE && isDesktopXXL) {
				if (
					clickOutside(e, ['calendarSessionLeaderboard', 'calendarSessionLeaderboard calendarSessionLeaderboardOut'])
				) {
					setLeaderboardVisible(false);
				}
			}
		};

		if (isDesktopXXL) {
			window.addEventListener('click', handleClickOutsideSidebar);
			return () => {
				window.removeEventListener('click', handleClickOutsideSidebar);
			};
		}
	}, [isDesktopXXL, step, setLeaderboardVisible]);

	// if(!leaderboardVisible) return null

	return (
		<div className={classNameLeaderboard} ref={calendarSessionLeaderboard}>
			<CalendarSessionLeaderboardTemplate
				dataExercise={dataExercise}
				sessionId={sessionId}
				setLeaderboardVisible={setLeaderboardVisible}
				leaderboardVisible={leaderboardVisible}
			/>
		</div>
	);
};

export default CalendarSessionLeaderboard;
