import './TextInput.scss';

const TextInput = ({
	name,
	errors,
	register,
	placeholder,
	required,
	type,
	label,
	Icon,
	textIcon,
	noLabel = false,
	onIconPress,
	classTextInput
}: any) => {
	const handleClick = (e: any) => {
		if (onIconPress) onIconPress();
	};

	return (
		<div 
			className={`
				textInput
				${classTextInput}
			`}
		>
			{!noLabel && (
				<label htmlFor={name} className="textNormal weightMedium">
					{label || placeholder}
				</label>
			)}

			<div className="inputZone">
				<input
					className={`textMedium weightMedium
						${errors[name] && 'inputError'}
					`}
					placeholder={placeholder}
					type={type ? type : 'text'}
					{...register(name, { required })}
				/>

				{Icon && (
					<div className="inputIcon" onClick={handleClick}>
						<Icon />
					</div>
				)}

				{textIcon && 
					<div className="inputTextIcon textSmall weightMedium">
						{textIcon}
					</div>
				}
			</div>

			<p className="errorMessage textSmall weightMedium">
				{errors[name] && errors[name].message}
			</p>
		</div>
	);
};

export default TextInput;
