import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ProgramNavbar from '../../../components/pages/programs/programs/ProgramNavbar';
import { PROG_NAVBAR } from '../../../config/type';
import useIsCoach from '../../../hooks/user/useIsCoach';
import useIsMember from '../../../hooks/user/useIsMember';
import ProgAcceptNewMember from '../../../components/pages/programs/ProgramMember/ProgAcceptNewMember';
import ProgListMember from '../../../components/pages/programs/ProgramMember/ProgListMember';

import './ProgramMemberPage.scss';
import SearchMemberWidget from '../../../components/pages/programs/ProgramMember/SearchMemberWidget';
import { useShowProgramQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';

export const ProgramMemberPage = () => {
	const { t } = useTranslation();
	const [userRole, setUserRole] = useState('guest');
	const { slug } = useParams<any>();
	const isCoach = useIsCoach(slug);
	const isMember = useIsMember(slug);
	const { data, error, loading } = useShowProgramQuery({
		variables: {
			id: slug,
		},
	});
	useErrorModal({ error });

	useEffect(() => {
		if (isCoach) setUserRole('coach');
		else if (isMember) setUserRole('member');
		else setUserRole('guest');
	}, [isCoach, isMember]);

	console.log('data', data);

	return (
		<div id="programMemberPage">
			<ProgramNavbar slug={slug} focus={PROG_NAVBAR.MEMBER} name={'member'} userRole={userRole} />

			<div className="programMemberPageWrapper">
				<div className="column1 column">
					{userRole === 'coach' && data?.showProgram?.price === 0 && (
						<SearchMemberWidget full={true} programId={slug} />
					)}
					<ProgListMember programId={slug} userRole={userRole} dataProgram={data?.showProgram} />
				</div>
				<div className="column2 column">
					{userRole === 'coach' && data?.showProgram?.price === 0 && <ProgAcceptNewMember programId={slug} />}
				</div>
			</div>
		</div>
	);
};
