import './CalendarSessionLeaderboardUserFeedback.scss';
import React from 'react';
import { useShowUserSessionExerciseFeedbackQuery } from '../../../../../generated/gql';
import { useTranslation } from 'react-i18next';
import { Star } from '../../../../main/Icons';

interface CalendarSessionLeaderboardUserFeedbackProps {
	exerciseId: string;
	userId: string;
}

const CalendarSessionLeaderboardUserFeedback = ({
	exerciseId,
	userId,
}: CalendarSessionLeaderboardUserFeedbackProps) => {
	const { t } = useTranslation();
	const { data, error, loading, refetch } = useShowUserSessionExerciseFeedbackQuery({
		variables: {
			exerciseId,
			userId,
		},
	});

	//console.log('data ShowUserSessionExerciseFeedback', data);

	if (data?.showUserSessionExerciseFeedback === null || loading) {
		return null;
	}

	return (
		<div className="leaderboardUserFeedback">
			<div className="comment textNormal weightMedium">
				<h1 className="textNormal weightMedium">{t('commons:comment')}</h1>
				<p className="textNormal weightMedium">{data?.showUserSessionExerciseFeedback?.comment}</p>
			</div>
			<div className="ratings">
				<FeedbackRating title={t('session:trainingFeeling')} rate={data?.showUserSessionExerciseFeedback?.feeling} />
				<FeedbackRating title={t('session:noteExercise')} rate={data?.showUserSessionExerciseFeedback?.note} />
			</div>
		</div>
	);
};

interface FeedbackRatingProps {
	title: string;
	rate: any;
}

const FeedbackRating = ({ title, rate }: FeedbackRatingProps) => {
	return (
		<div className="feedbackRating">
			<h1 className="textNormal weightMedium">{title}</h1>
			<div className="rate">
				{Array.from(Array(5), (e: any, key: number) => (
					<div
						key={key}
						className={`
								star
								${key < rate && 'starBlue'}
							`}
					>
						<Star />
					</div>
				))}
			</div>
		</div>
	);
};

export default CalendarSessionLeaderboardUserFeedback;
