import { ArrowRight } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';

import ProgramLine from '../../../pages/profile/programs/ProgramLine';

import './ProgramsCreated.scss';
import { useHistory } from 'react-router-dom';
const ProgramsCreated = ({ className, data, isSelfProfile }: any) => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<WidgetBase className={className}>
			<div className="programsCreatedWidgetWrapper">
				<WidgetTitle
					title={t('profile:widgetTitle:programsCreated')}
					ButtonIcon={isSelfProfile ? ArrowRight : null}
					buttonClass="btnLarge btnGrey btnCentered"
					onClick={() => history.push('/program')}
				/>
				<div className="programsList">
					{data.ownedPrograms.map((program: any) => (
						<ProgramLine
							key={program.id}
							data={program}
							isSelfProfile={isSelfProfile}
						/>
					))}
				</div>
			</div>
		</WidgetBase>
	);
};

export default ProgramsCreated;
