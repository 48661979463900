import { useCallback, useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { PubNubClient, setUserPubNubData } from '../../api/PubNub/PubNubClient';
import update from 'immutability-helper';
import {
	ListChatChannelsDocument,
	ListChatMembersAndMessagesDocument,
	ShowChatChannelDocument,
	ShowChatMemberDocument,
	useSetChannelLatestReceiptMutation,
} from '../../generated/gql';
import { useSelector } from 'react-redux';
import { SystemState } from '../../store/SystemReducer';
import { useLocation } from 'react-router-dom';

const PubNubChatListner = () => {
	const apolloClient = useApolloClient();
	const chatChannelfocusedId = useSelector((state) => state.chatChannelId);
	const location = useLocation();

	const [setChannelLatestReceipt] = useSetChannelLatestReceiptMutation();

	const onMessage = useCallback(
		async (data) => {
			if (data?.message?.pn_gcm?.data?.type === 'PROGRAM_JOIN_REQUEST_ACCEPTED') {
				setUserPubNubData(PubNubClient.getUUID());
				return;
			}
			if (data?.message?.type === 'UNSUBSCRIBE') {
				PubNubClient.unsubscribe({
					channel: data?.message?.channel,
				});
			}
			if (['INBOX_MESSAGE', 'PROGRAM_MESSAGE'].includes(data?.message?.pn_gcm?.data?.type)) {
				data.message.pn_gcm.data.messageData = {
					__typename: 'ChatMessage',
					attachment: null,
					content: null,
					...data?.message?.pn_gcm?.data.messageData,
				};

				console.log('New chat message received', data.message.pn_gcm.data.messageData);

				const listChatChannelsData = apolloClient.cache.readQuery({
					query: ListChatChannelsDocument,
				});

				if (listChatChannelsData) {
					if (
						!listChatChannelsData.listChatChannels.result.find(
							(c) => c.id === data?.message?.pn_gcm?.data?.messageData?.channel
						)
					) {
						const res = await apolloClient.query({
							query: ShowChatChannelDocument,
							variables: {
								channelId: data?.message?.pn_gcm?.data?.messageData?.channel,
							},
							fetchPolicy: 'network-only',
						});
						apolloClient.cache.writeQuery({
							query: ListChatChannelsDocument,
							data: update(listChatChannelsData, {
								listChatChannels: {
									result: {
										$push: [
											update(res?.data?.showChatChannel, {
												latestMessage: {
													content: {
														$set: data?.message?.pn_gcm?.data?.messageData.content || '[image]',
													},
												},
												unreadMessagesCount: {
													$apply: (x) => x + 1,
												},
											}),
										],
									},
									total: {
										$apply: (x) => x + 1,
									},
								},
							}),
						});
					}
				}

				let listChatChannelMessagesAndMembersData = apolloClient.cache.readQuery({
					query: ListChatMembersAndMessagesDocument,
					variables: {
						channelId: data?.message?.pn_gcm?.data?.messageData?.channel,
					},
				});

				if (!listChatChannelMessagesAndMembersData) {
					return;
				}

				let sender = listChatChannelMessagesAndMembersData.listChatChannelMembers?.result?.find(
					(m) => m.pubnub_uuid === data?.message?.pn_gcm?.data?.messageData?.sender
				);
				if (!sender) {
					const res = await apolloClient.query({
						query: ShowChatMemberDocument,
						variables: {
							pubnub_uuid: data?.message?.pn_gcm?.data?.messageData?.sender,
						},
						fetchPolicy: 'network-only',
					});
					sender = {
						...res?.data?.showChatMember,
						__typename: 'User',
					};

					listChatChannelMessagesAndMembersData = apolloClient.cache.readQuery({
						query: ListChatMembersAndMessagesDocument,
						variables: {
							channelId: data?.message?.pn_gcm?.data?.messageData?.channel,
						},
					});
					apolloClient.cache.writeQuery({
						query: ListChatMembersAndMessagesDocument,
						variables: {
							channelId: data?.message?.pn_gcm?.data?.messageData?.channel,
						},
						data: update(listChatChannelMessagesAndMembersData, {
							listChatChannelMembers: {
								result: {
									$push: [res?.data?.showChatMember],
								},
								total: {
									$apply: (x) => x + 1,
								},
							},
						}),
					});
				} else {
					apolloClient.cache.writeQuery({
						query: ListChatMembersAndMessagesDocument,
						variables: {
							channelId: data?.message?.pn_gcm?.data?.messageData?.channel,
						},
						data: update(listChatChannelMessagesAndMembersData, {
							listChatChannelMessages: {
								result: {
									$unshift: [{ ...data?.message?.pn_gcm?.data?.messageData, deleted: false }],
								},
								total: {
									$apply: (x) => x + 1,
								},
							},
						}),
					});
				}

				if (listChatChannelsData) {
					const channelIndex = listChatChannelsData.listChatChannels.result.findIndex(
						(c) => c.id === data?.message?.pn_gcm?.data?.messageData?.channel
					);

					if (
						location?.pathname === '/chat' &&
						chatChannelfocusedId === data?.message?.pn_gcm?.data?.messageData?.channel
					) {
						await setChannelLatestReceipt({
							variables: {
								channelId: chatChannelfocusedId,
								receivedAt: data?.message?.pn_gcm?.data?.messageData?.createdAt,
							},
						}).catch((e) => console.error('e', e));
					}

					apolloClient.cache.writeQuery({
						query: ListChatChannelsDocument,
						data: update(listChatChannelsData, {
							listChatChannels: {
								result: {
									[channelIndex]: {
										latestMessage: {
											$set: {
												__typename: 'LatestChannelMessage',
												attachment: data?.message?.pn_gcm?.data?.messageData.attachment || null,
												sender,
												content: data?.message?.pn_gcm?.data?.messageData?.content || '[image]',
												createdAt: data?.message?.pn_gcm?.data?.messageData?.createdAt,
												updatedAt: data?.message?.pn_gcm?.data?.messageData?.updatedAt,
												id: data?.message?.pn_gcm?.data?.messageData?.id,
												deleted: false,
											},
										},
										unreadMessagesCount: {
											$apply: (x) => x + 1,
										},
									},
								},
							},
						}),
					});
				}
			}
		},
		[apolloClient, chatChannelfocusedId, location?.pathname]
	);

	const pubnubListener = useMemo(() => {
		return {
			message: onMessage,
		};
	}, [onMessage]);

	useEffect(() => {
		if (!apolloClient) return;
		PubNubClient.addListener(pubnubListener);

		return () => {
			PubNubClient.removeListener(pubnubListener);
		};
	}, [apolloClient, pubnubListener]);

	return <div />;
};

export default PubNubChatListner;
