import { Users, Star } from '../../../main/Icons';

import './ProgramLine.scss';

import { ProgIcon } from '../../../main/ProgIcon';
import { computeProgramNote } from '../../../../utils/helpers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProgramLine = ({ data }: any) => {
	const history = useHistory();
	const { t } = useTranslation();
	const stars = computeProgramNote(data.points, data.notesNumber);

	return (
		<div className="programLineProgramPage" onClick={() => history.push('/program/' + data?.id)}>
			<div className="left">
				<div className="logo">
					<ProgIcon url={data.avatar?.url} />
				</div>

				<div className="infos">
					<h1 className="textNormal">{data.name}</h1>
					<div className="infosDetails">
						<div className="infosDetail">
							<Users />
							<h2 className="textNormal">{data.users}</h2>
						</div>

						<div className="infosDetail">
							<Star />
							<h2 className="textNormal">{stars ? stars : '-'}</h2>
						</div>
						{data?.price > 0 && (
							<div className="infosDetail">
								{/*<Price />*/}
								<h2 className="textNormal">
									{data?.price}€/{t('commons:month')}
								</h2>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProgramLine;
