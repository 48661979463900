import { useCallback } from 'react';
import UserCard from '../../../common/UserCard';
import Button from '../../../common/Button';
import { Check, X } from '../../../main/Icons';
import {
	ListProgramUsersDocument,
	useReplyToProgramJoinRequestMutation,
} from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';

import './ProgAcceptNewMemberLine.scss';
import update from 'immutability-helper';

const ProgAcceptNewMemberLine = ({ userData, programId, onUpdate }: any) => {
	const [
		replyToProgramJoinRequest,
		{ data, error, loading, refetch },
	]: any = useReplyToProgramJoinRequestMutation();
	useErrorModal({ error, onRetry: () => refetch() });

	const onReply = useCallback(
		(accept: boolean) => {
			replyToProgramJoinRequest({
				variables: {
					id: userData?.id,
					accept,
				},
				update(cache: any, { data }: any) {
					if (accept) {
						const queryData: any = cache.readQuery({
							query: ListProgramUsersDocument,
							variables: {
								id: programId,
							},
						});

						if (queryData?.listProgramUsers) {
							cache.writeQuery({
								query: ListProgramUsersDocument,
								variables: {
									id: programId,
								},
								data: {
									listProgramUsers: update(queryData.listProgramUsers, {
										result: {
											$unshift: [data],
										},
										total: {
											$apply: (x: number) => x + 1,
										},
									}),
								},
							});
						}
					}
				},
			})
				.then(() => onUpdate())
				.catch((e: any) => console.error(e));
		},
		[programId, refetch, replyToProgramJoinRequest, userData?.id]
	);

	return (
		<>
			<div className="memberLine">
				<div className="left">
					<UserCard
						key={userData.applicant.id}
						data={userData.applicant}
						imgSize={42}
						classNameName="textMedium weightMedium"
						classNameNickname="textSmall weightMedium"
					/>
				</div>

				<div className="right">
					<Button
						buttonClass="btnRedLine btnLarge btnCentered"
						ButtonIcon={X}
						onClick={() => onReply(false)}
					/>
					<Button
						buttonClass="btnGreenLine btnLarge btnCentered"
						ButtonIcon={Check}
						onClick={() => onReply(true)}
					/>
				</div>
			</div>
		</>
	);
};

export default ProgAcceptNewMemberLine;
