import './ProgramAddInfos.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pen } from '../../../main/Icons';
import TextInput from '../../../common/Form/TextInput';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Textarea from '../../../common/Form/Textarea';
import Button from '../../../common/Button';
import defaultProgImg from '../../../../assets/img/icon_hustle_up_small.png';
import { MeDocument, useCreateProgramMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import update from 'immutability-helper';
import { useHistory } from 'react-router-dom';
import { resizeFile } from '../../../../utils/helpers';
import { setUserPubNubData } from '../../../../api/PubNub/PubNubClient';
import { usePubNub } from 'pubnub-react';
import { PROG_CONFIDENTIALITY, PROG_PRICEING, PROG_TYPE } from '../../../../config/type';
import { Keywords } from '../Keyworlds';

type Inputs = {
	name: string;
	description: string;
	usersLimit: number;
	duration: number;
};

const ProgramAddInfos = ({ progConfig }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [programImg, setProgramImg] = useState<any>();
	const [keywords, setKeywords] = useState<any>([]);
	const [shouldSumbit, setShouldSubmit] = useState<any>(false);
	const pubnub = usePubNub();

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(t('formik:requiredField'))
			.min(2, t('formik:progNameValidation'))
			.max(40, t('formik:progNameValidation')),
		description: Yup.string()
			.trim()
			.required(t('formik:requiredField'))
			.max(3000, t('formik:progDescriptionValidation')),
		/*usersLimit: Yup.number().min(0, t('formik:min0')).max(100000000, t('formik:max')),*/
		/*.integer(t('formik:invalideNumber')),*/
		price: Yup.number().min(5, t('formik:min5')).max(10000, t('formik:max10000')).integer(t('formik:invalideNumber')),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const [
		createProgram,
		{ data, error, loading }, // eslint-disable-line
	] = useCreateProgramMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: MeDocument,
			});

			if (!data) {
				console.error('QUERY ME NO DATA');
				return;
			}

			cache.writeQuery({
				query: MeDocument,
				data: {
					me: update(queryData?.me, {
						ownedPrograms: {
							$push: [data?.createProgram],
						},
					}),
				},
			});
		},
	});
	useErrorModal({ error });

	const onSubmit = async (values: any) => {
		if (!shouldSumbit) return;

		try {
			if (values.usersLimit === '' || values.usersLimit === undefined) values.usersLimit = '0';

			const val = validationSchema.cast(values);

			//parseInt(val.usersLimit)
			let progConfigData = {
				description: val.description,
				avatar: null,
				duration: 0,
				name: val.name,
				price: values.price ? parseFloat(values.price) : 0,
				private: progConfig[1].value === PROG_CONFIDENTIALITY.PRIVATE,
				tags: keywords,
				type: progConfig[0].value,
				usersLimit: parseInt(val.usersLimit),
				open: false,
				published: true,
			};

			if (programImg?.currentFile) {
				try {
					try {
						const image = await resizeFile(programImg?.currentFile);
						// @ts-ignore
						progConfigData.avatar = image;
					} catch (err) {
						console.log(err);
					}
				} catch (e) {
					console.error('resize error', e);
				}
			}
			createProgram({
				variables: progConfigData,
			})
				.then((r) => {
					console.log('r createProgram', createProgram);
					if (
						// @ts-ignore
						r?.data?.createProgram?.usersLimit <= 100 &&
						r?.data?.createProgram?.type === 'GROUP'
					) {
						setUserPubNubData(pubnub.getUUID());
					}

					setTimeout(() => {
						history.push('/program/' + r?.data?.createProgram?.id);
					}, 1000);
				})
				.catch((e) => console.error('e', e));
		} catch (e) {
			console.error('handleSubmit ', JSON.stringify(e));
		}
	};

	const selectFile = (e: any) => {
		setProgramImg({
			currentFile: e.target.files[0],
			previewImage: URL.createObjectURL(e.target.files[0]),
		});
	};

	return (
		<div className="programStepInfos programStep">
			<div className="logoProgram">
				<div className="preview">
					<img src={programImg?.previewImage ? programImg.previewImage : defaultProgImg} alt="" />
				</div>
				<div className="actions textNormal weightMedium">
					<label htmlFor="imgUpload" className="imgUploadLabel">
						<Pen />
						{t('programs:programAdd:updateLogo')}
						<input id="imgUpload" type="file" accept="image/*" onChange={selectFile} />
					</label>
				</div>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="name"
					placeholder={t('programs:programAdd:programName')}
					type="text"
				/>
				<Textarea errors={errors} register={register} name="description" placeholder={t('formik:description')} />
				{progConfig[2]?.value === PROG_PRICEING.PAYING && (
					<>
						<TextInput
							errors={errors}
							register={register}
							name="price"
							placeholder={t('programs:programAdd:stepTitlePrice')}
							type="number"
						/>
						<p className="textSmall weightMedium helper price">
							{t('programs:programAdd:priceFieldHelper')}
							{t('programs:programAdd:priceFieldHelper2')}
						</p>
					</>
				)}
				{progConfig[0]?.value === PROG_TYPE.GROUP && (
					<>
						<TextInput
							// required
							errors={errors}
							register={register}
							name="usersLimit"
							placeholder={t('programs:programAdd:maxMember')}
							type="number"
						/>
						<p className="textSmall weightMedium helper">{t('programs:userMaxHelper')}</p>
					</>
				)}
				{/*<TextInput
					// required
					errors={errors}
					register={register}
					name="duration"
					placeholder={t('programs:programAdd:duration')}
					type="number"
				/>*/}
				<Keywords keywords={keywords} setKeywords={setKeywords} />
				<div className="actions">
					<Button
						loading={loading}
						buttonClass={'textNormal weightMedium btnGreen'}
						buttonText={t('commons:finish')}
						onClick={() => {
							setShouldSubmit(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default ProgramAddInfos;
