import { useTranslation } from 'react-i18next';
// @ts-ignore
import uuid from 'pubnub/src/core/components/uuid';
import './InputArea.scss';
import { useCallback, useMemo } from 'react';
import {
	ListChatChannelsDocument,
	useSendChatImageMutation,
	useSendChatMessageMutation,
} from '../../../../generated/gql';
import useUser from '../../../../hooks/user/useUser';
import update from 'immutability-helper';
import { MESSSAGE_INPUT_TYPE } from '../../../../config/type';
import MessageInput from '../../../common/MessageInput/MessageInput';
import { resizeFile } from '../../../../utils/helpers';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const InputArea = ({ channel, updateQuery, discussionZoneWrapper }: any) => {
	const { t } = useTranslation();
	const { user } = useUser();

	const [sendMessage, { error, loading, data }]: any = useSendChatMessageMutation({
		// @ts-ignore
		update(cache, { data: { sendChatMessage } }) {
			try {
				updateQuery((prev: any) => {
					const tempMessageIndex = prev.listChatChannelMessages.result.findIndex(
						(m: any) => m.id === sendChatMessage.tempId
					);

					if (tempMessageIndex === -1) return prev;

					const newData = update(prev, {
						listChatChannelMessages: {
							result: {
								$splice: [[tempMessageIndex, 1]],
								$unshift: [sendChatMessage.message],
							},
							total: {
								$apply: (x: any) => x + 1,
							},
						},
					});

					return newData;
				});

				const data: any = cache.readQuery({
					query: ListChatChannelsDocument,
				});

				if (!data) return;

				const { listChatChannels } = data;

				const channelIndex = listChatChannels.result.findIndex((c: any) => c.id === channel?.id);

				if (channelIndex === -1) {
					cache.writeQuery({
						query: ListChatChannelsDocument,
						data: {
							listChatChannels: update(listChatChannels, {
								result: {
									$push: [
										{
											__typename: 'ChatChannel',
											avatar: null,
											...channel,
											latestMessage: {
												sender: {
													__typename: 'User',
													id: user?.id,
													firstName: user?.firstName,
													lastName: user?.lastName,
													nickname: user?.nickname,
													pubnub_uuid: user?.pubnub_uuid,
												},
												attachment: null,
												content: sendChatMessage.message.content,
												createdAt: sendChatMessage.message.createdAt,
												updatedAt: sendChatMessage.message.updatedAt,
												deleted: null,
												id: sendChatMessage.message.id,
											},
											unreadMessagesCount: 0,
										},
									],
								},
								total: {
									$apply: (x: any) => x + 1,
								},
							}),
						},
					});
					return;
				}

				cache.writeQuery({
					query: ListChatChannelsDocument,
					data: {
						listChatChannels: update(listChatChannels, {
							result: {
								[channelIndex]: {
									latestMessage: {
										$set: {
											__typename: 'LatestChannelMessage',
											sender: {
												__typename: 'User',
												id: user?.id,
												firstName: user?.firstName,
												lastName: user?.lastName,
												nickname: user?.nickname,
												pubnub_uuid: user?.pubnub_uuid,
											},
											attachment: null,
											content: sendChatMessage.message.content,
											createdAt: sendChatMessage.message.createdAt,
											updatedAt: sendChatMessage.message.updatedAt,
											deleted: null,
											id: sendChatMessage.message.id,
										},
									},
								},
							},
						}),
					},
				});
			} catch (e) {
				console.error('UPDATE SEND MESSAGE', e, sendChatMessage.message.content);
			}
		},
	});
	useErrorModal({ error });

	const [sendChatImage, { error: sendChatImageError }]: any = useSendChatImageMutation({
		// @ts-ignore
		update(cache, { data: { sendChatImage } }) {
			try {
				updateQuery((prev: any) => {
					const tempMessageIndex = prev.listChatChannelMessages.result.findIndex(
						(m: any) => m.id === sendChatImage.tempId
					);

					if (tempMessageIndex === -1) return prev;

					return update(prev, {
						listChatChannelMessages: {
							result: {
								$splice: [[tempMessageIndex, 1]],
								$unshift: [sendChatImage.message],
							},
							total: {
								$apply: (x: any) => x + 1,
							},
						},
					});
				});

				const data: any = cache.readQuery({
					query: ListChatChannelsDocument,
				});

				if (!data) return;

				const { listChatChannels } = data;

				const channelIndex = listChatChannels.result.findIndex((c: any) => c.id === channel?.id);

				if (channelIndex === -1) {
					cache.writeQuery({
						query: ListChatChannelsDocument,
						data: {
							listChatChannels: update(listChatChannels, {
								result: {
									$push: [
										{
											__typename: 'ChatChannel',
											avatar: null,
											...channel,
											latestMessage: {
												__typename: 'LatestChannelMessage',
												sender: {
													id: user?.id,
													firstName: user?.firstName,
													lastName: user?.lastName,
													nickname: user?.nickname,
													pubnub_uuid: user?.pubnub_uuid,
												},
												attachment: null,
												content: '[image]',
												createdAt: sendChatImage.message.createdAt,
												updatedAt: sendChatImage.message.updatedAt,
												deleted: null,
												id: sendChatImage.message.id,
											},
											unreadMessagesCount: 0,
										},
									],
								},
								total: {
									$apply: (x: any) => x + 1,
								},
							}),
						},
					});
					return;
				}

				cache.writeQuery({
					query: ListChatChannelsDocument,
					data: {
						listChatChannels: update(listChatChannels, {
							result: {
								[channelIndex]: {
									latestMessage: {
										$set: {
											__typename: 'LatestChannelMessage',
											attachment: null,
											sender: {
												__typename: 'User',
												id: user?.id,
												firstName: user?.firstName,
												lastName: user?.lastName,
												nickname: user?.nickname,
												pubnub_uuid: user?.pubnub_uuid,
											},
											content: '[image]',
											createdAt: sendChatImage.message.createdAt,
											updatedAt: sendChatImage.message.updatedAt,
											deleted: null,
											id: sendChatImage.message.id,
										},
									},
								},
							},
						}),
					},
				});
			} catch (e) {
				console.error('UPDATE SEND IMAGE', e);
			}
		},
	});
	// @ts-ignore
	useErrorModal({ sendChatImageError });

	const channelBlockedMessage = useMemo(() => {
		if (!channel?.blockedBy) return null;

		// @ts-ignore
		return channel.blockedBy === user?.id ? t('chat:blockedByUser') : t('chat:blockedByOther');
	}, [channel.blockedBy, t, user?.id]);

	const sendChatMessage = useCallback(
		async (messages, image) => {
			if (channelBlockedMessage) return;
			const pubnub_uuid = user?.pubnub_uuid;

			if (messages[0]?.text.trim()) {
				const tempId = uuid.createUUID();
				// @ts-ignore

				const tempMessage = {
					id: tempId,
					attachment: null,
					content: messages[0]?.text.trim(),
					sender: pubnub_uuid,
					deleted: false,
					pending: true,
					channel: channel?.id,
					createdAt: new Date(),
					updatedAt: new Date(),
				};

				updateQuery((prev: any) => {
					return update(prev, {
						listChatChannelMessages: {
							result: {
								$unshift: [tempMessage],
							},
						},
					});
				});

				sendMessage({
					variables: {
						channelId: channel.id,
						content: messages[0].text.trim(),
						senderUuid: pubnub_uuid,
						tempId,
					},
				});
			}

			let imageFile;

			if (image) {
				//console.log('image', image);

				const tempId2 = uuid.createUUID();
				imageFile = await resizeFile(image.currentFile);
				await sendChatImage({
					variables: {
						image: imageFile,
						channelId: channel?.id,
						tempId2,
					},
				});

				const tempMessage = {
					id: tempId2,
					attachment: {
						type: 'IMAGE',
						url: image?.previewImage,
					},
					content: null,
					sender: pubnub_uuid,
					deleted: false,
					pending: true,
					channel: channel?.id,
					createdAt: new Date(),
					updatedAt: new Date(),
				};

				updateQuery((prev: any) => {
					return update(prev, {
						listChatChannelMessages: {
							result: {
								$unshift: [tempMessage],
							},
						},
					});
				});
			}
		},
		[channelBlockedMessage, user?.pubnub_uuid, channel.id, updateQuery, sendMessage, sendChatImage]
	);

	const handleSubmit = (textAreaValue: any, image: any) => {
		//console.log('handleSubmit', textAreaValue, image);

		if (textAreaValue !== '' || image !== null) {
			sendChatMessage([{ text: textAreaValue }], image).catch((e) => console.error(e));
		}
	};

	return <MessageInput handleSubmit={handleSubmit} type={MESSSAGE_INPUT_TYPE.CHAT} />;
};

export default InputArea;
