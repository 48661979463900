import React from 'react';
import hustleGreen from '../assets/img/hustle_up/hustle_green.png';
import { useTranslation } from 'react-i18next';

import './NotFound.scss';
import Button from '../components/common/Button';
import { useHistory } from 'react-router-dom';

export const NotFound = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div id="notFoundPage">
			<div className="main">
				<img src={hustleGreen} alt="Hustle Up logo" />
				<h1 className="titleXXXL weightBold">{t('commons:404Title')}</h1>
				<div className="returnButton">
					<Button
						buttonText={t('commons:return')}
						buttonClass="btnGreenLight textNormal weightMedium"
						onClick={() => history.push('/')}
					/>
				</div>
			</div>
		</div>
	);
};
