import { Mail, EyeOff, Eye } from '../../main/Icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';

import TextInput from '../../common/Form/TextInput';
import IconRound from '../../common/IconRound';
import Button from '../../common/Button';

import './Step2ResetPassword.scss';

type Inputs = {
	email: string;
};

export default function Step2ResetPassword({ setStep, email }: any) {
	const { t } = useTranslation();
	const [showPass, setShowPass] = useState(false);

	const validationSchema = Yup.object().shape({
		code: Yup.string().trim().required(t('formik:requiredField')),
		password: Yup.string()
			.required(t('formik:requiredField'))
			.min(6, t('formik:passwordMinLength'))
			.max(20, t('formik:passwordMaxLength'))
			.matches(
				/^(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9]).*$/,
				t('formik:passwordMustContain')
			),
		confirmPassword: Yup.string()
			.required(t('formik:requiredField'))
			.oneOf([Yup.ref('password'), null], t('formik:passwordsMustMatch')),
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = useCallback(
		async (data: { code: string; password: string }) => {
			await Auth.forgotPasswordSubmit(email, data?.code, data?.password)
				.then((r) => {
					console.log('r', r);
					setStep(3);
				})
				.catch((e) => console.log(e));
		},
		[email, setStep]
	);

	return (
		<div className="forgetPasswordStep2 loginLeftContainer">
			<div className="headerRoundIcon">
				<IconRound Icon={Mail} />
				<h1 className="formTitle titleXXL weightBold">{t('auth:checkmail')}</h1>
				<p className="formText textMedium weightMedium">
					{t('auth:checkMailText')}
					<br />
					<br />
					{t('auth:checkMailText2')}
				</p>
			</div>

			<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="code"
					placeholder="0 0 0 0"
					type="text"
					label={t('formik:codeReset')}
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="password"
					type={showPass ? 'text' : 'password'}
					label={t('formik:newPassword')}
					Icon={showPass ? Eye : EyeOff}
					onIconPress={() => setShowPass(!showPass)}
				/>
				<TextInput
					required
					errors={errors}
					register={register}
					name="confirmPassword"
					type={showPass ? 'text' : 'password'}
					label={t('formik:confirmPassword')}
				/>

				<div className="formValidationClassic">
					<Button
						buttonText={t('commons:validate')}
						buttonClass="btnGreen textMedium weightBold"
						onClick={() => {}}
					/>
				</div>
			</form>

			<button
				className="backButton textMedium weightMedium"
				onClick={() => setStep(1)}
			>
				{t('commons:back')}
			</button>
		</div>
	);
}
