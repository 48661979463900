import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import en from './en';
import fr from './fr';

const dict = {
	fr: fr,
	en: en,
};

export const initI18n = () => {
	const userLanguage =
		window.navigator.userLanguage || window.navigator.language.split('-')[0];

	const lng = localStorage.getItem('language') || userLanguage || 'en';

	if (lng === 'fr') moment.locale('fr');

	const i18nConfig = {
		debug: false,
		resources: dict,
		lng,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
	};

	i18next
		.use(initReactI18next) // passes i18n down to react-i18next
		.init(i18nConfig)
		.then((r) => {})
		.catch((e) => console.error('initI18n: ', e));
};

const momentFuckYou = (val) => {
	let fixedValue;
	switch (val) {
		case 'fr-FR':
			fixedValue = 'fr';
			break;
		case 'en':
			fixedValue = 'en-gb';
			break;
		case 'en-US':
			fixedValue = 'en-gb';
			break;

		default:
			fixedValue = val;
	}
	return fixedValue;
};

export const switchLang = async (language) => {
	await i18next
		.changeLanguage(language)
		.then(() => {
			moment.locale(momentFuckYou(language));
			localStorage.setItem('language', language);
		})
		.catch((e) => console.error(e));
};
