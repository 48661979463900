import './CalendarSessionComments.scss';

import React, { useCallback, useState } from 'react';
import { useListProgramSessionCommentsQuery } from '../../../../../generated/gql';
import { useParams } from 'react-router-dom';
import Comment from './Comment';
import EmptyMessage from '../../../../common/EmptyMessage';
import { useTranslation } from 'react-i18next';

import CommentInput from './CommentInput';
import Loader from '../../../../main/Loader';
import { useErrorModal } from '../../../../../hooks/useErrorModal';

const CalendarSessionComments = () => {
	const { idSession } = useParams<any>();
	const { t } = useTranslation();
	const [answerInputId, setAnswerInputId] = useState<string>();

	const { data, error, loading, fetchMore } = useListProgramSessionCommentsQuery({
		variables: {
			sessionId: idSession,
		},
	});
	useErrorModal({ error });

	const fetchMoreData = useCallback(() => {
		if (!fetchMore || loading) return;

		const offset = data?.listProgramSessionComments?.offset || 0;
		const limit = data?.listProgramSessionComments?.limit || 20;
		const newOffset = offset + limit;

		fetchMore({
			variables: {
				sessionId: idSession,
				offset: newOffset,
			},
			updateQuery: (prev, { fetchMoreResult }) => {
				if (!fetchMoreResult) return prev;

				//console.log('fetchMoreResult', fetchMoreResult);

				return {
					listProgramSessionComments: {
						// @ts-ignore
						...fetchMoreResult.listProgramSessionComments,
						result: [
							// @ts-ignore
							...prev.listProgramSessionComments.result,
							// @ts-ignore
							...fetchMoreResult.listProgramSessionComments.result,
						],
					},
				};
			},
		}).catch((e: any) => console.log('e', e));
	}, [fetchMore, idSession, data, loading]);

	if (loading) {
		return <Loader size={80} />;
	}

	const dataLengh = data?.listProgramSessionComments?.result?.length || 0;
	// @ts-ignore
	const totalComment = data?.listProgramSessionComments?.total || 0;

	return (
		<div className="calendarSessionComments">
			<CommentInput idSession={idSession} setAnswerInputId={setAnswerInputId} />

			{!data || data?.listProgramSessionComments?.result?.length === 0 ? (
				<EmptyMessage message={t('calendar:noComment')} />
			) : (
				<>
					{data?.listProgramSessionComments?.result?.map((comment: any, index: number) => (
						<Comment
							key={index}
							comment={comment}
							answerInputId={answerInputId}
							setAnswerInputId={setAnswerInputId}
							idSession={idSession}
						/>
					))}
					{dataLengh < totalComment && (
						<button onClick={() => fetchMoreData()} className="moreAnswers textMedium weightBold">
							{t('calendar:showMoreComments')}
						</button>
					)}
				</>
			)}
		</div>
	);
};

export default CalendarSessionComments;
