import { useShowWodBookEntryQuery } from '../../../../generated/gql';
import SelectedWodTemplate from './SelectedWodTemplate';
import { WODTYPE } from '../../../../config/type';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const SelectedWod = ({ wodId }: any) => {
	const { data, error, refetch, loading }: any = useShowWodBookEntryQuery({
		variables: {
			entryId: wodId,
		},
	});
	useErrorModal({ error });

	return (
		<SelectedWodTemplate
			createdAt={data?.showWodBookEntry?.createdAt}
			name={data?.showWodBookEntry?.exercise?.name}
			exercises={data?.showWodBookEntry?.exercise?.blocks}
			scoreExpected={data?.showWodBookEntry?.exercise?.expectedScores}
			scores={data?.showWodBookEntry?.scoreEntries}
			type={WODTYPE.PRIVATE}
			wodId={wodId}
			publicWod={false}
		/>
	);
};

export default SelectedWod;
