import { useCallback, useEffect, useMemo } from 'react';
import { useMeQuery } from '../../generated/gql';
import { setUserPubNubData } from '../../api/PubNub/PubNubClient';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export default () => {
	const history = useHistory();
	const { data: meData, loading, refetch } = useMeQuery({
		fetchPolicy: 'cache-first',
		nextFetchPolicy: 'cache-only',
	});

	const onLogout = useCallback(() => {
		Auth.signOut().then(() => {
			history.go(0);
		});
	}, [history]);

	useEffect(() => {
		if (meData?.me === null) {
			console.log('NOT_AUTHORIZED Account not created on backend !');
			onLogout();
		}
	}, [meData, onLogout]);

	return useMemo(() => {
		return {
			user: meData?.me,
			loading,
			refetch,
		};
	}, [meData, loading, refetch]);
};
