const clickOutside = (e: any, classArr: Array<string>, id: string = '') => {
	let sidebarElem = false;
	let endLoop = false;
	let element = e.target;

	if(element.parentElement === null) return false

	while (!endLoop) {
		if (element.parentElement) {

			if (element.classList) {
				for (let i = 0; i < classArr.length; i++) {
					if (element.classList[0] === classArr[i]) sidebarElem = true;
				}
			}
			element = element.parentElement;
		} else {
			endLoop = true;
		}
	}

	if (!sidebarElem) return true;
	return false;
};



export default clickOutside;
