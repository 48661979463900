import './CommentInput.scss';
import React from 'react';
import MessageInput from '../../../../common/MessageInput/MessageInput';
import {
	ListProgramSessionCommentsDocument,
	useAddProgramSessionCommentMutation,
} from '../../../../../generated/gql';
import update from 'immutability-helper';
import { resizeFile } from '../../../../../utils/helpers';
import { MESSSAGE_INPUT_TYPE } from '../../../../../config/type';

const CommentInput = ({ idSession, setAnswerInputId }: any) => {
	const [
		addProgramSessionComment,
		{ error, loading, data },
	]: any = useAddProgramSessionCommentMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListProgramSessionCommentsDocument,
				variables: {
					sessionId: idSession,
				},
			});

			if (queryData?.listProgramSessionComments) {
				cache.writeQuery({
					query: ListProgramSessionCommentsDocument,
					variables: {
						sessionId: idSession,
					},
					data: {
						listProgramSessionComments: update(
							queryData.listProgramSessionComments,
							{
								result: {
									$unshift: [data?.addProgramSessionComment],
								},
								total: {
									$apply: (x: number) => x + 1,
								},
							}
						),
					},
				});
			}
		},
	});

	const handleSubmit = async (textAreaValue: string, image: any) => {
		if (textAreaValue === '' && !image) return;

		if (image) {
			image = await resizeFile(image?.currentFile);
		}

		addProgramSessionComment({
			variables: {
				sessionId: idSession,
				content: textAreaValue,
				image: image,
			},
		});
	};

	const handleFocus = () => {
		setAnswerInputId()
	}

	return (
		<div className="commentInput">
			<MessageInput handleSubmit={handleSubmit} type={MESSSAGE_INPUT_TYPE.COMMENT} onFocus={handleFocus} />
		</div>
	);
};

export default CommentInput;
