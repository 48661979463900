import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CHRONO_CONGIG_INPUT_SHOW, CHRONO_UNITS } from '../../../../../config/type';
import { arrToSec, secToArr } from '../../../../../utils/chrono';
import { ChevronDown, ChevronUp } from '../../../../main/Icons';
import './ModalInputSet.scss';

const ModalInputSet = ({name, type, valueInit, setValueInit, setVisible, min, max}: any) => {

	const [inputMin, setInputMin] = useState<any>('00');
	const [inputSec, setInputSec] = useState<any>('00');
	const [inputNumber, setInputNumber] = useState<any>('00');

	const [valueInitState, setValueInitState] = useState<any>(valueInit);


	useEffect(() => {

		if (type === CHRONO_CONGIG_INPUT_SHOW.TIME) {
			const valueArrText = secToArr(valueInit);
			setInputMin(valueArrText[0]);
			setInputSec(valueArrText[1]);
		} else if(type === CHRONO_CONGIG_INPUT_SHOW.NUMBER){
			if(valueInit < 10){
				setInputNumber('0' + valueInit)
			} else {
				setInputNumber(valueInit.toString())
			}
			
		}
	}, [valueInit, type]);

	const handleClickSubmit = () => {
		if (type === CHRONO_CONGIG_INPUT_SHOW.TIME) {
			setValueInit(arrToSec([inputMin, inputSec]));
		} else if (type === CHRONO_CONGIG_INPUT_SHOW.NUMBER){
			setValueInit(parseInt(inputNumber))
		}

		setVisible(false);
	};

	return (
		<div 
			className="chronoConfigModalInputSet"
			onKeyDown={(e) => {if (e.key === 'Enter') {handleClickSubmit()}}}
		>
			<h1 className="titleXL weightBold">{name}</h1>

			<form className="selector noselect">
				{type === CHRONO_CONGIG_INPUT_SHOW.TIME ? (
					<div className="timeForm">
						<InputSet setters={setInputMin} getters={inputMin} type={CHRONO_UNITS.MIN} min={min} max={max} />
						<div className="separator">:</div>
						<InputSet setters={setInputSec} getters={inputSec} type={CHRONO_UNITS.SEC} min={min} max={max}  />
					</div>
				) : type === CHRONO_CONGIG_INPUT_SHOW.NUMBER ? (
					<div className="roundForm">
						<InputSet setters={setInputNumber} getters={inputNumber} type={CHRONO_UNITS.ROUND} min={min} max={max}  />
					</div>
				) : null}
			</form>

			<div className="actions">
				<button className="titleL weightBold" onClick={handleClickSubmit}>
					Ok
				</button>
			</div>
		</div>
	);
};




const InputSet = ({ setters, getters, type, min, max }: any) => {
	const {t} = useTranslation()
	const increment = type === CHRONO_UNITS.MIN ? 1 : type === CHRONO_UNITS.SEC ? 5 : 1;

	const handleChange = (e: any) => {
		let value = e.target.value;
		let valueInt = value ? parseInt(value) : 0;

		//MIN SEC VERIFICATION
		if (type === CHRONO_UNITS.MIN || type === CHRONO_UNITS.SEC) {
			if (valueInt > 59) {
				setters('59');
			} else {
				setters(value);
			}
		} else if(type === CHRONO_UNITS.ROUND){

			if (valueInt > 99) {
				setters('99');
			} else {
				setters(value);
			}

			if(min){
				
				if(valueInt < min){
					setters(min.toString())
				}
			}
		}
	};

	const handleBlur = (e: any) => {
		if (getters) {
			setters(formatValue(getters));
		}
	};

	const handleClick = (direction: boolean) => {
		let intValue = parseInt(getters);
		//Add
		if (direction) {
			intValue += increment;
		}
		//Substract
		else {
			let minTemp = min ? min : 0
			if(intValue - increment >= minTemp) intValue -= increment;
		
		}

		setters(formatValue(intValue.toString()));
	};

	const formatValue = (value: any) => {
		let valueInt = parseInt(value);
		let maxValue = type === CHRONO_UNITS.ROUND ? 99 : 59

		if (valueInt > maxValue) {
			return maxValue.toString();
		} else if (valueInt < 0) {
			return '00';
		}

		if (value.length === 1) {
			return '0' + value;
		} else if (value.length === 0) {
			return '00';
		} else {
			return value.toString();
		}
	};

	return (
		<div className="inputSet">
			<div className="add" onClick={() => handleClick(true)}>
				<ChevronUp />
			</div>
			<input
				type="text"
				onChange={handleChange}
				value={getters}
				pattern="[0-9]*"
				onBlur={handleBlur}
				onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
			/>
			<p className="units textMedium">
				{type === CHRONO_UNITS.MIN ? 'min' : type === CHRONO_UNITS.SEC ? 'sec' : t('chrono:rounds')}
			</p>
			<div className="add" onClick={() => handleClick(false)}>
				<ChevronDown />
			</div>
		</div>
	);
};

export default ModalInputSet;
