import './CalendarModalSessionMove.scss';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CalendarModalSessionMove = ({
	visible,
	setVisible,
	action,
	sessionDragging,
	sessionMoveTargetDate,
}: any) => {
	const { t } = useTranslation();

	return (
		<Modal visible={visible} setVisible={setVisible}>
			<div className="moveSessionModal">
				<div className="textValidation titleXL weightMedium">
					{t('calendar:moveSessionValidation:text1') + ' '}
					<strong>{moment(sessionDragging?.plannedAt).format('L')}</strong>
					{' ' + t('calendar:moveSessionValidation:text2') + ' '}
					<strong>{moment(sessionMoveTargetDate).format('L')}</strong>
					{' ' + t('calendar:moveSessionValidation:text3') + ' '}
					<strong>{sessionDragging?.name}</strong>
					{' ' + t('calendar:moveSessionValidation:text4') + ' '}
					<strong>{sessionDragging?.program.name}</strong>
					{' ' + t('calendar:moveSessionValidation:text5')}
				</div>

				<div className="actions">
					<Button
						buttonClass="btnGreyContrast textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							action(false);
						}}
					/>

					<Button
						buttonClass="btnGreen textNormal weightMedium"
						buttonText={t('commons:validate')}
						onClick={() => {
							action(true);
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default CalendarModalSessionMove;
