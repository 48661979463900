import { WODTYPE } from '../../../../config/type';
import { useShowPublicWodQuery } from '../../../../generated/gql';
import SelectedWodTemplate from './SelectedWodTemplate';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const SelectedWodPublic = ({ wodId }: any) => {
	const { data, error, refetch, loading }: any = useShowPublicWodQuery({
		variables: {
			wodId,
		},
	});
	useErrorModal({ error });

	return (
		<SelectedWodTemplate
			createdAt={data?.showPublicWod?.createdAt}
			name={data?.showPublicWod?.exercise?.nameTranslations[0]?.name}
			exercises={data?.showPublicWod?.exercise?.blockTranslations[1]?.blocks}
			scoreExpected={data?.showPublicWod?.exercise?.expectedScores}
			scores={data?.showPublicWod?.latestUserScores?.result}
			type={WODTYPE.PUBLIC}
			wodId={wodId}
			publicWod={true}
		/>
	);
};

export default SelectedWodPublic;
