import { ApolloClient } from '@apollo/client';
import { PrismicLink } from 'apollo-link-prismic';
import { apiEndpoint } from './prismic-configuration';
import fragmentTypes from './fragmentTypes.json';

import {
	InMemoryCache,
	IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';

const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData: fragmentTypes,
});

export const prismicApolloCache = new InMemoryCache({ fragmentMatcher });

export default new ApolloClient({
	link: PrismicLink({ uri: apiEndpoint }),
	cache: prismicApolloCache,
});
