import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../common/Button';
import { X } from '../../main/Icons';
import Modal from '../../common/Modal';
import TextInput from '../../common/Form/TextInput';

type Inputs = {
	keyword: string;
};

export const Keywords = ({ keywords, setKeywords }: any) => {
	const [modalView, setModalView] = useState(false);
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		keyword: Yup.string(),
	});

	const {
		register,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmitClick = () => {
		const newKeywords = [...keywords];
		newKeywords.push(getValues().keyword);
		setKeywords(newKeywords);
		setValue('keyword', '');
		setModalView(false);
	};

	const handleClickClose = () => {
		setValue('keyword', '');
		setModalView(false);
	};

	const handleClickDelete = (index: any) => {
		const newKeywords = [...keywords];
		newKeywords.splice(index, 1);
		setKeywords(newKeywords);
	};

	return (
		<div className="keywords">
			<div className="actions">
				<h2 className="textMedium weightMedium">{t('programs:programAdd:keywords')}</h2>
				<Button
					buttonText={t('commons:add')}
					buttonClass="textNormal weightMedium btnGreyContrast"
					onClick={(e: any) => {
						e.preventDefault();
						setModalView(true);
					}}
				/>
			</div>

			<div className="list">
				{keywords.map((keyword: any, index: number) => (
					<div className="keyword textSmall weightMedium" key={index}>
						{keyword}
						<button
							onClick={() => {
								handleClickDelete(index);
							}}
						>
							<X />
						</button>
					</div>
				))}
			</div>

			{keywords.length <= 0 && (
				<div className="emptyKeywords textMedium weightMedium">{t('programs:programAdd:emptyKeywords')}</div>
			)}

			<Modal visible={modalView} setVisible={setModalView}>
				<div className="modalKeyword">
					<h1 className="titleXL weightMedium">{t('programs:programAdd:addKeyword')}</h1>
					<TextInput
						errors={errors}
						register={register}
						name="keyword"
						placeholder={t('programs:programAdd:keyword')}
					/>
					<div className="actions textNormal weightMedium">
						<button onClick={() => handleClickClose()}>{t('commons:cancel')}</button>
						<button onClick={() => onSubmitClick()}>{t('commons:add')}</button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
