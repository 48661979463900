import gql from 'graphql-tag';

export const GRANT_PUBNUB_ACCESS = gql`
	query grantPubNubAccess {
		grantPubNubAccess {
			granted
			programChannels
		}
	}
`;
