import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { NotFound } from '../pages/NotFound';
import UnauthenticatedRoute from './route/UnauthenticatedRoute';
import AuthenticatedRoute from './route/AuthenticatedRoute';
import { RegisterPage } from '../pages/unauthenticated/RegisterPage';
import { LoginPage } from '../pages/unauthenticated/LoginPage';
import { HomePage } from '../pages/authenticated/HomePage';
import { ForgetPasswordPage } from '../pages/unauthenticated/ForgetPasswordPage';
import { CalendarPage } from '../pages/authenticated/CalendarPage';
import CalendarSession from '../pages/authenticated/calendar/CalendarSession';
import { ChatPage } from '../pages/authenticated/ChatPage';
import { TimerPage } from '../pages/authenticated/TimerPage';
import { WodBookPage } from '../pages/authenticated/WodBookPage';
import WodBookAdd from '../pages/authenticated/wodbook/WodBookAdd';
import WodBookEdit from '../pages/authenticated/wodbook/WodBookEdit';
import { ProfilePage } from '../pages/authenticated/ProfilePage';
import { SettingsPage } from '../pages/authenticated/SettingsPage';
import { ProgramPage } from '../pages/authenticated/ProgramPage';
import { ProgramHomePage } from '../pages/authenticated/program/ProgramHomePage';
import { ProgramMemberPage } from '../pages/authenticated/program/ProgramMemberPage';
import { ProgramCalendarPage } from '../pages/authenticated/program/ProgramCalendarPage';
import { ProgramSettingsPage } from '../pages/authenticated/program/ProgramSettingsPage';
import SessionExerciseEdit from '../pages/authenticated/session/SessionExerciseEdit';
import { useTranslation } from 'react-i18next';
import ProgramAdd from '../pages/authenticated/program/ProgramAdd';
import useGaTracker from '../hooks/useGaTracker';
import { CguPage } from '../pages/unauthenticated/CguPage';

export const Router = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
	useGaTracker();
	const { t } = useTranslation();
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Switch>
			{/* List all public routes here */}
			<UnauthenticatedRoute exact path="/login" layout="login" component={LoginPage} appProps={{ isAuthenticated }} />
			<UnauthenticatedRoute
				exact
				path="/register"
				layout="login"
				component={RegisterPage}
				appProps={{ isAuthenticated }}
			/>
			<UnauthenticatedRoute
				exact
				path="/forget-password"
				layout="login"
				component={ForgetPasswordPage}
				appProps={{ isAuthenticated }}
			/>
			<UnauthenticatedRoute exact path="/cgu" layout="login" component={CguPage} appProps={{ isAuthenticated }} />
			{/* List all private/auth routes here */}
			<AuthenticatedRoute exact path="/cgu" layout="login" component={CguPage} appProps={{ isAuthenticated }} />
			<AuthenticatedRoute
				exact
				path="/"
				name="home"
				appProps={{ isAuthenticated }}
				component={HomePage}
				breadcrumb={[{ name: t('commons:home'), path: '/' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/calendar"
				appProps={{ isAuthenticated }}
				component={CalendarPage}
				breadcrumb={[{ name: t('commons:calendar'), path: '/calendar' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/chat"
				appProps={{ isAuthenticated }}
				component={ChatPage}
				breadcrumb={[{ name: t('commons:chat'), path: '/chat' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/chat/:slug"
				appProps={{ isAuthenticated }}
				component={ChatPage}
				breadcrumb={[{ name: t('commons:chat'), path: '/chat' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/timer"
				appProps={{ isAuthenticated }}
				component={TimerPage}
				breadcrumb={[{ name: t('commons:timer'), path: '/timer' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/timer/start"
				appProps={{ isAuthenticated }}
				component={TimerPage}
				breadcrumb={[{ name: t('commons:timer'), path: '/timer' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/wodbook"
				appProps={{ isAuthenticated }}
				component={WodBookPage}
				breadcrumb={[{ name: t('commons:wodbook'), path: '/wodbook' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/wodbook/:slug"
				appProps={{ isAuthenticated }}
				component={WodBookPage}
				breadcrumb={[{ name: t('commons:wodbook'), path: '/wodbook' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/wodbook/create/:id"
				appProps={{ isAuthenticated }}
				component={WodBookAdd}
				breadcrumb={[{ name: t('commons:wodbook'), path: '/wodbook' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/wodbook/edit/:id"
				appProps={{ isAuthenticated }}
				component={WodBookEdit}
				breadcrumb={[{ name: t('commons:wodbook'), path: '/wodbook' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/program"
				appProps={{ isAuthenticated }}
				component={ProgramPage}
				breadcrumb={[{ name: t('commons:program'), path: '/program' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/add"
				appProps={{ isAuthenticated }}
				component={ProgramAdd}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program' },
					{ name: t('commons:create'), path: '/add' },
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/session/:idSession"
				appProps={{ isAuthenticated }}
				component={CalendarSession}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program/:slug' },
					{
						name: t('commons:calendar'),
						path: '/program/:slug/calendar',
					},
					{
						name: t('commons:session'),
						path: '/program/:slug/session/:idSession',
					},
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/session/:idSession/edit/:idExercise"
				appProps={{ isAuthenticated }}
				component={SessionExerciseEdit}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program/:slug' },
					{
						name: t('commons:calendar'),
						path: '/program/:slug/calendar',
					},
					{
						name: t('commons:session'),
						path: '/program/:slug/session/:idSession',
					},
					{
						name: t('commons:exercice'),
						path: '/program/:slug/session/:idSession/edit/:idExercise',
					},
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug"
				appProps={{ isAuthenticated }}
				component={ProgramHomePage}
				breadcrumb={[{ name: t('commons:program'), path: '/program/:slug' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/member"
				appProps={{ isAuthenticated }}
				component={ProgramMemberPage}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program/:slug' },
					{ name: t('commons:member'), path: '/program/:slug/member' },
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/calendar"
				appProps={{ isAuthenticated }}
				component={ProgramCalendarPage}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program/:slug' },
					{ name: t('commons:calendar'), path: '/program/:slug/calendar' },
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/settings"
				appProps={{ isAuthenticated }}
				component={ProgramSettingsPage}
				breadcrumb={[
					{ name: t('commons:program'), path: '/program/:slug/' },
					{ name: t('commons:settings'), path: '/program/:slug/settings' },
				]}
			/>
			<AuthenticatedRoute
				exact
				path="/program/:slug/:status"
				appProps={{ isAuthenticated }}
				component={ProgramHomePage}
				breadcrumb={[{ name: t('commons:program'), path: '/program/:slug' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/profile"
				appProps={{ isAuthenticated }}
				component={ProfilePage}
				breadcrumb={[{ name: t('commons:profil'), path: '/profile' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/profile/:slug"
				appProps={{ isAuthenticated }}
				component={ProfilePage}
				breadcrumb={[{ name: t('commons:profil'), path: '/profile/:slug' }]}
			/>
			<AuthenticatedRoute
				exact
				path="/settings"
				appProps={{ isAuthenticated }}
				component={SettingsPage}
				breadcrumb={[{ name: t('commons:settings'), path: '/settings' }]}
			/>
			{/* List a generic 404-Not Found route here */}
			<Route path="*">
				<NotFound />
			</Route>
		</Switch>
	);
};
