import './ModalSessionAdd.scss';
import React, { useContext } from 'react';
import Modal from '../../../common/Modal';
import TextInput from '../../../common/Form/TextInput';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import Button from '../../../common/Button';
import { CalendarContext } from '../contexts/CalendarContext';
import { useCreateProgramSessionMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { useHistory } from 'react-router-dom';
import { timeToString } from '../../../../utils/helpers';

type Inputs = {
	sessionName: string;
	date: string;
};

const ModalSessionAdd = ({ visible, setVisible, programId }: any) => {
	const { t } = useTranslation();
	const { dateSelected } = useContext(CalendarContext);
	const history = useHistory();

	const [createProgramSession, { error }] = useCreateProgramSessionMutation();
	useErrorModal({ error });

	const validationSchema = Yup.object().shape({
		sessionName: Yup.string().trim().required(t('formik:requiredField')),
		date: Yup.string().required(t('formik:requiredField')),
	});
	console.log("moment(dateSelected).format('L')", moment(dateSelected));
	const {
		register,
		handleSubmit,
		setError,
		control,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			date: timeToString(moment(dateSelected).add(6, 'hours').format()),
		},
	});

	const onSubmit = (values: any, e: any) => {
		const val = validationSchema.cast(values);

		createProgramSession({
			variables: {
				programId: programId,
				name: val?.sessionName,
				plannedAt: val?.date,
			},
		})
			.then((response) => {
				history.push('/program/' + programId + '/session/' + response?.data?.createProgramSession?.id);
			})
			.catch((e) => console.log(e));
	};

	return (
		<Modal visible={visible} setVisible={setVisible}>
			<div className="modalSessionAdd">
				<h1 className="titleXL weightMedium">{t('calendar:addSeance')}</h1>

				<form onSubmit={handleSubmit(onSubmit)}>
					<TextInput required errors={errors} register={register} name="sessionName" label={t('calendar:seanceName')} />
					<TextInput required errors={errors} register={register} type="date" name="date" label={t('calendar:date')} />
				</form>

				<div className="actions noselect">
					<Button
						buttonText={t('commons:cancel')}
						buttonClass="btnGreyNoBack textNormal weightMedium"
						onClick={() => {
							setVisible(false);
						}}
					/>

					<Button
						buttonText={t('commons:add')}
						buttonClass="btnGreenNoBack textNormal weightMedium"
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSessionAdd;
