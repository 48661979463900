import PubNub from 'pubnub';
import { Auth } from 'aws-amplify';
import ApolloClient from '../apollo/ApolloClient';
import { GRANT_PUBNUB_ACCESS } from '../apollo/user/userQueries';

export const PubNubClient = new PubNub({
	subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
	publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
	autoNetworkDetection: true, // enable for non-browser environment automatic reconnection
	restore: true, // enable catchup on missed messages
	ssl: true,
});

export const setUserPubNubData = (uuid) => {
	Auth.currentAuthenticatedUser()
		.then((user) => {
			const token = user.getSignInUserSession().getAccessToken().getJwtToken();
			ApolloClient.query({
				query: GRANT_PUBNUB_ACCESS,
				fetchPolicy: 'network-only',
			})
				.then((result) => {
					/*	console.log('GRANT_PUBNUB_ACCESS: ', {
						result,
						uuid,
					});*/
					PubNubClient.setUUID(uuid);
					PubNubClient.setAuthKey(token);
					//subscribe to self notification channel
					pubNubSubInitscribedChannel(uuid, result?.data?.grantPubNubAccess?.programChannels);
				})
				.catch((e) => {
					console.error('error', e);
				});
		})
		.catch((e) => {
			console.error('error', e);
		});
};

const pubNubSubInitscribedChannel = (uuid) => {
	PubNubClient.setFilterExpression("uuid != '" + PubNubClient.getUUID() + "'");

	PubNubClient.subscribe({
		channels: [uuid + '.notifications', 'chat.inbox.' + uuid, 'notifications', uuid + '.control'],
		channelGroups: [`${uuid}.program.chats.cg`],
		withPresence: false,
	});
};
