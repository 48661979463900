import './ProgramSettingsPage.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import ProgramNavbar from '../../../components/pages/programs/programs/ProgramNavbar';
import { PROG_NAVBAR } from '../../../config/type';
import Button from '../../../components/common/Button';
import { Check, HelpCircle } from '../../../components/main/Icons';
import Modal from '../../../components/common/Modal';
import { MeDocument, useDeleteProgramMutation, useShowProgramQuery } from '../../../generated/gql';
import ProgramEditInfos from '../../../components/pages/programs/ProgramEdit/ProgramEditInfos';
import useIsCoach from '../../../hooks/user/useIsCoach';
import Loader from '../../../components/main/Loader';
import update from 'immutability-helper';

export const ProgramSettingsPage = () => {
	const { t } = useTranslation();
	const { slug } = useParams<any>();
	const isCoach = useIsCoach(slug);

	const [deleteModal, setDeleteModal] = useState(false);
	/*	const [confidentiality, setConfidentiality] = useState(false);*/

	const { data, error, loading, refetch } = useShowProgramQuery({
		variables: {
			id: slug,
		},
	});

	if (loading) return <Loader type="Rings" color="#319795" height={80} width={80} />;

	if (!isCoach) {
		return (
			<>
				<p>vous n'etes pas le coach</p>
			</>
		);
	}

	return (
		<div id="programSettingsPage">
			<ProgramNavbar slug={slug} focus={PROG_NAVBAR.SETTINGS} name={'member'} userRole={'coach'} />

			<div className="programSettingsPageWrapper">
				<h1 className="title titleXL weightBold">{t('programs:programAdd:editProgram')}</h1>
				<ProgramEditInfos progConfig={data?.showProgram} />
			</div>

			{/*<div className="confidentiality">
				<div className="left">
					<h1 className="textMedium weightMedium">
						{t('programs:programAdd:confidentiality')}
					</h1>
					<HelpCircle />
				</div>
				<div className="right">
					<SwitchButton value={confidentiality} setValue={setConfidentiality} />
				</div>
			</div>*/}

			<div className="actions">
				{data?.showProgram?.price === 0 && (
					<div className="right">
						<button
							className="textNormal weightMedium"
							onClick={() => {
								setDeleteModal(true);
							}}
						>
							{t('programs:programAdd:deleteProgram')}
						</button>

						{deleteModal && (
							<Modal visible={deleteModal} setVisible={setDeleteModal}>
								<div className="deleteModal">
									<h1 className="titleL weightMedium">{t('programs:deleteProgram')}</h1>

									<p className="textSmall weightMedium">
										{t('programs:programAdd:deleteProgramConfirmation') + ' '}
										<strong>{data?.showProgram?.name + ' '}</strong> ?
									</p>

									<div className="actions textNormal weightMedium">
										<button
											onClick={() => {
												setDeleteModal(false);
											}}
										>
											{t('commons:cancel')}
										</button>
										<DeleteProgramButton setDeleteModal={setDeleteModal} programId={data?.showProgram?.id} />
									</div>
								</div>
							</Modal>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const DeleteProgramButton = ({ setDeleteModal, programId }: any) => {
	const { t } = useTranslation();
	const history = useHistory();

	const [deleteProgram, { data, error, loading }]: any = useDeleteProgramMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: MeDocument,
			});

			if (!data) {
				console.error('QUERY ME NO DATA');
				return;
			}

			if (queryData?.me) {
				const index = queryData?.me?.ownedPrograms.findIndex((u: any) => u?.id === data?.deleteProgram);

				if (index === -1) return;

				cache.writeQuery({
					query: MeDocument,
					data: {
						me: update(queryData?.me, {
							ownedPrograms: {
								$splice: [[index, 1]],
							},
						}),
					},
				});
			}
		},
	});

	const onClick = () => {
		deleteProgram({
			variables: {
				programId,
			},
		}).then(() => {
			setDeleteModal(false);
			history.replace('/program');
		});
	};

	return <button onClick={onClick}>{t('commons:delete')}</button>;
};
