import './CommentReplyInput.scss';
import React, { useEffect } from 'react';
import MessageInput from '../../../../common/MessageInput/MessageInput';
import {
	ListProgramSessionCommentsDocument,
	useAddProgramSessionCommentReplyMutation,
} from '../../../../../generated/gql';
import { resizeFile } from '../../../../../utils/helpers';
import { MESSSAGE_INPUT_TYPE } from '../../../../../config/type';

const CommentReplyInput = ({ commentId, idSession }: any) => {
	const [
		addProgramSessionCommentReply,
		{ error, loading, data },
	]: any = useAddProgramSessionCommentReplyMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListProgramSessionCommentsDocument,
				variables: {
					sessionId: idSession,
				},
			});

			if (queryData?.listProgramSessionComments) {
				let newData = JSON.parse(
					JSON.stringify(queryData?.listProgramSessionComments)
				);

				console.log('newData', newData);
				// @ts-ignore
				if (newData?.result.length > 0) {
					const commentToUUpdateIndex = newData?.result.findIndex(
						(e: any) => e?.id === commentId
					);

					if (commentToUUpdateIndex > -1) {
						console.log('commentToUUpdateIndex', commentToUUpdateIndex);
						// @ts-ignore
						newData.result[commentToUUpdateIndex] = {
							...newData.result[commentToUUpdateIndex],
							replies: {
								...newData.result[commentToUUpdateIndex].replies,
								total: newData.result[commentToUUpdateIndex].replies.total + 1,
							},
						};
					}
				}

				cache.writeQuery({
					query: ListProgramSessionCommentsDocument,
					variables: {
						sessionId: idSession,
					},
					data: {
						listProgramSessionComments: newData,
					},
				});
			}
		},
	});

	const handleSubmit = async (textAreaValue: string, image: any) => {
		if (textAreaValue === '' && !image) return;

		if (image) {
			image = await resizeFile(image?.currentFile);
		}

		addProgramSessionCommentReply({
			variables: {
				commentId: commentId,
				content: textAreaValue,
				image: image,
			},
		}).then((r: any) => console.log('r', r));
	};



	console.log('replu');
	

	return (
		<div className="commentInput">
			<MessageInput handleSubmit={handleSubmit} type={MESSSAGE_INPUT_TYPE.COMMENT} />
		</div>
	);
};

export default CommentReplyInput;
