import React, { useState, useContext, useEffect } from 'react';
import { CalendarContext } from './CalendarContext';
import moment from 'moment';

export const CalendarDatesContext = React.createContext({} as any);

const CalendarDatesContextProvider = ({ children, sessions }: any) => {
	const { dateSelected } = useContext(CalendarContext);
	const [datesArr, setDatesArr] = useState<any>([]);
	const [datesArrSessions, setDatesArrSessions] = useState<any>([]);
	const [programList, setProgramList] = useState<any>([]);

	//Generate month dates
	useEffect(() => {
		const calendar: any[] = [];

		const startDay = dateSelected.clone().startOf('month').startOf('week');
		const endDay = dateSelected.clone().endOf('month').endOf('week');
		let date = startDay.clone().subtract(1, 'day');

		while (date.isBefore(endDay, 'day')) {
			calendar.push({
				days: Array(7)
					.fill(0)
					.map(() => ({
						dateMoment: date.add(1, 'day').clone(),
						state: {
							selected:
								moment(date).format('DD/MM/YYYY') ===
								moment(dateSelected).format('DD/MM/YYYY'),
							active: date.clone().month() === dateSelected.month(), //Detect if date is in the month selected
						},
						sessions: [],
					})),
			});
		}

		setDatesArr(calendar);
		fillCalendarWithSession();
	}, [dateSelected, sessions]);

	const fillCalendarWithSession = () => {
		if (sessions) {
			const programArr: any = [];
			const programArrState: any = [];

			sessions.listProgramSessions.result.forEach((session: any) => {
				let sessionIndex = programArr.indexOf(session.program);
				if (sessionIndex < 0) {
					programArr.push(session.program);
				}
			});

			programArr.forEach((program: any) => {
				const programUpdate = { ...program };
				programUpdate.visible = true;
				programArrState.push(programUpdate);
			});

			setProgramList(programArrState);
		}
	};

	//Generate Program List
	/*useEffect(() => {
		if (sessions) {
			const programArr: any = [];
			const programArrState: any = [];

			sessions.listProgramSessions.result.forEach((session: any) => {
				let sessionIndex = programArr.indexOf(session.program);
				if (sessionIndex < 0) {
					programArr.push(session.program);
				}
			});

			/!*sessions.listProgramSessions.result.forEach((session: any) => {
				let sessionIndex = programArr.indexOf(session.program);
				if (sessionIndex < 0) {
					programArr.push(session.program);
				}
			});*!/

			/!* sessions?.listProgramSessions?.result.forEach((e: any) => {
                if (programArr.findIndex((i: any) => i?.id === e?.id) === -1) {
                    programArr.push(e);
                }
            });*!/

			programArr.forEach((program: any) => {
				const programUpdate = { ...program };
				programUpdate.visible = true;
				programArrState.push(programUpdate);
			});

			setProgramList(programArrState);
		}
	}, [sessions]);*/

	//Assign sessions to dates
	useEffect(() => {
		if (sessions?.listProgramSessions) {
			let newState = [...datesArr];

			sessions?.listProgramSessions?.result.forEach(
				(session: any, index0: number) => {
					datesArr.forEach((weekDates: any, index1: number) => {
						weekDates.days.forEach((dateUnique: any, index2: number) => {
							if (
								moment(session.plannedAt).format('DD/MM/YYYY') ===
								moment(dateUnique.dateMoment).format('DD/MM/YYYY')
							) {
								//Date for the session find !
								newState[index1].days[index2].sessions.push(
									sessions?.listProgramSessions?.result[index0]
								);
							}
						});
					});
				}
			);

			setDatesArrSessions(newState);
		}
	}, [datesArr]);

	return (
		<CalendarDatesContext.Provider
			value={{
				datesArrSessions,
				setDatesArrSessions,
				programList,
				setProgramList,
			}}
		>
			{children}
		</CalendarDatesContext.Provider>
	);
};

export default CalendarDatesContextProvider;
