import './About.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import hustleUp from '../../../../assets/img/hustle_up/hustle_green.png';
import Button from '../../../common/Button';

const About = () => {
	const { t } = useTranslation();

	return (
		<div className="settingsAbout pageContainerSettings">
			<h1 className="titleXXL weightBold">{t('settings:menu:about')}</h1>
			<div className="settingsAboutWrapper settingsWrappers">
				<img src={hustleUp} alt="Hustle Up Logo" />
				<p className="textMedium weightMedium">{t('settings:about:aboutUsText1')}</p>
				<p className="textMedium weightMedium">
					{t('settings:about:aboutUsText2-1')} <b>{t('settings:about:aboutUsText2-2')}</b>
					{t('settings:about:aboutUsText2-3')} <b>{t('settings:about:aboutUsText2-4')}</b>
					{t('settings:about:aboutUsText2-5')}
				</p>
				<p className="textMedium weightMedium">
					{t('settings:about:aboutUsText3-1')}
					<b>{t('settings:about:aboutUsText3-2')}</b>
				</p>
				<p className="textMedium weightMedium">
					{t('settings:about:aboutUsText4-1')}
					<b>{t('settings:about:aboutUsText4-2')}</b>
				</p>
				<p className="textMedium weightMedium">{t('settings:about:aboutUsText5-1')}</p>
				<a
					className="textMedium weightBold"
					href="https://www.instagram.com/hustleup.app/"
					target="_blank"
					rel="noopener noreferrer"
				>
					{t('settings:about:aboutUsText5-2')}
				</a>
			</div>
		</div>
	);
};

export default About;
