import './ProgramNavbar.scss';
import { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import useIsCoach from '../../../../hooks/user/useIsCoach';
import { LogOut, Warning } from '../../../main/Icons';
import ProgramNavbarMenu from './ProgramNavbarMenu';
import Modal from '../../../common/Modal';
import { useLeaveProgramMutation, useShowProgramQuery } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { PROG_NAVBAR } from '../../../../config/type';
import moment from 'moment';

const ProgramNavbar = ({ slug, focus, name, userRole }: any) => {
	const { t } = useTranslation();
	const isCoach = useIsCoach(slug);
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	const [modalExitView, setModalExitView] = useState(false);

	const { data, error, refetch } = useShowProgramQuery({
		fetchPolicy: 'cache-first',
		variables: {
			id: slug,
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	// use focus to put underline
	// focus === PROG_NAVBAR.HOME

	const handleClickModalExit = () => {
		setModalExitView(true);
	};

	return (
		<div className="programHomePageNavbar">
			<div className="left">
				<Link to={'/program/' + slug}>
					<h1
						className={`
							titleXL weightBold
							${focus === PROG_NAVBAR.HOME && 'titleActive'}
						`}
					>
						{data?.showProgram?.name}
					</h1>
				</Link>

				{isDesktop && (
					<div className="programNavbarMenuDesktop textMedium weightSemiBold">
						{userRole !== 'guest' && (
							<>
								<Link
									to={'/program/' + slug + '/calendar'}
									className={`
										${focus === PROG_NAVBAR.CALENDAR && 'activeLink'}
									`}
								>
									{t('commons:planning')}
								</Link>
								<Link
									to={'/program/' + slug + '/member'}
									className={`
										${focus === PROG_NAVBAR.MEMBER && 'activeLink'}
									`}
								>
									{t('commons:members')}
								</Link>
							</>
						)}

						{userRole === 'coach' && (
							<Link
								to={'/program/' + slug + '/settings'}
								className={`
									${focus === PROG_NAVBAR.SETTINGS && 'activeLink'}
								`}
							>
								{t('commons:settings')}
							</Link>
						)}
					</div>
				)}
			</div>

			<div className="right">
				{data?.showProgram?.subscriptionStatus?.cancel_at_period_end && (
					<div className={'suscription'}>
						<p className={'textSmall weightMedium'}>{t('programs:dateEndSub')}</p>
						<div className={'suscription--btn'}>
							<p className={'textSmall weightMedium'}>
								{moment(data?.showProgram?.subscriptionStatus?.current_period_end).format('L')}
							</p>
							{/*<Warning />*/}
						</div>
					</div>
				)}
				{!isDesktop && userRole !== 'guest' && (
					<ProgramNavbarMenu
						userRole={userRole}
						slug={slug}
						handleClickModalExit={handleClickModalExit}
						progData={data?.showProgram}
					/>
				)}
				{isDesktop && userRole === 'member' && focus === PROG_NAVBAR.MEMBER && data?.showProgram?.price === 0 && (
					<div className="programNavbarMenuDesktop textMedium weightSemiBold">
						<button className="logoutLink" onClick={handleClickModalExit}>
							<LogOut />
							{t('commons:leaveProgram')}
						</button>
					</div>
				)}
			</div>
			<Modal visible={modalExitView} setVisible={setModalExitView}>
				<div className="exitProgramConfirmation">
					<h1 className="titleXL weightMedium">
						{t('programs:programExit:title')} <span className="weightBold">{data?.showProgram?.name + ' '}</span> ?
					</h1>
					<p className="textSmall">{t('programs:programExit:content')}</p>
					<div className="actions">
						<button
							className="textNormal weightMedium"
							onClick={() => {
								setModalExitView(false);
							}}
						>
							{t('commons:cancel')}
						</button>
						<ButtonLeaveProgram programId={data?.showProgram?.id} setModalExitView={setModalExitView} />
					</div>
				</div>
			</Modal>
		</div>
	);
};

const ButtonLeaveProgram = ({ programId, setModalExitView }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { slug } = useParams<any>();

	const [replyToProgramInvitation, { data, error, loading, refetch }]: any = useLeaveProgramMutation();
	useErrorModal({ error, onRetry: () => refetch() });

	const onClick = () => {
		setModalExitView(false);

		replyToProgramInvitation({
			variables: {
				programId: programId,
			},
		})
			.then((r: any) => {
				if (history?.location?.pathname === '/program/' + slug) window.location.reload();
				else history.replace('/program/' + slug);
			})
			.catch((e: any) => console.error(e));
	};

	return (
		<button className="textNormal weightMedium" onClick={onClick}>
			{t('commons:leave')}
		</button>
	);
};

export default ProgramNavbar;
