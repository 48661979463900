import { useContext, useEffect, useState } from 'react';
import { CHRONO_CONFIG_CONTEXT, CHRONO_SCREEN } from '../../../../config/type';
import { MainLayoutContext } from '../../../../layout/_contexts';
import { TimerContext } from '../contexts/TimerContext';
import ButtonStart from './ButtonStart';
import Form from './Form';
import Navbar from './Navbar';
import SaveButtons from './SaveButtons';
import './TimerConfig.scss';

const TimerConfig = () => {
	const [chronoConfigContext, setChronoConfigContext] = useState<CHRONO_CONFIG_CONTEXT>();

	const { breadcrumb } = useContext(MainLayoutContext);
	const { setScreen } = useContext(TimerContext);

	//Detect if configuration is for start or configure a timer
	useEffect(() => {
		if (breadcrumb[0]?.path === '/timer') setChronoConfigContext(CHRONO_CONFIG_CONTEXT.START);
		else setChronoConfigContext(CHRONO_CONFIG_CONTEXT.CONFIG);
	}, [breadcrumb]);

	const handleClickStartTimer = () => {
		setScreen(CHRONO_SCREEN.APP);
	};

	return (
		<div className="timerConfig">
			<Navbar />
			<Form />

			{/* <ButtonStart onClick={handleClickStartTimer} /> */}

			{chronoConfigContext === CHRONO_CONFIG_CONTEXT.START ? (
				<ButtonStart onClick={handleClickStartTimer} />
			) : (
				<SaveButtons />
			)}
		</div>
	);
};

export default TimerConfig;
