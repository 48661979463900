import { useTranslation } from 'react-i18next';
import { useListPublicWodsQuery } from '../../../../generated/gql';
import Loader from '../../../main/Loader';
import EmptyMessage from '../../../common/EmptyMessage';
import WodLine from './WodLine';

const BenchmarkWod = ({ categoryId }: any) => {
	const { t } = useTranslation();

	const { data, error, refetch, loading }: any = useListPublicWodsQuery({
		variables: {
			categoryId,
		},
	});

	if (loading) return <Loader size={80} />;

	return (
		<div className="wodLineList">
			{data?.listPublicWods?.result?.length > 0 ? (
				data?.listPublicWods?.result?.map((wod: any, key: any) => <WodLine key={key} wod={wod} wodCat="public" />)
			) : (
				<EmptyMessage message={t('wodbook:nowodList')} />
			)}
		</div>
	);
};

export default BenchmarkWod;
