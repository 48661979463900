import './MessageInput.scss';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../Dropdown';
import { Add, Image, Send, X } from '../../main/Icons';
import { useTranslation } from 'react-i18next';
import { MESSSAGE_INPUT_TYPE } from '../../../config/type';

interface MessageInputProps {
	handleSubmit: (textAreaValue: string, image: any) => void;
	type: any;
	onFocus?: any;
}

const MessageInput = ({ handleSubmit, type, onFocus }: MessageInputProps) => {
	const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
	const { t } = useTranslation();
	const textarea = useRef<any>();
	const [textAreaValue, setTextAreaValue] = useState<any>('');
	const [commentImg, setCommentImg] = useState<any>(null);
	const [messageJustsent, setMessageJustSent] = useState(false);

	const handleChange = (e: any) => {
		if (e.target.value !== '\n' && !messageJustsent) {
			setTextAreaValue(e?.target?.value);
			resizeTextarea(false);
		} else {
			setMessageJustSent(false);
		}
	};

	const handleKeyDown = (e: any) => {
		// Shortkey send message
		if ((e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey) {
			handleSubmitTemp();
			resizeTextarea(true);
		} else {
			// Resize height Textarea
			resizeTextarea(false);
		}
	};

	const handleSubmitTemp = () => {
		handleSubmit(textAreaValue.trim(), commentImg);
		setCommentImg(null);
		resizeTextarea(true);
		setTextAreaValue((o: any) => '');
		setMessageJustSent(true);
	};

	const resizeTextarea = (reset: boolean) => {
		if (textarea?.current) {
			textarea.current.style.height = 0 + 'px';
			textarea.current.style.height = reset ? 0 + 'px' : textarea.current.scrollHeight + 'px';
		}
	};

	const selectFile = (e: any) => {
		setCommentImg({
			currentFile: e.target.files[0],
			previewImage: URL.createObjectURL(e.target.files[0]),
		});
		setMenuDropdownVisible(false);
	};

	//Chat Scroll down when img preview is enable
	useEffect(() => {
		if (type === MESSSAGE_INPUT_TYPE.CHAT) {
			if (commentImg?.previewImage) {
				const discussionZoneWrapper = document.querySelector('.discussionZoneWrapper');
				if (discussionZoneWrapper) {
					discussionZoneWrapper.scrollTop = discussionZoneWrapper.scrollHeight;
				}
			}
		}
	}, [commentImg?.previewImage]);

	return (
		<div className="inputAreaChat">
			{commentImg?.previewImage && (
				<div className="imgPreviewRow">
					<div className="imagePreview">
						<button
							onClick={() => {
								setCommentImg(null);
							}}
						>
							<X />
						</button>

						<div className="imagePreviewWrapper">
							<img src={commentImg.previewImage} alt="" />
						</div>
					</div>
				</div>
			)}

			<div className="inputRow">
				<div className="addFiles">
					<button
						onClick={() => {
							setMenuDropdownVisible(true);
						}}
					>
						<Add />
					</button>

					<Dropdown
						visible={menuDropdownVisible}
						setVisible={setMenuDropdownVisible}
						direction="top"
						style={{
							top: '-50px',
							left: '-30%',
							// transform:'translateX(-50%)'
						}}
					>
						<div className="actions">
							<label htmlFor="imgUpload" className="imgUploadLabel">
								<Image />
								<h1 className="textNormal weightMedium">{t('calendar:addImage')}</h1>
								<input id="imgUpload" type="file" accept="image/!*" onChange={selectFile} />
							</label>
						</div>
					</Dropdown>
				</div>

				<div className="inputZone">
					<textarea
						placeholder={t('chat:yourMessage')}
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						className="scrollbarThin"
						value={textAreaValue}
						ref={textarea}
						onFocus={onFocus}
					/>
				</div>

				<div id="sendButton" className="sendMessage" onClick={handleSubmitTemp}>
					<Send />
				</div>
			</div>
		</div>
	);
};

export default MessageInput;
