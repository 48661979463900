import React from 'react'
import {use100vh} from 'react-div-100vh'

import './Login.scss'

const Login = ({ children }: { children: React.Component }) => {
	const height = use100vh()

	return (
		<div 
			id="loginLayout"
			style={{
				minHeight: height ? height : "100vh"
			}}
		>
			{ children }
		</div>
	)
}

export default Login
