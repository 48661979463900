import { useTranslation } from 'react-i18next'
import './ButtonStart.scss'

const ButtonStart = ({onClick} : any) => {
	const {t} = useTranslation()

	return (
		<button className="chronoConfigButtonStart titleXXL weightMedium" onClick={onClick}>
			{t("chrono:start")}
		</button>
	)
}

export default ButtonStart
