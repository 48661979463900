import { useCallback } from 'react';
import { HelpCircle, AtSign } from '../../main/Icons';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

// import TextInput from '../../material/TextInput';
import TextInput from '../../common/Form/TextInput';
import IconRound from '../../common/IconRound';
import Button from '../../common/Button'

import './Step1ResetPassword.scss'

type Inputs = {
	email: string;
};

export default function Step1ResetPassword({ setStep, setEmail }: any) {
	const { t } = useTranslation();
	const history = useHistory();

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.trim()
			.lowercase()
			.required(t('validation:requiredField'))
			.email(t('validation:emailWrong')),
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = useCallback(
		async (data: { email: string }) => {
			await Auth.forgotPassword(data?.email)
				.then((val) => {
					console.log('val', val);
					setEmail(data?.email);
					setStep(2);
				})
				.catch((e) => console.error(e));
		},
		[setEmail, setStep]
	);

	return (
		<div className="forgetPasswordStep1 loginLeftContainer">

			<div className="headerRoundIcon">
				<IconRound Icon={HelpCircle} />

				<h1 className="titleXXL weightBold">{t('auth:forgotPassword')}</h1>
				<p className="textMedium weightMedium">{t('auth:forgotPasswordText')}</p>
			</div>


			<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="email"
					placeholder={t('commons:email')}
					type="text"
					Icon={AtSign}
					noLabel={true}
				/>

				<div className="formValidationClassic">
					<Button 
						buttonText={t('commons:send')}
						buttonClass="btnGreen textMedium weightBold"
						onClick={() => {}}
					/>
				</div>

			</form>

			
			<button
				className="backButton textMedium weightMedium"
				onClick={() => history.goBack()}
			>
				{t('commons:back')}
			</button>
			
		</div>
	);
}
