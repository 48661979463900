import './InformationsItem.scss';

const InformationsItem = ({ title, Icon, data }: any) => {
	return (
		<div className="informationsItem">
			<div className="icon">
				<Icon />
			</div>

			<div className="texts">
				<h1 className="title textMedium weightMedium">{title}</h1>
				<h2 className="textNormal weightBold">{data}</h2>
			</div>
		</div>
	);
};

export default InformationsItem;
