import { useDispatch } from 'react-redux';
import { toggleSidebar } from '../../../store/actions';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams } from 'react-router-dom';

import NavbarSearch from './NavbarSearch';

import './Navbar.scss';

const Navbar = ({ breadcrumb }: any) => {
	const isMedium = useMediaQuery({ query: '(min-width: 600px)' });

	return (
		<header id="navbar">
			<div className="left">
				<SidebarShowButton />

				{isMedium && <Breadcrumb breadcrumb={breadcrumb} />}
			</div>

			<div className="right">
				<NavbarSearch />
			</div>
		</header>
	);
};

const SidebarShowButton = () => {
	const dispatch = useDispatch();
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			strokeWidth="2"
			viewBox="0 0 24 24"
			className="slidebarButton"
			onClick={() => {
				dispatch(toggleSidebar(true));
			}}
		>
			<path d="M3 12h18M3 6h18M3 18h18" />
		</svg>
	);
};

const Breadcrumb = ({ breadcrumb }: any) => {
	// @ts-ignore
	const { slug, idSession, idExercise } = useParams();
	return (
		<div className="breadcrumb textSmall weightMedium">
			{breadcrumb?.map((bread: any, key: any) => {
				let link = bread?.path.replace(':slug', slug);
				if (idSession) link = link?.replace(':idSession', idSession);
				if (idExercise) link = link?.replace(':idExercise', idExercise);

				return (
					<Link key={key} to={link}>
						{bread?.name} {key < breadcrumb.length - 1 && ' / '}
					</Link>
				);
			})}
		</div>
	);
};

export default Navbar;
