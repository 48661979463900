import Timer from '../../components/pages/timer/Timer';
import './TimerPage.scss';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const TimerPage = () => {
	const { state } = useLocation<any>();

	return (
		<div id="timerPage">
			{state && state[0] && state[0].chronoConfiguration ? (
				<Timer chronoConfiguration={state[0].chronoConfiguration} chronoConfigurationData={state[0]} />
			) : (
				<Timer />
			)}
		</div>
	);
};
