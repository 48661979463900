import { Check, Send, X } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import Button from '../../../common/Button';
import EmptyMessage from '../../../common/EmptyMessage';
import ProgCard from '../../../common/ProgCard';

import './ProgramsPending.scss';
import { useReplyToProgramInvitationMutation, useReplyToProgramJoinRequestMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';

const ProgramsPending = ({ dataRequests, dataInvitations, className, refetchUser }: any) => {
	const { t } = useTranslation();

	return (
		<WidgetBase className={className}>
			{/*<div className="programsApplied">
				<WidgetTitle
					title={t('programs:programsPending:appliedProgramsTitle')}
					onClick={() => {}}
				/>

				{dataRequests?.length > 0 ? (
					<div className="programsList">
						{dataRequests.map((program: any, index: number) => (
							<ProgramLine key={index} data={program} />
						))}
					</div>
				) : (
					<div className="noApplied noData textNormal">
						{t('programs:programsPending:noApplied')}

						<Button
							buttonText={t('programs:programsPending:applyAt')}
							buttonClass="btnGreyContrast btnReversed"
							ButtonIcon={Add}
							onClick={() => {}}
						/>
					</div>
				)}
			</div>*/}

			<div className="programsInvitation">
				<WidgetTitle title={t('programs:programsPending:invitationsReceived')} onClick={() => {}} />

				{dataInvitations?.length > 0 ? (
					<div className="programsList">
						{dataInvitations.map((invitation: any, index: number) => {
							return (
								<ProgCard
									key={index}
									data={invitation?.program}
									imgSize={42}
									sizeTitle="textMedium weightMedium"
									sizeInfos="textSmall weightMedium"
									rightDecoration={
										<div className="right">
											<ButtonReplyToInvitation invitation={invitation} refetchUser={refetchUser} validate />
											<ButtonReplyToInvitation invitation={invitation} refetchUser={refetchUser} />
										</div>
									}
								/>
							);
						})}
					</div>
				) : (
					<div className="noInvitation noData">
						<EmptyMessage message={t('programs:programsPending:noInvitations')} />
					</div>
				)}
			</div>
		</WidgetBase>
	);
};

const ButtonReplyToInvitation = ({ invitation, validate, refetchUser }: any) => {
	const [replyToProgramInvitation, { data, error, loading, refetch }]: any = useReplyToProgramInvitationMutation();
	useErrorModal({ error, onRetry: () => refetch() });
	console.log('refetchUser', refetchUser, typeof refetchUser);
	console.log('dataInvitations', invitation);

	const handleReply = (accept: boolean) => {
		replyToProgramInvitation({
			variables: {
				id: invitation?.id,
				accept,
			},
		})
			.then(() => refetchUser())
			.catch((e: any) => console.error(e));
	};

	return (
		<>
			{validate ? (
				<Button buttonClass="btnGreenLine btnCentered" ButtonIcon={Check} onClick={() => handleReply(true)} />
			) : (
				<Button buttonClass="btnRedLine btnCentered" ButtonIcon={X} onClick={() => handleReply(false)} />
			)}
		</>
	);
};

export default ProgramsPending;
