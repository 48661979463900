import { Search } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';

import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import Button from '../../../common/Button';
import ProgramLine from '../programs/ProgramLine';

import './ProgramsFollowed.scss';
import { useDispatch } from 'react-redux';
import { focusNavbarSearch, toggleNavbarSearch } from '../../../../store/actions';

const ProgramsFollowed = ({ className, data }: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<WidgetBase className={!data ? 'widgetBackgroundGrey ' + className : className}>
			{data?.length > 0 ? (
				<WidgetTitle
					title={t('profile:widgetTitle:programsFollowed')}
					buttonClass="btnBlue btnCentered btnLarge"
					ButtonIcon={Search}
					onClick={() => dispatch(focusNavbarSearch(true))}
				/>
			) : (
				<WidgetTitle title={t('commons:findProgram')} onClick={() => dispatch(focusNavbarSearch(true))} />
			)}

			{data?.length > 0 ? (
				<div className="programsList">
					{data.map((program: any, index: number) => (
						<ProgramLine key={index} data={program} />
					))}
				</div>
			) : (
				<div className="programsDataEmpty">
					<p className="textSmall weightMedium">{t('home:startOnHustleUpBody')}</p>
					<Button
						buttonText={t('home:findProgram')}
						buttonClass="btnBlueLight  textNormal weightMedium"
						onClick={() => dispatch(focusNavbarSearch(true))}
					/>
				</div>
			)}
		</WidgetBase>
	);
};

export default ProgramsFollowed;
