import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ScoreValueType,
	ShowProgramSessionDocument,
	useAddSessionExerciseScoresMutation,
} from '../../../../../generated/gql';
import Button from '../../../../common/Button';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import './ModalAddScore.scss';
import { useForm } from 'react-hook-form';
import TextInput from '../../../../common/Form/TextInput';
import { useErrorModal } from '../../../../../hooks/useErrorModal';

const ModalAddScore = ({ visible, setVisible, scoreExpected, exerciseId, sessionId, type, onScoreAdded }: any) => {
	const { t } = useTranslation();
	const [shouldSubmit, setShouldSubmit] = useState<any>(false);

	const [scoresExpectedFormat, setScoresExpectedFormat] = useState<any[]>();
	const [yupObjectDynamique, setYupObjectDynamique] = useState<any>({});
	const [addSessionExerciseScores, { error, loading }]: any = useAddSessionExerciseScoresMutation({
		update(cache, { data: dataQuery }) {
			//update ShowProgramSessionDocument
			const data = cache.readQuery({
				query: ShowProgramSessionDocument,
				variables: {
					id: sessionId,
				},
			});

			if (!data) return;
			// @ts-ignore
			const { showProgramSession, showProgramSessionCommentsTotal } = data;

			const updatedExercises = showProgramSession.exercises.map((e: any) => {
				if (e.id === exerciseId) {
					return {
						...e,
						userScores: dataQuery?.addSessionExerciseScores?.scores,
					};
				}
				return e;
			});

			cache.writeQuery({
				query: ShowProgramSessionDocument,
				variables: {
					id: sessionId,
				},
				data: {
					showProgramSessionCommentsTotal,
					showProgramSession: {
						...showProgramSession,
						exercises: updatedExercises,
					},
				},
			});
		},
	});
	useErrorModal({ error });

	useEffect(() => {
		const scoresExpectedFormatTemp: any = [];
		let yupObjectDynamique: any = {};
		let timeSkip = false;

		if (scoreExpected) {
			for (let i = 0; i < scoreExpected.length; i++) {
				let name: string | null = null;
				let yupValidationName: string | null = null;
				let yupValidationMethods: any = null;

				if (scoreExpected[i].type === ScoreValueType.Calories) {
					name = t('wodbook:addScoreCalories');
					yupValidationName = ScoreValueType.Calories;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Distance) {
					name = t('wodbook:addScoreDistance');
					yupValidationName = ScoreValueType.Distance;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Duration && !timeSkip) {
					name = t('wodbook:addScoreMinutes');
					yupValidationName = 'minutes';
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
					i--;
					timeSkip = true;
				} else if (scoreExpected[i].type === ScoreValueType.Duration) {
					name = t('wodbook:addScoreSeconds');
					yupValidationName = 'seconds';
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Load) {
					name = t('wodbook:addScoreWeight');
					yupValidationName = ScoreValueType.Load;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				} else if (scoreExpected[i].type === ScoreValueType.Repetitions) {
					name = t('wodbook:addScoreRepetitions');
					yupValidationName = ScoreValueType.Repetitions;
					yupValidationMethods = Yup.number().required(t('formik:requiredField'));
				}

				const scoreLineFormat = {
					name,
					yupValidationName,
					yupValidationMethods,
				};

				scoresExpectedFormatTemp.push(scoreLineFormat);

				if (yupValidationName && yupValidationMethods) {
					yupObjectDynamique = {
						...yupObjectDynamique,
						[yupValidationName]: yupValidationMethods,
					};
				}
			}
		}

		setScoresExpectedFormat(scoresExpectedFormatTemp);
		setYupObjectDynamique(yupObjectDynamique);
	}, [scoreExpected, setScoresExpectedFormat, t]);

	//console.log(yupObjectDynamique, scoresExpectedFormat);

	const validationSchema = Yup.object().shape({
		...yupObjectDynamique,
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = (values: any) => {
		if (!shouldSubmit) return;

		const val = validationSchema.cast(values);

		if (val.minutes) {
			// @ts-ignore
			val.DURATION = (parseInt(val.minutes) * 60 + parseInt(val.seconds)).toString();
			delete val.minutes;
			delete val.seconds;
		}

		const scores = Object.entries(val).map((value) => {
			return {
				type: value[0],
				// @ts-ignore
				value: parseFloat(value[1].toString().replace(/,/g, '.')),
			};
		});

		addSessionExerciseScores({
			variables: {
				exerciseId,
				sessionId,
				scores,
			},
		}).then((r: any) => {
			console.log('r', r);
			setShouldSubmit(false);
			setVisible(false);
			onScoreAdded();
		});
	};

	return (
		<div className="calendarLeaderboardAddScore modalValidation">
			<h1 className="titleXL weightMedium">{t('wodbook:addYourScores')}</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				{scoresExpectedFormat?.map(
					(scoreExpected: any, i: number) =>
						scoreExpected.yupValidationName !== 'minutes' &&
						scoreExpected.yupValidationName !== 'seconds' && (
							<TextInput
								key={i}
								required
								errors={errors}
								register={register}
								name={scoreExpected.yupValidationName}
								label={scoreExpected.name}
							/>
						)
				)}
				<div className="timeInputs">
					{scoresExpectedFormat?.map(
						(scoreExpected: any, i: number) =>
							(scoreExpected.yupValidationName === 'minutes' || scoreExpected.yupValidationName === 'seconds') && (
								<TextInput
									key={i}
									required
									errors={errors}
									register={register}
									name={scoreExpected.yupValidationName}
									label={scoreExpected.name}
								/>
							)
					)}
				</div>

				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setShouldSubmit(false);
							setVisible(false);
						}}
					/>
					<Button
						buttonClass="btnDropdownGreen textNormal weightMedium"
						buttonText={t('commons:add')}
						onClick={() => {
							setShouldSubmit(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

export default ModalAddScore;
