import Loader from 'react-loader-spinner';

import './Loader.scss'

const RingsLoader = ({size} : any) => (
	<div className="loader">
		<Loader type="Rings" color="#319795" height={size || 125} width={size || 125} />
	</div>
);

export default RingsLoader;
