import './SessionExerciseEdit.scss';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useShowProgramSessionQuery } from '../../../generated/gql';
import useUser from '../../../hooks/user/useUser';
import ExerciseEditor from '../../../components/pages/calendar/calendarSession/CalendarSessionExercices/ExerciseEditor/ExerciseEditor';
import { useErrorModal } from '../../../hooks/useErrorModal';

const SessionExerciseEdit = () => {
	const { idSession, idExercise } = useParams<any>();

	const me = useUser();

	const [ownerSession, setOwnerSession] = useState(false);

	const { data, error, loading, refetch } = useShowProgramSessionQuery({
		variables: {
			id: idSession,
		},
	});
	useErrorModal({ error });

	useEffect(() => {
		setOwnerSession(data?.showProgramSession?.program?.creator?.id === me?.user?.id);
	}, [data, me]);

	// console.log(data);

	if (!ownerSession) return <div />;

	return (
		<div className="sessionExerciseEdit">
			<div className="exerciseEditorWrapper">
				<ExerciseEditor exercise={data?.showProgramSession?.exercises?.find((x: any) => x.id === idExercise)} />
			</div>
		</div>
	);
};

export default SessionExerciseEdit;
