import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setErrorModal } from '../store/actions';
import { Error, SystemState } from '../store/SystemReducer';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const useErrorModal = (err?: {
	error?: any;
	title?: string;
	body?: string;
	onRetry?: Error['onRetry'];
	onClose?: Error['onClose'];
}) => {
	const [_error, setError] = useState<Error | null>(null);
	const dispatch = useDispatch();
	const currentError = useSelector((state: SystemState) => state.error);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		if (!err || currentError) return;

		let { error, body, title, onRetry, onClose } = err;

		// if we have multiple error, get the first one to display on error modal
		const firstMessage = error?.message.split('\n')[0];

		if (firstMessage === 'INVALID_USER_ID') {
			onClose = () => {
				history.goBack();
			};
			if (!body) {
				body = t('errors:invalidUserErrorBody');
			}
		} else if (firstMessage === 'INVALID_PROGRAM_ID') {
			onClose = () => {
				history.goBack();
			};
			if (!body) {
				body = t('errors:invalidProgramErrorBody');
			}
		} else if (firstMessage === 'INVALID_SESSION_ID' || firstMessage === 'INVALID_PROGRAM_SESSION_ID') {
			console.log('iciciici');
			onClose = () => {
				history.goBack();
			};
			if (!body) {
				body = t('errors:invalidSessionErrorBody');
			}
		} else if (firstMessage === 'NOT_AUTHORIZED') {
			onRetry = undefined;
			onClose = () => {
				history.goBack();
			};
			if (!body) {
				body = t('errors:notAuthorized');
			}
		}

		if (!error?.networkError) {
			onRetry = undefined;
		}

		if (error) {
			dispatch(
				setErrorModal({
					title: title || t('commons:error'),
					body: body || t('errors:default'),
					onRetry,
					onClose,
				})
			);
		}
	}, [err, dispatch, currentError, history, t]);

	useEffect(() => {
		if (_error) {
			dispatch(setErrorModal(_error as any));
		}
	}, [_error, dispatch]);

	return {
		setError,
	};
};
