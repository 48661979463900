// @ts-ignore
import Linkify from 'react-linkify';
import { useState } from 'react';
import Dropdown from '../../../common/Dropdown';
import Modal from '../../../common/Modal';
import { Flag, MenuDots, Trash2 } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import './Bubble.scss';
import { capitalizeName } from '../../../../utils/string';
import moment from 'moment';
import Button from '../../../common/Button';
import {
	ListChatChannelsDocument,
	useDeleteChatMessageMutation,
	useReportChatMessageMutation,
} from '../../../../generated/gql';
import gql from 'graphql-tag';
import update from 'immutability-helper';

const Bubble = ({ message, sender, channel, firstMessage, lastMessage }: any) => {
	const [menuVisible, setMenuVisible] = useState(false);
	const [menuDropdownVisible, setMenuDropdownVisible] = useState(false);
	const [imgModal, setImgModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const { t } = useTranslation();
	const senderType = sender?.__typename;
	let messageStyle: any;

	if (senderType === 'OwnedUser') {
		messageStyle = {
			borderTopRightRadius: firstMessage ? '10px' : '4px',
			borderBottomRightRadius: lastMessage ? '10px' : '4px',
		};
	} else {
		messageStyle = {
			borderTopLeftRadius: firstMessage ? '10px' : '4px',
			borderBottomLeftRadius: lastMessage ? '10px' : '4px',
		};
	}

	const hourDateMessage = moment(message.createdAt).format('LT');

	return (
		<div
			className="bubbleChat"
			onMouseEnter={() => {
				setMenuVisible(true);
			}}
			onMouseLeave={() => {
				setMenuVisible(false);
				setMenuDropdownVisible(false);
			}}
			style={{
				flexDirection: senderType === 'OwnedUser' ? 'row-reverse' : 'row',
			}}
		>
			<div
				className={`
					message textMedium
					${senderType === 'OwnedUser' ? 'messageGrey' : 'messageBlue'}
				`}
				style={messageStyle}
			>
				{message?.deleted ? (
					<div className="messageText">{t('chat:messageDeleted')}</div>
				) : (
					<div className="messageText">
						<Linkify>{message.content}</Linkify>
					</div>
				)}
				{message.attachment && (
					<>
						<div
							className="imgContainer noselect"
							onClick={() => {
								setImgModal(true);
							}}
						>
							<img src={message.attachment.url} alt="" />
						</div>
						<Modal visible={imgModal} setVisible={setImgModal}>
							<div className="imgModal">
								<img src={message.attachment.url} alt="" />
							</div>
						</Modal>
					</>
				)}

				<div className="messageInfos noselect">
					<div className="hours">{hourDateMessage}</div>
					{channel.type === 'PROGRAM' && senderType !== 'OwnedUser' && (
						<div className="name">{capitalizeName(sender.firstName) + ' ' + capitalizeName(sender.lastName)}</div>
					)}
				</div>
			</div>

			<div
				className="messageMenu"
				style={{
					marginLeft: senderType === 'OwnedUser' ? '0' : '4px',
					marginRight: senderType === 'OwnedUser' ? '4px' : '0',
					paddingLeft: senderType === 'OwnedUser' ? '14px' : '0',
					paddingRight: senderType === 'OwnedUser' ? '0' : '14px',
					visibility: menuVisible ? 'unset' : 'hidden',
				}}
			>
				<div className="menuDots noselect">
					<div
						className="menuDotsIcon"
						onClick={() => {
							setMenuDropdownVisible(true);
						}}
					>
						<MenuDots />
					</div>

					<Dropdown
						visible={menuDropdownVisible}
						setVisible={setMenuDropdownVisible}
						style={{
							left: '50%',
							transform: 'translateX(-50%)',
							top: '30px',
						}}
					>
						<div className="actions textMedium weightMedium">
							{senderType === 'OwnedUser' ? (
								<>
									<button className="delete" onClick={() => setDeleteModal(true)}>
										<Trash2 />
										{t('commons:delete')}
									</button>
								</>
							) : (
								<button className="standard" onClick={() => setReportModal(true)}>
									<Flag />
									{t('chat:report')}
								</button>
							)}
						</div>
					</Dropdown>

					{deleteModal && (
						<DeleteModal
							deleteModal={deleteModal}
							setDeleteModal={setDeleteModal}
							messageId={message?.id}
							channelId={channel?.id}
							lastMessage={lastMessage}
						/>
					)}
					{reportModal && (
						<ReportModal reportModal={reportModal} setReportModal={setReportModal} messageId={message?.id} />
					)}
				</div>
			</div>
		</div>
	);
};

const DeleteModal = ({ deleteModal, setDeleteModal, messageId, channelId, lastMessage }: any) => {
	const { t } = useTranslation();

	const [deleteChatMessage] = useDeleteChatMessageMutation({
		update(cache, { data: dataDeleteChatMessage }) {
			// If the message is the latest one, update
			if (lastMessage) {
				const data = cache.readQuery({
					query: ListChatChannelsDocument,
				});

				if (!data) return;

				// @ts-ignore
				const { listChatChannels } = data;

				const channelIndex = listChatChannels.result.findIndex((c: any) => c.id === channelId);

				cache.writeQuery({
					query: ListChatChannelsDocument,
					data: {
						listChatChannels: update(listChatChannels, {
							result: {
								[channelIndex]: {
									latestMessage: {
										content: {
											$set: '',
										},
										deleted: {
											$set: true,
										},
										attachment: {
											$set: null,
										},
									},
								},
							},
						}),
					},
				});
			}

			// @ts-ignore
			const { deleteChatMessage } = dataDeleteChatMessage;
			console.log('dataDeleteChatMessage', dataDeleteChatMessage);

			cache.writeFragment({
				id: `ChatMessage:${deleteChatMessage}`,
				fragment: gql`
					fragment DeletedMessage on ChatMessage {
						deleted
						content
						attachment {
							type
							url
						}
					}
				`,
				data: {
					deleted: true,
					content: '',
					attachment: null,
				},
			});
		},
	});

	const onDeleteClick = () => {
		deleteChatMessage({
			variables: {
				messageId,
			},
		}).then((r: any) => {
			console.log('r', r);
			setDeleteModal(false);
		});
	};

	return (
		<Modal visible={deleteModal} setVisible={setDeleteModal}>
			<div className="modalValidation">
				<h1 className="titleXL weightMedium">{t('chat:deleteYourMessage')}</h1>
				<h2 className="textMedium weightMedium">{t('chat:deleteYourMessageConfirmation')}</h2>
				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setDeleteModal(false);
						}}
					/>
					<Button
						buttonClass="btnTextRed textNormal weightMedium"
						buttonText={t('commons:delete')}
						onClick={onDeleteClick}
					/>
				</div>
			</div>
		</Modal>
	);
};

const ReportModal = ({ reportModal, setReportModal, messageId }: any) => {
	const { t } = useTranslation();
	const [reportChatMessage] = useReportChatMessageMutation();

	const onReportClick = () => {
		reportChatMessage({
			variables: {
				messageId,
			},
		}).then((r: any) => {
			console.log('r', r);
			setReportModal(false);
		});
	};

	return (
		<Modal visible={reportModal} setVisible={setReportModal}>
			<div className="modalValidation">
				<h1 className="titleXL weightMedium">{t('chat:reportMessage')}</h1>
				<h2 className="textMedium weightMedium">{t('chat:reportMessageConfirmation')}</h2>
				<div className="actions">
					<Button
						buttonClass="btnGreyNoBack textNormal weightMedium"
						buttonText={t('commons:cancel')}
						onClick={() => {
							setReportModal(false);
						}}
					/>
					<Button
						buttonClass="btnTextRed textNormal weightMedium"
						buttonText={t('chat:report')}
						onClick={onReportClick}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default Bubble;
