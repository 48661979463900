export enum DateShowFormat {
	'MONTH',
	'WEEK',
}

export enum CalendarSessionTab {
	'EXERCICES',
	'COMMENTS',
	'ALL'
}

export enum CalendarMainSidebarVisible {
	'VISIBLE',
	'HIDDEN',
}