import { Check } from '../../main/Icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '../../common/Button';
import IconRound from '../../common/IconRound';

export default function Step3ResetPassword() {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div className="forgetPasswordStep3 loginLeftContainer">
			<div className="headerRoundIcon">
				<IconRound Icon={Check} />
				<h1 className="formTitle titleXXL weightBold">{t('auth:mailUpdate')}</h1>
			</div>

			<div className="formValidationClassic">
				<Button
					buttonText={t('commons:validate')}
					buttonClass="btnGreyContrast textMedium weightNormal"
					onClick={() => history.goBack()}
				/>
			</div>
		</div>
	);
}
