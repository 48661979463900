import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useUser from '../../../../hooks/user/useUser';
import { UserIcon } from '../../../main/UserIcon';
import { useTranslation } from 'react-i18next';
import Bubble from './Bubble';
import './ChatArea.scss';
import Loader from '../../../main/Loader';

const ChatArea = ({ data, discussionZoneWrapper, channel, fetchMoreMessage }: any) => {
	const { t } = useTranslation();
	const [messages, setMessages] = useState<any>([]);
	const [shouldScrollBottom, setShouldScrollBottom] = useState(true);
	const mySelf = useUser();

	// Structure datas
	useEffect(() => {
		let lastDate: any;
		let lastUser: any;

		const messagesDatas: any = [];

		data.listChatChannelMessages.result.forEach((message: any) => {
			const dayDateMessage = moment(message.createdAt).format('LL');

			// Detect senders
			let senderDatas = data.listChatChannelMembers.result.find((x: any) => x.pubnub_uuid === message.sender);
			if (!senderDatas) {
				senderDatas = mySelf.user;
			}

			// Detect dates by day
			if (dayDateMessage !== lastDate) {
				lastDate = dayDateMessage;
				messagesDatas.push({
					dayDate: lastDate,
					messages: [
						{
							sender: senderDatas,
							messages: [message],
						},
					],
				});
			} else {
				const objectDateDay = messagesDatas.find((x: any) => x.dayDate === dayDateMessage);
				const objectDateDayIndex = messagesDatas.indexOf(objectDateDay);

				if (lastUser === senderDatas) {
					const lastIndex: number = messagesDatas[objectDateDayIndex].messages.length - 1;
					messagesDatas[objectDateDayIndex].messages[lastIndex].messages.push(message);
				} else {
					messagesDatas[objectDateDayIndex].messages.push({
						sender: senderDatas,
						messages: [message],
					});
				}
			}

			lastUser = senderDatas;
		});
		setMessages(messagesDatas);
	}, [data, mySelf.user]);

	useEffect(() => {
		if (fetchMoreMessage) {
			setShouldScrollBottom(false);
		}
		setTimeout(() => {
			setShouldScrollBottom(true);
		}, 1000);
	}, [fetchMoreMessage]);

	useEffect(() => {
		setShouldScrollBottom(true);
	}, [channel]);

	// Scroll gestion
	useEffect(() => {
		if (shouldScrollBottom) {
			scrollBottom();
		}
	}, [messages]);

	const scrollBottom = () => {
		if (discussionZoneWrapper.current) {
			discussionZoneWrapper.current.scrollTop = discussionZoneWrapper.current.scrollHeight;
		}
	};

	return (
		<div className="chatArea">
			{fetchMoreMessage && (
				<div style={{ position: 'absolute', top: -30, left: '50%', transform: 'translateX(-50%)' }}>
					<Loader type="Rings" color="#319795" height={40} width={40} />
				</div>
			)}
			{data?.listChatChannelMessages?.result?.length > 0 ? (
				<div className="listeMessages">
					{messages.map((messageDateBloc: any, index: any) => (
						<div className="listeMessageDatebloc" key={index}>
							<div className="dateShow textMedium weightMedium noselect">{messageDateBloc.dayDate}</div>

							<div className="messagesContainer">
								{messageDateBloc.messages.map((messageGroup: any, index2: any) => (
									<div
										className="messageGroup"
										key={index2}
										style={{
											justifyContent: messageGroup.sender.__typename === 'OwnedUser' ? 'flex-end' : 'flex-start',
										}}
									>
										{messageGroup.sender.__typename !== 'OwnedUser' && (
											<div className="avatar">
												<UserIcon
													url={messageGroup.sender.profile_picture?.url}
													text={messageGroup.sender.firstName.charAt(0) + messageGroup.sender.lastName.charAt(0)}
													size={34}
												/>
											</div>
										)}

										<div className="messagesList">
											{messageGroup.messages.map((message: any, index3: any) => (
												<div className="messageBloc" key={message.id}>
													<Bubble
														channel={channel}
														sender={messageGroup.sender}
														message={message}
														firstMessage={index3 === messageGroup.messages.length - 1}
														lastMessage={index3 === 0}
														// borderRadiusTop={messageGroup.messages.length >= 2 && index3 === messageGroup.messages.length - 1 ? true : false}
													/>
												</div>
											))}
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="firstMessage titleXL">{t('chat:firstMessage')}</div>
			)}
		</div>
	);
};

export default ChatArea;
