export interface Error {
	title: string;
	body: string;
	onRetry?: () => void;
	onClose?: () => void;
}

export interface ChronoConfigData {
	sessionId: string;
	exerciseId: string;
}

export interface SystemState {
	authenticated: boolean;
	error: Error | null;
	sidebarToggled: boolean;
	navbarSearchToggled: boolean;
	navbarSearchFocus: boolean;
	chatChannelId: string | null;
	saveChronoConfigData: ChronoConfigData | null;
}

export const SYSTEM_STORE_ACTIONS = {
	SET_AUTHENTICATED: 'SET_AUTHENTICATED',
	SET_ERROR_MODAL: 'SET_ERROR_MODAL',
	TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
	TOGGLE_NAVBARSEARCH: 'TOGGLE_NAVBARSEARCH',
	TOGGLE_NAVBARSEARCHFOCUS: 'TOGGLE_NAVBARSEARCHFOCUS',
	SET_CHAT_CHANNEL_ID: 'SET_CHAT_CHANNEL_ID',
	SET_SAVE_CHRONO_CONFIG_DATA: 'SET_SAVE_CHRONO_CONFIG_DATA',
};

const initialState = {
	authenticated: false,
	error: null,
	sidebarToggled: false,
	navbarSearchToggled: false,
	navbarSearchFocus: false,
	chatChannelId: null,
	saveChronoConfigData: null,
};

export default function SystemReducer(state = initialState, action: any) {
	switch (action.type) {
		case SYSTEM_STORE_ACTIONS.SET_AUTHENTICATED:
			return {
				...state,
				authenticated: action.value,
			};
		case SYSTEM_STORE_ACTIONS.SET_ERROR_MODAL:
			return {
				...state,
				error: action.value,
			};
		case SYSTEM_STORE_ACTIONS.TOGGLE_SIDEBAR:
			return {
				...state,
				sidebarToggled: action.value,
			};
		case SYSTEM_STORE_ACTIONS.TOGGLE_NAVBARSEARCH:
			return {
				...state,
				navbarSearchToggled: action.value,
			};
		case SYSTEM_STORE_ACTIONS.TOGGLE_NAVBARSEARCHFOCUS:
			return {
				...state,
				navbarSearchFocus: action.value,
			};
		case SYSTEM_STORE_ACTIONS.SET_CHAT_CHANNEL_ID:
			return {
				...state,
				chatChannelId: action.value,
			};
		case SYSTEM_STORE_ACTIONS.SET_SAVE_CHRONO_CONFIG_DATA:
			return {
				...state,
				saveChronoConfigData: action.value,
			};
		default:
			return state;
	}
}
