import { useState, useEffect } from 'react';

import './CategorySelector.scss';

const CategorySelector = ({ selectDefault, options, setCategorySelect }: any) => {
	const [optionsDisplay, setOptionsDisplay] = useState(false);

	const handleClick = () => {
		if (optionsDisplay) {
			setOptionsDisplay(false);
		} else {
			setOptionsDisplay(true);
		}
	};

	//CLICK OUTSIDE GESTION
	useEffect(() => {
		window.addEventListener('click', handleClickOutsideSelect);

		return () => {
			window.removeEventListener('click', handleClickOutsideSelect);
		};
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	const handleClickOutsideSelect = (e: any) => {
		let searchElements = false;

		let endLoop = false;
		let element = e.target;

		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (element.classList[0] === 'recordCategorySelectorWrapper') {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}

		if (!searchElements) {
			setOptionsDisplay(false);
		}
	};

	const handleClickOption = (e: any, data: any) => {
		setCategorySelect(data);
		setOptionsDisplay(false);
	};

	return (
		<div className="recordCategorySelector noselect">
			<div
				className="recordCategorySelectorWrapper"
				style={{
					boxShadow: optionsDisplay ? '0px 10px 20px rgba(108, 121, 191, 0.1)' : 'unset',
				}}
			>
				<div className="defaultSelect ">
					<Option data={selectDefault} onClick={handleClick} />

					<svg
						style={{
							transform: optionsDisplay ? 'rotate(180deg)' : 'unset',
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 512 512"
					>
						<path d="M505 130c-9-10-24-11-34-1L256 332 41 129a24 24 0 10-33 35l231 219a24 24 0 0034 0l231-219c10-9 11-25 1-34z" />
					</svg>
				</div>

				{optionsDisplay && (
					<div className="options">
						{options?.map((option: any) => {
							if (option.key !== selectDefault.key) {
								return (
									<Option
										key={option.key}
										data={option}
										onClick={(e: any) => {
											handleClickOption(e, option);
										}}
									/>
								);
							} else {
								return null;
							}
						})}
					</div>
				)}
			</div>
		</div>
	);
};

const Option = ({ data, onClick }: any) => {
	return (
		<div className="option titleL weightMedium" onClick={onClick}>
			{data.title}
		</div>
	);
};

export default CategorySelector;
