import React from 'react';

export const PrismicApolloClientContext = React.createContext();

export default ({ client, children }) => {
	return (
		<PrismicApolloClientContext.Provider value={client}>
			{children}
		</PrismicApolloClientContext.Provider>
	);
};
