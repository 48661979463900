import './Record.scss';
// @ts-ignore
import moment from 'moment';
import { formatScore } from '../../../../utils/helpers';

const Record = ({data} : any) => {

	return (
		<div className="recordLine">
			<div className="left">
				<h2 className="weightBold textMedium">{data.exercise}</h2>
				<h1 className="textSmall weightMedium">{data.name}</h1>
			</div>
			<div className="right">
				<h2 className="textSmall weightMedium">
					{formatScore(
						data.scoreEntries[0].scores[0].type,
						data.scoreEntries[0].scores[0].value
					)}
				</h2>
			</div>
			<div className="right">
				<h2 className="textSmall weightMedium">
					{moment(data.scoreEntries[0].date).format('L')}
				</h2>
			</div>
		</div>
	);
};

export default Record;
