import './CalendarApp.scss';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useUser from '../../../hooks/user/useUser';
import moment from 'moment';
import CalendarNavbar from '../../../components/pages/calendar/CalendarNavbar';
import { CalendarContext } from '../../../components/pages/calendar/contexts/CalendarContext';
import CalendarMainSidebarContextProvider from '../../../components/pages/calendar/contexts/CalendarMainSidebarContext';
import { useListProgramSessionsDayLazyQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import { DateShowFormat } from '../../../components/pages/calendar/_types';
import CalendarMainSidebar from '../../../components/pages/calendar/CalendarMainSidebar';
import CalendarDatesContextProvider from '../../../components/pages/calendar/contexts/CalendarDatesContext';
import Calendar from '../../../components/pages/calendar/calendar/Calendar';
import { useMediaQuery } from 'react-responsive';
import CalendarMobile from '../../../components/pages/calendar/calendarMobile/CalendarMobile';
import { useLocation, useParams } from 'react-router-dom';
import { MainLayoutContext } from '../../../layout/_contexts';
import Button from '../../../components/common/Button';
import { useTranslation } from 'react-i18next';
import useIsCoach from '../../../hooks/user/useIsCoach';
import ModalSessionAdd from '../../../components/pages/calendar/calendar/ModalSessionAdd';

const CalendarApp = ({ programData = null }: any) => {
	const me = useUser();
	const { t } = useTranslation();
	const isCoach = useIsCoach(programData?.showProgram?.id);
	const isTablet = useMediaQuery({ query: '(min-width: 800px)' });
	const [dateSelected, setDateSelected] = useState(moment());
	const [firstime, setFirstime] = useState(true);
	const [dateShowFormat, setDateShowFormat] = useState<DateShowFormat>(DateShowFormat.MONTH);
	const [modalAddSession, setModalAddSession] = useState<boolean>(false);

	const { breadcrumb } = useContext(MainLayoutContext);
	const { slug } = useParams<any>();
	const location = useLocation<any>();

	useEffect(() => {
		// @ts-ignore
		if (location?.state && location?.state?.date) {
			setDateSelected(moment(location?.state?.date));
		}
	}, [location]);

	const day = new Date();
	const [listProgramSessions, { data, error, loading, refetch, fetchMore }] = useListProgramSessionsDayLazyQuery();
	useErrorModal({ error, onRetry: () => refetch && refetch() });

	const fetchMoreData = useCallback(
		(day) => {
			if (!fetchMore) return;

			fetchMore({
				variables: {
					month: day.month,
					year: day.year,
				},
				updateQuery: (prev, { fetchMoreResult }) => {
					if (!fetchMoreResult) return prev;
					let newResult = [
						// @ts-ignore
						...prev?.listProgramSessions?.result,
					];

					// @ts-ignore
					fetchMoreResult?.listProgramSessions?.result.forEach((e: any) => {
						if (newResult.findIndex((i: any) => i?.id === e?.id) === -1) {
							newResult.push(e);
						}
					});

					const newData = {
						listProgramSessions: {
							// @ts-ignore
							...fetchMoreResult.listProgramSessions,
							/*result: [
								// @ts-ignore
								...prev?.listProgramSessions?.result,
								// @ts-ignore
								...fetchMoreResult?.listProgramSessions?.result,
							],*/
							result: newResult,
						},
					};
					return newData;
				},
			});
		},
		[fetchMore]
	);

	useEffect(() => {
		listProgramSessions({
			variables: {
				month: day.getMonth() + 1,
				year: day.getFullYear(),
				programId: slug || null,
			},
		});
	}, [slug]);

	const fetchInitialData = useCallback(() => {
		if (data?.listProgramSessions) {
			fetchMoreData({ month: day.getMonth(), year: day.getFullYear() });
			fetchMoreData({ month: day.getMonth() + 2, year: day.getFullYear() });
		}
	}, [data?.listProgramSessions, day, fetchMoreData]);

	const fetchMoreCalendarData = useCallback(
		(month) => {
			if (data?.listProgramSessions) {
				fetchMoreData({ month: month, year: day.getFullYear() });
			}
		},
		[data?.listProgramSessions, day, fetchMoreData]
	);

	useEffect(() => {
		if (firstime && data?.listProgramSessions) {
			setFirstime(false);
			fetchInitialData();
		}
	}, [data, fetchInitialData, firstime]);

	useEffect(() => {}, [data?.listProgramSessions]);

	if (loading) return null;

	return (
		<CalendarContext.Provider
			value={{
				setFirstime,
				refetch,
				dateSelected,
				setDateSelected,
				dateShowFormat,
				setDateShowFormat,
				me,
				programPage: slug,
				programOwner: isCoach,
				modalAddSession,
				setModalAddSession,
				fetchMoreCalendarData,
			}}
		>
			<div className="calendarApp">
				<div className="row row1">
					<CalendarNavbar />
				</div>

				<CalendarDatesContextProvider sessions={data}>
					<CalendarMainSidebarContextProvider>
						<div className="row row2">
							<div className="column column1">{(!slug || isTablet) && <CalendarMainSidebar />}</div>

							<div className="column column2">{isTablet ? <Calendar /> : <CalendarMobile />}</div>
						</div>
					</CalendarMainSidebarContextProvider>
				</CalendarDatesContextProvider>

				{isCoach && !isTablet && (
					<Button
						buttonClass="btnAddSession btnGreen titleL weightMedium"
						buttonText={t('programs:programSessions:addSession')}
						onClick={() => {
							setModalAddSession(true);
						}}
					/>
				)}
			</div>

			{modalAddSession && (
				<ModalSessionAdd visible={modalAddSession} setVisible={setModalAddSession} programId={slug} />
			)}
		</CalendarContext.Provider>
	);
};

const sameSessions = (prevProps: any, nextProps: any) => {
	if (!prevProps.data && !nextProps.data) {
		return true;
	} else {
		return prevProps.data?.listProgramSessions?.result[0]?.id && nextProps.data?.listProgramSessions?.result[0]?.id;
	}
};

export default React.memo(CalendarApp, sameSessions);
