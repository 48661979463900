import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccountLink = {
  __typename?: 'AccountLink';
  url?: Maybe<Scalars['String']>;
};

export type AddAhapChronoConfigurationInput = {
  ahaps?: Maybe<Array<Maybe<AhapConfigurationInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type AddAmrapChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
};

export type AddDeviceTokenInput = {
  token: Scalars['String'];
  type?: Maybe<DeviceTokenType>;
};

export type AddEmomChronoConfigurationInput = {
  emoms?: Maybe<Array<Maybe<EmomConfigurationInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type AddForTimeChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type AddPersonalRecordScoresInput = {
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type AddProgramSessionCommentInput = {
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
};

export type AddProgramSessionCommentReplyInput = {
  commentId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

export type AddPublicWodAhapChronoConfigurationInput = {
  ahaps?: Maybe<Array<Maybe<AhapConfigurationInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodAmrapChronoConfigurationInput = {
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  wodId: Scalars['ID'];
};

export type AddPublicWodBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
};

export type AddPublicWodEmomChronoConfigurationInput = {
  emoms?: Maybe<Array<Maybe<EmomConfigurationInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodForTimeChronoConfigurationInput = {
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  timeLimit?: Maybe<Scalars['Int']>;
  wodId: Scalars['ID'];
};

export type AddPublicWodScoresInput = {
  date?: Maybe<Scalars['Date']>;
  scores?: Maybe<Array<Maybe<PublicWodScoreInput>>>;
  wodId: Scalars['ID'];
};

export type AddPublicWodTabataChronoConfigurationInput = {
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  wodId: Scalars['ID'];
};

export type AddSessionExerciseBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
};

export type AddSessionExerciseFeedbackInput = {
  comment?: Maybe<Scalars['String']>;
  exerciseId: Scalars['ID'];
  feeling?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['Float']>;
  sessionId: Scalars['ID'];
};

export type AddSessionExerciseScoresInput = {
  exerciseId: Scalars['ID'];
  scores?: Maybe<Array<Maybe<SessionExerciseScoreInput>>>;
  sessionId: Scalars['ID'];
};

export type AddTabataChronoConfigurationInput = {
  exerciseId: Scalars['ID'];
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  sessionId: Scalars['ID'];
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
};

export type AddWodBookEntryBlockInput = {
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type AddWodBookEntryScoresInput = {
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  scores?: Maybe<Array<Maybe<WodBookEntryScoreInput>>>;
};

export type AddedScoresDailyStats = {
  __typename?: 'AddedScoresDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type AhapChronoConfiguration = {
  __typename?: 'AhapChronoConfiguration';
  ahaps?: Maybe<Array<Maybe<AhapConfiguration>>>;
  type?: Maybe<ChronoConfigurationType>;
};

export type AhapConfiguration = {
  __typename?: 'AhapConfiguration';
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type AhapConfigurationInput = {
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type AmrapChronoConfiguration = {
  __typename?: 'AmrapChronoConfiguration';
  roundConfiguration?: Maybe<ChronoRoundConfiguration>;
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  type?: Maybe<ChronoConfigurationType>;
};

export enum AvailableLanguage {
  En = 'en',
  Fr = 'fr'
}

export type BirthDate = {
  __typename?: 'BirthDate';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Date']>;
};

export type BlockChatMemberInput = {
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type BlockOrderInput = {
  blockId: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
};

export type ChatAvatar = {
  __typename?: 'ChatAvatar';
  url?: Maybe<Scalars['String']>;
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  /** Default : program avatar or partner profile picture */
  avatar?: Maybe<ChatAvatar>;
  blockedBy?: Maybe<Scalars['ID']>;
  coach?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  latestMessage?: Maybe<LatestChannelMessage>;
  members?: Maybe<Array<Maybe<User>>>;
  name?: Maybe<Scalars['String']>;
  pubnub_id?: Maybe<Scalars['String']>;
  totalMembers?: Maybe<Scalars['Int']>;
  type?: Maybe<ChatChannelType>;
  unreadMessagesCount?: Maybe<Scalars['Int']>;
};

/** Chat channel type */
export enum ChatChannelType {
  Duo = 'DUO',
  Program = 'PROGRAM'
}

export type ChatChannelsStat = {
  __typename?: 'ChatChannelsStat';
  duo?: Maybe<Scalars['Int']>;
  program?: Maybe<Scalars['Int']>;
};

export type ChatMember = {
  __typename?: 'ChatMember';
  birthDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCoach?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<UserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  profile_picture?: Maybe<ProfilePicture>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};


export type ChatMemberPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  attachment?: Maybe<ChatMessageAttachment>;
  channel: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sender: Scalars['ID'];
  updatedAt: Scalars['Date'];
};

export type ChatMessageAttachment = {
  __typename?: 'ChatMessageAttachment';
  type?: Maybe<ChatMessageAttachmentType>;
  url?: Maybe<Scalars['String']>;
};

/** Chat message attachment type */
export enum ChatMessageAttachmentType {
  Image = 'IMAGE'
}

export type ChatMessagesStat = {
  __typename?: 'ChatMessagesStat';
  image?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type ChronoConfiguration = AhapChronoConfiguration | AmrapChronoConfiguration | EmomChronoConfiguration | ForTimeChronoConfiguration | TabataChronoConfiguration;

export enum ChronoConfigurationType {
  Ahap = 'AHAP',
  Amrap = 'AMRAP',
  Emom = 'EMOM',
  Fortime = 'FORTIME',
  Tabata = 'TABATA'
}

export type ChronoRoundConfiguration = {
  __typename?: 'ChronoRoundConfiguration';
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type ChronoRoundConfigurationInput = {
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  timeLimit?: Maybe<Scalars['Int']>;
};

export type ChronoSetConfiguration = {
  __typename?: 'ChronoSetConfiguration';
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
};

export type ChronoSetConfigurationInput = {
  number?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
};

export type City = {
  __typename?: 'City';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  attachment?: Maybe<CommentAttachment>;
  author?: Maybe<User>;
  canLike?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  likes?: Maybe<Scalars['Int']>;
  replies?: Maybe<CommentReplies>;
  replyable?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CommentType>;
};


export type CommentRepliesArgs = {
  input?: Maybe<CommentRepliesInput>;
};

export type CommentAttachment = {
  __typename?: 'CommentAttachment';
  type?: Maybe<CommentAttachmentType>;
  url?: Maybe<Scalars['String']>;
};

/** Comment attachment type */
export enum CommentAttachmentType {
  Image = 'IMAGE'
}

export type CommentLikedNotificationSettings = {
  __typename?: 'CommentLikedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export type CommentReplies = {
  __typename?: 'CommentReplies';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CommentRepliesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type CommentReplyAddedNotificationSettings = {
  __typename?: 'CommentReplyAddedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export enum CommentType {
  Session = 'SESSION'
}

export type Country = {
  __typename?: 'Country';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type CreateAccountLinkInput = {
  returnUrl?: Maybe<Scalars['String']>;
};

export type CreateChatChannelInput = {
  membersIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateCustomerPortalSessionInput = {
  returnUrl?: Maybe<Scalars['String']>;
};

export type CreatePersonalRecordInput = {
  category?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  exercise?: Maybe<Scalars['String']>;
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type CreateProgramCheckoutSessionInput = {
  cancelUrl?: Maybe<Scalars['String']>;
  programId?: Maybe<Scalars['ID']>;
  returnUrl?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CreateProgramInput = {
  avatar?: Maybe<Scalars['Upload']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type CreateProgramInvitationInput = {
  program: Scalars['ID'];
  recipient: Scalars['ID'];
};

export type CreateProgramJoinRequestInput = {
  program: Scalars['ID'];
};

export type CreateProgramSessionInput = {
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  programId: Scalars['ID'];
};

export type CreatePublicWodCategoryInput = {
  name?: Maybe<PublicWodCategoryNameInput>;
};

export type CreatePublicWodInput = {
  categoryId: Scalars['ID'];
  exercise?: Maybe<PublicWodExerciseInput>;
};

export type CreateSessionExerciseInput = {
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<Scalars['String']>;
  sessionId: Scalars['ID'];
};

export type CreateUserInput = {
  birthDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};

export type CreateWodBookEntryInput = {
  exerciseName: Scalars['String'];
};

export type CreatedProfilesDailyStats = {
  __typename?: 'CreatedProfilesDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type CreatedProgramsDailyStats = {
  __typename?: 'CreatedProgramsDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type CreatedSessionsDailyStats = {
  __typename?: 'CreatedSessionsDailyStats';
  count?: Maybe<Scalars['Int']>;
  previousDayCount?: Maybe<Scalars['Int']>;
};

export type CustomerPortalSession = {
  __typename?: 'CustomerPortalSession';
  url?: Maybe<Scalars['String']>;
};

export type DailyStats = {
  __typename?: 'DailyStats';
  addedScores?: Maybe<AddedScoresDailyStats>;
  createdProfiles?: Maybe<CreatedProfilesDailyStats>;
  createdPrograms?: Maybe<CreatedProgramsDailyStats>;
  createdSessions?: Maybe<CreatedSessionsDailyStats>;
};


export type DeleteMessageInput = {
  messageId: Scalars['ID'];
};

export type DeleteOwnedUserInput = {
  id: Scalars['ID'];
};

export type DeletePersonalRecordInput = {
  id: Scalars['ID'];
};

export type DeleteProgramInput = {
  programId: Scalars['ID'];
};

export type DeleteProgramSessionInput = {
  id: Scalars['ID'];
};

export type DeletePublicWodBlockInput = {
  blockId: Scalars['ID'];
  lang: AvailableLanguage;
  wodId: Scalars['ID'];
};

export type DeletePublicWodCategoryInput = {
  /** WOD category id */
  id: Scalars['ID'];
};

export type DeletePublicWodInput = {
  wodId: Scalars['ID'];
};

export type DeletePublicWodScoresInput = {
  scoreEntryId: Scalars['ID'];
};

export type DeleteReportedChatMessageInput = {
  messageId: Scalars['ID'];
};

export type DeleteReportedCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type DeleteSessionExerciseBlockInput = {
  blockId: Scalars['ID'];
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type DeleteSessionExerciseInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type DeleteWodBookEntryBlockInput = {
  blockId: Scalars['ID'];
  entryId: Scalars['ID'];
};

export type DeleteWodBookEntryInput = {
  id: Scalars['ID'];
};

export type DeleteWodBookEntryScoresInput = {
  entryId: Scalars['ID'];
  scoreId: Scalars['ID'];
};

export enum DeviceTokenType {
  Apns = 'apns',
  Gcm = 'gcm'
}

export type EmomChronoConfiguration = {
  __typename?: 'EmomChronoConfiguration';
  emoms?: Maybe<Array<Maybe<EmomConfiguration>>>;
  type?: Maybe<ChronoConfigurationType>;
};

export type EmomConfiguration = {
  __typename?: 'EmomConfiguration';
  everyTime?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  roundsNumber?: Maybe<Scalars['Int']>;
  whileTime?: Maybe<Scalars['Int']>;
};

export type EmomConfigurationInput = {
  everyTime?: Maybe<Scalars['Int']>;
  restTime?: Maybe<Scalars['Int']>;
  roundsNumber?: Maybe<Scalars['Int']>;
  whileTime?: Maybe<Scalars['Int']>;
};

export enum EvolutionRange {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type ExerciseBlock = {
  __typename?: 'ExerciseBlock';
  /** For text block */
  content?: Maybe<Scalars['String']>;
  /** For list block */
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  /** For image block */
  image?: Maybe<SessionExerciseBlockImage>;
  order?: Maybe<Scalars['Int']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type ExerciseExpectedScore = {
  __typename?: 'ExerciseExpectedScore';
  sort?: Maybe<SortType>;
  type?: Maybe<ScoreValueType>;
};

export type ExerciseScore = {
  __typename?: 'ExerciseScore';
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type ExerciseScoreRankTc = {
  __typename?: 'ExerciseScoreRankTC';
  member?: Maybe<User>;
  position?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
};

export type ExpectedScoreInput = {
  sort?: Maybe<SortType>;
  type: ScoreValueType;
};

export type FootSize = {
  __typename?: 'FootSize';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Int']>;
};

export type ForTimeChronoConfiguration = {
  __typename?: 'ForTimeChronoConfiguration';
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  timeLimit?: Maybe<Scalars['Int']>;
  type?: Maybe<ChronoConfigurationType>;
};

/** User gender */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type Height = {
  __typename?: 'Height';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Int']>;
};

export type JoinOpenProgramInput = {
  programId: Scalars['ID'];
};

export type LatestChannelMessage = {
  __typename?: 'LatestChannelMessage';
  attachment?: Maybe<ChatMessageAttachment>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sender?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type LeaveChatChannelInput = {
  channelId: Scalars['ID'];
};

export type LeaveProgramInput = {
  programId: Scalars['ID'];
};

export type LeaveProgramResult = {
  __typename?: 'LeaveProgramResult';
  programId?: Maybe<Scalars['String']>;
};

export type LikeProgramSessionCommentInput = {
  commentId: Scalars['ID'];
};

export type ListChatChannelMembersInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListChatChannelMembersResult = {
  __typename?: 'ListChatChannelMembersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatMember>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListChatChannelMessagesInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListChatChannelMessagesResult = {
  __typename?: 'ListChatChannelMessagesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatMessage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListChatChannelsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListChatChannelsResult = {
  __typename?: 'ListChatChannelsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ChatChannel>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListCommentRepliesInput = {
  commentId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<CommentType>;
};

export type ListCommentRepliesResult = {
  __typename?: 'ListCommentRepliesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListInvitableProgramUsersInput = {
  filter?: Maybe<ProgramUsersFilterInput>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListInvitableProgramUsersResult = {
  __typename?: 'ListInvitableProgramUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListOwnedUsersInput = {
  filter?: Maybe<OwnedUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListOwnedUsersResult = {
  __typename?: 'ListOwnedUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<OwnedUser>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPersonalRecordsInput = {
  category?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
};

export type ListPersonalRecordsResultTc = {
  __typename?: 'ListPersonalRecordsResultTC';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PersonalRecord>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramInvitationsResult = {
  __typename?: 'ListProgramInvitationsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramInvitation>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramJoinRequestsResult = {
  __typename?: 'ListProgramJoinRequestsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramJoinRequest>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramSessionCommentsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
};

export type ListProgramSessionCommentsResult = {
  __typename?: 'ListProgramSessionCommentsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Comment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramSessionsInput = {
  /** Day of month */
  day?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  /** Month number (January is 1 / December is 12) */
  month?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['ID']>;
  /** Full year (2020) */
  year?: Maybe<Scalars['Int']>;
};

export type ListProgramSessionsResult = {
  __typename?: 'ListProgramSessionsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramSession>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramUsersInput = {
  filter?: Maybe<ProgramUsersFilterInput>;
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListProgramUsersResult = {
  __typename?: 'ListProgramUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ProgramMember>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListProgramsInput = {
  filter?: Maybe<ProgramFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListProgramsResult = {
  __typename?: 'ListProgramsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<Program>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodCategoriesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  /** Only available for admin role */
  showUnpublished?: Maybe<Scalars['Boolean']>;
};

export type ListPublicWodCategoriesResult = {
  __typename?: 'ListPublicWodCategoriesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PublicWodCategory>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodUserScoresInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  wodId: Scalars['ID'];
};

export type ListPublicWodUserScoresResult = {
  __typename?: 'ListPublicWodUserScoresResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ScoreEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListPublicWodsInput = {
  categoryId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListPublicWodsResult = {
  __typename?: 'ListPublicWodsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<PublicWod>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListReportedChatMessagesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  processed?: Maybe<Scalars['Boolean']>;
};

export type ListReportedChatMessagesResult = {
  __typename?: 'ListReportedChatMessagesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ReportedChatMessage>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListReportedCommentsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  processed?: Maybe<Scalars['Boolean']>;
};

export type ListReportedCommentsResult = {
  __typename?: 'ListReportedCommentsResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ReportedComment>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSessionExerciseFeedbacksResult = {
  __typename?: 'ListSessionExerciseFeedbacksResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<SessionExerciseFeedBack>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSessionExerciseScoreRanksInput = {
  exerciseId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
};

export type ListSessionExerciseScoreRanksResult = {
  __typename?: 'ListSessionExerciseScoreRanksResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<ExerciseScoreRankTc>>>;
  total?: Maybe<Scalars['Int']>;
  userRank?: Maybe<ExerciseScoreRankTc>;
};

export type ListSessionExercisesFeedbacksInput = {
  exerciseId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
};

export type ListUsersInput = {
  filter?: Maybe<UserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListUsersResult = {
  __typename?: 'ListUsersResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<User>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ListWodBookEntriesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListWodBookEntriesResult = {
  __typename?: 'ListWodBookEntriesResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  result?: Maybe<Array<Maybe<WodBookEntry>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAhapChronoConfiguration?: Maybe<SessionExercise>;
  addAmrapChronoConfiguration?: Maybe<SessionExercise>;
  addDeviceToken?: Maybe<Scalars['String']>;
  addEmomChronoConfiguration?: Maybe<SessionExercise>;
  addForTimeChronoConfiguration?: Maybe<SessionExercise>;
  addPersonalRecordScores?: Maybe<PersonalRecord>;
  addProgramAvatar?: Maybe<ProgramAvatar>;
  addProgramSessionComment?: Maybe<Comment>;
  addProgramSessionCommentReply?: Maybe<Comment>;
  addPublicWodAhapChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodAmrapChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodBlock?: Maybe<ExerciseBlock>;
  addPublicWodEmomChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodForTimeChronoConfiguration?: Maybe<PublicWod>;
  addPublicWodScores?: Maybe<ScoreEntry>;
  addPublicWodTabataChronoConfiguration?: Maybe<PublicWod>;
  addSessionExerciseBlock?: Maybe<SessionExercise>;
  addSessionExerciseFeedback?: Maybe<SessionExerciseFeedBack>;
  addSessionExerciseScores?: Maybe<SessionExerciseUserScores>;
  addTabataChronoConfiguration?: Maybe<SessionExercise>;
  addUserProfilePicture?: Maybe<ProfilePicture>;
  addWodBookEntryBlock?: Maybe<WodBookEntry>;
  addWodBookEntryScores?: Maybe<WodBookEntry>;
  /** Returns blocked member id */
  blockChatMember?: Maybe<Scalars['String']>;
  createAccountLink?: Maybe<AccountLink>;
  createChatChannel?: Maybe<ChatChannel>;
  createCustomerPortalSession?: Maybe<CustomerPortalSession>;
  createPersonalRecord?: Maybe<PersonalRecord>;
  createProgram?: Maybe<Program>;
  createProgramCheckoutSession?: Maybe<ProgramCheckoutSession>;
  createProgramInvitation?: Maybe<ProgramInvitation>;
  createProgramJoinRequest?: Maybe<ProgramJoinRequest>;
  createProgramSession?: Maybe<ProgramSession>;
  createPublicWod?: Maybe<PublicWod>;
  createPublicWodCategory?: Maybe<PublicWodCategory>;
  createSessionExercise?: Maybe<SessionExercise>;
  createUser?: Maybe<OwnedUser>;
  createWodBookEntry?: Maybe<WodBookEntry>;
  /** Returns deleted chat message id */
  deleteChatMessage?: Maybe<Scalars['String']>;
  /** Returns deleted user id */
  deleteOwnedUser?: Maybe<Scalars['String']>;
  /** Returns deleted personal record id */
  deletePersonalRecord?: Maybe<Scalars['String']>;
  /** Returns delete program id */
  deleteProgram?: Maybe<Scalars['String']>;
  /** Returns deleted program session id */
  deleteProgramSession?: Maybe<Scalars['String']>;
  /** Returns deleted WOD id */
  deletePublicWod?: Maybe<Scalars['String']>;
  deletePublicWodBlock?: Maybe<Scalars['String']>;
  /** Returns deleted WOD category id */
  deletePublicWodCategory?: Maybe<Scalars['String']>;
  /** Returns deleted public wod score id */
  deletePublicWodScores?: Maybe<Scalars['String']>;
  deleteReportedChatMessage?: Maybe<ReportedChatMessage>;
  /** Returns deleted comment id */
  deleteReportedComment?: Maybe<Scalars['String']>;
  /** Returns deleted exercise id */
  deleteSessionExercise?: Maybe<Scalars['String']>;
  /** Returns deleted exercise block id */
  deleteSessionExerciseBlock?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<OwnedUser>;
  /** Returns deleted entry id */
  deleteWodBookEntry?: Maybe<Scalars['String']>;
  /** Returns deleted blockId */
  deleteWodBookEntryBlock?: Maybe<Scalars['String']>;
  deleteWodBookEntryScores?: Maybe<WodBookEntry>;
  /** Returns joined program id */
  joinOpenProgram?: Maybe<Scalars['String']>;
  /** Returns leaved chat channel id */
  leaveChatChannel?: Maybe<Scalars['String']>;
  leaveProgram?: Maybe<LeaveProgramResult>;
  likeProgramSessionComment?: Maybe<Comment>;
  publishProgramSession?: Maybe<ProgramSession>;
  /** Returns removed member id */
  removeChatMember?: Maybe<Scalars['String']>;
  /** Returns deleted comment id */
  removeComment?: Maybe<Scalars['String']>;
  removeProgramSessionCommentLike?: Maybe<Comment>;
  removeProgramUser?: Maybe<RemovedProgramUserResult>;
  replyToProgramInvitation?: Maybe<Program>;
  replyToProgramJoinRequest?: Maybe<Program>;
  /** Returns reported chat message id */
  reportChatMessage?: Maybe<Scalars['String']>;
  /** Returns reported comment id */
  reportComment?: Maybe<Scalars['String']>;
  saveSessionExerciseToWodBook?: Maybe<WodBookEntry>;
  sendChatImage?: Maybe<SendChatMessageResultTc>;
  sendChatMessage?: Maybe<SendChatMessageResultTc>;
  /** Returns given date */
  setChannelLatestReceipt?: Maybe<Scalars['Date']>;
  setReportedCommentProcessed?: Maybe<ReportedComment>;
  setReportedMessageProcessed?: Maybe<ReportedChatMessage>;
  setUserPrivateFields?: Maybe<OwnedUser>;
  setWodBookEntryExpectedScores?: Maybe<WodBookEntry>;
  toggleOwnedUserNotification?: Maybe<OwnedUser>;
  toggleProgramNotification?: Maybe<Program>;
  updateOwnedUserEmail?: Maybe<OwnedUser>;
  updateProgram?: Maybe<Program>;
  updateProgramSession?: Maybe<ProgramSession>;
  updatePublicWod?: Maybe<PublicWod>;
  updatePublicWodBlock?: Maybe<ExerciseBlock>;
  updatePublicWodBlocksOrder?: Maybe<PublicWod>;
  updatePublicWodCategory?: Maybe<PublicWodCategory>;
  updateSessionExercise?: Maybe<SessionExercise>;
  updateSessionExerciseBlock?: Maybe<SessionExercise>;
  updateSessionExerciseBlocksOrder?: Maybe<SessionExercise>;
  updateUser?: Maybe<OwnedUser>;
  updateWodBookEntry?: Maybe<WodBookEntry>;
  updateWodBookEntryBlock?: Maybe<WodBookEntry>;
  updateWodBookEntryBlocksOrder?: Maybe<WodBookEntry>;
  verifyUserEmail?: Maybe<OwnedUser>;
};


export type MutationAddAhapChronoConfigurationArgs = {
  input?: Maybe<AddAhapChronoConfigurationInput>;
};


export type MutationAddAmrapChronoConfigurationArgs = {
  input?: Maybe<AddAmrapChronoConfigurationInput>;
};


export type MutationAddDeviceTokenArgs = {
  input?: Maybe<AddDeviceTokenInput>;
};


export type MutationAddEmomChronoConfigurationArgs = {
  input?: Maybe<AddEmomChronoConfigurationInput>;
};


export type MutationAddForTimeChronoConfigurationArgs = {
  input?: Maybe<AddForTimeChronoConfigurationInput>;
};


export type MutationAddPersonalRecordScoresArgs = {
  input?: Maybe<AddPersonalRecordScoresInput>;
};


export type MutationAddProgramAvatarArgs = {
  file: Scalars['Upload'];
  programId: Scalars['ID'];
};


export type MutationAddProgramSessionCommentArgs = {
  input?: Maybe<AddProgramSessionCommentInput>;
};


export type MutationAddProgramSessionCommentReplyArgs = {
  input?: Maybe<AddProgramSessionCommentReplyInput>;
};


export type MutationAddPublicWodAhapChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodAhapChronoConfigurationInput>;
};


export type MutationAddPublicWodAmrapChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodAmrapChronoConfigurationInput>;
};


export type MutationAddPublicWodBlockArgs = {
  input?: Maybe<AddPublicWodBlockInput>;
};


export type MutationAddPublicWodEmomChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodEmomChronoConfigurationInput>;
};


export type MutationAddPublicWodForTimeChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodForTimeChronoConfigurationInput>;
};


export type MutationAddPublicWodScoresArgs = {
  input?: Maybe<AddPublicWodScoresInput>;
};


export type MutationAddPublicWodTabataChronoConfigurationArgs = {
  input?: Maybe<AddPublicWodTabataChronoConfigurationInput>;
};


export type MutationAddSessionExerciseBlockArgs = {
  input?: Maybe<AddSessionExerciseBlockInput>;
};


export type MutationAddSessionExerciseFeedbackArgs = {
  input?: Maybe<AddSessionExerciseFeedbackInput>;
};


export type MutationAddSessionExerciseScoresArgs = {
  input?: Maybe<AddSessionExerciseScoresInput>;
};


export type MutationAddTabataChronoConfigurationArgs = {
  input?: Maybe<AddTabataChronoConfigurationInput>;
};


export type MutationAddUserProfilePictureArgs = {
  file: Scalars['Upload'];
};


export type MutationAddWodBookEntryBlockArgs = {
  input?: Maybe<AddWodBookEntryBlockInput>;
};


export type MutationAddWodBookEntryScoresArgs = {
  input?: Maybe<AddWodBookEntryScoresInput>;
};


export type MutationBlockChatMemberArgs = {
  input?: Maybe<BlockChatMemberInput>;
};


export type MutationCreateAccountLinkArgs = {
  input?: Maybe<CreateAccountLinkInput>;
};


export type MutationCreateChatChannelArgs = {
  input?: Maybe<CreateChatChannelInput>;
};


export type MutationCreateCustomerPortalSessionArgs = {
  input?: Maybe<CreateCustomerPortalSessionInput>;
};


export type MutationCreatePersonalRecordArgs = {
  input?: Maybe<CreatePersonalRecordInput>;
};


export type MutationCreateProgramArgs = {
  input?: Maybe<CreateProgramInput>;
};


export type MutationCreateProgramCheckoutSessionArgs = {
  input?: Maybe<CreateProgramCheckoutSessionInput>;
};


export type MutationCreateProgramInvitationArgs = {
  input?: Maybe<CreateProgramInvitationInput>;
};


export type MutationCreateProgramJoinRequestArgs = {
  input?: Maybe<CreateProgramJoinRequestInput>;
};


export type MutationCreateProgramSessionArgs = {
  input?: Maybe<CreateProgramSessionInput>;
};


export type MutationCreatePublicWodArgs = {
  input?: Maybe<CreatePublicWodInput>;
};


export type MutationCreatePublicWodCategoryArgs = {
  input?: Maybe<CreatePublicWodCategoryInput>;
};


export type MutationCreateSessionExerciseArgs = {
  input?: Maybe<CreateSessionExerciseInput>;
};


export type MutationCreateUserArgs = {
  input?: Maybe<CreateUserInput>;
};


export type MutationCreateWodBookEntryArgs = {
  input?: Maybe<CreateWodBookEntryInput>;
};


export type MutationDeleteChatMessageArgs = {
  input?: Maybe<DeleteMessageInput>;
};


export type MutationDeleteOwnedUserArgs = {
  input?: Maybe<DeleteOwnedUserInput>;
};


export type MutationDeletePersonalRecordArgs = {
  input?: Maybe<DeletePersonalRecordInput>;
};


export type MutationDeleteProgramArgs = {
  input?: Maybe<DeleteProgramInput>;
};


export type MutationDeleteProgramSessionArgs = {
  input?: Maybe<DeleteProgramSessionInput>;
};


export type MutationDeletePublicWodArgs = {
  input?: Maybe<DeletePublicWodInput>;
};


export type MutationDeletePublicWodBlockArgs = {
  input?: Maybe<DeletePublicWodBlockInput>;
};


export type MutationDeletePublicWodCategoryArgs = {
  input?: Maybe<DeletePublicWodCategoryInput>;
};


export type MutationDeletePublicWodScoresArgs = {
  input?: Maybe<DeletePublicWodScoresInput>;
};


export type MutationDeleteReportedChatMessageArgs = {
  input?: Maybe<DeleteReportedChatMessageInput>;
};


export type MutationDeleteReportedCommentArgs = {
  input?: Maybe<DeleteReportedCommentInput>;
};


export type MutationDeleteSessionExerciseArgs = {
  input?: Maybe<DeleteSessionExerciseInput>;
};


export type MutationDeleteSessionExerciseBlockArgs = {
  input?: Maybe<DeleteSessionExerciseBlockInput>;
};


export type MutationDeleteWodBookEntryArgs = {
  input?: Maybe<DeleteWodBookEntryInput>;
};


export type MutationDeleteWodBookEntryBlockArgs = {
  input?: Maybe<DeleteWodBookEntryBlockInput>;
};


export type MutationDeleteWodBookEntryScoresArgs = {
  input?: Maybe<DeleteWodBookEntryScoresInput>;
};


export type MutationJoinOpenProgramArgs = {
  input?: Maybe<JoinOpenProgramInput>;
};


export type MutationLeaveChatChannelArgs = {
  input?: Maybe<LeaveChatChannelInput>;
};


export type MutationLeaveProgramArgs = {
  input?: Maybe<LeaveProgramInput>;
};


export type MutationLikeProgramSessionCommentArgs = {
  input?: Maybe<LikeProgramSessionCommentInput>;
};


export type MutationPublishProgramSessionArgs = {
  input?: Maybe<PublishProgramSessionInput>;
};


export type MutationRemoveChatMemberArgs = {
  input?: Maybe<RemoveChatMemberInput>;
};


export type MutationRemoveCommentArgs = {
  input?: Maybe<RemoveCommentInput>;
};


export type MutationRemoveProgramSessionCommentLikeArgs = {
  input?: Maybe<RemoveProgramSessionCommentLikeInput>;
};


export type MutationRemoveProgramUserArgs = {
  input?: Maybe<RemoveProgramUserInput>;
};


export type MutationReplyToProgramInvitationArgs = {
  input?: Maybe<ReplyToProgramInvitationInput>;
};


export type MutationReplyToProgramJoinRequestArgs = {
  input?: Maybe<ReplyToProgramJoinRequestInput>;
};


export type MutationReportChatMessageArgs = {
  input?: Maybe<ReportChatMessageInput>;
};


export type MutationReportCommentArgs = {
  input?: Maybe<ReportCommentInput>;
};


export type MutationSaveSessionExerciseToWodBookArgs = {
  input?: Maybe<SaveSessionExerciseToWodBookInput>;
};


export type MutationSendChatImageArgs = {
  input?: Maybe<SendChatImageInput>;
};


export type MutationSendChatMessageArgs = {
  input?: Maybe<SendChatMessageInput>;
};


export type MutationSetChannelLatestReceiptArgs = {
  input?: Maybe<SetChannelLatestReceiptInput>;
};


export type MutationSetReportedCommentProcessedArgs = {
  input?: Maybe<SetReportedCommentProcessedInput>;
};


export type MutationSetReportedMessageProcessedArgs = {
  input?: Maybe<SetReportedChatMessageProcessedInput>;
};


export type MutationSetUserPrivateFieldsArgs = {
  input?: Maybe<SetUserPrivateFieldsInput>;
};


export type MutationSetWodBookEntryExpectedScoresArgs = {
  input?: Maybe<SetWodBookEntryExpectedScoresInput>;
};


export type MutationToggleOwnedUserNotificationArgs = {
  input?: Maybe<ToggleOwnedUserNotificationInput>;
};


export type MutationToggleProgramNotificationArgs = {
  input?: Maybe<ToggleProgramNotificationInput>;
};


export type MutationUpdateOwnedUserEmailArgs = {
  input?: Maybe<UpdateOwnedUserEmailInput>;
};


export type MutationUpdateProgramArgs = {
  input?: Maybe<UpdateProgramInput>;
};


export type MutationUpdateProgramSessionArgs = {
  input?: Maybe<UpdateProgramSessionInput>;
};


export type MutationUpdatePublicWodArgs = {
  input?: Maybe<UpdatePublicWodInput>;
};


export type MutationUpdatePublicWodBlockArgs = {
  input?: Maybe<UpdatePublicWodBlockInput>;
};


export type MutationUpdatePublicWodBlocksOrderArgs = {
  input?: Maybe<UpdatePublicWodBlocksOrderInput>;
};


export type MutationUpdatePublicWodCategoryArgs = {
  input?: Maybe<UpdatePublicWodCategoryInput>;
};


export type MutationUpdateSessionExerciseArgs = {
  input?: Maybe<UpdateSessionExerciseInput>;
};


export type MutationUpdateSessionExerciseBlockArgs = {
  input?: Maybe<UpdateSessionExerciseBlockInput>;
};


export type MutationUpdateSessionExerciseBlocksOrderArgs = {
  input?: Maybe<UpdateSessionExerciseBlocksOrderInput>;
};


export type MutationUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>;
};


export type MutationUpdateWodBookEntryArgs = {
  input?: Maybe<UpdateWodBookEntryInput>;
};


export type MutationUpdateWodBookEntryBlockArgs = {
  input?: Maybe<UpdateWodBookEntryBlockInput>;
};


export type MutationUpdateWodBookEntryBlocksOrderArgs = {
  input?: Maybe<UpdateWodBookEntryBlocksOrderInput>;
};


export type MutationVerifyUserEmailArgs = {
  input?: Maybe<VerifyuserEmailInput>;
};

export enum NotificationType {
  CommentLiked = 'COMMENT_LIKED',
  CommentReplyAdded = 'COMMENT_REPLY_ADDED'
}

export type OwnedUser = {
  __typename?: 'OwnedUser';
  birthDate?: Maybe<BirthDate>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<FootSize>;
  gender?: Maybe<Gender>;
  height?: Maybe<Height>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<OwnedUserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  preferences?: Maybe<OwnedUserPreferences>;
  profile_picture?: Maybe<ProfilePicture>;
  programInvitations?: Maybe<Array<Maybe<ProgramInvitation>>>;
  programJoinRequests?: Maybe<Array<Maybe<ProgramJoinRequest>>>;
  provider?: Maybe<Provider>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  sportsRoom_name?: Maybe<SportsRoomName>;
  stripe_connect_account_id?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TshirtSize>;
  validated_stripe_connect_account?: Maybe<Scalars['Boolean']>;
  weight?: Maybe<Weight>;
};


export type OwnedUserPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type OwnedUserFilterInput = {
  nameOrNicknameOrEmail?: Maybe<StringFilterInput>;
};

export type OwnedUserLocation = {
  __typename?: 'OwnedUserLocation';
  city?: Maybe<City>;
  country?: Maybe<Country>;
};

export type OwnedUserPreferences = {
  __typename?: 'OwnedUserPreferences';
  lang?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<UserNotificationSettings>;
};

export type PersonalRecord = {
  __typename?: 'PersonalRecord';
  category?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  scoreEntries?: Maybe<Array<Maybe<ScoreEntry>>>;
};

export enum PrivateField {
  BirthDate = 'birthDate',
  City = 'city',
  Country = 'country',
  FootSize = 'foot_size',
  Height = 'height',
  SportsRoomName = 'sportsRoom_name',
  TshirtSize = 'tshirt_size',
  Weight = 'weight'
}

export type PrivateFieldInput = {
  field?: Maybe<PrivateField>;
  private?: Maybe<Scalars['Boolean']>;
};

export type ProfilePicture = {
  __typename?: 'ProfilePicture';
  url?: Maybe<Scalars['String']>;
};

export type Program = {
  __typename?: 'Program';
  avatar?: Maybe<ProgramAvatar>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notesNumber?: Maybe<Scalars['Int']>;
  notificationSettings?: Maybe<ProgramNotificationSettings>;
  open?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  subscriptionStatus?: Maybe<ProgramSubscriptionStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  users?: Maybe<Scalars['Int']>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type ProgramAvatar = {
  __typename?: 'ProgramAvatar';
  url?: Maybe<Scalars['String']>;
};

export type ProgramCheckoutSession = {
  __typename?: 'ProgramCheckoutSession';
  url?: Maybe<Scalars['String']>;
};

export type ProgramFilterInput = {
  nameOrTagsOrCreator?: Maybe<StringFilterInput>;
};

export type ProgramInvitation = {
  __typename?: 'ProgramInvitation';
  id: Scalars['ID'];
  program?: Maybe<Program>;
  recipient?: Maybe<User>;
};

export type ProgramJoinRequest = {
  __typename?: 'ProgramJoinRequest';
  applicant?: Maybe<User>;
  id: Scalars['ID'];
  program?: Maybe<Program>;
};

export type ProgramMember = {
  __typename?: 'ProgramMember';
  memberSince?: Maybe<Scalars['Date']>;
  profile?: Maybe<User>;
};

export type ProgramNotificationSettings = {
  __typename?: 'ProgramNotificationSettings';
  sessionCommentAdded?: Maybe<SessionCommentAddedNotificationSettings>;
};

export enum ProgramNotificationType {
  SessionCommentAdded = 'SESSION_COMMENT_ADDED'
}

export type ProgramSession = {
  __typename?: 'ProgramSession';
  exercises?: Maybe<Array<Maybe<SessionExercise>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  program?: Maybe<Program>;
};

export type ProgramSubscriptionStatus = {
  __typename?: 'ProgramSubscriptionStatus';
  cancel_at_period_end?: Maybe<Scalars['Boolean']>;
  current_period_end?: Maybe<Scalars['Date']>;
  current_period_start?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
};

/** Program type */
export enum ProgramType {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL',
  Specific = 'SPECIFIC'
}

export type ProgramUsersFilterInput = {
  nameOrNickname?: Maybe<StringFilterInput>;
};

/** SignIn provider */
export enum Provider {
  Cognito = 'Cognito',
  Facebook = 'Facebook',
  Google = 'Google',
  SignInWithApple = 'SignInWithApple'
}

export type PubNubGrantResult = {
  __typename?: 'PubNubGrantResult';
  channels?: Maybe<Array<Maybe<Scalars['String']>>>;
  granted?: Maybe<Scalars['Boolean']>;
  programChannels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PublicWod = {
  __typename?: 'PublicWod';
  category?: Maybe<PublicWodCategory>;
  createdAt?: Maybe<Scalars['Date']>;
  exercise?: Maybe<PublicWodExercise>;
  id: Scalars['ID'];
  latestUserScores?: Maybe<ListPublicWodUserScoresResult>;
  published?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type PublicWodLatestUserScoresArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type PublicWodCategory = {
  __typename?: 'PublicWodCategory';
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  translations?: Maybe<Array<Maybe<TranslatedCategory>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type PublicWodCategoryTranslationsArgs = {
  lang?: Maybe<Array<Maybe<AvailableLanguage>>>;
};

export type PublicWodCategoryNameInput = {
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type PublicWodExercise = {
  __typename?: 'PublicWodExercise';
  blockTranslations?: Maybe<Array<Maybe<TranslatedExerciseBlocks>>>;
  chronoConfiguration?: Maybe<ChronoConfiguration>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  nameTranslations?: Maybe<Array<Maybe<TranslatedExerciseName>>>;
};


export type PublicWodExerciseBlockTranslationsArgs = {
  lang?: Maybe<AvailableLanguage>;
};


export type PublicWodExerciseNameTranslationsArgs = {
  lang?: Maybe<AvailableLanguage>;
};

export type PublicWodExerciseInput = {
  name?: Maybe<PublicWodExerciseNameInput>;
};

export type PublicWodExerciseNameInput = {
  en?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
};

export type PublicWodScoreInput = {
  type: ScoreValueType;
  value: Scalars['Float'];
};

export type PublishProgramSessionInput = {
  id: Scalars['ID'];
};

export type PublishedProgramsStat = {
  __typename?: 'PublishedProgramsStat';
  private?: Maybe<Scalars['Int']>;
  public?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  grantPubNubAccess?: Maybe<PubNubGrantResult>;
  listChatChannelMembers?: Maybe<ListChatChannelMembersResult>;
  listChatChannelMessages?: Maybe<ListChatChannelMessagesResult>;
  listChatChannels?: Maybe<ListChatChannelsResult>;
  listCommentReplies?: Maybe<ListCommentRepliesResult>;
  listInvitableProgramUsers?: Maybe<ListInvitableProgramUsersResult>;
  listOwnedUsers?: Maybe<ListOwnedUsersResult>;
  listPersonalRecords?: Maybe<ListPersonalRecordsResultTc>;
  listProgramInvitations?: Maybe<ListProgramInvitationsResult>;
  listProgramJoinRequests?: Maybe<ListProgramJoinRequestsResult>;
  listProgramSessionComments?: Maybe<ListProgramSessionCommentsResult>;
  listProgramSessions?: Maybe<ListProgramSessionsResult>;
  listProgramUsers?: Maybe<ListProgramUsersResult>;
  listPrograms?: Maybe<ListProgramsResult>;
  listPublicWodCategories?: Maybe<ListPublicWodCategoriesResult>;
  listPublicWodUserScores?: Maybe<ListPublicWodUserScoresResult>;
  listPublicWods?: Maybe<ListPublicWodsResult>;
  listReportedChatMessages?: Maybe<ListReportedChatMessagesResult>;
  listReportedComments?: Maybe<ListReportedCommentsResult>;
  listSessionExerciseFeedbacks?: Maybe<ListSessionExerciseFeedbacksResult>;
  listSessionExerciseScoreRanks?: Maybe<ListSessionExerciseScoreRanksResult>;
  listUsers?: Maybe<ListUsersResult>;
  listWodBookEntries?: Maybe<ListWodBookEntriesResult>;
  me?: Maybe<OwnedUser>;
  showChatChannel?: Maybe<ChatChannel>;
  showComment?: Maybe<Comment>;
  showDailyStats?: Maybe<DailyStats>;
  showOwnedUser?: Maybe<OwnedUser>;
  showProgram?: Maybe<Program>;
  showProgramSession?: Maybe<ProgramSession>;
  showProgramSessionCommentsTotal?: Maybe<Scalars['Int']>;
  showPublicWod?: Maybe<PublicWod>;
  showPublicWodCategory?: Maybe<PublicWodCategory>;
  showReportedChatMessage?: Maybe<ReportedChatMessage>;
  showReportedComment?: Maybe<ReportedComment>;
  showTotalCreatedRessourcesEvolution?: Maybe<TotalCreatedRessourcesEvolution>;
  showTotalStats?: Maybe<TotalStats>;
  showUser?: Maybe<User>;
  showUserSessionExerciseFeedback?: Maybe<SessionExerciseFeedBack>;
  showUserSessionExerciseScores?: Maybe<SessionExerciseUserScores>;
  showWodBookEntry?: Maybe<WodBookEntry>;
};


export type QueryListChatChannelMembersArgs = {
  input?: Maybe<ListChatChannelMembersInput>;
};


export type QueryListChatChannelMessagesArgs = {
  input?: Maybe<ListChatChannelMessagesInput>;
};


export type QueryListChatChannelsArgs = {
  input?: Maybe<ListChatChannelsInput>;
};


export type QueryListCommentRepliesArgs = {
  input?: Maybe<ListCommentRepliesInput>;
};


export type QueryListInvitableProgramUsersArgs = {
  input?: Maybe<ListInvitableProgramUsersInput>;
};


export type QueryListOwnedUsersArgs = {
  input?: Maybe<ListOwnedUsersInput>;
};


export type QueryListPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};


export type QueryListProgramInvitationsArgs = {
  input?: Maybe<ListProgramInvitationsInput>;
};


export type QueryListProgramJoinRequestsArgs = {
  input?: Maybe<ListProgramJoinRequestsInput>;
};


export type QueryListProgramSessionCommentsArgs = {
  input?: Maybe<ListProgramSessionCommentsInput>;
};


export type QueryListProgramSessionsArgs = {
  input?: Maybe<ListProgramSessionsInput>;
};


export type QueryListProgramUsersArgs = {
  input?: Maybe<ListProgramUsersInput>;
};


export type QueryListProgramsArgs = {
  input?: Maybe<ListProgramsInput>;
};


export type QueryListPublicWodCategoriesArgs = {
  input?: Maybe<ListPublicWodCategoriesInput>;
};


export type QueryListPublicWodUserScoresArgs = {
  input?: Maybe<ListPublicWodUserScoresInput>;
};


export type QueryListPublicWodsArgs = {
  input?: Maybe<ListPublicWodsInput>;
};


export type QueryListReportedChatMessagesArgs = {
  input?: Maybe<ListReportedChatMessagesInput>;
};


export type QueryListReportedCommentsArgs = {
  input?: Maybe<ListReportedCommentsInput>;
};


export type QueryListSessionExerciseFeedbacksArgs = {
  input?: Maybe<ListSessionExercisesFeedbacksInput>;
};


export type QueryListSessionExerciseScoreRanksArgs = {
  input?: Maybe<ListSessionExerciseScoreRanksInput>;
};


export type QueryListUsersArgs = {
  input?: Maybe<ListUsersInput>;
};


export type QueryListWodBookEntriesArgs = {
  input?: Maybe<ListWodBookEntriesInput>;
};


export type QueryShowChatChannelArgs = {
  input?: Maybe<ShowChatChannelInput>;
};


export type QueryShowCommentArgs = {
  input?: Maybe<ShowCommentInput>;
};


export type QueryShowDailyStatsArgs = {
  input?: Maybe<ShowDailyStatsInput>;
};


export type QueryShowOwnedUserArgs = {
  input?: Maybe<ShowOwnedUserInput>;
};


export type QueryShowProgramArgs = {
  input?: Maybe<ShowProgramInput>;
};


export type QueryShowProgramSessionArgs = {
  input?: Maybe<ShowProgramSessionInput>;
};


export type QueryShowProgramSessionCommentsTotalArgs = {
  input?: Maybe<ShowProgramSessionCommentsTotalInput>;
};


export type QueryShowPublicWodArgs = {
  input?: Maybe<ShowPublicWodInput>;
};


export type QueryShowPublicWodCategoryArgs = {
  input?: Maybe<ShowPublicWodCategoryInput>;
};


export type QueryShowReportedChatMessageArgs = {
  input?: Maybe<ShowReportedChatMessageInput>;
};


export type QueryShowReportedCommentArgs = {
  input?: Maybe<ShowReportedCommentInput>;
};


export type QueryShowTotalCreatedRessourcesEvolutionArgs = {
  input?: Maybe<ShowTotalCreatedRessourcesEvolutionInput>;
};


export type QueryShowUserArgs = {
  input?: Maybe<ShowUserInput>;
};


export type QueryShowUserSessionExerciseFeedbackArgs = {
  input?: Maybe<ShowUserSessionExerciseFeedbackInput>;
};


export type QueryShowUserSessionExerciseScoresArgs = {
  input?: Maybe<ShowUserSessionExerciseScoresInput>;
};


export type QueryShowWodBookEntryArgs = {
  input?: Maybe<ShowWodBookEntryInput>;
};

export type RemoveChatMemberInput = {
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
};

export type RemoveCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type RemoveProgramSessionCommentLikeInput = {
  commentId: Scalars['ID'];
};

export type RemoveProgramUserInput = {
  programId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RemovedProgramUserResult = {
  __typename?: 'RemovedProgramUserResult';
  programId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ReportChatMessageInput = {
  messageId: Scalars['ID'];
};

export type ReportCommentInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type ReportedChatMessage = {
  __typename?: 'ReportedChatMessage';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  message?: Maybe<ChatMessage>;
  previousMessages?: Maybe<Array<Maybe<ChatMessage>>>;
  processed?: Maybe<Scalars['Boolean']>;
  reportedBy?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['Date'];
};

export type ReportedComment = {
  __typename?: 'ReportedComment';
  comment?: Maybe<Comment>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  processed?: Maybe<Scalars['Boolean']>;
  replyTo?: Maybe<Comment>;
  reportedBy?: Maybe<Scalars['ID']>;
  type?: Maybe<CommentType>;
  updatedAt: Scalars['Date'];
};

export enum RessourceEvolutionAvailable {
  CreatedFeedbacks = 'CREATED_FEEDBACKS',
  CreatedMessages = 'CREATED_MESSAGES',
  CreatedPersonalRecords = 'CREATED_PERSONAL_RECORDS',
  CreatedProfiles = 'CREATED_PROFILES',
  CreatedPrograms = 'CREATED_PROGRAMS',
  CreatedProgramSessionComments = 'CREATED_PROGRAM_SESSION_COMMENTS',
  CreatedScores = 'CREATED_SCORES',
  CreatedSessions = 'CREATED_SESSIONS',
  CreatedWodbookEntries = 'CREATED_WODBOOK_ENTRIES'
}

export enum Role {
  Admin = 'admin',
  BusinessUser = 'business_user',
  User = 'user'
}

export type SaveSessionExerciseToWodBookInput = {
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ScoreEntry = {
  __typename?: 'ScoreEntry';
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  scores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

/** Score value type */
export enum ScoreValueType {
  Calories = 'CALORIES',
  Distance = 'DISTANCE',
  Duration = 'DURATION',
  Load = 'LOAD',
  Repetitions = 'REPETITIONS'
}

export type SendChatImageInput = {
  channel: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image: Scalars['Upload'];
  tempId?: Maybe<Scalars['ID']>;
};

export type SendChatMessageInput = {
  channel: Scalars['ID'];
  content: Scalars['String'];
  tempId?: Maybe<Scalars['ID']>;
};

export type SendChatMessageResultTc = {
  __typename?: 'SendChatMessageResultTC';
  message?: Maybe<ChatMessage>;
  /** Given temporary id */
  tempId?: Maybe<Scalars['ID']>;
};

export type SessionCommentAddedNotificationSettings = {
  __typename?: 'SessionCommentAddedNotificationSettings';
  disabled?: Maybe<Scalars['Boolean']>;
};

export type SessionExercise = {
  __typename?: 'SessionExercise';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  chronoConfiguration?: Maybe<ChronoConfiguration>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  notesNumber?: Maybe<Scalars['Int']>;
  points?: Maybe<Scalars['Float']>;
  savedToWodBook?: Maybe<Scalars['Boolean']>;
  userFeedback?: Maybe<SessionExerciseFeedBack>;
  userScores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

export type SessionExerciseBlockImage = {
  __typename?: 'SessionExerciseBlockImage';
  url?: Maybe<Scalars['String']>;
};

/** Session exercise block type */
export enum SessionExerciseBlockType {
  Image = 'IMAGE',
  List = 'LIST',
  Text = 'TEXT'
}

export type SessionExerciseFeedBack = {
  __typename?: 'SessionExerciseFeedBack';
  comment?: Maybe<Scalars['String']>;
  exercise?: Maybe<SessionExercise>;
  feeling?: Maybe<Scalars['Float']>;
  member?: Maybe<User>;
  note?: Maybe<Scalars['Float']>;
};

export type SessionExerciseScoreInput = {
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
};

export type SessionExerciseUserScores = {
  __typename?: 'SessionExerciseUserScores';
  scores?: Maybe<Array<Maybe<ExerciseScore>>>;
};

export type SetChannelLatestReceiptInput = {
  channelId: Scalars['ID'];
  receivedAt?: Maybe<Scalars['Date']>;
};

export type SetReportedChatMessageProcessedInput = {
  reportId: Scalars['ID'];
};

export type SetReportedCommentProcessedInput = {
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type SetUserPrivateFieldsInput = {
  fields?: Maybe<Array<Maybe<PrivateFieldInput>>>;
};

export type SetWodBookEntryExpectedScoresInput = {
  entryId: Scalars['ID'];
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
};

export type ShowChatChannelInput = {
  channelId: Scalars['ID'];
};

export type ShowCommentInput = {
  id: Scalars['ID'];
  type?: Maybe<CommentType>;
};

export type ShowDailyStatsInput = {
  date?: Maybe<Scalars['Date']>;
};

export type ShowOwnedUserInput = {
  id: Scalars['ID'];
};

export type ShowProgramInput = {
  id?: Maybe<Scalars['ID']>;
};

export type ShowProgramSessionCommentsTotalInput = {
  sessionId: Scalars['ID'];
};

export type ShowProgramSessionInput = {
  sessionId: Scalars['ID'];
};

export type ShowPublicWodCategoryInput = {
  id: Scalars['ID'];
};

export type ShowPublicWodInput = {
  wodId: Scalars['ID'];
};

export type ShowReportedChatMessageInput = {
  messageId: Scalars['ID'];
};

export type ShowReportedCommentInput = {
  commentId?: Maybe<Scalars['ID']>;
  type?: Maybe<CommentType>;
};

export type ShowTotalCreatedRessourcesEvolutionInput = {
  range?: Maybe<EvolutionRange>;
  ressource: RessourceEvolutionAvailable;
};

export type ShowUserInput = {
  id?: Maybe<Scalars['ID']>;
  pubnub_uuid?: Maybe<Scalars['String']>;
};

export type ShowUserSessionExerciseFeedbackInput = {
  exerciseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ShowUserSessionExerciseScoresInput = {
  exerciseId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ShowWodBookEntryInput = {
  entryId: Scalars['ID'];
};

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SportsRoomName = {
  __typename?: 'SportsRoomName';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['String']>;
};

export type StringFilterInput = {
  contains?: Maybe<Scalars['String']>;
};

export enum TShirtSize {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxs = 'XXS',
  Xxxl = 'XXXL'
}

export type TabataChronoConfiguration = {
  __typename?: 'TabataChronoConfiguration';
  roundConfiguration?: Maybe<ChronoRoundConfiguration>;
  setConfiguration?: Maybe<ChronoSetConfiguration>;
  type?: Maybe<ChronoConfigurationType>;
};

export type ToggleOwnedUserNotificationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<NotificationType>;
};

export type ToggleProgramNotificationInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  notification?: Maybe<ProgramNotificationType>;
  programId: Scalars['ID'];
};

export type TotalCreatedProfileForDate = {
  __typename?: 'TotalCreatedProfileForDate';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
};

export type TotalCreatedRessourcesEvolution = {
  __typename?: 'TotalCreatedRessourcesEvolution';
  counts?: Maybe<Array<Maybe<TotalCreatedProfileForDate>>>;
  ressource?: Maybe<RessourceEvolutionAvailable>;
};

export type TotalStats = {
  __typename?: 'TotalStats';
  addedFeedbacks?: Maybe<Scalars['Int']>;
  addedScores?: Maybe<Scalars['Int']>;
  averageWodBookEntriesPerUser?: Maybe<Scalars['Float']>;
  chatChannels?: Maybe<ChatChannelsStat>;
  chatMessages?: Maybe<ChatMessagesStat>;
  chronoConfigurations?: Maybe<Scalars['Int']>;
  createdPersonalRecords?: Maybe<Scalars['Int']>;
  createdProfiles?: Maybe<Scalars['Int']>;
  createdPrograms?: Maybe<Scalars['Int']>;
  createdSessions?: Maybe<Scalars['Int']>;
  createdWodBookEntries?: Maybe<Scalars['Int']>;
  emailVerifiedProfiles?: Maybe<Scalars['Int']>;
  profilesWithPersonalData?: Maybe<Scalars['Int']>;
  profilesWithPicture?: Maybe<Scalars['Int']>;
  programSessionComments?: Maybe<Scalars['Int']>;
  publishedPrograms?: Maybe<PublishedProgramsStat>;
  unPublishedPrograms?: Maybe<Scalars['Int']>;
  usersProgramFollowing?: Maybe<UsersProgramFollowingStat>;
  usersWithAtLeastOnePersonalRecord?: Maybe<Scalars['Int']>;
  usersWithAtLeastOneWodBookEntry?: Maybe<Scalars['Int']>;
};

export type TranslatedCategory = {
  __typename?: 'TranslatedCategory';
  lang?: Maybe<AvailableLanguage>;
  name?: Maybe<Scalars['String']>;
};

export type TranslatedExerciseBlocks = {
  __typename?: 'TranslatedExerciseBlocks';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  lang?: Maybe<AvailableLanguage>;
};

export type TranslatedExerciseName = {
  __typename?: 'TranslatedExerciseName';
  lang?: Maybe<AvailableLanguage>;
  name?: Maybe<Scalars['String']>;
};

export type TshirtSize = {
  __typename?: 'TshirtSize';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<TShirtSize>;
};

export type UpdateOwnedUserEmailInput = {
  newEmail?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdateProgramInput = {
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
};

export type UpdateProgramSessionInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublicWodBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['Upload']>;
  lang: AvailableLanguage;
  type: SessionExerciseBlockType;
  wodId: Scalars['ID'];
};

export type UpdatePublicWodBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  lang: AvailableLanguage;
  wodId: Scalars['ID'];
};

export type UpdatePublicWodCategoryInput = {
  /** WOD category id */
  id: Scalars['ID'];
  name?: Maybe<PublicWodCategoryNameInput>;
  published?: Maybe<Scalars['Boolean']>;
};

export type UpdatePublicWodInput = {
  /** New category id */
  categoryId?: Maybe<Scalars['ID']>;
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<PublicWodExerciseNameInput>;
  wodId: Scalars['ID'];
};

export type UpdateSessionExerciseBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
};

export type UpdateSessionExerciseBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type UpdateSessionExerciseInput = {
  exerciseId: Scalars['ID'];
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>>>;
  name?: Maybe<Scalars['String']>;
  sessionId: Scalars['ID'];
};

export type UpdateUserInput = {
  birthDate?: Maybe<Scalars['Date']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};

export type UpdateWodBookEntryBlockInput = {
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
};

export type UpdateWodBookEntryBlocksOrderInput = {
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>>>;
  entryId: Scalars['ID'];
};

export type UpdateWodBookEntryInput = {
  entryId: Scalars['ID'];
  exerciseName?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  birthDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  followedPrograms?: Maybe<Array<Maybe<Program>>>;
  foot_size?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<UserLocation>;
  nickname?: Maybe<Scalars['String']>;
  ownedPrograms?: Maybe<Array<Maybe<Program>>>;
  personalRecords?: Maybe<ListPersonalRecordsResultTc>;
  profile_picture?: Maybe<ProfilePicture>;
  pubnub_uuid?: Maybe<Scalars['String']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  tshirt_size?: Maybe<TShirtSize>;
  weight?: Maybe<Scalars['Float']>;
};


export type UserPersonalRecordsArgs = {
  input?: Maybe<ListPersonalRecordsInput>;
};

export type UserFilterInput = {
  nameOrNickname?: Maybe<StringFilterInput>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UserNotificationSettings = {
  __typename?: 'UserNotificationSettings';
  commentLiked?: Maybe<CommentLikedNotificationSettings>;
  commentReplyAdded?: Maybe<CommentReplyAddedNotificationSettings>;
};

export type UsersProgramFollowingStat = {
  __typename?: 'UsersProgramFollowingStat';
  fiveAndMore?: Maybe<Scalars['Int']>;
  four?: Maybe<Scalars['Int']>;
  one?: Maybe<Scalars['Int']>;
  three?: Maybe<Scalars['Int']>;
  two?: Maybe<Scalars['Int']>;
};

export type VerifyuserEmailInput = {
  code?: Maybe<Scalars['String']>;
};

export type Weight = {
  __typename?: 'Weight';
  private?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Float']>;
};

export type WodBookEntry = {
  __typename?: 'WodBookEntry';
  createdAt?: Maybe<Scalars['Date']>;
  exercise?: Maybe<WodBookExercise>;
  id: Scalars['ID'];
  scoreEntries?: Maybe<Array<Maybe<ScoreEntry>>>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type WodBookEntryScoreInput = {
  type: ScoreValueType;
  value: Scalars['Float'];
};

export type WodBookExercise = {
  __typename?: 'WodBookExercise';
  blocks?: Maybe<Array<Maybe<ExerciseBlock>>>;
  createdAt?: Maybe<Scalars['Date']>;
  expectedScores?: Maybe<Array<Maybe<ExerciseExpectedScore>>>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type ListProgramInvitationsInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ListProgramJoinRequestsInput = {
  id: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ReplyToProgramInvitationInput = {
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type ReplyToProgramJoinRequestInput = {
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type ChatChannelFragment = (
  { __typename?: 'ChatChannel' }
  & Pick<ChatChannel, 'id' | 'name' | 'pubnub_id' | 'totalMembers' | 'unreadMessagesCount' | 'type' | 'blockedBy' | 'coach'>
  & { avatar?: Maybe<(
    { __typename?: 'ChatAvatar' }
    & Pick<ChatAvatar, 'url'>
  )>, latestMessage?: Maybe<(
    { __typename?: 'LatestChannelMessage' }
    & Pick<LatestChannelMessage, 'id' | 'content' | 'createdAt' | 'deleted' | 'updatedAt'>
    & { attachment?: Maybe<(
      { __typename?: 'ChatMessageAttachment' }
      & Pick<ChatMessageAttachment, 'type' | 'url'>
    )>, sender?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'pubnub_uuid'>
    )> }
  )>, members?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'pubnub_uuid'>
    & { profile_picture?: Maybe<(
      { __typename?: 'ProfilePicture' }
      & Pick<ProfilePicture, 'url'>
    )> }
  )>>> }
);

export type ListChatChannelsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListChatChannelsQuery = (
  { __typename?: 'Query' }
  & { listChatChannels?: Maybe<(
    { __typename?: 'ListChatChannelsResult' }
    & Pick<ListChatChannelsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ChatChannel' }
      & ChatChannelFragment
    )>>> }
  )> }
);

export type ListChatMembersAndMessagesQueryVariables = Exact<{
  channelId: Scalars['ID'];
  messagesOffset?: Maybe<Scalars['Int']>;
}>;


export type ListChatMembersAndMessagesQuery = (
  { __typename?: 'Query' }
  & { listChatChannelMessages?: Maybe<(
    { __typename?: 'ListChatChannelMessagesResult' }
    & Pick<ListChatChannelMessagesResult, 'limit' | 'total' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'sender' | 'deleted' | 'channel' | 'content' | 'createdAt' | 'updatedAt'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'type' | 'url'>
      )> }
    )>>> }
  )>, listChatChannelMembers?: Maybe<(
    { __typename?: 'ListChatChannelMembersResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMember' }
      & Pick<ChatMember, 'id' | 'firstName' | 'lastName' | 'nickname' | 'pubnub_uuid'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>>> }
  )> }
);

export type ListChatChannelMessagesQueryVariables = Exact<{
  channelId: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListChatChannelMessagesQuery = (
  { __typename?: 'Query' }
  & { listChatChannelMessages?: Maybe<(
    { __typename?: 'ListChatChannelMessagesResult' }
    & Pick<ListChatChannelMessagesResult, 'limit' | 'total' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'sender' | 'deleted' | 'channel' | 'content' | 'createdAt' | 'updatedAt'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'type' | 'url'>
      )> }
    )>>> }
  )> }
);

export type ListChatChannelMembersQueryVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type ListChatChannelMembersQuery = (
  { __typename?: 'Query' }
  & { listChatChannelMembers?: Maybe<(
    { __typename?: 'ListChatChannelMembersResult' }
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ChatMember' }
      & Pick<ChatMember, 'id' | 'firstName' | 'lastName' | 'nickname' | 'pubnub_uuid' | 'isCoach'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>>> }
  )> }
);

export type ShowChatChannelQueryVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type ShowChatChannelQuery = (
  { __typename?: 'Query' }
  & { showChatChannel?: Maybe<(
    { __typename?: 'ChatChannel' }
    & ChatChannelFragment
  )> }
);

export type ShowChatMemberQueryVariables = Exact<{
  pubnub_uuid?: Maybe<Scalars['String']>;
}>;


export type ShowChatMemberQuery = (
  { __typename?: 'Query' }
  & { showUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'pubnub_uuid'>
    & { profile_picture?: Maybe<(
      { __typename?: 'ProfilePicture' }
      & Pick<ProfilePicture, 'url'>
    )> }
  )> }
);

export type CreateChatChannelMutationVariables = Exact<{
  membersIds?: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
}>;


export type CreateChatChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChatChannel?: Maybe<(
    { __typename?: 'ChatChannel' }
    & ChatChannelFragment
  )> }
);

export type SendChatMessageMutationVariables = Exact<{
  channelId: Scalars['ID'];
  content: Scalars['String'];
  tempId?: Maybe<Scalars['ID']>;
}>;


export type SendChatMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendChatMessage?: Maybe<(
    { __typename?: 'SendChatMessageResultTC' }
    & Pick<SendChatMessageResultTc, 'tempId'>
    & { message?: Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'sender' | 'deleted' | 'channel' | 'content' | 'createdAt' | 'updatedAt'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'type' | 'url'>
      )> }
    )> }
  )> }
);

export type SendChatImageMutationVariables = Exact<{
  channelId: Scalars['ID'];
  image: Scalars['Upload'];
  tempId?: Maybe<Scalars['ID']>;
}>;


export type SendChatImageMutation = (
  { __typename?: 'Mutation' }
  & { sendChatImage?: Maybe<(
    { __typename?: 'SendChatMessageResultTC' }
    & Pick<SendChatMessageResultTc, 'tempId'>
    & { message?: Maybe<(
      { __typename?: 'ChatMessage' }
      & Pick<ChatMessage, 'id' | 'sender' | 'deleted' | 'channel' | 'content' | 'createdAt' | 'updatedAt'>
      & { attachment?: Maybe<(
        { __typename?: 'ChatMessageAttachment' }
        & Pick<ChatMessageAttachment, 'type' | 'url'>
      )> }
    )> }
  )> }
);

export type SetChannelLatestReceiptMutationVariables = Exact<{
  channelId: Scalars['ID'];
  receivedAt: Scalars['Date'];
}>;


export type SetChannelLatestReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setChannelLatestReceipt'>
);

export type LeaveChatChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type LeaveChatChannelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveChatChannel'>
);

export type RemoveChatMemberMutationVariables = Exact<{
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type RemoveChatMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeChatMember'>
);

export type ReportChatMessageMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type ReportChatMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportChatMessage'>
);

export type DeleteChatMessageMutationVariables = Exact<{
  messageId: Scalars['ID'];
}>;


export type DeleteChatMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteChatMessage'>
);

export type BlockChatMemberMutationVariables = Exact<{
  channelId: Scalars['ID'];
  memberId: Scalars['ID'];
}>;


export type BlockChatMemberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'blockChatMember'>
);

export type ListPersonalRecordsQueryVariables = Exact<{
  category?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['ID']>;
}>;


export type ListPersonalRecordsQuery = (
  { __typename?: 'Query' }
  & { listPersonalRecords?: Maybe<(
    { __typename?: 'ListPersonalRecordsResultTC' }
    & Pick<ListPersonalRecordsResultTc, 'total' | 'limit' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'PersonalRecord' }
      & Pick<PersonalRecord, 'id' | 'exercise' | 'category'>
      & { scoreEntries?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreEntry' }
        & Pick<ScoreEntry, 'date'>
        & { scores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseScore' }
          & Pick<ExerciseScore, 'type' | 'value'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type CreatePersonalRecordMutationVariables = Exact<{
  category?: Maybe<Scalars['String']>;
  exercise?: Maybe<Scalars['String']>;
  type?: Maybe<ScoreValueType>;
  date?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
}>;


export type CreatePersonalRecordMutation = (
  { __typename?: 'Mutation' }
  & { createPersonalRecord?: Maybe<(
    { __typename?: 'PersonalRecord' }
    & Pick<PersonalRecord, 'id' | 'exercise' | 'category'>
    & { scoreEntries?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreEntry' }
      & Pick<ScoreEntry, 'date'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & Pick<ExerciseScore, 'type' | 'value'>
      )>>> }
    )>>> }
  )> }
);

export type DeletePersonalRecordMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePersonalRecordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePersonalRecord'>
);

export type AddPersonalRecordsMutationVariables = Exact<{
  id: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
  value?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
}>;


export type AddPersonalRecordsMutation = (
  { __typename?: 'Mutation' }
  & { addPersonalRecordScores?: Maybe<(
    { __typename?: 'PersonalRecord' }
    & Pick<PersonalRecord, 'category' | 'exercise' | 'id'>
    & { scoreEntries?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreEntry' }
      & Pick<ScoreEntry, 'date'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & Pick<ExerciseScore, 'type' | 'value'>
      )>>> }
    )>>> }
  )> }
);

export type ProgramFragment = (
  { __typename?: 'Program' }
  & Pick<Program, 'description' | 'duration' | 'id' | 'name' | 'price' | 'private' | 'published' | 'notesNumber' | 'points' | 'tags' | 'type' | 'users' | 'usersLimit' | 'open'>
  & { avatar?: Maybe<(
    { __typename?: 'ProgramAvatar' }
    & Pick<ProgramAvatar, 'url'>
  )>, creator?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    & { profile_picture?: Maybe<(
      { __typename?: 'ProfilePicture' }
      & Pick<ProfilePicture, 'url'>
    )> }
  )>, notificationSettings?: Maybe<(
    { __typename?: 'ProgramNotificationSettings' }
    & { sessionCommentAdded?: Maybe<(
      { __typename?: 'SessionCommentAddedNotificationSettings' }
      & Pick<SessionCommentAddedNotificationSettings, 'disabled'>
    )> }
  )>, subscriptionStatus?: Maybe<(
    { __typename?: 'ProgramSubscriptionStatus' }
    & Pick<ProgramSubscriptionStatus, 'current_period_end' | 'current_period_start' | 'cancel_at_period_end' | 'status'>
  )> }
);

export type ListProgramSessionsDayQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['ID']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
}>;


export type ListProgramSessionsDayQuery = (
  { __typename?: 'Query' }
  & { listProgramSessions?: Maybe<(
    { __typename?: 'ListProgramSessionsResult' }
    & Pick<ListProgramSessionsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProgramSession' }
      & Pick<ProgramSession, 'id' | 'name' | 'plannedAt'>
      & { exercises?: Maybe<Array<Maybe<(
        { __typename?: 'SessionExercise' }
        & Pick<SessionExercise, 'id' | 'name' | 'notesNumber' | 'points'>
        & { expectedScores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseExpectedScore' }
          & Pick<ExerciseExpectedScore, 'sort' | 'type'>
        )>>> }
      )>>>, program?: Maybe<(
        { __typename?: 'Program' }
        & Pick<Program, 'id' | 'name'>
        & { avatar?: Maybe<(
          { __typename?: 'ProgramAvatar' }
          & Pick<ProgramAvatar, 'url'>
        )>, creator?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'nickname'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ListProgramsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<Scalars['String']>;
}>;


export type ListProgramsQuery = (
  { __typename?: 'Query' }
  & { listPrograms?: Maybe<(
    { __typename?: 'ListProgramsResult' }
    & Pick<ListProgramsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'description' | 'duration' | 'price' | 'notesNumber' | 'points' | 'tags' | 'users' | 'usersLimit' | 'open'>
      & { avatar?: Maybe<(
        { __typename?: 'ProgramAvatar' }
        & Pick<ProgramAvatar, 'url'>
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type ListProgramSessionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
  programId?: Maybe<Scalars['ID']>;
}>;


export type ListProgramSessionsQuery = (
  { __typename?: 'Query' }
  & { listProgramSessions?: Maybe<(
    { __typename?: 'ListProgramSessionsResult' }
    & Pick<ListProgramSessionsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProgramSession' }
      & Pick<ProgramSession, 'id' | 'name' | 'plannedAt'>
      & { exercises?: Maybe<Array<Maybe<(
        { __typename?: 'SessionExercise' }
        & Pick<SessionExercise, 'id' | 'name' | 'notesNumber' | 'points'>
        & { blocks?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseBlock' }
          & Pick<ExerciseBlock, 'id'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type ShowProgramQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type ShowProgramQuery = (
  { __typename?: 'Query' }
  & { showProgram?: Maybe<(
    { __typename?: 'Program' }
    & ProgramFragment
  )> }
);

export type ListProgramJoinRequestsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
}>;


export type ListProgramJoinRequestsQuery = (
  { __typename?: 'Query' }
  & { listProgramJoinRequests?: Maybe<(
    { __typename?: 'ListProgramJoinRequestsResult' }
    & Pick<ListProgramJoinRequestsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProgramJoinRequest' }
      & Pick<ProgramJoinRequest, 'id'>
      & { applicant?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
        & { profile_picture?: Maybe<(
          { __typename?: 'ProfilePicture' }
          & Pick<ProfilePicture, 'url'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ListProgramUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
}>;


export type ListProgramUsersQuery = (
  { __typename?: 'Query' }
  & { listProgramUsers?: Maybe<(
    { __typename?: 'ListProgramUsersResult' }
    & Pick<ListProgramUsersResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'ProgramMember' }
      & Pick<ProgramMember, 'memberSince'>
      & { profile?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
        & { profile_picture?: Maybe<(
          { __typename?: 'ProfilePicture' }
          & Pick<ProfilePicture, 'url'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ListInvitableProgramUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
}>;


export type ListInvitableProgramUsersQuery = (
  { __typename?: 'Query' }
  & { listInvitableProgramUsers?: Maybe<(
    { __typename?: 'ListInvitableProgramUsersResult' }
    & Pick<ListInvitableProgramUsersResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>>> }
  )> }
);

export type UpdateProgramMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateProgramMutation = (
  { __typename?: 'Mutation' }
  & { updateProgram?: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'description' | 'duration' | 'name' | 'price' | 'private' | 'published' | 'points' | 'notesNumber' | 'tags' | 'type' | 'users' | 'usersLimit' | 'open'>
    & { avatar?: Maybe<(
      { __typename?: 'ProgramAvatar' }
      & Pick<ProgramAvatar, 'url'>
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'nickname'>
    )> }
  )> }
);

export type CreateProgramMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  private?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  type?: Maybe<ProgramType>;
  usersLimit?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['Upload']>;
  open?: Maybe<Scalars['Boolean']>;
  published?: Maybe<Scalars['Boolean']>;
}>;


export type CreateProgramMutation = (
  { __typename?: 'Mutation' }
  & { createProgram?: Maybe<(
    { __typename?: 'Program' }
    & ProgramFragment
  )> }
);

export type AddProgramAvatarMutationVariables = Exact<{
  file: Scalars['Upload'];
  programId: Scalars['ID'];
}>;


export type AddProgramAvatarMutation = (
  { __typename?: 'Mutation' }
  & { addProgramAvatar?: Maybe<(
    { __typename?: 'ProgramAvatar' }
    & Pick<ProgramAvatar, 'url'>
  )> }
);

export type CreateProgramJoinRequestMutationVariables = Exact<{
  program: Scalars['ID'];
}>;


export type CreateProgramJoinRequestMutation = (
  { __typename?: 'Mutation' }
  & { createProgramJoinRequest?: Maybe<(
    { __typename?: 'ProgramJoinRequest' }
    & Pick<ProgramJoinRequest, 'id'>
  )> }
);

export type CreateProgramInvitationMutationVariables = Exact<{
  program: Scalars['ID'];
  recipient: Scalars['ID'];
}>;


export type CreateProgramInvitationMutation = (
  { __typename?: 'Mutation' }
  & { createProgramInvitation?: Maybe<(
    { __typename?: 'ProgramInvitation' }
    & Pick<ProgramInvitation, 'id'>
  )> }
);

export type ReplyToProgramJoinRequestMutationVariables = Exact<{
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
}>;


export type ReplyToProgramJoinRequestMutation = (
  { __typename?: 'Mutation' }
  & { replyToProgramJoinRequest?: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id'>
  )> }
);

export type ReplyToProgramInvitationMutationVariables = Exact<{
  accept?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
}>;


export type ReplyToProgramInvitationMutation = (
  { __typename?: 'Mutation' }
  & { replyToProgramInvitation?: Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id'>
  )> }
);

export type LeaveProgramMutationVariables = Exact<{
  programId: Scalars['ID'];
}>;


export type LeaveProgramMutation = (
  { __typename?: 'Mutation' }
  & { leaveProgram?: Maybe<(
    { __typename?: 'LeaveProgramResult' }
    & Pick<LeaveProgramResult, 'programId'>
  )> }
);

export type RemoveProgramUserMutationVariables = Exact<{
  userId: Scalars['ID'];
  programId: Scalars['ID'];
}>;


export type RemoveProgramUserMutation = (
  { __typename?: 'Mutation' }
  & { removeProgramUser?: Maybe<(
    { __typename?: 'RemovedProgramUserResult' }
    & Pick<RemovedProgramUserResult, 'programId' | 'userId'>
  )> }
);

export type DeleteProgramMutationVariables = Exact<{
  programId: Scalars['ID'];
}>;


export type DeleteProgramMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProgram'>
);

export type LikeProgramSessionCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type LikeProgramSessionCommentMutation = (
  { __typename?: 'Mutation' }
  & { likeProgramSessionComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'canLike' | 'likes'>
  )> }
);

export type RemoveProgramSessionCommentLikeMutationVariables = Exact<{
  commentId: Scalars['ID'];
}>;


export type RemoveProgramSessionCommentLikeMutation = (
  { __typename?: 'Mutation' }
  & { removeProgramSessionCommentLike?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'canLike' | 'likes'>
  )> }
);

export type RemoveCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeComment'>
);

export type ReportCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  type?: Maybe<CommentType>;
}>;


export type ReportCommentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportComment'>
);

export type AddProgramSessionCommentMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
}>;


export type AddProgramSessionCommentMutation = (
  { __typename?: 'Mutation' }
  & { addProgramSessionComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
    & { attachment?: Maybe<(
      { __typename?: 'CommentAttachment' }
      & Pick<CommentAttachment, 'type' | 'url'>
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>, replies?: Maybe<(
      { __typename?: 'CommentReplies' }
      & Pick<CommentReplies, 'limit' | 'offset' | 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
        & { attachment?: Maybe<(
          { __typename?: 'CommentAttachment' }
          & Pick<CommentAttachment, 'type' | 'url'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
          & { profile_picture?: Maybe<(
            { __typename?: 'ProfilePicture' }
            & Pick<ProfilePicture, 'url'>
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type AddProgramSessionCommentReplyMutationVariables = Exact<{
  commentId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
}>;


export type AddProgramSessionCommentReplyMutation = (
  { __typename?: 'Mutation' }
  & { addProgramSessionCommentReply?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike'>
    & { attachment?: Maybe<(
      { __typename?: 'CommentAttachment' }
      & Pick<CommentAttachment, 'type' | 'url'>
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>, replies?: Maybe<(
      { __typename?: 'CommentReplies' }
      & Pick<CommentReplies, 'limit' | 'offset' | 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike'>
        & { attachment?: Maybe<(
          { __typename?: 'CommentAttachment' }
          & Pick<CommentAttachment, 'type' | 'url'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
          & { profile_picture?: Maybe<(
            { __typename?: 'ProfilePicture' }
            & Pick<ProfilePicture, 'url'>
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type ScoreFragmentFragment = (
  { __typename?: 'ExerciseScore' }
  & Pick<ExerciseScore, 'type' | 'value'>
);

export type ShowProgramSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ShowProgramSessionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'showProgramSessionCommentsTotal'>
  & { showProgramSession?: Maybe<(
    { __typename?: 'ProgramSession' }
    & Pick<ProgramSession, 'id' | 'name' | 'plannedAt'>
    & { exercises?: Maybe<Array<Maybe<(
      { __typename?: 'SessionExercise' }
      & Pick<SessionExercise, 'id' | 'name' | 'savedToWodBook' | 'notesNumber' | 'points'>
      & { blocks?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseBlock' }
        & Pick<ExerciseBlock, 'id' | 'content' | 'elements' | 'order' | 'type'>
        & { image?: Maybe<(
          { __typename?: 'SessionExerciseBlockImage' }
          & Pick<SessionExerciseBlockImage, 'url'>
        )> }
      )>>>, expectedScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseExpectedScore' }
        & Pick<ExerciseExpectedScore, 'sort' | 'type'>
      )>>>, userScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & ScoreFragmentFragment
      )>>>, userFeedback?: Maybe<(
        { __typename?: 'SessionExerciseFeedBack' }
        & Pick<SessionExerciseFeedBack, 'comment' | 'feeling' | 'note'>
      )>, chronoConfiguration?: Maybe<(
        { __typename?: 'AhapChronoConfiguration' }
        & Pick<AhapChronoConfiguration, 'type'>
        & { ahaps?: Maybe<Array<Maybe<(
          { __typename?: 'AhapConfiguration' }
          & Pick<AhapConfiguration, 'timeLimit' | 'restTime'>
        )>>> }
      ) | (
        { __typename?: 'AmrapChronoConfiguration' }
        & Pick<AmrapChronoConfiguration, 'type'>
        & { roundConfiguration?: Maybe<(
          { __typename?: 'ChronoRoundConfiguration' }
          & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
        )>, setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      ) | (
        { __typename?: 'EmomChronoConfiguration' }
        & Pick<EmomChronoConfiguration, 'type'>
        & { emoms?: Maybe<Array<Maybe<(
          { __typename?: 'EmomConfiguration' }
          & Pick<EmomConfiguration, 'everyTime' | 'restTime' | 'roundsNumber' | 'whileTime'>
        )>>> }
      ) | (
        { __typename?: 'ForTimeChronoConfiguration' }
        & Pick<ForTimeChronoConfiguration, 'type' | 'timeLimit'>
        & { setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      ) | (
        { __typename?: 'TabataChronoConfiguration' }
        & Pick<TabataChronoConfiguration, 'type'>
        & { roundConfiguration?: Maybe<(
          { __typename?: 'ChronoRoundConfiguration' }
          & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
        )>, setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      )> }
    )>>>, program?: Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'type'>
      & { avatar?: Maybe<(
        { __typename?: 'ProgramAvatar' }
        & Pick<ProgramAvatar, 'url'>
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  )> }
);

export type ListProgramSessionCommentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
}>;


export type ListProgramSessionCommentsQuery = (
  { __typename?: 'Query' }
  & { listProgramSessionComments?: Maybe<(
    { __typename?: 'ListProgramSessionCommentsResult' }
    & Pick<ListProgramSessionCommentsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
      & { attachment?: Maybe<(
        { __typename?: 'CommentAttachment' }
        & Pick<CommentAttachment, 'type' | 'url'>
      )>, author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
        & { profile_picture?: Maybe<(
          { __typename?: 'ProfilePicture' }
          & Pick<ProfilePicture, 'url'>
        )> }
      )>, replies?: Maybe<(
        { __typename?: 'CommentReplies' }
        & Pick<CommentReplies, 'limit' | 'offset' | 'total'>
        & { result?: Maybe<Array<Maybe<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
          & { attachment?: Maybe<(
            { __typename?: 'CommentAttachment' }
            & Pick<CommentAttachment, 'type' | 'url'>
          )>, author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
            & { profile_picture?: Maybe<(
              { __typename?: 'ProfilePicture' }
              & Pick<ProfilePicture, 'url'>
            )> }
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ShowCommentQueryVariables = Exact<{
  type?: Maybe<CommentType>;
  id: Scalars['ID'];
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ShowCommentQuery = (
  { __typename?: 'Query' }
  & { showComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
    & { attachment?: Maybe<(
      { __typename?: 'CommentAttachment' }
      & Pick<CommentAttachment, 'type' | 'url'>
    )>, author?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>, replies?: Maybe<(
      { __typename?: 'CommentReplies' }
      & Pick<CommentReplies, 'limit' | 'offset' | 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'content' | 'likes' | 'replyable' | 'type' | 'canLike' | 'createdAt'>
        & { attachment?: Maybe<(
          { __typename?: 'CommentAttachment' }
          & Pick<CommentAttachment, 'type' | 'url'>
        )>, author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
          & { profile_picture?: Maybe<(
            { __typename?: 'ProfilePicture' }
            & Pick<ProfilePicture, 'url'>
          )> }
        )> }
      )>>> }
    )> }
  )> }
);

export type CreateProgramSessionMutationVariables = Exact<{
  programId: Scalars['ID'];
  plannedAt?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
}>;


export type CreateProgramSessionMutation = (
  { __typename?: 'Mutation' }
  & { createProgramSession?: Maybe<(
    { __typename?: 'ProgramSession' }
    & Pick<ProgramSession, 'id' | 'plannedAt' | 'name'>
    & { exercises?: Maybe<Array<Maybe<(
      { __typename?: 'SessionExercise' }
      & Pick<SessionExercise, 'id' | 'name' | 'notesNumber' | 'points'>
      & { blocks?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseBlock' }
        & Pick<ExerciseBlock, 'id' | 'content' | 'elements' | 'order' | 'type'>
        & { image?: Maybe<(
          { __typename?: 'SessionExerciseBlockImage' }
          & Pick<SessionExerciseBlockImage, 'url'>
        )> }
      )>>>, expectedScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseExpectedScore' }
        & Pick<ExerciseExpectedScore, 'sort' | 'type'>
      )>>>, userFeedback?: Maybe<(
        { __typename?: 'SessionExerciseFeedBack' }
        & Pick<SessionExerciseFeedBack, 'comment' | 'feeling' | 'note'>
      )> }
    )>>>, program?: Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name'>
      & { avatar?: Maybe<(
        { __typename?: 'ProgramAvatar' }
        & Pick<ProgramAvatar, 'url'>
      )>, creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  )> }
);

export type CreateSessionExerciseMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>> | Maybe<ExpectedScoreInput>>;
}>;


export type CreateSessionExerciseMutation = (
  { __typename?: 'Mutation' }
  & { createSessionExercise?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id' | 'name'>
    & { blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'content' | 'order' | 'type' | 'elements'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>> }
  )> }
);

export type AddSessionExerciseBlockMutationVariables = Exact<{
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
}>;


export type AddSessionExerciseBlockMutation = (
  { __typename?: 'Mutation' }
  & { addSessionExerciseBlock?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id' | 'name'>
    & { blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'content' | 'order' | 'type' | 'elements'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>>, expectedScores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseExpectedScore' }
      & Pick<ExerciseExpectedScore, 'sort' | 'type'>
    )>>> }
  )> }
);

export type UpdateSessionExerciseBlockMutationVariables = Exact<{
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  exerciseId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  sessionId: Scalars['ID'];
  type?: Maybe<SessionExerciseBlockType>;
}>;


export type UpdateSessionExerciseBlockMutation = (
  { __typename?: 'Mutation' }
  & { updateSessionExerciseBlock?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id' | 'name'>
    & { blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'content' | 'order' | 'type' | 'elements'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>>, expectedScores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseExpectedScore' }
      & Pick<ExerciseExpectedScore, 'sort' | 'type'>
    )>>> }
  )> }
);

export type AddSessionExerciseScoresMutationVariables = Exact<{
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  scores?: Maybe<Array<Maybe<SessionExerciseScoreInput>> | Maybe<SessionExerciseScoreInput>>;
}>;


export type AddSessionExerciseScoresMutation = (
  { __typename?: 'Mutation' }
  & { addSessionExerciseScores?: Maybe<(
    { __typename?: 'SessionExerciseUserScores' }
    & { scores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseScore' }
      & ScoreFragmentFragment
    )>>> }
  )> }
);

export type AddSessionExerciseFeedbackMutationVariables = Exact<{
  sessionId: Scalars['ID'];
  exerciseId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  feeling?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['Float']>;
}>;


export type AddSessionExerciseFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { addSessionExerciseFeedback?: Maybe<(
    { __typename?: 'SessionExerciseFeedBack' }
    & Pick<SessionExerciseFeedBack, 'feeling' | 'note' | 'comment'>
  )> }
);

export type UpdateSessionExerciseMutationVariables = Exact<{
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>> | Maybe<ExpectedScoreInput>>;
  name?: Maybe<Scalars['String']>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type UpdateSessionExerciseMutation = (
  { __typename?: 'Mutation' }
  & { updateSessionExercise?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id' | 'name'>
    & { blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'content' | 'order' | 'type' | 'elements'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>>, expectedScores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseExpectedScore' }
      & Pick<ExerciseExpectedScore, 'sort' | 'type'>
    )>>> }
  )> }
);

export type UpdateProgramSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  plannedAt?: Maybe<Scalars['Date']>;
}>;


export type UpdateProgramSessionMutation = (
  { __typename?: 'Mutation' }
  & { updateProgramSession?: Maybe<(
    { __typename?: 'ProgramSession' }
    & Pick<ProgramSession, 'id' | 'plannedAt' | 'name'>
  )> }
);

export type DeleteProgramSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProgramSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProgramSession'>
);

export type AddAhapChronoConfigurationMutationVariables = Exact<{
  ahaps?: Maybe<Array<Maybe<AhapConfigurationInput>> | Maybe<AhapConfigurationInput>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type AddAhapChronoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addAhapChronoConfiguration?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id'>
    & { chronoConfiguration?: Maybe<(
      { __typename?: 'AhapChronoConfiguration' }
      & Pick<AhapChronoConfiguration, 'type'>
      & { ahaps?: Maybe<Array<Maybe<(
        { __typename?: 'AhapConfiguration' }
        & Pick<AhapConfiguration, 'timeLimit' | 'restTime'>
      )>>> }
    ) | { __typename?: 'AmrapChronoConfiguration' } | { __typename?: 'EmomChronoConfiguration' } | { __typename?: 'ForTimeChronoConfiguration' } | { __typename?: 'TabataChronoConfiguration' }> }
  )> }
);

export type AddAmrapChronoConfigurationMutationVariables = Exact<{
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type AddAmrapChronoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addAmrapChronoConfiguration?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id'>
    & { chronoConfiguration?: Maybe<{ __typename?: 'AhapChronoConfiguration' } | (
      { __typename?: 'AmrapChronoConfiguration' }
      & Pick<AmrapChronoConfiguration, 'type'>
      & { roundConfiguration?: Maybe<(
        { __typename?: 'ChronoRoundConfiguration' }
        & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
      )>, setConfiguration?: Maybe<(
        { __typename?: 'ChronoSetConfiguration' }
        & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
      )> }
    ) | { __typename?: 'EmomChronoConfiguration' } | { __typename?: 'ForTimeChronoConfiguration' } | { __typename?: 'TabataChronoConfiguration' }> }
  )> }
);

export type AddEmomChronoConfigurationMutationVariables = Exact<{
  emoms?: Maybe<Array<Maybe<EmomConfigurationInput>> | Maybe<EmomConfigurationInput>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type AddEmomChronoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addEmomChronoConfiguration?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id'>
    & { chronoConfiguration?: Maybe<{ __typename?: 'AhapChronoConfiguration' } | { __typename?: 'AmrapChronoConfiguration' } | (
      { __typename?: 'EmomChronoConfiguration' }
      & Pick<EmomChronoConfiguration, 'type'>
      & { emoms?: Maybe<Array<Maybe<(
        { __typename?: 'EmomConfiguration' }
        & Pick<EmomConfiguration, 'everyTime' | 'restTime' | 'roundsNumber' | 'whileTime'>
      )>>> }
    ) | { __typename?: 'ForTimeChronoConfiguration' } | { __typename?: 'TabataChronoConfiguration' }> }
  )> }
);

export type AddForTimeChronoConfigurationMutationVariables = Exact<{
  timeLimit?: Maybe<Scalars['Int']>;
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type AddForTimeChronoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addForTimeChronoConfiguration?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id'>
    & { chronoConfiguration?: Maybe<{ __typename?: 'AhapChronoConfiguration' } | { __typename?: 'AmrapChronoConfiguration' } | { __typename?: 'EmomChronoConfiguration' } | (
      { __typename?: 'ForTimeChronoConfiguration' }
      & Pick<ForTimeChronoConfiguration, 'type' | 'timeLimit'>
      & { setConfiguration?: Maybe<(
        { __typename?: 'ChronoSetConfiguration' }
        & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
      )> }
    ) | { __typename?: 'TabataChronoConfiguration' }> }
  )> }
);

export type AddTabataChronoConfigurationMutationVariables = Exact<{
  setConfiguration?: Maybe<ChronoSetConfigurationInput>;
  roundConfiguration?: Maybe<ChronoRoundConfigurationInput>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type AddTabataChronoConfigurationMutation = (
  { __typename?: 'Mutation' }
  & { addTabataChronoConfiguration?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id'>
    & { chronoConfiguration?: Maybe<{ __typename?: 'AhapChronoConfiguration' } | { __typename?: 'AmrapChronoConfiguration' } | { __typename?: 'EmomChronoConfiguration' } | { __typename?: 'ForTimeChronoConfiguration' } | (
      { __typename?: 'TabataChronoConfiguration' }
      & Pick<TabataChronoConfiguration, 'type'>
      & { roundConfiguration?: Maybe<(
        { __typename?: 'ChronoRoundConfiguration' }
        & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
      )>, setConfiguration?: Maybe<(
        { __typename?: 'ChronoSetConfiguration' }
        & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
      )> }
    )> }
  )> }
);

export type ListSessionExerciseScoreRanksQueryVariables = Exact<{
  exerciseId: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sessionId: Scalars['ID'];
  type?: Maybe<ScoreValueType>;
}>;


export type ListSessionExerciseScoreRanksQuery = (
  { __typename?: 'Query' }
  & { listSessionExerciseScoreRanks?: Maybe<(
    { __typename?: 'ListSessionExerciseScoreRanksResult' }
    & Pick<ListSessionExerciseScoreRanksResult, 'limit' | 'offset' | 'total'>
    & { userRank?: Maybe<(
      { __typename?: 'ExerciseScoreRankTC' }
      & Pick<ExerciseScoreRankTc, 'position' | 'score'>
    )>, result?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseScoreRankTC' }
      & Pick<ExerciseScoreRankTc, 'position' | 'score'>
      & { member?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
        & { profile_picture?: Maybe<(
          { __typename?: 'ProfilePicture' }
          & Pick<ProfilePicture, 'url'>
        )> }
      )> }
    )>>> }
  )> }
);

export type ShowUserSessionExerciseFeedbackQueryVariables = Exact<{
  exerciseId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type ShowUserSessionExerciseFeedbackQuery = (
  { __typename?: 'Query' }
  & { showUserSessionExerciseFeedback?: Maybe<(
    { __typename?: 'SessionExerciseFeedBack' }
    & Pick<SessionExerciseFeedBack, 'comment' | 'feeling' | 'note'>
  )> }
);

export type DeleteSessionExerciseMutationVariables = Exact<{
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type DeleteSessionExerciseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSessionExercise'>
);

export type DeleteSessionExerciseBlockMutationVariables = Exact<{
  blockId: Scalars['ID'];
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type DeleteSessionExerciseBlockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSessionExerciseBlock'>
);

export type UpdateSessionExerciseBlocksOrderMutationVariables = Exact<{
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>> | Maybe<BlockOrderInput>>;
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
}>;


export type UpdateSessionExerciseBlocksOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateSessionExerciseBlocksOrder?: Maybe<(
    { __typename?: 'SessionExercise' }
    & Pick<SessionExercise, 'id' | 'name'>
    & { blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'content' | 'order' | 'type' | 'elements'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>>, expectedScores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseExpectedScore' }
      & Pick<ExerciseExpectedScore, 'sort' | 'type'>
    )>>> }
  )> }
);

export type CreateProgramCheckoutSessionMutationVariables = Exact<{
  programId?: Maybe<Scalars['ID']>;
  cancelUrl?: Maybe<Scalars['String']>;
  returnUrl?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
}>;


export type CreateProgramCheckoutSessionMutation = (
  { __typename?: 'Mutation' }
  & { createProgramCheckoutSession?: Maybe<(
    { __typename?: 'ProgramCheckoutSession' }
    & Pick<ProgramCheckoutSession, 'url'>
  )> }
);

export type CreateCustomerPortalSessionMutationVariables = Exact<{
  returnUrl?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerPortalSessionMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerPortalSession?: Maybe<(
    { __typename?: 'CustomerPortalSession' }
    & Pick<CustomerPortalSession, 'url'>
  )> }
);

export type CreateAccountLinkMutationVariables = Exact<{
  returnUrl?: Maybe<Scalars['String']>;
}>;


export type CreateAccountLinkMutation = (
  { __typename?: 'Mutation' }
  & { createAccountLink?: Maybe<(
    { __typename?: 'AccountLink' }
    & Pick<AccountLink, 'url'>
  )> }
);

export type OwnedUserFragment = (
  { __typename?: 'OwnedUser' }
  & Pick<OwnedUser, 'id' | 'role' | 'pubnub_uuid' | 'stripe_customer_id' | 'validated_stripe_connect_account' | 'firstName' | 'lastName' | 'nickname' | 'provider' | 'email' | 'email_verified' | 'gender'>
  & { birthDate?: Maybe<(
    { __typename?: 'BirthDate' }
    & Pick<BirthDate, 'private' | 'value'>
  )>, height?: Maybe<(
    { __typename?: 'Height' }
    & Pick<Height, 'private' | 'value'>
  )>, weight?: Maybe<(
    { __typename?: 'Weight' }
    & Pick<Weight, 'private' | 'value'>
  )>, location?: Maybe<(
    { __typename?: 'OwnedUserLocation' }
    & { city?: Maybe<(
      { __typename?: 'City' }
      & Pick<City, 'private' | 'value'>
    )>, country?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'private' | 'value'>
    )> }
  )>, profile_picture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'url'>
  )>, sportsRoom_name?: Maybe<(
    { __typename?: 'SportsRoomName' }
    & Pick<SportsRoomName, 'private' | 'value'>
  )>, followedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'points' | 'price' | 'notesNumber' | 'users'>
    & { avatar?: Maybe<(
      { __typename?: 'ProgramAvatar' }
      & Pick<ProgramAvatar, 'url'>
    )> }
  )>>>, ownedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'published' | 'private' | 'price' | 'points' | 'notesNumber' | 'users'>
    & { avatar?: Maybe<(
      { __typename?: 'ProgramAvatar' }
      & Pick<ProgramAvatar, 'url'>
    )> }
  )>>>, programInvitations?: Maybe<Array<Maybe<(
    { __typename?: 'ProgramInvitation' }
    & Pick<ProgramInvitation, 'id'>
    & { program?: Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'points' | 'notesNumber' | 'users' | 'price' | 'description'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'nickname'>
      )>, avatar?: Maybe<(
        { __typename?: 'ProgramAvatar' }
        & Pick<ProgramAvatar, 'url'>
      )> }
    )> }
  )>>>, programJoinRequests?: Maybe<Array<Maybe<(
    { __typename?: 'ProgramJoinRequest' }
    & Pick<ProgramJoinRequest, 'id'>
    & { program?: Maybe<(
      { __typename?: 'Program' }
      & Pick<Program, 'id' | 'name' | 'points' | 'notesNumber' | 'users' | 'price' | 'description'>
      & { creator?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'nickname'>
      )>, avatar?: Maybe<(
        { __typename?: 'ProgramAvatar' }
        & Pick<ProgramAvatar, 'url'>
      )> }
    )> }
  )>>>, preferences?: Maybe<(
    { __typename?: 'OwnedUserPreferences' }
    & { notificationSettings?: Maybe<(
      { __typename?: 'UserNotificationSettings' }
      & { commentLiked?: Maybe<(
        { __typename?: 'CommentLikedNotificationSettings' }
        & Pick<CommentLikedNotificationSettings, 'disabled'>
      )>, commentReplyAdded?: Maybe<(
        { __typename?: 'CommentReplyAddedNotificationSettings' }
        & Pick<CommentReplyAddedNotificationSettings, 'disabled'>
      )> }
    )> }
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'birthDate' | 'height' | 'weight' | 'sportsRoom_name' | 'gender'>
  & { location?: Maybe<(
    { __typename?: 'UserLocation' }
    & Pick<UserLocation, 'city' | 'country'>
  )>, profile_picture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'url'>
  )>, followedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'notesNumber' | 'points' | 'users' | 'usersLimit' | 'tags' | 'description' | 'private' | 'price'>
    & { avatar?: Maybe<(
      { __typename?: 'ProgramAvatar' }
      & Pick<ProgramAvatar, 'url'>
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'nickname'>
    )> }
  )>>>, ownedPrograms?: Maybe<Array<Maybe<(
    { __typename?: 'Program' }
    & Pick<Program, 'id' | 'name' | 'notesNumber' | 'points' | 'users' | 'usersLimit' | 'tags' | 'private' | 'price' | 'description'>
    & { avatar?: Maybe<(
      { __typename?: 'ProgramAvatar' }
      & Pick<ProgramAvatar, 'url'>
    )>, creator?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'nickname'>
    )> }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'OwnedUser' }
    & OwnedUserFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'OwnedUser' }
    & OwnedUserFragment
  )> }
);

export type ListUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers?: Maybe<(
    { __typename?: 'ListUsersResult' }
    & Pick<ListUsersResult, 'limit' | 'total' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      & { profile_picture?: Maybe<(
        { __typename?: 'ProfilePicture' }
        & Pick<ProfilePicture, 'url'>
      )> }
    )>>> }
  )> }
);

export type ShowUserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  pubnub_uuid?: Maybe<Scalars['String']>;
}>;


export type ShowUserQuery = (
  { __typename?: 'Query' }
  & { showUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  sportsRoom_name?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  gender?: Maybe<Gender>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'OwnedUser' }
    & OwnedUserFragment
  )> }
);

export type AddUserProfilePictureMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type AddUserProfilePictureMutation = (
  { __typename?: 'Mutation' }
  & { addUserProfilePicture?: Maybe<(
    { __typename?: 'ProfilePicture' }
    & Pick<ProfilePicture, 'url'>
  )> }
);

export type VerifyUserEmailMutationVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type VerifyUserEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyUserEmail?: Maybe<(
    { __typename?: 'OwnedUser' }
    & Pick<OwnedUser, 'id'>
  )> }
);

export type SetUserPrivateFieldsMutationVariables = Exact<{
  fields?: Maybe<Array<Maybe<PrivateFieldInput>> | Maybe<PrivateFieldInput>>;
}>;


export type SetUserPrivateFieldsMutation = (
  { __typename?: 'Mutation' }
  & { setUserPrivateFields?: Maybe<(
    { __typename?: 'OwnedUser' }
    & OwnedUserFragment
  )> }
);

export type WodBookEntryFragment = (
  { __typename?: 'WodBookEntry' }
  & Pick<WodBookEntry, 'id' | 'createdAt' | 'updatedAt'>
  & { exercise?: Maybe<(
    { __typename?: 'WodBookExercise' }
    & Pick<WodBookExercise, 'name'>
    & { expectedScores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseExpectedScore' }
      & Pick<ExerciseExpectedScore, 'sort' | 'type'>
    )>>>, blocks?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseBlock' }
      & Pick<ExerciseBlock, 'id' | 'order' | 'type' | 'content'>
      & { image?: Maybe<(
        { __typename?: 'SessionExerciseBlockImage' }
        & Pick<SessionExerciseBlockImage, 'url'>
      )> }
    )>>> }
  )>, scoreEntries?: Maybe<Array<Maybe<(
    { __typename?: 'ScoreEntry' }
    & Pick<ScoreEntry, 'id' | 'date'>
    & { scores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseScore' }
      & Pick<ExerciseScore, 'type' | 'value'>
    )>>> }
  )>>> }
);

export type ListWodBookEntriesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListWodBookEntriesQuery = (
  { __typename?: 'Query' }
  & { listWodBookEntries?: Maybe<(
    { __typename?: 'ListWodBookEntriesResult' }
    & Pick<ListWodBookEntriesResult, 'total' | 'limit' | 'offset'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'WodBookEntry' }
      & Pick<WodBookEntry, 'id' | 'createdAt' | 'updatedAt'>
      & { exercise?: Maybe<(
        { __typename?: 'WodBookExercise' }
        & Pick<WodBookExercise, 'name'>
        & { blocks?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseBlock' }
          & Pick<ExerciseBlock, 'content' | 'type' | 'order'>
          & { image?: Maybe<(
            { __typename?: 'SessionExerciseBlockImage' }
            & Pick<SessionExerciseBlockImage, 'url'>
          )> }
        )>>>, expectedScores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseExpectedScore' }
          & Pick<ExerciseExpectedScore, 'type' | 'sort'>
        )>>> }
      )>, scoreEntries?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreEntry' }
        & Pick<ScoreEntry, 'id' | 'date'>
        & { scores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseScore' }
          & Pick<ExerciseScore, 'value' | 'type'>
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type ShowWodBookEntryQueryVariables = Exact<{
  entryId: Scalars['ID'];
}>;


export type ShowWodBookEntryQuery = (
  { __typename?: 'Query' }
  & { showWodBookEntry?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type ListPublicWodCategoriesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPublicWodCategoriesQuery = (
  { __typename?: 'Query' }
  & { listPublicWodCategories?: Maybe<(
    { __typename?: 'ListPublicWodCategoriesResult' }
    & Pick<ListPublicWodCategoriesResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'PublicWodCategory' }
      & Pick<PublicWodCategory, 'id' | 'createdAt' | 'updatedAt' | 'published'>
      & { translations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedCategory' }
        & Pick<TranslatedCategory, 'lang' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type ListPublicWodsQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type ListPublicWodsQuery = (
  { __typename?: 'Query' }
  & { listPublicWods?: Maybe<(
    { __typename?: 'ListPublicWodsResult' }
    & Pick<ListPublicWodsResult, 'limit' | 'offset' | 'total'>
    & { result?: Maybe<Array<Maybe<(
      { __typename?: 'PublicWod' }
      & Pick<PublicWod, 'id' | 'createdAt' | 'published'>
      & { category?: Maybe<(
        { __typename?: 'PublicWodCategory' }
        & { translations?: Maybe<Array<Maybe<(
          { __typename?: 'TranslatedCategory' }
          & Pick<TranslatedCategory, 'lang' | 'name'>
        )>>> }
      )>, exercise?: Maybe<(
        { __typename?: 'PublicWodExercise' }
        & { expectedScores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseExpectedScore' }
          & Pick<ExerciseExpectedScore, 'type' | 'sort'>
        )>>>, nameTranslations?: Maybe<Array<Maybe<(
          { __typename?: 'TranslatedExerciseName' }
          & Pick<TranslatedExerciseName, 'lang' | 'name'>
        )>>> }
      )>, latestUserScores?: Maybe<(
        { __typename?: 'ListPublicWodUserScoresResult' }
        & Pick<ListPublicWodUserScoresResult, 'limit' | 'offset' | 'total'>
        & { result?: Maybe<Array<Maybe<(
          { __typename?: 'ScoreEntry' }
          & Pick<ScoreEntry, 'date' | 'id'>
          & { scores?: Maybe<Array<Maybe<(
            { __typename?: 'ExerciseScore' }
            & Pick<ExerciseScore, 'type' | 'value'>
          )>>> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type ShowPublicWodQueryVariables = Exact<{
  wodId: Scalars['ID'];
}>;


export type ShowPublicWodQuery = (
  { __typename?: 'Query' }
  & { showPublicWod?: Maybe<(
    { __typename?: 'PublicWod' }
    & Pick<PublicWod, 'id' | 'createdAt' | 'published'>
    & { category?: Maybe<(
      { __typename?: 'PublicWodCategory' }
      & { translations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedCategory' }
        & Pick<TranslatedCategory, 'lang' | 'name'>
      )>>> }
    )>, exercise?: Maybe<(
      { __typename?: 'PublicWodExercise' }
      & { blockTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseBlocks' }
        & Pick<TranslatedExerciseBlocks, 'lang'>
        & { blocks?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseBlock' }
          & Pick<ExerciseBlock, 'content' | 'elements' | 'id' | 'order' | 'type'>
          & { image?: Maybe<(
            { __typename?: 'SessionExerciseBlockImage' }
            & Pick<SessionExerciseBlockImage, 'url'>
          )> }
        )>>> }
      )>>>, expectedScores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseExpectedScore' }
        & Pick<ExerciseExpectedScore, 'type' | 'sort'>
      )>>>, chronoConfiguration?: Maybe<(
        { __typename?: 'AhapChronoConfiguration' }
        & Pick<AhapChronoConfiguration, 'type'>
        & { ahaps?: Maybe<Array<Maybe<(
          { __typename?: 'AhapConfiguration' }
          & Pick<AhapConfiguration, 'timeLimit' | 'restTime'>
        )>>> }
      ) | (
        { __typename?: 'AmrapChronoConfiguration' }
        & Pick<AmrapChronoConfiguration, 'type'>
        & { roundConfiguration?: Maybe<(
          { __typename?: 'ChronoRoundConfiguration' }
          & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
        )>, setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      ) | (
        { __typename?: 'EmomChronoConfiguration' }
        & Pick<EmomChronoConfiguration, 'type'>
        & { emoms?: Maybe<Array<Maybe<(
          { __typename?: 'EmomConfiguration' }
          & Pick<EmomConfiguration, 'everyTime' | 'restTime' | 'roundsNumber' | 'whileTime'>
        )>>> }
      ) | (
        { __typename?: 'ForTimeChronoConfiguration' }
        & Pick<ForTimeChronoConfiguration, 'type' | 'timeLimit'>
        & { setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      ) | (
        { __typename?: 'TabataChronoConfiguration' }
        & Pick<TabataChronoConfiguration, 'type'>
        & { roundConfiguration?: Maybe<(
          { __typename?: 'ChronoRoundConfiguration' }
          & Pick<ChronoRoundConfiguration, 'number' | 'restTime' | 'timeLimit'>
        )>, setConfiguration?: Maybe<(
          { __typename?: 'ChronoSetConfiguration' }
          & Pick<ChronoSetConfiguration, 'number' | 'restTime'>
        )> }
      )>, nameTranslations?: Maybe<Array<Maybe<(
        { __typename?: 'TranslatedExerciseName' }
        & Pick<TranslatedExerciseName, 'lang' | 'name'>
      )>>> }
    )>, latestUserScores?: Maybe<(
      { __typename?: 'ListPublicWodUserScoresResult' }
      & Pick<ListPublicWodUserScoresResult, 'limit' | 'offset' | 'total'>
      & { result?: Maybe<Array<Maybe<(
        { __typename?: 'ScoreEntry' }
        & Pick<ScoreEntry, 'date' | 'id'>
        & { scores?: Maybe<Array<Maybe<(
          { __typename?: 'ExerciseScore' }
          & Pick<ExerciseScore, 'type' | 'value'>
        )>>> }
      )>>> }
    )> }
  )> }
);

export type SaveSessionExerciseToWodBookMutationVariables = Exact<{
  exerciseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
}>;


export type SaveSessionExerciseToWodBookMutation = (
  { __typename?: 'Mutation' }
  & { saveSessionExerciseToWodBook?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & Pick<WodBookEntry, 'id' | 'createdAt' | 'updatedAt'>
    & { exercise?: Maybe<(
      { __typename?: 'WodBookExercise' }
      & Pick<WodBookExercise, 'name'>
      & { blocks?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseBlock' }
        & Pick<ExerciseBlock, 'order' | 'type' | 'content'>
        & { image?: Maybe<(
          { __typename?: 'SessionExerciseBlockImage' }
          & Pick<SessionExerciseBlockImage, 'url'>
        )> }
      )>>> }
    )>, scoreEntries?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreEntry' }
      & Pick<ScoreEntry, 'id' | 'date'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & Pick<ExerciseScore, 'value' | 'type'>
      )>>> }
    )>>> }
  )> }
);

export type DeleteWodBookEntryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWodBookEntryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWodBookEntry'>
);

export type AddWodBookEntryScoresMutationVariables = Exact<{
  id: Scalars['ID'];
  scores?: Maybe<Array<Maybe<WodBookEntryScoreInput>> | Maybe<WodBookEntryScoreInput>>;
  date?: Maybe<Scalars['Date']>;
}>;


export type AddWodBookEntryScoresMutation = (
  { __typename?: 'Mutation' }
  & { addWodBookEntryScores?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & Pick<WodBookEntry, 'id'>
    & { scoreEntries?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreEntry' }
      & Pick<ScoreEntry, 'id' | 'date'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & Pick<ExerciseScore, 'value' | 'type'>
      )>>> }
    )>>> }
  )> }
);

export type DeleteWodBookEntryScoresMutationVariables = Exact<{
  scoreId: Scalars['ID'];
  entryId: Scalars['ID'];
}>;


export type DeleteWodBookEntryScoresMutation = (
  { __typename?: 'Mutation' }
  & { deleteWodBookEntryScores?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & Pick<WodBookEntry, 'id'>
    & { scoreEntries?: Maybe<Array<Maybe<(
      { __typename?: 'ScoreEntry' }
      & Pick<ScoreEntry, 'id' | 'date'>
      & { scores?: Maybe<Array<Maybe<(
        { __typename?: 'ExerciseScore' }
        & Pick<ExerciseScore, 'value' | 'type'>
      )>>> }
    )>>> }
  )> }
);

export type CreateWodBookEntryMutationVariables = Exact<{
  exerciseName: Scalars['String'];
}>;


export type CreateWodBookEntryMutation = (
  { __typename?: 'Mutation' }
  & { createWodBookEntry?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type UpdateWodBookEntryMutationVariables = Exact<{
  entryId: Scalars['ID'];
  exerciseName: Scalars['String'];
}>;


export type UpdateWodBookEntryMutation = (
  { __typename?: 'Mutation' }
  & { updateWodBookEntry?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type AddWodBookEntryBlockMutationVariables = Exact<{
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
}>;


export type AddWodBookEntryBlockMutation = (
  { __typename?: 'Mutation' }
  & { addWodBookEntryBlock?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type UpdateWodBookEntryBlockMutationVariables = Exact<{
  blockId: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  elements?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  entryId: Scalars['ID'];
  image?: Maybe<Scalars['Upload']>;
  type?: Maybe<SessionExerciseBlockType>;
}>;


export type UpdateWodBookEntryBlockMutation = (
  { __typename?: 'Mutation' }
  & { updateWodBookEntryBlock?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type DeleteWodBookEntryBlockMutationVariables = Exact<{
  blockId: Scalars['ID'];
  entryId: Scalars['ID'];
}>;


export type DeleteWodBookEntryBlockMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteWodBookEntryBlock'>
);

export type SetWodBookEntryExpectedScoresMutationVariables = Exact<{
  entryId: Scalars['ID'];
  expectedScores?: Maybe<Array<Maybe<ExpectedScoreInput>> | Maybe<ExpectedScoreInput>>;
}>;


export type SetWodBookEntryExpectedScoresMutation = (
  { __typename?: 'Mutation' }
  & { setWodBookEntryExpectedScores?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export type AddPublicWodScoresMutationVariables = Exact<{
  wodId: Scalars['ID'];
  scores?: Maybe<Array<Maybe<PublicWodScoreInput>> | Maybe<PublicWodScoreInput>>;
  date?: Maybe<Scalars['Date']>;
}>;


export type AddPublicWodScoresMutation = (
  { __typename?: 'Mutation' }
  & { addPublicWodScores?: Maybe<(
    { __typename?: 'ScoreEntry' }
    & Pick<ScoreEntry, 'id' | 'date'>
    & { scores?: Maybe<Array<Maybe<(
      { __typename?: 'ExerciseScore' }
      & Pick<ExerciseScore, 'type' | 'value'>
    )>>> }
  )> }
);

export type DeletePublicWodScoresMutationVariables = Exact<{
  scoreEntryId: Scalars['ID'];
}>;


export type DeletePublicWodScoresMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePublicWodScores'>
);

export type UpdateWodBookEntryBlocksOrderMutationVariables = Exact<{
  blockOrders?: Maybe<Array<Maybe<BlockOrderInput>> | Maybe<BlockOrderInput>>;
  entryId: Scalars['ID'];
}>;


export type UpdateWodBookEntryBlocksOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateWodBookEntryBlocksOrder?: Maybe<(
    { __typename?: 'WodBookEntry' }
    & WodBookEntryFragment
  )> }
);

export const ChatChannelFragmentDoc = gql`
    fragment ChatChannel on ChatChannel {
  id
  avatar {
    url
  }
  latestMessage {
    id
    content
    createdAt
    attachment {
      type
      url
    }
    deleted
    sender {
      id
      firstName
      lastName
      nickname
      pubnub_uuid
    }
    updatedAt
  }
  members {
    id
    firstName
    lastName
    nickname
    profile_picture {
      url
    }
    pubnub_uuid
  }
  name
  pubnub_id
  totalMembers
  unreadMessagesCount
  type
  blockedBy
  coach
}
    `;
export const ProgramFragmentDoc = gql`
    fragment Program on Program {
  avatar {
    url
  }
  creator {
    id
    firstName
    lastName
    nickname
    profile_picture {
      url
    }
  }
  description
  duration
  id
  name
  price
  private
  published
  notesNumber
  points
  tags
  type
  users
  usersLimit
  avatar {
    url
  }
  open
  notificationSettings {
    sessionCommentAdded {
      disabled
    }
  }
  subscriptionStatus {
    current_period_end
    current_period_start
    cancel_at_period_end
    status
  }
}
    `;
export const ScoreFragmentFragmentDoc = gql`
    fragment scoreFragment on ExerciseScore {
  type
  value
}
    `;
export const OwnedUserFragmentDoc = gql`
    fragment OwnedUser on OwnedUser {
  id
  role
  pubnub_uuid
  stripe_customer_id
  validated_stripe_connect_account
  firstName
  lastName
  nickname
  provider
  birthDate {
    private
    value
  }
  height {
    private
    value
  }
  weight {
    private
    value
  }
  email
  email_verified
  location {
    city {
      private
      value
    }
    country {
      private
      value
    }
  }
  profile_picture {
    url
  }
  sportsRoom_name {
    private
    value
  }
  birthDate {
    private
    value
  }
  gender
  followedPrograms {
    id
    name
    points
    price
    notesNumber
    users
    avatar {
      url
    }
  }
  ownedPrograms {
    id
    name
    published
    private
    price
    points
    notesNumber
    users
    avatar {
      url
    }
  }
  programInvitations {
    id
    program {
      id
      name
      points
      notesNumber
      users
      price
      description
      creator {
        nickname
      }
      avatar {
        url
      }
    }
  }
  programJoinRequests {
    id
    program {
      id
      name
      points
      notesNumber
      users
      price
      description
      creator {
        nickname
      }
      avatar {
        url
      }
    }
  }
  preferences {
    notificationSettings {
      commentLiked {
        disabled
      }
      commentReplyAdded {
        disabled
      }
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  nickname
  birthDate
  height
  weight
  location {
    city
    country
  }
  profile_picture {
    url
  }
  sportsRoom_name
  birthDate
  gender
  followedPrograms {
    id
    name
    notesNumber
    points
    users
    usersLimit
    tags
    description
    private
    price
    avatar {
      url
    }
    creator {
      firstName
      lastName
      nickname
    }
  }
  ownedPrograms {
    id
    name
    notesNumber
    points
    users
    usersLimit
    tags
    private
    price
    description
    avatar {
      url
    }
    creator {
      firstName
      lastName
      nickname
    }
  }
}
    `;
export const WodBookEntryFragmentDoc = gql`
    fragment WodBookEntry on WodBookEntry {
  id
  createdAt
  updatedAt
  exercise {
    name
    expectedScores {
      sort
      type
    }
    blocks {
      id
      order
      type
      content
      image {
        url
      }
    }
  }
  scoreEntries {
    id
    date
    scores {
      type
      value
    }
  }
  createdAt
  updatedAt
}
    `;
export const ListChatChannelsDocument = gql`
    query listChatChannels($limit: Int, $offset: Int) {
  listChatChannels(input: {limit: $limit, offset: $offset}) {
    limit
    offset
    result {
      ...ChatChannel
    }
    total
  }
}
    ${ChatChannelFragmentDoc}`;

/**
 * __useListChatChannelsQuery__
 *
 * To run a query within a React component, call `useListChatChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatChannelsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListChatChannelsQuery(baseOptions?: Apollo.QueryHookOptions<ListChatChannelsQuery, ListChatChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatChannelsQuery, ListChatChannelsQueryVariables>(ListChatChannelsDocument, options);
      }
export function useListChatChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatChannelsQuery, ListChatChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatChannelsQuery, ListChatChannelsQueryVariables>(ListChatChannelsDocument, options);
        }
export type ListChatChannelsQueryHookResult = ReturnType<typeof useListChatChannelsQuery>;
export type ListChatChannelsLazyQueryHookResult = ReturnType<typeof useListChatChannelsLazyQuery>;
export type ListChatChannelsQueryResult = Apollo.QueryResult<ListChatChannelsQuery, ListChatChannelsQueryVariables>;
export const ListChatMembersAndMessagesDocument = gql`
    query listChatMembersAndMessages($channelId: ID!, $messagesOffset: Int) {
  listChatChannelMessages(input: {id: $channelId, offset: $messagesOffset}) {
    limit
    total
    offset
    result {
      id
      sender
      attachment {
        type
        url
      }
      deleted
      channel
      content
      createdAt
      updatedAt
    }
  }
  listChatChannelMembers(input: {id: $channelId}) {
    result {
      id
      firstName
      lastName
      nickname
      pubnub_uuid
      profile_picture {
        url
      }
    }
  }
}
    `;

/**
 * __useListChatMembersAndMessagesQuery__
 *
 * To run a query within a React component, call `useListChatMembersAndMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatMembersAndMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatMembersAndMessagesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      messagesOffset: // value for 'messagesOffset'
 *   },
 * });
 */
export function useListChatMembersAndMessagesQuery(baseOptions: Apollo.QueryHookOptions<ListChatMembersAndMessagesQuery, ListChatMembersAndMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatMembersAndMessagesQuery, ListChatMembersAndMessagesQueryVariables>(ListChatMembersAndMessagesDocument, options);
      }
export function useListChatMembersAndMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatMembersAndMessagesQuery, ListChatMembersAndMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatMembersAndMessagesQuery, ListChatMembersAndMessagesQueryVariables>(ListChatMembersAndMessagesDocument, options);
        }
export type ListChatMembersAndMessagesQueryHookResult = ReturnType<typeof useListChatMembersAndMessagesQuery>;
export type ListChatMembersAndMessagesLazyQueryHookResult = ReturnType<typeof useListChatMembersAndMessagesLazyQuery>;
export type ListChatMembersAndMessagesQueryResult = Apollo.QueryResult<ListChatMembersAndMessagesQuery, ListChatMembersAndMessagesQueryVariables>;
export const ListChatChannelMessagesDocument = gql`
    query listChatChannelMessages($channelId: ID!, $offset: Int) {
  listChatChannelMessages(input: {id: $channelId, offset: $offset}) {
    limit
    total
    offset
    result {
      id
      sender
      attachment {
        type
        url
      }
      deleted
      channel
      content
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListChatChannelMessagesQuery__
 *
 * To run a query within a React component, call `useListChatChannelMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatChannelMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatChannelMessagesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListChatChannelMessagesQuery(baseOptions: Apollo.QueryHookOptions<ListChatChannelMessagesQuery, ListChatChannelMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatChannelMessagesQuery, ListChatChannelMessagesQueryVariables>(ListChatChannelMessagesDocument, options);
      }
export function useListChatChannelMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatChannelMessagesQuery, ListChatChannelMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatChannelMessagesQuery, ListChatChannelMessagesQueryVariables>(ListChatChannelMessagesDocument, options);
        }
export type ListChatChannelMessagesQueryHookResult = ReturnType<typeof useListChatChannelMessagesQuery>;
export type ListChatChannelMessagesLazyQueryHookResult = ReturnType<typeof useListChatChannelMessagesLazyQuery>;
export type ListChatChannelMessagesQueryResult = Apollo.QueryResult<ListChatChannelMessagesQuery, ListChatChannelMessagesQueryVariables>;
export const ListChatChannelMembersDocument = gql`
    query listChatChannelMembers($channelId: ID!) {
  listChatChannelMembers(input: {id: $channelId}) {
    result {
      id
      firstName
      lastName
      nickname
      pubnub_uuid
      profile_picture {
        url
      }
      isCoach
    }
  }
}
    `;

/**
 * __useListChatChannelMembersQuery__
 *
 * To run a query within a React component, call `useListChatChannelMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChatChannelMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChatChannelMembersQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useListChatChannelMembersQuery(baseOptions: Apollo.QueryHookOptions<ListChatChannelMembersQuery, ListChatChannelMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListChatChannelMembersQuery, ListChatChannelMembersQueryVariables>(ListChatChannelMembersDocument, options);
      }
export function useListChatChannelMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListChatChannelMembersQuery, ListChatChannelMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListChatChannelMembersQuery, ListChatChannelMembersQueryVariables>(ListChatChannelMembersDocument, options);
        }
export type ListChatChannelMembersQueryHookResult = ReturnType<typeof useListChatChannelMembersQuery>;
export type ListChatChannelMembersLazyQueryHookResult = ReturnType<typeof useListChatChannelMembersLazyQuery>;
export type ListChatChannelMembersQueryResult = Apollo.QueryResult<ListChatChannelMembersQuery, ListChatChannelMembersQueryVariables>;
export const ShowChatChannelDocument = gql`
    query showChatChannel($channelId: ID!) {
  showChatChannel(input: {channelId: $channelId}) {
    ...ChatChannel
  }
}
    ${ChatChannelFragmentDoc}`;

/**
 * __useShowChatChannelQuery__
 *
 * To run a query within a React component, call `useShowChatChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowChatChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowChatChannelQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useShowChatChannelQuery(baseOptions: Apollo.QueryHookOptions<ShowChatChannelQuery, ShowChatChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowChatChannelQuery, ShowChatChannelQueryVariables>(ShowChatChannelDocument, options);
      }
export function useShowChatChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowChatChannelQuery, ShowChatChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowChatChannelQuery, ShowChatChannelQueryVariables>(ShowChatChannelDocument, options);
        }
export type ShowChatChannelQueryHookResult = ReturnType<typeof useShowChatChannelQuery>;
export type ShowChatChannelLazyQueryHookResult = ReturnType<typeof useShowChatChannelLazyQuery>;
export type ShowChatChannelQueryResult = Apollo.QueryResult<ShowChatChannelQuery, ShowChatChannelQueryVariables>;
export const ShowChatMemberDocument = gql`
    query showChatMember($pubnub_uuid: String) {
  showUser(input: {pubnub_uuid: $pubnub_uuid}) {
    id
    firstName
    lastName
    nickname
    pubnub_uuid
    profile_picture {
      url
    }
  }
}
    `;

/**
 * __useShowChatMemberQuery__
 *
 * To run a query within a React component, call `useShowChatMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowChatMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowChatMemberQuery({
 *   variables: {
 *      pubnub_uuid: // value for 'pubnub_uuid'
 *   },
 * });
 */
export function useShowChatMemberQuery(baseOptions?: Apollo.QueryHookOptions<ShowChatMemberQuery, ShowChatMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowChatMemberQuery, ShowChatMemberQueryVariables>(ShowChatMemberDocument, options);
      }
export function useShowChatMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowChatMemberQuery, ShowChatMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowChatMemberQuery, ShowChatMemberQueryVariables>(ShowChatMemberDocument, options);
        }
export type ShowChatMemberQueryHookResult = ReturnType<typeof useShowChatMemberQuery>;
export type ShowChatMemberLazyQueryHookResult = ReturnType<typeof useShowChatMemberLazyQuery>;
export type ShowChatMemberQueryResult = Apollo.QueryResult<ShowChatMemberQuery, ShowChatMemberQueryVariables>;
export const CreateChatChannelDocument = gql`
    mutation createChatChannel($membersIds: [ID]) {
  createChatChannel(input: {membersIds: $membersIds}) {
    ...ChatChannel
  }
}
    ${ChatChannelFragmentDoc}`;
export type CreateChatChannelMutationFn = Apollo.MutationFunction<CreateChatChannelMutation, CreateChatChannelMutationVariables>;

/**
 * __useCreateChatChannelMutation__
 *
 * To run a mutation, you first call `useCreateChatChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatChannelMutation, { data, loading, error }] = useCreateChatChannelMutation({
 *   variables: {
 *      membersIds: // value for 'membersIds'
 *   },
 * });
 */
export function useCreateChatChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatChannelMutation, CreateChatChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatChannelMutation, CreateChatChannelMutationVariables>(CreateChatChannelDocument, options);
      }
export type CreateChatChannelMutationHookResult = ReturnType<typeof useCreateChatChannelMutation>;
export type CreateChatChannelMutationResult = Apollo.MutationResult<CreateChatChannelMutation>;
export type CreateChatChannelMutationOptions = Apollo.BaseMutationOptions<CreateChatChannelMutation, CreateChatChannelMutationVariables>;
export const SendChatMessageDocument = gql`
    mutation sendChatMessage($channelId: ID!, $content: String!, $tempId: ID) {
  sendChatMessage(
    input: {channel: $channelId, content: $content, tempId: $tempId}
  ) {
    message {
      id
      sender
      attachment {
        type
        url
      }
      deleted
      channel
      content
      createdAt
      updatedAt
    }
    tempId
  }
}
    `;
export type SendChatMessageMutationFn = Apollo.MutationFunction<SendChatMessageMutation, SendChatMessageMutationVariables>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      content: // value for 'content'
 *      tempId: // value for 'tempId'
 *   },
 * });
 */
export function useSendChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendChatMessageMutation, SendChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatMessageMutation, SendChatMessageMutationVariables>(SendChatMessageDocument, options);
      }
export type SendChatMessageMutationHookResult = ReturnType<typeof useSendChatMessageMutation>;
export type SendChatMessageMutationResult = Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<SendChatMessageMutation, SendChatMessageMutationVariables>;
export const SendChatImageDocument = gql`
    mutation sendChatImage($channelId: ID!, $image: Upload!, $tempId: ID) {
  sendChatImage(input: {tempId: $tempId, channel: $channelId, image: $image}) {
    message {
      id
      sender
      attachment {
        type
        url
      }
      deleted
      channel
      content
      createdAt
      updatedAt
    }
    tempId
  }
}
    `;
export type SendChatImageMutationFn = Apollo.MutationFunction<SendChatImageMutation, SendChatImageMutationVariables>;

/**
 * __useSendChatImageMutation__
 *
 * To run a mutation, you first call `useSendChatImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatImageMutation, { data, loading, error }] = useSendChatImageMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      image: // value for 'image'
 *      tempId: // value for 'tempId'
 *   },
 * });
 */
export function useSendChatImageMutation(baseOptions?: Apollo.MutationHookOptions<SendChatImageMutation, SendChatImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatImageMutation, SendChatImageMutationVariables>(SendChatImageDocument, options);
      }
export type SendChatImageMutationHookResult = ReturnType<typeof useSendChatImageMutation>;
export type SendChatImageMutationResult = Apollo.MutationResult<SendChatImageMutation>;
export type SendChatImageMutationOptions = Apollo.BaseMutationOptions<SendChatImageMutation, SendChatImageMutationVariables>;
export const SetChannelLatestReceiptDocument = gql`
    mutation setChannelLatestReceipt($channelId: ID!, $receivedAt: Date!) {
  setChannelLatestReceipt(input: {channelId: $channelId, receivedAt: $receivedAt})
}
    `;
export type SetChannelLatestReceiptMutationFn = Apollo.MutationFunction<SetChannelLatestReceiptMutation, SetChannelLatestReceiptMutationVariables>;

/**
 * __useSetChannelLatestReceiptMutation__
 *
 * To run a mutation, you first call `useSetChannelLatestReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChannelLatestReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChannelLatestReceiptMutation, { data, loading, error }] = useSetChannelLatestReceiptMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      receivedAt: // value for 'receivedAt'
 *   },
 * });
 */
export function useSetChannelLatestReceiptMutation(baseOptions?: Apollo.MutationHookOptions<SetChannelLatestReceiptMutation, SetChannelLatestReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetChannelLatestReceiptMutation, SetChannelLatestReceiptMutationVariables>(SetChannelLatestReceiptDocument, options);
      }
export type SetChannelLatestReceiptMutationHookResult = ReturnType<typeof useSetChannelLatestReceiptMutation>;
export type SetChannelLatestReceiptMutationResult = Apollo.MutationResult<SetChannelLatestReceiptMutation>;
export type SetChannelLatestReceiptMutationOptions = Apollo.BaseMutationOptions<SetChannelLatestReceiptMutation, SetChannelLatestReceiptMutationVariables>;
export const LeaveChatChannelDocument = gql`
    mutation leaveChatChannel($channelId: ID!) {
  leaveChatChannel(input: {channelId: $channelId})
}
    `;
export type LeaveChatChannelMutationFn = Apollo.MutationFunction<LeaveChatChannelMutation, LeaveChatChannelMutationVariables>;

/**
 * __useLeaveChatChannelMutation__
 *
 * To run a mutation, you first call `useLeaveChatChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChatChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChatChannelMutation, { data, loading, error }] = useLeaveChatChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useLeaveChatChannelMutation(baseOptions?: Apollo.MutationHookOptions<LeaveChatChannelMutation, LeaveChatChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveChatChannelMutation, LeaveChatChannelMutationVariables>(LeaveChatChannelDocument, options);
      }
export type LeaveChatChannelMutationHookResult = ReturnType<typeof useLeaveChatChannelMutation>;
export type LeaveChatChannelMutationResult = Apollo.MutationResult<LeaveChatChannelMutation>;
export type LeaveChatChannelMutationOptions = Apollo.BaseMutationOptions<LeaveChatChannelMutation, LeaveChatChannelMutationVariables>;
export const RemoveChatMemberDocument = gql`
    mutation removeChatMember($channelId: ID!, $memberId: ID!) {
  removeChatMember(input: {channelId: $channelId, memberId: $memberId})
}
    `;
export type RemoveChatMemberMutationFn = Apollo.MutationFunction<RemoveChatMemberMutation, RemoveChatMemberMutationVariables>;

/**
 * __useRemoveChatMemberMutation__
 *
 * To run a mutation, you first call `useRemoveChatMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChatMemberMutation, { data, loading, error }] = useRemoveChatMemberMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useRemoveChatMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveChatMemberMutation, RemoveChatMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveChatMemberMutation, RemoveChatMemberMutationVariables>(RemoveChatMemberDocument, options);
      }
export type RemoveChatMemberMutationHookResult = ReturnType<typeof useRemoveChatMemberMutation>;
export type RemoveChatMemberMutationResult = Apollo.MutationResult<RemoveChatMemberMutation>;
export type RemoveChatMemberMutationOptions = Apollo.BaseMutationOptions<RemoveChatMemberMutation, RemoveChatMemberMutationVariables>;
export const ReportChatMessageDocument = gql`
    mutation reportChatMessage($messageId: ID!) {
  reportChatMessage(input: {messageId: $messageId})
}
    `;
export type ReportChatMessageMutationFn = Apollo.MutationFunction<ReportChatMessageMutation, ReportChatMessageMutationVariables>;

/**
 * __useReportChatMessageMutation__
 *
 * To run a mutation, you first call `useReportChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportChatMessageMutation, { data, loading, error }] = useReportChatMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useReportChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<ReportChatMessageMutation, ReportChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportChatMessageMutation, ReportChatMessageMutationVariables>(ReportChatMessageDocument, options);
      }
export type ReportChatMessageMutationHookResult = ReturnType<typeof useReportChatMessageMutation>;
export type ReportChatMessageMutationResult = Apollo.MutationResult<ReportChatMessageMutation>;
export type ReportChatMessageMutationOptions = Apollo.BaseMutationOptions<ReportChatMessageMutation, ReportChatMessageMutationVariables>;
export const DeleteChatMessageDocument = gql`
    mutation deleteChatMessage($messageId: ID!) {
  deleteChatMessage(input: {messageId: $messageId})
}
    `;
export type DeleteChatMessageMutationFn = Apollo.MutationFunction<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>;

/**
 * __useDeleteChatMessageMutation__
 *
 * To run a mutation, you first call `useDeleteChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatMessageMutation, { data, loading, error }] = useDeleteChatMessageMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useDeleteChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>(DeleteChatMessageDocument, options);
      }
export type DeleteChatMessageMutationHookResult = ReturnType<typeof useDeleteChatMessageMutation>;
export type DeleteChatMessageMutationResult = Apollo.MutationResult<DeleteChatMessageMutation>;
export type DeleteChatMessageMutationOptions = Apollo.BaseMutationOptions<DeleteChatMessageMutation, DeleteChatMessageMutationVariables>;
export const BlockChatMemberDocument = gql`
    mutation blockChatMember($channelId: ID!, $memberId: ID!) {
  blockChatMember(input: {memberId: $memberId, channelId: $channelId})
}
    `;
export type BlockChatMemberMutationFn = Apollo.MutationFunction<BlockChatMemberMutation, BlockChatMemberMutationVariables>;

/**
 * __useBlockChatMemberMutation__
 *
 * To run a mutation, you first call `useBlockChatMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockChatMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockChatMemberMutation, { data, loading, error }] = useBlockChatMemberMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useBlockChatMemberMutation(baseOptions?: Apollo.MutationHookOptions<BlockChatMemberMutation, BlockChatMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockChatMemberMutation, BlockChatMemberMutationVariables>(BlockChatMemberDocument, options);
      }
export type BlockChatMemberMutationHookResult = ReturnType<typeof useBlockChatMemberMutation>;
export type BlockChatMemberMutationResult = Apollo.MutationResult<BlockChatMemberMutation>;
export type BlockChatMemberMutationOptions = Apollo.BaseMutationOptions<BlockChatMemberMutation, BlockChatMemberMutationVariables>;
export const ListPersonalRecordsDocument = gql`
    query listPersonalRecords($category: String, $offset: Int, $limit: Int, $user: ID) {
  listPersonalRecords(
    input: {category: $category, offset: $offset, limit: $limit, user: $user}
  ) {
    total
    limit
    offset
    result {
      id
      exercise
      scoreEntries {
        date
        scores {
          type
          value
        }
      }
      category
    }
  }
}
    `;

/**
 * __useListPersonalRecordsQuery__
 *
 * To run a query within a React component, call `useListPersonalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonalRecordsQuery({
 *   variables: {
 *      category: // value for 'category'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useListPersonalRecordsQuery(baseOptions?: Apollo.QueryHookOptions<ListPersonalRecordsQuery, ListPersonalRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPersonalRecordsQuery, ListPersonalRecordsQueryVariables>(ListPersonalRecordsDocument, options);
      }
export function useListPersonalRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPersonalRecordsQuery, ListPersonalRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPersonalRecordsQuery, ListPersonalRecordsQueryVariables>(ListPersonalRecordsDocument, options);
        }
export type ListPersonalRecordsQueryHookResult = ReturnType<typeof useListPersonalRecordsQuery>;
export type ListPersonalRecordsLazyQueryHookResult = ReturnType<typeof useListPersonalRecordsLazyQuery>;
export type ListPersonalRecordsQueryResult = Apollo.QueryResult<ListPersonalRecordsQuery, ListPersonalRecordsQueryVariables>;
export const CreatePersonalRecordDocument = gql`
    mutation createPersonalRecord($category: String, $exercise: String, $type: ScoreValueType, $date: Date, $value: Float) {
  createPersonalRecord(
    input: {category: $category, exercise: $exercise, type: $type, date: $date, value: $value}
  ) {
    id
    exercise
    scoreEntries {
      date
      scores {
        type
        value
      }
    }
    category
  }
}
    `;
export type CreatePersonalRecordMutationFn = Apollo.MutationFunction<CreatePersonalRecordMutation, CreatePersonalRecordMutationVariables>;

/**
 * __useCreatePersonalRecordMutation__
 *
 * To run a mutation, you first call `useCreatePersonalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalRecordMutation, { data, loading, error }] = useCreatePersonalRecordMutation({
 *   variables: {
 *      category: // value for 'category'
 *      exercise: // value for 'exercise'
 *      type: // value for 'type'
 *      date: // value for 'date'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreatePersonalRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonalRecordMutation, CreatePersonalRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonalRecordMutation, CreatePersonalRecordMutationVariables>(CreatePersonalRecordDocument, options);
      }
export type CreatePersonalRecordMutationHookResult = ReturnType<typeof useCreatePersonalRecordMutation>;
export type CreatePersonalRecordMutationResult = Apollo.MutationResult<CreatePersonalRecordMutation>;
export type CreatePersonalRecordMutationOptions = Apollo.BaseMutationOptions<CreatePersonalRecordMutation, CreatePersonalRecordMutationVariables>;
export const DeletePersonalRecordDocument = gql`
    mutation deletePersonalRecord($id: ID!) {
  deletePersonalRecord(input: {id: $id})
}
    `;
export type DeletePersonalRecordMutationFn = Apollo.MutationFunction<DeletePersonalRecordMutation, DeletePersonalRecordMutationVariables>;

/**
 * __useDeletePersonalRecordMutation__
 *
 * To run a mutation, you first call `useDeletePersonalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonalRecordMutation, { data, loading, error }] = useDeletePersonalRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonalRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonalRecordMutation, DeletePersonalRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePersonalRecordMutation, DeletePersonalRecordMutationVariables>(DeletePersonalRecordDocument, options);
      }
export type DeletePersonalRecordMutationHookResult = ReturnType<typeof useDeletePersonalRecordMutation>;
export type DeletePersonalRecordMutationResult = Apollo.MutationResult<DeletePersonalRecordMutation>;
export type DeletePersonalRecordMutationOptions = Apollo.BaseMutationOptions<DeletePersonalRecordMutation, DeletePersonalRecordMutationVariables>;
export const AddPersonalRecordsDocument = gql`
    mutation addPersonalRecords($id: ID!, $type: ScoreValueType, $value: Float, $date: Date) {
  addPersonalRecordScores(
    input: {value: $value, date: $date, id: $id, type: $type}
  ) {
    category
    scoreEntries {
      date
      scores {
        type
        value
      }
    }
    exercise
    id
  }
}
    `;
export type AddPersonalRecordsMutationFn = Apollo.MutationFunction<AddPersonalRecordsMutation, AddPersonalRecordsMutationVariables>;

/**
 * __useAddPersonalRecordsMutation__
 *
 * To run a mutation, you first call `useAddPersonalRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalRecordsMutation, { data, loading, error }] = useAddPersonalRecordsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      value: // value for 'value'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddPersonalRecordsMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonalRecordsMutation, AddPersonalRecordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPersonalRecordsMutation, AddPersonalRecordsMutationVariables>(AddPersonalRecordsDocument, options);
      }
export type AddPersonalRecordsMutationHookResult = ReturnType<typeof useAddPersonalRecordsMutation>;
export type AddPersonalRecordsMutationResult = Apollo.MutationResult<AddPersonalRecordsMutation>;
export type AddPersonalRecordsMutationOptions = Apollo.BaseMutationOptions<AddPersonalRecordsMutation, AddPersonalRecordsMutationVariables>;
export const ListProgramSessionsDayDocument = gql`
    query listProgramSessionsDay($limit: Int, $offset: Int, $programId: ID, $month: Int, $year: Int, $day: Int) {
  listProgramSessions(
    input: {limit: $limit, offset: $offset, programId: $programId, month: $month, year: $year, day: $day}
  ) {
    limit
    offset
    total
    result {
      id
      name
      exercises {
        id
        name
        expectedScores {
          sort
          type
        }
        notesNumber
        points
      }
      plannedAt
      program {
        id
        name
        avatar {
          url
        }
        creator {
          id
          nickname
        }
      }
    }
  }
}
    `;

/**
 * __useListProgramSessionsDayQuery__
 *
 * To run a query within a React component, call `useListProgramSessionsDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramSessionsDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramSessionsDayQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      programId: // value for 'programId'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      day: // value for 'day'
 *   },
 * });
 */
export function useListProgramSessionsDayQuery(baseOptions?: Apollo.QueryHookOptions<ListProgramSessionsDayQuery, ListProgramSessionsDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramSessionsDayQuery, ListProgramSessionsDayQueryVariables>(ListProgramSessionsDayDocument, options);
      }
export function useListProgramSessionsDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramSessionsDayQuery, ListProgramSessionsDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramSessionsDayQuery, ListProgramSessionsDayQueryVariables>(ListProgramSessionsDayDocument, options);
        }
export type ListProgramSessionsDayQueryHookResult = ReturnType<typeof useListProgramSessionsDayQuery>;
export type ListProgramSessionsDayLazyQueryHookResult = ReturnType<typeof useListProgramSessionsDayLazyQuery>;
export type ListProgramSessionsDayQueryResult = Apollo.QueryResult<ListProgramSessionsDayQuery, ListProgramSessionsDayQueryVariables>;
export const ListProgramsDocument = gql`
    query listPrograms($limit: Int, $offset: Int, $filter: String) {
  listPrograms(
    input: {limit: $limit, offset: $offset, filter: {nameOrTagsOrCreator: {contains: $filter}}}
  ) {
    limit
    offset
    total
    result {
      id
      name
      description
      duration
      price
      notesNumber
      points
      tags
      users
      usersLimit
      avatar {
        url
      }
      creator {
        firstName
        lastName
        nickname
      }
      open
    }
  }
}
    `;

/**
 * __useListProgramsQuery__
 *
 * To run a query within a React component, call `useListProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListProgramsQuery(baseOptions?: Apollo.QueryHookOptions<ListProgramsQuery, ListProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramsQuery, ListProgramsQueryVariables>(ListProgramsDocument, options);
      }
export function useListProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramsQuery, ListProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramsQuery, ListProgramsQueryVariables>(ListProgramsDocument, options);
        }
export type ListProgramsQueryHookResult = ReturnType<typeof useListProgramsQuery>;
export type ListProgramsLazyQueryHookResult = ReturnType<typeof useListProgramsLazyQuery>;
export type ListProgramsQueryResult = Apollo.QueryResult<ListProgramsQuery, ListProgramsQueryVariables>;
export const ListProgramSessionsDocument = gql`
    query listProgramSessions($limit: Int, $offset: Int, $day: Int, $month: Int, $year: Int, $programId: ID) {
  listProgramSessions(
    input: {limit: $limit, offset: $offset, day: $day, month: $month, year: $year, programId: $programId}
  ) {
    limit
    offset
    total
    result {
      id
      name
      plannedAt
      exercises {
        id
        name
        notesNumber
        points
        blocks {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useListProgramSessionsQuery__
 *
 * To run a query within a React component, call `useListProgramSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramSessionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      day: // value for 'day'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useListProgramSessionsQuery(baseOptions?: Apollo.QueryHookOptions<ListProgramSessionsQuery, ListProgramSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramSessionsQuery, ListProgramSessionsQueryVariables>(ListProgramSessionsDocument, options);
      }
export function useListProgramSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramSessionsQuery, ListProgramSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramSessionsQuery, ListProgramSessionsQueryVariables>(ListProgramSessionsDocument, options);
        }
export type ListProgramSessionsQueryHookResult = ReturnType<typeof useListProgramSessionsQuery>;
export type ListProgramSessionsLazyQueryHookResult = ReturnType<typeof useListProgramSessionsLazyQuery>;
export type ListProgramSessionsQueryResult = Apollo.QueryResult<ListProgramSessionsQuery, ListProgramSessionsQueryVariables>;
export const ShowProgramDocument = gql`
    query showProgram($id: ID) {
  showProgram(input: {id: $id}) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;

/**
 * __useShowProgramQuery__
 *
 * To run a query within a React component, call `useShowProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowProgramQuery(baseOptions?: Apollo.QueryHookOptions<ShowProgramQuery, ShowProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowProgramQuery, ShowProgramQueryVariables>(ShowProgramDocument, options);
      }
export function useShowProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowProgramQuery, ShowProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowProgramQuery, ShowProgramQueryVariables>(ShowProgramDocument, options);
        }
export type ShowProgramQueryHookResult = ReturnType<typeof useShowProgramQuery>;
export type ShowProgramLazyQueryHookResult = ReturnType<typeof useShowProgramLazyQuery>;
export type ShowProgramQueryResult = Apollo.QueryResult<ShowProgramQuery, ShowProgramQueryVariables>;
export const ListProgramJoinRequestsDocument = gql`
    query listProgramJoinRequests($limit: Int, $offset: Int, $id: ID!) {
  listProgramJoinRequests(input: {id: $id, limit: $limit, offset: $offset}) {
    limit
    offset
    total
    result {
      id
      applicant {
        id
        firstName
        lastName
        nickname
        profile_picture {
          url
        }
      }
    }
  }
}
    `;

/**
 * __useListProgramJoinRequestsQuery__
 *
 * To run a query within a React component, call `useListProgramJoinRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramJoinRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramJoinRequestsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListProgramJoinRequestsQuery(baseOptions: Apollo.QueryHookOptions<ListProgramJoinRequestsQuery, ListProgramJoinRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramJoinRequestsQuery, ListProgramJoinRequestsQueryVariables>(ListProgramJoinRequestsDocument, options);
      }
export function useListProgramJoinRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramJoinRequestsQuery, ListProgramJoinRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramJoinRequestsQuery, ListProgramJoinRequestsQueryVariables>(ListProgramJoinRequestsDocument, options);
        }
export type ListProgramJoinRequestsQueryHookResult = ReturnType<typeof useListProgramJoinRequestsQuery>;
export type ListProgramJoinRequestsLazyQueryHookResult = ReturnType<typeof useListProgramJoinRequestsLazyQuery>;
export type ListProgramJoinRequestsQueryResult = Apollo.QueryResult<ListProgramJoinRequestsQuery, ListProgramJoinRequestsQueryVariables>;
export const ListProgramUsersDocument = gql`
    query listProgramUsers($limit: Int, $offset: Int, $id: ID!) {
  listProgramUsers(input: {id: $id, limit: $limit, offset: $offset}) {
    limit
    offset
    total
    result {
      memberSince
      profile {
        id
        firstName
        lastName
        nickname
        profile_picture {
          url
        }
      }
    }
  }
}
    `;

/**
 * __useListProgramUsersQuery__
 *
 * To run a query within a React component, call `useListProgramUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListProgramUsersQuery(baseOptions: Apollo.QueryHookOptions<ListProgramUsersQuery, ListProgramUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramUsersQuery, ListProgramUsersQueryVariables>(ListProgramUsersDocument, options);
      }
export function useListProgramUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramUsersQuery, ListProgramUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramUsersQuery, ListProgramUsersQueryVariables>(ListProgramUsersDocument, options);
        }
export type ListProgramUsersQueryHookResult = ReturnType<typeof useListProgramUsersQuery>;
export type ListProgramUsersLazyQueryHookResult = ReturnType<typeof useListProgramUsersLazyQuery>;
export type ListProgramUsersQueryResult = Apollo.QueryResult<ListProgramUsersQuery, ListProgramUsersQueryVariables>;
export const ListInvitableProgramUsersDocument = gql`
    query listInvitableProgramUsers($limit: Int, $offset: Int, $name: String, $id: ID!) {
  listInvitableProgramUsers(
    input: {id: $id, limit: $limit, offset: $offset, filter: {nameOrNickname: {contains: $name}}}
  ) {
    limit
    offset
    total
    result {
      id
      firstName
      lastName
      nickname
      profile_picture {
        url
      }
    }
  }
}
    `;

/**
 * __useListInvitableProgramUsersQuery__
 *
 * To run a query within a React component, call `useListInvitableProgramUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitableProgramUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitableProgramUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListInvitableProgramUsersQuery(baseOptions: Apollo.QueryHookOptions<ListInvitableProgramUsersQuery, ListInvitableProgramUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInvitableProgramUsersQuery, ListInvitableProgramUsersQueryVariables>(ListInvitableProgramUsersDocument, options);
      }
export function useListInvitableProgramUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInvitableProgramUsersQuery, ListInvitableProgramUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInvitableProgramUsersQuery, ListInvitableProgramUsersQueryVariables>(ListInvitableProgramUsersDocument, options);
        }
export type ListInvitableProgramUsersQueryHookResult = ReturnType<typeof useListInvitableProgramUsersQuery>;
export type ListInvitableProgramUsersLazyQueryHookResult = ReturnType<typeof useListInvitableProgramUsersLazyQuery>;
export type ListInvitableProgramUsersQueryResult = Apollo.QueryResult<ListInvitableProgramUsersQuery, ListInvitableProgramUsersQueryVariables>;
export const UpdateProgramDocument = gql`
    mutation updateProgram($id: ID!, $description: String, $duration: Int, $name: String, $price: Float, $private: Boolean, $published: Boolean, $tags: [String], $type: ProgramType, $usersLimit: Int, $open: Boolean) {
  updateProgram(
    input: {id: $id, name: $name, description: $description, duration: $duration, price: $price, private: $private, published: $published, tags: $tags, type: $type, usersLimit: $usersLimit, open: $open}
  ) {
    id
    avatar {
      url
    }
    creator {
      firstName
      lastName
      nickname
    }
    description
    duration
    id
    name
    price
    private
    published
    points
    notesNumber
    tags
    type
    users
    usersLimit
    open
  }
}
    `;
export type UpdateProgramMutationFn = Apollo.MutationFunction<UpdateProgramMutation, UpdateProgramMutationVariables>;

/**
 * __useUpdateProgramMutation__
 *
 * To run a mutation, you first call `useUpdateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramMutation, { data, loading, error }] = useUpdateProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      duration: // value for 'duration'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      private: // value for 'private'
 *      published: // value for 'published'
 *      tags: // value for 'tags'
 *      type: // value for 'type'
 *      usersLimit: // value for 'usersLimit'
 *      open: // value for 'open'
 *   },
 * });
 */
export function useUpdateProgramMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramMutation, UpdateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgramMutation, UpdateProgramMutationVariables>(UpdateProgramDocument, options);
      }
export type UpdateProgramMutationHookResult = ReturnType<typeof useUpdateProgramMutation>;
export type UpdateProgramMutationResult = Apollo.MutationResult<UpdateProgramMutation>;
export type UpdateProgramMutationOptions = Apollo.BaseMutationOptions<UpdateProgramMutation, UpdateProgramMutationVariables>;
export const CreateProgramDocument = gql`
    mutation createProgram($description: String, $duration: Int, $name: String, $price: Float, $private: Boolean, $tags: [String], $type: ProgramType, $usersLimit: Int, $avatar: Upload, $open: Boolean, $published: Boolean) {
  createProgram(
    input: {published: $published, description: $description, duration: $duration, name: $name, price: $price, private: $private, tags: $tags, type: $type, usersLimit: $usersLimit, avatar: $avatar, open: $open}
  ) {
    ...Program
  }
}
    ${ProgramFragmentDoc}`;
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      description: // value for 'description'
 *      duration: // value for 'duration'
 *      name: // value for 'name'
 *      price: // value for 'price'
 *      private: // value for 'private'
 *      tags: // value for 'tags'
 *      type: // value for 'type'
 *      usersLimit: // value for 'usersLimit'
 *      avatar: // value for 'avatar'
 *      open: // value for 'open'
 *      published: // value for 'published'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const AddProgramAvatarDocument = gql`
    mutation addProgramAvatar($file: Upload!, $programId: ID!) {
  addProgramAvatar(file: $file, programId: $programId) {
    url
  }
}
    `;
export type AddProgramAvatarMutationFn = Apollo.MutationFunction<AddProgramAvatarMutation, AddProgramAvatarMutationVariables>;

/**
 * __useAddProgramAvatarMutation__
 *
 * To run a mutation, you first call `useAddProgramAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProgramAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProgramAvatarMutation, { data, loading, error }] = useAddProgramAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useAddProgramAvatarMutation(baseOptions?: Apollo.MutationHookOptions<AddProgramAvatarMutation, AddProgramAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProgramAvatarMutation, AddProgramAvatarMutationVariables>(AddProgramAvatarDocument, options);
      }
export type AddProgramAvatarMutationHookResult = ReturnType<typeof useAddProgramAvatarMutation>;
export type AddProgramAvatarMutationResult = Apollo.MutationResult<AddProgramAvatarMutation>;
export type AddProgramAvatarMutationOptions = Apollo.BaseMutationOptions<AddProgramAvatarMutation, AddProgramAvatarMutationVariables>;
export const CreateProgramJoinRequestDocument = gql`
    mutation createProgramJoinRequest($program: ID!) {
  createProgramJoinRequest(input: {program: $program}) {
    id
  }
}
    `;
export type CreateProgramJoinRequestMutationFn = Apollo.MutationFunction<CreateProgramJoinRequestMutation, CreateProgramJoinRequestMutationVariables>;

/**
 * __useCreateProgramJoinRequestMutation__
 *
 * To run a mutation, you first call `useCreateProgramJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramJoinRequestMutation, { data, loading, error }] = useCreateProgramJoinRequestMutation({
 *   variables: {
 *      program: // value for 'program'
 *   },
 * });
 */
export function useCreateProgramJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramJoinRequestMutation, CreateProgramJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramJoinRequestMutation, CreateProgramJoinRequestMutationVariables>(CreateProgramJoinRequestDocument, options);
      }
export type CreateProgramJoinRequestMutationHookResult = ReturnType<typeof useCreateProgramJoinRequestMutation>;
export type CreateProgramJoinRequestMutationResult = Apollo.MutationResult<CreateProgramJoinRequestMutation>;
export type CreateProgramJoinRequestMutationOptions = Apollo.BaseMutationOptions<CreateProgramJoinRequestMutation, CreateProgramJoinRequestMutationVariables>;
export const CreateProgramInvitationDocument = gql`
    mutation createProgramInvitation($program: ID!, $recipient: ID!) {
  createProgramInvitation(input: {program: $program, recipient: $recipient}) {
    id
  }
}
    `;
export type CreateProgramInvitationMutationFn = Apollo.MutationFunction<CreateProgramInvitationMutation, CreateProgramInvitationMutationVariables>;

/**
 * __useCreateProgramInvitationMutation__
 *
 * To run a mutation, you first call `useCreateProgramInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramInvitationMutation, { data, loading, error }] = useCreateProgramInvitationMutation({
 *   variables: {
 *      program: // value for 'program'
 *      recipient: // value for 'recipient'
 *   },
 * });
 */
export function useCreateProgramInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramInvitationMutation, CreateProgramInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramInvitationMutation, CreateProgramInvitationMutationVariables>(CreateProgramInvitationDocument, options);
      }
export type CreateProgramInvitationMutationHookResult = ReturnType<typeof useCreateProgramInvitationMutation>;
export type CreateProgramInvitationMutationResult = Apollo.MutationResult<CreateProgramInvitationMutation>;
export type CreateProgramInvitationMutationOptions = Apollo.BaseMutationOptions<CreateProgramInvitationMutation, CreateProgramInvitationMutationVariables>;
export const ReplyToProgramJoinRequestDocument = gql`
    mutation replyToProgramJoinRequest($accept: Boolean, $id: ID!) {
  replyToProgramJoinRequest(input: {id: $id, accept: $accept}) {
    id
  }
}
    `;
export type ReplyToProgramJoinRequestMutationFn = Apollo.MutationFunction<ReplyToProgramJoinRequestMutation, ReplyToProgramJoinRequestMutationVariables>;

/**
 * __useReplyToProgramJoinRequestMutation__
 *
 * To run a mutation, you first call `useReplyToProgramJoinRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToProgramJoinRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToProgramJoinRequestMutation, { data, loading, error }] = useReplyToProgramJoinRequestMutation({
 *   variables: {
 *      accept: // value for 'accept'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplyToProgramJoinRequestMutation(baseOptions?: Apollo.MutationHookOptions<ReplyToProgramJoinRequestMutation, ReplyToProgramJoinRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyToProgramJoinRequestMutation, ReplyToProgramJoinRequestMutationVariables>(ReplyToProgramJoinRequestDocument, options);
      }
export type ReplyToProgramJoinRequestMutationHookResult = ReturnType<typeof useReplyToProgramJoinRequestMutation>;
export type ReplyToProgramJoinRequestMutationResult = Apollo.MutationResult<ReplyToProgramJoinRequestMutation>;
export type ReplyToProgramJoinRequestMutationOptions = Apollo.BaseMutationOptions<ReplyToProgramJoinRequestMutation, ReplyToProgramJoinRequestMutationVariables>;
export const ReplyToProgramInvitationDocument = gql`
    mutation replyToProgramInvitation($accept: Boolean, $id: ID!) {
  replyToProgramInvitation(input: {id: $id, accept: $accept}) {
    id
  }
}
    `;
export type ReplyToProgramInvitationMutationFn = Apollo.MutationFunction<ReplyToProgramInvitationMutation, ReplyToProgramInvitationMutationVariables>;

/**
 * __useReplyToProgramInvitationMutation__
 *
 * To run a mutation, you first call `useReplyToProgramInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToProgramInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToProgramInvitationMutation, { data, loading, error }] = useReplyToProgramInvitationMutation({
 *   variables: {
 *      accept: // value for 'accept'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReplyToProgramInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ReplyToProgramInvitationMutation, ReplyToProgramInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReplyToProgramInvitationMutation, ReplyToProgramInvitationMutationVariables>(ReplyToProgramInvitationDocument, options);
      }
export type ReplyToProgramInvitationMutationHookResult = ReturnType<typeof useReplyToProgramInvitationMutation>;
export type ReplyToProgramInvitationMutationResult = Apollo.MutationResult<ReplyToProgramInvitationMutation>;
export type ReplyToProgramInvitationMutationOptions = Apollo.BaseMutationOptions<ReplyToProgramInvitationMutation, ReplyToProgramInvitationMutationVariables>;
export const LeaveProgramDocument = gql`
    mutation leaveProgram($programId: ID!) {
  leaveProgram(input: {programId: $programId}) {
    programId
  }
}
    `;
export type LeaveProgramMutationFn = Apollo.MutationFunction<LeaveProgramMutation, LeaveProgramMutationVariables>;

/**
 * __useLeaveProgramMutation__
 *
 * To run a mutation, you first call `useLeaveProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveProgramMutation, { data, loading, error }] = useLeaveProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useLeaveProgramMutation(baseOptions?: Apollo.MutationHookOptions<LeaveProgramMutation, LeaveProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveProgramMutation, LeaveProgramMutationVariables>(LeaveProgramDocument, options);
      }
export type LeaveProgramMutationHookResult = ReturnType<typeof useLeaveProgramMutation>;
export type LeaveProgramMutationResult = Apollo.MutationResult<LeaveProgramMutation>;
export type LeaveProgramMutationOptions = Apollo.BaseMutationOptions<LeaveProgramMutation, LeaveProgramMutationVariables>;
export const RemoveProgramUserDocument = gql`
    mutation removeProgramUser($userId: ID!, $programId: ID!) {
  removeProgramUser(input: {programId: $programId, userId: $userId}) {
    programId
    userId
  }
}
    `;
export type RemoveProgramUserMutationFn = Apollo.MutationFunction<RemoveProgramUserMutation, RemoveProgramUserMutationVariables>;

/**
 * __useRemoveProgramUserMutation__
 *
 * To run a mutation, you first call `useRemoveProgramUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgramUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgramUserMutation, { data, loading, error }] = useRemoveProgramUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useRemoveProgramUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProgramUserMutation, RemoveProgramUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProgramUserMutation, RemoveProgramUserMutationVariables>(RemoveProgramUserDocument, options);
      }
export type RemoveProgramUserMutationHookResult = ReturnType<typeof useRemoveProgramUserMutation>;
export type RemoveProgramUserMutationResult = Apollo.MutationResult<RemoveProgramUserMutation>;
export type RemoveProgramUserMutationOptions = Apollo.BaseMutationOptions<RemoveProgramUserMutation, RemoveProgramUserMutationVariables>;
export const DeleteProgramDocument = gql`
    mutation deleteProgram($programId: ID!) {
  deleteProgram(input: {programId: $programId})
}
    `;
export type DeleteProgramMutationFn = Apollo.MutationFunction<DeleteProgramMutation, DeleteProgramMutationVariables>;

/**
 * __useDeleteProgramMutation__
 *
 * To run a mutation, you first call `useDeleteProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramMutation, { data, loading, error }] = useDeleteProgramMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *   },
 * });
 */
export function useDeleteProgramMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProgramMutation, DeleteProgramMutationVariables>(DeleteProgramDocument, options);
      }
export type DeleteProgramMutationHookResult = ReturnType<typeof useDeleteProgramMutation>;
export type DeleteProgramMutationResult = Apollo.MutationResult<DeleteProgramMutation>;
export type DeleteProgramMutationOptions = Apollo.BaseMutationOptions<DeleteProgramMutation, DeleteProgramMutationVariables>;
export const LikeProgramSessionCommentDocument = gql`
    mutation likeProgramSessionComment($commentId: ID!) {
  likeProgramSessionComment(input: {commentId: $commentId}) {
    id
    canLike
    likes
  }
}
    `;
export type LikeProgramSessionCommentMutationFn = Apollo.MutationFunction<LikeProgramSessionCommentMutation, LikeProgramSessionCommentMutationVariables>;

/**
 * __useLikeProgramSessionCommentMutation__
 *
 * To run a mutation, you first call `useLikeProgramSessionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeProgramSessionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeProgramSessionCommentMutation, { data, loading, error }] = useLikeProgramSessionCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useLikeProgramSessionCommentMutation(baseOptions?: Apollo.MutationHookOptions<LikeProgramSessionCommentMutation, LikeProgramSessionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeProgramSessionCommentMutation, LikeProgramSessionCommentMutationVariables>(LikeProgramSessionCommentDocument, options);
      }
export type LikeProgramSessionCommentMutationHookResult = ReturnType<typeof useLikeProgramSessionCommentMutation>;
export type LikeProgramSessionCommentMutationResult = Apollo.MutationResult<LikeProgramSessionCommentMutation>;
export type LikeProgramSessionCommentMutationOptions = Apollo.BaseMutationOptions<LikeProgramSessionCommentMutation, LikeProgramSessionCommentMutationVariables>;
export const RemoveProgramSessionCommentLikeDocument = gql`
    mutation removeProgramSessionCommentLike($commentId: ID!) {
  removeProgramSessionCommentLike(input: {commentId: $commentId}) {
    id
    canLike
    likes
  }
}
    `;
export type RemoveProgramSessionCommentLikeMutationFn = Apollo.MutationFunction<RemoveProgramSessionCommentLikeMutation, RemoveProgramSessionCommentLikeMutationVariables>;

/**
 * __useRemoveProgramSessionCommentLikeMutation__
 *
 * To run a mutation, you first call `useRemoveProgramSessionCommentLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProgramSessionCommentLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProgramSessionCommentLikeMutation, { data, loading, error }] = useRemoveProgramSessionCommentLikeMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useRemoveProgramSessionCommentLikeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProgramSessionCommentLikeMutation, RemoveProgramSessionCommentLikeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProgramSessionCommentLikeMutation, RemoveProgramSessionCommentLikeMutationVariables>(RemoveProgramSessionCommentLikeDocument, options);
      }
export type RemoveProgramSessionCommentLikeMutationHookResult = ReturnType<typeof useRemoveProgramSessionCommentLikeMutation>;
export type RemoveProgramSessionCommentLikeMutationResult = Apollo.MutationResult<RemoveProgramSessionCommentLikeMutation>;
export type RemoveProgramSessionCommentLikeMutationOptions = Apollo.BaseMutationOptions<RemoveProgramSessionCommentLikeMutation, RemoveProgramSessionCommentLikeMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation removeComment($commentId: ID!, $type: CommentType) {
  removeComment(input: {commentId: $commentId, type: $type})
}
    `;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const ReportCommentDocument = gql`
    mutation reportComment($commentId: ID!, $type: CommentType) {
  reportComment(input: {commentId: $commentId, type: $type})
}
    `;
export type ReportCommentMutationFn = Apollo.MutationFunction<ReportCommentMutation, ReportCommentMutationVariables>;

/**
 * __useReportCommentMutation__
 *
 * To run a mutation, you first call `useReportCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCommentMutation, { data, loading, error }] = useReportCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReportCommentMutation(baseOptions?: Apollo.MutationHookOptions<ReportCommentMutation, ReportCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportCommentMutation, ReportCommentMutationVariables>(ReportCommentDocument, options);
      }
export type ReportCommentMutationHookResult = ReturnType<typeof useReportCommentMutation>;
export type ReportCommentMutationResult = Apollo.MutationResult<ReportCommentMutation>;
export type ReportCommentMutationOptions = Apollo.BaseMutationOptions<ReportCommentMutation, ReportCommentMutationVariables>;
export const AddProgramSessionCommentDocument = gql`
    mutation addProgramSessionComment($sessionId: ID!, $content: String, $image: Upload) {
  addProgramSessionComment(
    input: {sessionId: $sessionId, content: $content, image: $image}
  ) {
    id
    attachment {
      type
      url
    }
    author {
      id
      firstName
      lastName
      profile_picture {
        url
      }
    }
    content
    likes
    replyable
    type
    canLike
    createdAt
    replies(input: {limit: 1}) {
      limit
      offset
      total
      result {
        id
        attachment {
          type
          url
        }
        author {
          id
          firstName
          lastName
          profile_picture {
            url
          }
        }
        content
        likes
        replyable
        type
        canLike
        createdAt
      }
    }
  }
}
    `;
export type AddProgramSessionCommentMutationFn = Apollo.MutationFunction<AddProgramSessionCommentMutation, AddProgramSessionCommentMutationVariables>;

/**
 * __useAddProgramSessionCommentMutation__
 *
 * To run a mutation, you first call `useAddProgramSessionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProgramSessionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProgramSessionCommentMutation, { data, loading, error }] = useAddProgramSessionCommentMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      content: // value for 'content'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddProgramSessionCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddProgramSessionCommentMutation, AddProgramSessionCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProgramSessionCommentMutation, AddProgramSessionCommentMutationVariables>(AddProgramSessionCommentDocument, options);
      }
export type AddProgramSessionCommentMutationHookResult = ReturnType<typeof useAddProgramSessionCommentMutation>;
export type AddProgramSessionCommentMutationResult = Apollo.MutationResult<AddProgramSessionCommentMutation>;
export type AddProgramSessionCommentMutationOptions = Apollo.BaseMutationOptions<AddProgramSessionCommentMutation, AddProgramSessionCommentMutationVariables>;
export const AddProgramSessionCommentReplyDocument = gql`
    mutation addProgramSessionCommentReply($commentId: ID!, $content: String, $image: Upload) {
  addProgramSessionCommentReply(
    input: {commentId: $commentId, content: $content, image: $image}
  ) {
    id
    attachment {
      type
      url
    }
    author {
      id
      firstName
      lastName
      profile_picture {
        url
      }
    }
    content
    likes
    replyable
    type
    canLike
    replies {
      limit
      offset
      total
      result {
        id
        attachment {
          type
          url
        }
        author {
          id
          firstName
          lastName
          profile_picture {
            url
          }
        }
        content
        likes
        replyable
        type
        canLike
      }
    }
  }
}
    `;
export type AddProgramSessionCommentReplyMutationFn = Apollo.MutationFunction<AddProgramSessionCommentReplyMutation, AddProgramSessionCommentReplyMutationVariables>;

/**
 * __useAddProgramSessionCommentReplyMutation__
 *
 * To run a mutation, you first call `useAddProgramSessionCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProgramSessionCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProgramSessionCommentReplyMutation, { data, loading, error }] = useAddProgramSessionCommentReplyMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      content: // value for 'content'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddProgramSessionCommentReplyMutation(baseOptions?: Apollo.MutationHookOptions<AddProgramSessionCommentReplyMutation, AddProgramSessionCommentReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProgramSessionCommentReplyMutation, AddProgramSessionCommentReplyMutationVariables>(AddProgramSessionCommentReplyDocument, options);
      }
export type AddProgramSessionCommentReplyMutationHookResult = ReturnType<typeof useAddProgramSessionCommentReplyMutation>;
export type AddProgramSessionCommentReplyMutationResult = Apollo.MutationResult<AddProgramSessionCommentReplyMutation>;
export type AddProgramSessionCommentReplyMutationOptions = Apollo.BaseMutationOptions<AddProgramSessionCommentReplyMutation, AddProgramSessionCommentReplyMutationVariables>;
export const ShowProgramSessionDocument = gql`
    query showProgramSession($id: ID!) {
  showProgramSession(input: {sessionId: $id}) {
    id
    name
    exercises {
      id
      name
      savedToWodBook
      blocks {
        id
        content
        elements
        image {
          url
        }
        order
        type
      }
      expectedScores {
        sort
        type
      }
      userScores {
        ...scoreFragment
      }
      userFeedback {
        comment
        feeling
        note
      }
      notesNumber
      points
      chronoConfiguration {
        ... on AhapChronoConfiguration {
          ahaps {
            timeLimit
            restTime
          }
          type
        }
        ... on AmrapChronoConfiguration {
          roundConfiguration {
            number
            restTime
            timeLimit
          }
          setConfiguration {
            number
            restTime
          }
          type
        }
        ... on EmomChronoConfiguration {
          emoms {
            everyTime
            restTime
            roundsNumber
            whileTime
          }
          type
        }
        ... on ForTimeChronoConfiguration {
          type
          timeLimit
          setConfiguration {
            number
            restTime
          }
        }
        ... on TabataChronoConfiguration {
          type
          roundConfiguration {
            number
            restTime
            timeLimit
          }
          setConfiguration {
            number
            restTime
          }
        }
      }
    }
    plannedAt
    program {
      id
      name
      avatar {
        url
      }
      creator {
        id
      }
      type
    }
  }
  showProgramSessionCommentsTotal(input: {sessionId: $id})
}
    ${ScoreFragmentFragmentDoc}`;

/**
 * __useShowProgramSessionQuery__
 *
 * To run a query within a React component, call `useShowProgramSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowProgramSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowProgramSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowProgramSessionQuery(baseOptions: Apollo.QueryHookOptions<ShowProgramSessionQuery, ShowProgramSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowProgramSessionQuery, ShowProgramSessionQueryVariables>(ShowProgramSessionDocument, options);
      }
export function useShowProgramSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowProgramSessionQuery, ShowProgramSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowProgramSessionQuery, ShowProgramSessionQueryVariables>(ShowProgramSessionDocument, options);
        }
export type ShowProgramSessionQueryHookResult = ReturnType<typeof useShowProgramSessionQuery>;
export type ShowProgramSessionLazyQueryHookResult = ReturnType<typeof useShowProgramSessionLazyQuery>;
export type ShowProgramSessionQueryResult = Apollo.QueryResult<ShowProgramSessionQuery, ShowProgramSessionQueryVariables>;
export const ListProgramSessionCommentsDocument = gql`
    query listProgramSessionComments($limit: Int, $offset: Int, $sessionId: ID!) {
  listProgramSessionComments(
    input: {limit: $limit, offset: $offset, sessionId: $sessionId}
  ) {
    limit
    offset
    total
    result {
      id
      attachment {
        type
        url
      }
      author {
        id
        firstName
        lastName
        profile_picture {
          url
        }
      }
      content
      likes
      replyable
      type
      canLike
      createdAt
      replies(input: {limit: 1}) {
        limit
        offset
        total
        result {
          id
          attachment {
            type
            url
          }
          author {
            id
            firstName
            lastName
            profile_picture {
              url
            }
          }
          content
          likes
          replyable
          type
          canLike
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useListProgramSessionCommentsQuery__
 *
 * To run a query within a React component, call `useListProgramSessionCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProgramSessionCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProgramSessionCommentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useListProgramSessionCommentsQuery(baseOptions: Apollo.QueryHookOptions<ListProgramSessionCommentsQuery, ListProgramSessionCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListProgramSessionCommentsQuery, ListProgramSessionCommentsQueryVariables>(ListProgramSessionCommentsDocument, options);
      }
export function useListProgramSessionCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListProgramSessionCommentsQuery, ListProgramSessionCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListProgramSessionCommentsQuery, ListProgramSessionCommentsQueryVariables>(ListProgramSessionCommentsDocument, options);
        }
export type ListProgramSessionCommentsQueryHookResult = ReturnType<typeof useListProgramSessionCommentsQuery>;
export type ListProgramSessionCommentsLazyQueryHookResult = ReturnType<typeof useListProgramSessionCommentsLazyQuery>;
export type ListProgramSessionCommentsQueryResult = Apollo.QueryResult<ListProgramSessionCommentsQuery, ListProgramSessionCommentsQueryVariables>;
export const ShowCommentDocument = gql`
    query showComment($type: CommentType, $id: ID!, $offset: Int, $limit: Int) {
  showComment(input: {id: $id, type: $type}) {
    id
    attachment {
      type
      url
    }
    author {
      id
      firstName
      lastName
      profile_picture {
        url
      }
    }
    content
    likes
    replyable
    type
    canLike
    createdAt
    replies(input: {offset: $offset, limit: $limit}) {
      limit
      offset
      total
      result {
        id
        attachment {
          type
          url
        }
        author {
          id
          firstName
          lastName
          profile_picture {
            url
          }
        }
        content
        likes
        replyable
        type
        canLike
        createdAt
      }
    }
  }
}
    `;

/**
 * __useShowCommentQuery__
 *
 * To run a query within a React component, call `useShowCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowCommentQuery({
 *   variables: {
 *      type: // value for 'type'
 *      id: // value for 'id'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useShowCommentQuery(baseOptions: Apollo.QueryHookOptions<ShowCommentQuery, ShowCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowCommentQuery, ShowCommentQueryVariables>(ShowCommentDocument, options);
      }
export function useShowCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowCommentQuery, ShowCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowCommentQuery, ShowCommentQueryVariables>(ShowCommentDocument, options);
        }
export type ShowCommentQueryHookResult = ReturnType<typeof useShowCommentQuery>;
export type ShowCommentLazyQueryHookResult = ReturnType<typeof useShowCommentLazyQuery>;
export type ShowCommentQueryResult = Apollo.QueryResult<ShowCommentQuery, ShowCommentQueryVariables>;
export const CreateProgramSessionDocument = gql`
    mutation createProgramSession($programId: ID!, $plannedAt: Date, $name: String) {
  createProgramSession(
    input: {programId: $programId, plannedAt: $plannedAt, name: $name}
  ) {
    id
    plannedAt
    name
    exercises {
      id
      name
      blocks {
        id
        content
        elements
        image {
          url
        }
        order
        type
      }
      expectedScores {
        sort
        type
      }
      userFeedback {
        comment
        feeling
        note
      }
      notesNumber
      points
    }
    plannedAt
    program {
      id
      name
      avatar {
        url
      }
      creator {
        id
      }
    }
  }
}
    `;
export type CreateProgramSessionMutationFn = Apollo.MutationFunction<CreateProgramSessionMutation, CreateProgramSessionMutationVariables>;

/**
 * __useCreateProgramSessionMutation__
 *
 * To run a mutation, you first call `useCreateProgramSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramSessionMutation, { data, loading, error }] = useCreateProgramSessionMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      plannedAt: // value for 'plannedAt'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateProgramSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramSessionMutation, CreateProgramSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramSessionMutation, CreateProgramSessionMutationVariables>(CreateProgramSessionDocument, options);
      }
export type CreateProgramSessionMutationHookResult = ReturnType<typeof useCreateProgramSessionMutation>;
export type CreateProgramSessionMutationResult = Apollo.MutationResult<CreateProgramSessionMutation>;
export type CreateProgramSessionMutationOptions = Apollo.BaseMutationOptions<CreateProgramSessionMutation, CreateProgramSessionMutationVariables>;
export const CreateSessionExerciseDocument = gql`
    mutation createSessionExercise($sessionId: ID!, $name: String, $expectedScores: [ExpectedScoreInput]) {
  createSessionExercise(
    input: {sessionId: $sessionId, name: $name, expectedScores: $expectedScores}
  ) {
    id
    name
    blocks {
      id
      content
      order
      image {
        url
      }
      type
      elements
    }
  }
}
    `;
export type CreateSessionExerciseMutationFn = Apollo.MutationFunction<CreateSessionExerciseMutation, CreateSessionExerciseMutationVariables>;

/**
 * __useCreateSessionExerciseMutation__
 *
 * To run a mutation, you first call `useCreateSessionExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionExerciseMutation, { data, loading, error }] = useCreateSessionExerciseMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      name: // value for 'name'
 *      expectedScores: // value for 'expectedScores'
 *   },
 * });
 */
export function useCreateSessionExerciseMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionExerciseMutation, CreateSessionExerciseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionExerciseMutation, CreateSessionExerciseMutationVariables>(CreateSessionExerciseDocument, options);
      }
export type CreateSessionExerciseMutationHookResult = ReturnType<typeof useCreateSessionExerciseMutation>;
export type CreateSessionExerciseMutationResult = Apollo.MutationResult<CreateSessionExerciseMutation>;
export type CreateSessionExerciseMutationOptions = Apollo.BaseMutationOptions<CreateSessionExerciseMutation, CreateSessionExerciseMutationVariables>;
export const AddSessionExerciseBlockDocument = gql`
    mutation addSessionExerciseBlock($content: String, $elements: [String], $exerciseId: ID!, $image: Upload, $sessionId: ID!, $type: SessionExerciseBlockType) {
  addSessionExerciseBlock(
    input: {content: $content, elements: $elements, exerciseId: $exerciseId, image: $image, sessionId: $sessionId, type: $type}
  ) {
    id
    name
    blocks {
      id
      content
      order
      image {
        url
      }
      type
      elements
    }
    expectedScores {
      sort
      type
    }
  }
}
    `;
export type AddSessionExerciseBlockMutationFn = Apollo.MutationFunction<AddSessionExerciseBlockMutation, AddSessionExerciseBlockMutationVariables>;

/**
 * __useAddSessionExerciseBlockMutation__
 *
 * To run a mutation, you first call `useAddSessionExerciseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionExerciseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionExerciseBlockMutation, { data, loading, error }] = useAddSessionExerciseBlockMutation({
 *   variables: {
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      exerciseId: // value for 'exerciseId'
 *      image: // value for 'image'
 *      sessionId: // value for 'sessionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddSessionExerciseBlockMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionExerciseBlockMutation, AddSessionExerciseBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSessionExerciseBlockMutation, AddSessionExerciseBlockMutationVariables>(AddSessionExerciseBlockDocument, options);
      }
export type AddSessionExerciseBlockMutationHookResult = ReturnType<typeof useAddSessionExerciseBlockMutation>;
export type AddSessionExerciseBlockMutationResult = Apollo.MutationResult<AddSessionExerciseBlockMutation>;
export type AddSessionExerciseBlockMutationOptions = Apollo.BaseMutationOptions<AddSessionExerciseBlockMutation, AddSessionExerciseBlockMutationVariables>;
export const UpdateSessionExerciseBlockDocument = gql`
    mutation updateSessionExerciseBlock($blockId: ID!, $content: String, $elements: [String], $exerciseId: ID!, $image: Upload, $sessionId: ID!, $type: SessionExerciseBlockType) {
  updateSessionExerciseBlock(
    input: {blockId: $blockId, content: $content, elements: $elements, exerciseId: $exerciseId, image: $image, sessionId: $sessionId, type: $type}
  ) {
    id
    name
    blocks {
      id
      content
      order
      image {
        url
      }
      type
      elements
    }
    expectedScores {
      sort
      type
    }
  }
}
    `;
export type UpdateSessionExerciseBlockMutationFn = Apollo.MutationFunction<UpdateSessionExerciseBlockMutation, UpdateSessionExerciseBlockMutationVariables>;

/**
 * __useUpdateSessionExerciseBlockMutation__
 *
 * To run a mutation, you first call `useUpdateSessionExerciseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionExerciseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionExerciseBlockMutation, { data, loading, error }] = useUpdateSessionExerciseBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      exerciseId: // value for 'exerciseId'
 *      image: // value for 'image'
 *      sessionId: // value for 'sessionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateSessionExerciseBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionExerciseBlockMutation, UpdateSessionExerciseBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionExerciseBlockMutation, UpdateSessionExerciseBlockMutationVariables>(UpdateSessionExerciseBlockDocument, options);
      }
export type UpdateSessionExerciseBlockMutationHookResult = ReturnType<typeof useUpdateSessionExerciseBlockMutation>;
export type UpdateSessionExerciseBlockMutationResult = Apollo.MutationResult<UpdateSessionExerciseBlockMutation>;
export type UpdateSessionExerciseBlockMutationOptions = Apollo.BaseMutationOptions<UpdateSessionExerciseBlockMutation, UpdateSessionExerciseBlockMutationVariables>;
export const AddSessionExerciseScoresDocument = gql`
    mutation addSessionExerciseScores($exerciseId: ID!, $sessionId: ID!, $scores: [SessionExerciseScoreInput]) {
  addSessionExerciseScores(
    input: {exerciseId: $exerciseId, sessionId: $sessionId, scores: $scores}
  ) {
    scores {
      ...scoreFragment
    }
  }
}
    ${ScoreFragmentFragmentDoc}`;
export type AddSessionExerciseScoresMutationFn = Apollo.MutationFunction<AddSessionExerciseScoresMutation, AddSessionExerciseScoresMutationVariables>;

/**
 * __useAddSessionExerciseScoresMutation__
 *
 * To run a mutation, you first call `useAddSessionExerciseScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionExerciseScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionExerciseScoresMutation, { data, loading, error }] = useAddSessionExerciseScoresMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *      scores: // value for 'scores'
 *   },
 * });
 */
export function useAddSessionExerciseScoresMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionExerciseScoresMutation, AddSessionExerciseScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSessionExerciseScoresMutation, AddSessionExerciseScoresMutationVariables>(AddSessionExerciseScoresDocument, options);
      }
export type AddSessionExerciseScoresMutationHookResult = ReturnType<typeof useAddSessionExerciseScoresMutation>;
export type AddSessionExerciseScoresMutationResult = Apollo.MutationResult<AddSessionExerciseScoresMutation>;
export type AddSessionExerciseScoresMutationOptions = Apollo.BaseMutationOptions<AddSessionExerciseScoresMutation, AddSessionExerciseScoresMutationVariables>;
export const AddSessionExerciseFeedbackDocument = gql`
    mutation addSessionExerciseFeedback($sessionId: ID!, $exerciseId: ID!, $comment: String, $feeling: Float, $note: Float) {
  addSessionExerciseFeedback(
    input: {exerciseId: $exerciseId, sessionId: $sessionId, note: $note, feeling: $feeling, comment: $comment}
  ) {
    feeling
    note
    comment
  }
}
    `;
export type AddSessionExerciseFeedbackMutationFn = Apollo.MutationFunction<AddSessionExerciseFeedbackMutation, AddSessionExerciseFeedbackMutationVariables>;

/**
 * __useAddSessionExerciseFeedbackMutation__
 *
 * To run a mutation, you first call `useAddSessionExerciseFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionExerciseFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionExerciseFeedbackMutation, { data, loading, error }] = useAddSessionExerciseFeedbackMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      exerciseId: // value for 'exerciseId'
 *      comment: // value for 'comment'
 *      feeling: // value for 'feeling'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useAddSessionExerciseFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionExerciseFeedbackMutation, AddSessionExerciseFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSessionExerciseFeedbackMutation, AddSessionExerciseFeedbackMutationVariables>(AddSessionExerciseFeedbackDocument, options);
      }
export type AddSessionExerciseFeedbackMutationHookResult = ReturnType<typeof useAddSessionExerciseFeedbackMutation>;
export type AddSessionExerciseFeedbackMutationResult = Apollo.MutationResult<AddSessionExerciseFeedbackMutation>;
export type AddSessionExerciseFeedbackMutationOptions = Apollo.BaseMutationOptions<AddSessionExerciseFeedbackMutation, AddSessionExerciseFeedbackMutationVariables>;
export const UpdateSessionExerciseDocument = gql`
    mutation updateSessionExercise($expectedScores: [ExpectedScoreInput], $name: String, $exerciseId: ID!, $sessionId: ID!) {
  updateSessionExercise(
    input: {sessionId: $sessionId, exerciseId: $exerciseId, expectedScores: $expectedScores, name: $name}
  ) {
    id
    name
    blocks {
      id
      content
      order
      image {
        url
      }
      type
      elements
    }
    expectedScores {
      sort
      type
    }
  }
}
    `;
export type UpdateSessionExerciseMutationFn = Apollo.MutationFunction<UpdateSessionExerciseMutation, UpdateSessionExerciseMutationVariables>;

/**
 * __useUpdateSessionExerciseMutation__
 *
 * To run a mutation, you first call `useUpdateSessionExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionExerciseMutation, { data, loading, error }] = useUpdateSessionExerciseMutation({
 *   variables: {
 *      expectedScores: // value for 'expectedScores'
 *      name: // value for 'name'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUpdateSessionExerciseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionExerciseMutation, UpdateSessionExerciseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionExerciseMutation, UpdateSessionExerciseMutationVariables>(UpdateSessionExerciseDocument, options);
      }
export type UpdateSessionExerciseMutationHookResult = ReturnType<typeof useUpdateSessionExerciseMutation>;
export type UpdateSessionExerciseMutationResult = Apollo.MutationResult<UpdateSessionExerciseMutation>;
export type UpdateSessionExerciseMutationOptions = Apollo.BaseMutationOptions<UpdateSessionExerciseMutation, UpdateSessionExerciseMutationVariables>;
export const UpdateProgramSessionDocument = gql`
    mutation updateProgramSession($id: ID!, $name: String, $plannedAt: Date) {
  updateProgramSession(input: {id: $id, name: $name, plannedAt: $plannedAt}) {
    id
    plannedAt
    name
  }
}
    `;
export type UpdateProgramSessionMutationFn = Apollo.MutationFunction<UpdateProgramSessionMutation, UpdateProgramSessionMutationVariables>;

/**
 * __useUpdateProgramSessionMutation__
 *
 * To run a mutation, you first call `useUpdateProgramSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProgramSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProgramSessionMutation, { data, loading, error }] = useUpdateProgramSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      plannedAt: // value for 'plannedAt'
 *   },
 * });
 */
export function useUpdateProgramSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProgramSessionMutation, UpdateProgramSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProgramSessionMutation, UpdateProgramSessionMutationVariables>(UpdateProgramSessionDocument, options);
      }
export type UpdateProgramSessionMutationHookResult = ReturnType<typeof useUpdateProgramSessionMutation>;
export type UpdateProgramSessionMutationResult = Apollo.MutationResult<UpdateProgramSessionMutation>;
export type UpdateProgramSessionMutationOptions = Apollo.BaseMutationOptions<UpdateProgramSessionMutation, UpdateProgramSessionMutationVariables>;
export const DeleteProgramSessionDocument = gql`
    mutation deleteProgramSession($id: ID!) {
  deleteProgramSession(input: {id: $id})
}
    `;
export type DeleteProgramSessionMutationFn = Apollo.MutationFunction<DeleteProgramSessionMutation, DeleteProgramSessionMutationVariables>;

/**
 * __useDeleteProgramSessionMutation__
 *
 * To run a mutation, you first call `useDeleteProgramSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProgramSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProgramSessionMutation, { data, loading, error }] = useDeleteProgramSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProgramSessionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProgramSessionMutation, DeleteProgramSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProgramSessionMutation, DeleteProgramSessionMutationVariables>(DeleteProgramSessionDocument, options);
      }
export type DeleteProgramSessionMutationHookResult = ReturnType<typeof useDeleteProgramSessionMutation>;
export type DeleteProgramSessionMutationResult = Apollo.MutationResult<DeleteProgramSessionMutation>;
export type DeleteProgramSessionMutationOptions = Apollo.BaseMutationOptions<DeleteProgramSessionMutation, DeleteProgramSessionMutationVariables>;
export const AddAhapChronoConfigurationDocument = gql`
    mutation addAhapChronoConfiguration($ahaps: [AhapConfigurationInput], $exerciseId: ID!, $sessionId: ID!) {
  addAhapChronoConfiguration(
    input: {ahaps: $ahaps, exerciseId: $exerciseId, sessionId: $sessionId}
  ) {
    id
    chronoConfiguration {
      ... on AhapChronoConfiguration {
        ahaps {
          timeLimit
          restTime
        }
        type
      }
    }
  }
}
    `;
export type AddAhapChronoConfigurationMutationFn = Apollo.MutationFunction<AddAhapChronoConfigurationMutation, AddAhapChronoConfigurationMutationVariables>;

/**
 * __useAddAhapChronoConfigurationMutation__
 *
 * To run a mutation, you first call `useAddAhapChronoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAhapChronoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAhapChronoConfigurationMutation, { data, loading, error }] = useAddAhapChronoConfigurationMutation({
 *   variables: {
 *      ahaps: // value for 'ahaps'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddAhapChronoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AddAhapChronoConfigurationMutation, AddAhapChronoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAhapChronoConfigurationMutation, AddAhapChronoConfigurationMutationVariables>(AddAhapChronoConfigurationDocument, options);
      }
export type AddAhapChronoConfigurationMutationHookResult = ReturnType<typeof useAddAhapChronoConfigurationMutation>;
export type AddAhapChronoConfigurationMutationResult = Apollo.MutationResult<AddAhapChronoConfigurationMutation>;
export type AddAhapChronoConfigurationMutationOptions = Apollo.BaseMutationOptions<AddAhapChronoConfigurationMutation, AddAhapChronoConfigurationMutationVariables>;
export const AddAmrapChronoConfigurationDocument = gql`
    mutation addAmrapChronoConfiguration($roundConfiguration: ChronoRoundConfigurationInput, $setConfiguration: ChronoSetConfigurationInput, $exerciseId: ID!, $sessionId: ID!) {
  addAmrapChronoConfiguration(
    input: {roundConfiguration: $roundConfiguration, setConfiguration: $setConfiguration, exerciseId: $exerciseId, sessionId: $sessionId}
  ) {
    id
    chronoConfiguration {
      ... on AmrapChronoConfiguration {
        roundConfiguration {
          number
          restTime
          timeLimit
        }
        setConfiguration {
          number
          restTime
        }
        type
      }
    }
  }
}
    `;
export type AddAmrapChronoConfigurationMutationFn = Apollo.MutationFunction<AddAmrapChronoConfigurationMutation, AddAmrapChronoConfigurationMutationVariables>;

/**
 * __useAddAmrapChronoConfigurationMutation__
 *
 * To run a mutation, you first call `useAddAmrapChronoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAmrapChronoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAmrapChronoConfigurationMutation, { data, loading, error }] = useAddAmrapChronoConfigurationMutation({
 *   variables: {
 *      roundConfiguration: // value for 'roundConfiguration'
 *      setConfiguration: // value for 'setConfiguration'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddAmrapChronoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AddAmrapChronoConfigurationMutation, AddAmrapChronoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAmrapChronoConfigurationMutation, AddAmrapChronoConfigurationMutationVariables>(AddAmrapChronoConfigurationDocument, options);
      }
export type AddAmrapChronoConfigurationMutationHookResult = ReturnType<typeof useAddAmrapChronoConfigurationMutation>;
export type AddAmrapChronoConfigurationMutationResult = Apollo.MutationResult<AddAmrapChronoConfigurationMutation>;
export type AddAmrapChronoConfigurationMutationOptions = Apollo.BaseMutationOptions<AddAmrapChronoConfigurationMutation, AddAmrapChronoConfigurationMutationVariables>;
export const AddEmomChronoConfigurationDocument = gql`
    mutation addEmomChronoConfiguration($emoms: [EmomConfigurationInput], $exerciseId: ID!, $sessionId: ID!) {
  addEmomChronoConfiguration(
    input: {emoms: $emoms, exerciseId: $exerciseId, sessionId: $sessionId}
  ) {
    id
    chronoConfiguration {
      ... on EmomChronoConfiguration {
        emoms {
          everyTime
          restTime
          roundsNumber
          whileTime
        }
        type
      }
    }
  }
}
    `;
export type AddEmomChronoConfigurationMutationFn = Apollo.MutationFunction<AddEmomChronoConfigurationMutation, AddEmomChronoConfigurationMutationVariables>;

/**
 * __useAddEmomChronoConfigurationMutation__
 *
 * To run a mutation, you first call `useAddEmomChronoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmomChronoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmomChronoConfigurationMutation, { data, loading, error }] = useAddEmomChronoConfigurationMutation({
 *   variables: {
 *      emoms: // value for 'emoms'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddEmomChronoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AddEmomChronoConfigurationMutation, AddEmomChronoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmomChronoConfigurationMutation, AddEmomChronoConfigurationMutationVariables>(AddEmomChronoConfigurationDocument, options);
      }
export type AddEmomChronoConfigurationMutationHookResult = ReturnType<typeof useAddEmomChronoConfigurationMutation>;
export type AddEmomChronoConfigurationMutationResult = Apollo.MutationResult<AddEmomChronoConfigurationMutation>;
export type AddEmomChronoConfigurationMutationOptions = Apollo.BaseMutationOptions<AddEmomChronoConfigurationMutation, AddEmomChronoConfigurationMutationVariables>;
export const AddForTimeChronoConfigurationDocument = gql`
    mutation addForTimeChronoConfiguration($timeLimit: Int, $setConfiguration: ChronoSetConfigurationInput, $exerciseId: ID!, $sessionId: ID!) {
  addForTimeChronoConfiguration(
    input: {timeLimit: $timeLimit, setConfiguration: $setConfiguration, exerciseId: $exerciseId, sessionId: $sessionId}
  ) {
    id
    chronoConfiguration {
      ... on ForTimeChronoConfiguration {
        type
        timeLimit
        setConfiguration {
          number
          restTime
        }
      }
    }
  }
}
    `;
export type AddForTimeChronoConfigurationMutationFn = Apollo.MutationFunction<AddForTimeChronoConfigurationMutation, AddForTimeChronoConfigurationMutationVariables>;

/**
 * __useAddForTimeChronoConfigurationMutation__
 *
 * To run a mutation, you first call `useAddForTimeChronoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddForTimeChronoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addForTimeChronoConfigurationMutation, { data, loading, error }] = useAddForTimeChronoConfigurationMutation({
 *   variables: {
 *      timeLimit: // value for 'timeLimit'
 *      setConfiguration: // value for 'setConfiguration'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddForTimeChronoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AddForTimeChronoConfigurationMutation, AddForTimeChronoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddForTimeChronoConfigurationMutation, AddForTimeChronoConfigurationMutationVariables>(AddForTimeChronoConfigurationDocument, options);
      }
export type AddForTimeChronoConfigurationMutationHookResult = ReturnType<typeof useAddForTimeChronoConfigurationMutation>;
export type AddForTimeChronoConfigurationMutationResult = Apollo.MutationResult<AddForTimeChronoConfigurationMutation>;
export type AddForTimeChronoConfigurationMutationOptions = Apollo.BaseMutationOptions<AddForTimeChronoConfigurationMutation, AddForTimeChronoConfigurationMutationVariables>;
export const AddTabataChronoConfigurationDocument = gql`
    mutation addTabataChronoConfiguration($setConfiguration: ChronoSetConfigurationInput, $roundConfiguration: ChronoRoundConfigurationInput, $exerciseId: ID!, $sessionId: ID!) {
  addTabataChronoConfiguration(
    input: {exerciseId: $exerciseId, sessionId: $sessionId, setConfiguration: $setConfiguration, roundConfiguration: $roundConfiguration}
  ) {
    id
    chronoConfiguration {
      ... on TabataChronoConfiguration {
        type
        roundConfiguration {
          number
          restTime
          timeLimit
        }
        setConfiguration {
          number
          restTime
        }
      }
    }
  }
}
    `;
export type AddTabataChronoConfigurationMutationFn = Apollo.MutationFunction<AddTabataChronoConfigurationMutation, AddTabataChronoConfigurationMutationVariables>;

/**
 * __useAddTabataChronoConfigurationMutation__
 *
 * To run a mutation, you first call `useAddTabataChronoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTabataChronoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTabataChronoConfigurationMutation, { data, loading, error }] = useAddTabataChronoConfigurationMutation({
 *   variables: {
 *      setConfiguration: // value for 'setConfiguration'
 *      roundConfiguration: // value for 'roundConfiguration'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useAddTabataChronoConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<AddTabataChronoConfigurationMutation, AddTabataChronoConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTabataChronoConfigurationMutation, AddTabataChronoConfigurationMutationVariables>(AddTabataChronoConfigurationDocument, options);
      }
export type AddTabataChronoConfigurationMutationHookResult = ReturnType<typeof useAddTabataChronoConfigurationMutation>;
export type AddTabataChronoConfigurationMutationResult = Apollo.MutationResult<AddTabataChronoConfigurationMutation>;
export type AddTabataChronoConfigurationMutationOptions = Apollo.BaseMutationOptions<AddTabataChronoConfigurationMutation, AddTabataChronoConfigurationMutationVariables>;
export const ListSessionExerciseScoreRanksDocument = gql`
    query listSessionExerciseScoreRanks($exerciseId: ID!, $limit: Int, $offset: Int, $sessionId: ID!, $type: ScoreValueType) {
  listSessionExerciseScoreRanks(
    input: {exerciseId: $exerciseId, limit: $limit, offset: $offset, sessionId: $sessionId, type: $type}
  ) {
    limit
    offset
    total
    userRank {
      position
      score
    }
    result {
      member {
        id
        firstName
        lastName
        nickname
        profile_picture {
          url
        }
      }
      position
      score
    }
  }
}
    `;

/**
 * __useListSessionExerciseScoreRanksQuery__
 *
 * To run a query within a React component, call `useListSessionExerciseScoreRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSessionExerciseScoreRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSessionExerciseScoreRanksQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sessionId: // value for 'sessionId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListSessionExerciseScoreRanksQuery(baseOptions: Apollo.QueryHookOptions<ListSessionExerciseScoreRanksQuery, ListSessionExerciseScoreRanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSessionExerciseScoreRanksQuery, ListSessionExerciseScoreRanksQueryVariables>(ListSessionExerciseScoreRanksDocument, options);
      }
export function useListSessionExerciseScoreRanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSessionExerciseScoreRanksQuery, ListSessionExerciseScoreRanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSessionExerciseScoreRanksQuery, ListSessionExerciseScoreRanksQueryVariables>(ListSessionExerciseScoreRanksDocument, options);
        }
export type ListSessionExerciseScoreRanksQueryHookResult = ReturnType<typeof useListSessionExerciseScoreRanksQuery>;
export type ListSessionExerciseScoreRanksLazyQueryHookResult = ReturnType<typeof useListSessionExerciseScoreRanksLazyQuery>;
export type ListSessionExerciseScoreRanksQueryResult = Apollo.QueryResult<ListSessionExerciseScoreRanksQuery, ListSessionExerciseScoreRanksQueryVariables>;
export const ShowUserSessionExerciseFeedbackDocument = gql`
    query showUserSessionExerciseFeedback($exerciseId: ID!, $userId: ID!) {
  showUserSessionExerciseFeedback(
    input: {exerciseId: $exerciseId, userId: $userId}
  ) {
    comment
    feeling
    note
  }
}
    `;

/**
 * __useShowUserSessionExerciseFeedbackQuery__
 *
 * To run a query within a React component, call `useShowUserSessionExerciseFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowUserSessionExerciseFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowUserSessionExerciseFeedbackQuery({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useShowUserSessionExerciseFeedbackQuery(baseOptions: Apollo.QueryHookOptions<ShowUserSessionExerciseFeedbackQuery, ShowUserSessionExerciseFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowUserSessionExerciseFeedbackQuery, ShowUserSessionExerciseFeedbackQueryVariables>(ShowUserSessionExerciseFeedbackDocument, options);
      }
export function useShowUserSessionExerciseFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowUserSessionExerciseFeedbackQuery, ShowUserSessionExerciseFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowUserSessionExerciseFeedbackQuery, ShowUserSessionExerciseFeedbackQueryVariables>(ShowUserSessionExerciseFeedbackDocument, options);
        }
export type ShowUserSessionExerciseFeedbackQueryHookResult = ReturnType<typeof useShowUserSessionExerciseFeedbackQuery>;
export type ShowUserSessionExerciseFeedbackLazyQueryHookResult = ReturnType<typeof useShowUserSessionExerciseFeedbackLazyQuery>;
export type ShowUserSessionExerciseFeedbackQueryResult = Apollo.QueryResult<ShowUserSessionExerciseFeedbackQuery, ShowUserSessionExerciseFeedbackQueryVariables>;
export const DeleteSessionExerciseDocument = gql`
    mutation deleteSessionExercise($exerciseId: ID!, $sessionId: ID!) {
  deleteSessionExercise(input: {exerciseId: $exerciseId, sessionId: $sessionId})
}
    `;
export type DeleteSessionExerciseMutationFn = Apollo.MutationFunction<DeleteSessionExerciseMutation, DeleteSessionExerciseMutationVariables>;

/**
 * __useDeleteSessionExerciseMutation__
 *
 * To run a mutation, you first call `useDeleteSessionExerciseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionExerciseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionExerciseMutation, { data, loading, error }] = useDeleteSessionExerciseMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDeleteSessionExerciseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionExerciseMutation, DeleteSessionExerciseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionExerciseMutation, DeleteSessionExerciseMutationVariables>(DeleteSessionExerciseDocument, options);
      }
export type DeleteSessionExerciseMutationHookResult = ReturnType<typeof useDeleteSessionExerciseMutation>;
export type DeleteSessionExerciseMutationResult = Apollo.MutationResult<DeleteSessionExerciseMutation>;
export type DeleteSessionExerciseMutationOptions = Apollo.BaseMutationOptions<DeleteSessionExerciseMutation, DeleteSessionExerciseMutationVariables>;
export const DeleteSessionExerciseBlockDocument = gql`
    mutation deleteSessionExerciseBlock($blockId: ID!, $exerciseId: ID!, $sessionId: ID!) {
  deleteSessionExerciseBlock(
    input: {blockId: $blockId, exerciseId: $exerciseId, sessionId: $sessionId}
  )
}
    `;
export type DeleteSessionExerciseBlockMutationFn = Apollo.MutationFunction<DeleteSessionExerciseBlockMutation, DeleteSessionExerciseBlockMutationVariables>;

/**
 * __useDeleteSessionExerciseBlockMutation__
 *
 * To run a mutation, you first call `useDeleteSessionExerciseBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionExerciseBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionExerciseBlockMutation, { data, loading, error }] = useDeleteSessionExerciseBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDeleteSessionExerciseBlockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionExerciseBlockMutation, DeleteSessionExerciseBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionExerciseBlockMutation, DeleteSessionExerciseBlockMutationVariables>(DeleteSessionExerciseBlockDocument, options);
      }
export type DeleteSessionExerciseBlockMutationHookResult = ReturnType<typeof useDeleteSessionExerciseBlockMutation>;
export type DeleteSessionExerciseBlockMutationResult = Apollo.MutationResult<DeleteSessionExerciseBlockMutation>;
export type DeleteSessionExerciseBlockMutationOptions = Apollo.BaseMutationOptions<DeleteSessionExerciseBlockMutation, DeleteSessionExerciseBlockMutationVariables>;
export const UpdateSessionExerciseBlocksOrderDocument = gql`
    mutation updateSessionExerciseBlocksOrder($blockOrders: [BlockOrderInput], $exerciseId: ID!, $sessionId: ID!) {
  updateSessionExerciseBlocksOrder(
    input: {exerciseId: $exerciseId, sessionId: $sessionId, blockOrders: $blockOrders}
  ) {
    id
    name
    blocks {
      id
      content
      order
      image {
        url
      }
      type
      elements
    }
    expectedScores {
      sort
      type
    }
  }
}
    `;
export type UpdateSessionExerciseBlocksOrderMutationFn = Apollo.MutationFunction<UpdateSessionExerciseBlocksOrderMutation, UpdateSessionExerciseBlocksOrderMutationVariables>;

/**
 * __useUpdateSessionExerciseBlocksOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSessionExerciseBlocksOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionExerciseBlocksOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionExerciseBlocksOrderMutation, { data, loading, error }] = useUpdateSessionExerciseBlocksOrderMutation({
 *   variables: {
 *      blockOrders: // value for 'blockOrders'
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUpdateSessionExerciseBlocksOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionExerciseBlocksOrderMutation, UpdateSessionExerciseBlocksOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionExerciseBlocksOrderMutation, UpdateSessionExerciseBlocksOrderMutationVariables>(UpdateSessionExerciseBlocksOrderDocument, options);
      }
export type UpdateSessionExerciseBlocksOrderMutationHookResult = ReturnType<typeof useUpdateSessionExerciseBlocksOrderMutation>;
export type UpdateSessionExerciseBlocksOrderMutationResult = Apollo.MutationResult<UpdateSessionExerciseBlocksOrderMutation>;
export type UpdateSessionExerciseBlocksOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSessionExerciseBlocksOrderMutation, UpdateSessionExerciseBlocksOrderMutationVariables>;
export const CreateProgramCheckoutSessionDocument = gql`
    mutation createProgramCheckoutSession($programId: ID, $cancelUrl: String, $returnUrl: String, $successUrl: String) {
  createProgramCheckoutSession(
    input: {programId: $programId, cancelUrl: $cancelUrl, returnUrl: $returnUrl, successUrl: $successUrl}
  ) {
    url
  }
}
    `;
export type CreateProgramCheckoutSessionMutationFn = Apollo.MutationFunction<CreateProgramCheckoutSessionMutation, CreateProgramCheckoutSessionMutationVariables>;

/**
 * __useCreateProgramCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateProgramCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramCheckoutSessionMutation, { data, loading, error }] = useCreateProgramCheckoutSessionMutation({
 *   variables: {
 *      programId: // value for 'programId'
 *      cancelUrl: // value for 'cancelUrl'
 *      returnUrl: // value for 'returnUrl'
 *      successUrl: // value for 'successUrl'
 *   },
 * });
 */
export function useCreateProgramCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramCheckoutSessionMutation, CreateProgramCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramCheckoutSessionMutation, CreateProgramCheckoutSessionMutationVariables>(CreateProgramCheckoutSessionDocument, options);
      }
export type CreateProgramCheckoutSessionMutationHookResult = ReturnType<typeof useCreateProgramCheckoutSessionMutation>;
export type CreateProgramCheckoutSessionMutationResult = Apollo.MutationResult<CreateProgramCheckoutSessionMutation>;
export type CreateProgramCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateProgramCheckoutSessionMutation, CreateProgramCheckoutSessionMutationVariables>;
export const CreateCustomerPortalSessionDocument = gql`
    mutation createCustomerPortalSession($returnUrl: String) {
  createCustomerPortalSession(input: {returnUrl: $returnUrl}) {
    url
  }
}
    `;
export type CreateCustomerPortalSessionMutationFn = Apollo.MutationFunction<CreateCustomerPortalSessionMutation, CreateCustomerPortalSessionMutationVariables>;

/**
 * __useCreateCustomerPortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerPortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerPortalSessionMutation, { data, loading, error }] = useCreateCustomerPortalSessionMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateCustomerPortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerPortalSessionMutation, CreateCustomerPortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerPortalSessionMutation, CreateCustomerPortalSessionMutationVariables>(CreateCustomerPortalSessionDocument, options);
      }
export type CreateCustomerPortalSessionMutationHookResult = ReturnType<typeof useCreateCustomerPortalSessionMutation>;
export type CreateCustomerPortalSessionMutationResult = Apollo.MutationResult<CreateCustomerPortalSessionMutation>;
export type CreateCustomerPortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateCustomerPortalSessionMutation, CreateCustomerPortalSessionMutationVariables>;
export const CreateAccountLinkDocument = gql`
    mutation createAccountLink($returnUrl: String) {
  createAccountLink(input: {returnUrl: $returnUrl}) {
    url
  }
}
    `;
export type CreateAccountLinkMutationFn = Apollo.MutationFunction<CreateAccountLinkMutation, CreateAccountLinkMutationVariables>;

/**
 * __useCreateAccountLinkMutation__
 *
 * To run a mutation, you first call `useCreateAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountLinkMutation, { data, loading, error }] = useCreateAccountLinkMutation({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useCreateAccountLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountLinkMutation, CreateAccountLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountLinkMutation, CreateAccountLinkMutationVariables>(CreateAccountLinkDocument, options);
      }
export type CreateAccountLinkMutationHookResult = ReturnType<typeof useCreateAccountLinkMutation>;
export type CreateAccountLinkMutationResult = Apollo.MutationResult<CreateAccountLinkMutation>;
export type CreateAccountLinkMutationOptions = Apollo.BaseMutationOptions<CreateAccountLinkMutation, CreateAccountLinkMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...OwnedUser
  }
}
    ${OwnedUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($firstName: String, $lastName: String, $nickname: String, $lang: String, $email: String) {
  createUser(
    input: {firstName: $firstName, lastName: $lastName, nickname: $nickname, lang: $lang, email: $email}
  ) {
    ...OwnedUser
  }
}
    ${OwnedUserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      nickname: // value for 'nickname'
 *      lang: // value for 'lang'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ListUsersDocument = gql`
    query listUsers($limit: Int, $offset: Int, $name: String) {
  listUsers(
    input: {limit: $limit, offset: $offset, filter: {nameOrNickname: {contains: $name}}}
  ) {
    limit
    total
    offset
    result {
      id
      firstName
      lastName
      nickname
      profile_picture {
        url
      }
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const ShowUserDocument = gql`
    query showUser($id: ID, $pubnub_uuid: String) {
  showUser(input: {id: $id, pubnub_uuid: $pubnub_uuid}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useShowUserQuery__
 *
 * To run a query within a React component, call `useShowUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      pubnub_uuid: // value for 'pubnub_uuid'
 *   },
 * });
 */
export function useShowUserQuery(baseOptions?: Apollo.QueryHookOptions<ShowUserQuery, ShowUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowUserQuery, ShowUserQueryVariables>(ShowUserDocument, options);
      }
export function useShowUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowUserQuery, ShowUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowUserQuery, ShowUserQueryVariables>(ShowUserDocument, options);
        }
export type ShowUserQueryHookResult = ReturnType<typeof useShowUserQuery>;
export type ShowUserLazyQueryHookResult = ReturnType<typeof useShowUserLazyQuery>;
export type ShowUserQueryResult = Apollo.QueryResult<ShowUserQuery, ShowUserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($city: String, $country: String, $firstName: String, $lastName: String, $height: Int, $weight: Float, $sportsRoom_name: String, $birthDate: Date, $gender: Gender) {
  updateUser(
    input: {city: $city, country: $country, firstName: $firstName, lastName: $lastName, height: $height, weight: $weight, sportsRoom_name: $sportsRoom_name, birthDate: $birthDate, gender: $gender}
  ) {
    ...OwnedUser
  }
}
    ${OwnedUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      city: // value for 'city'
 *      country: // value for 'country'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      height: // value for 'height'
 *      weight: // value for 'weight'
 *      sportsRoom_name: // value for 'sportsRoom_name'
 *      birthDate: // value for 'birthDate'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AddUserProfilePictureDocument = gql`
    mutation addUserProfilePicture($file: Upload!) {
  addUserProfilePicture(file: $file) {
    url
  }
}
    `;
export type AddUserProfilePictureMutationFn = Apollo.MutationFunction<AddUserProfilePictureMutation, AddUserProfilePictureMutationVariables>;

/**
 * __useAddUserProfilePictureMutation__
 *
 * To run a mutation, you first call `useAddUserProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserProfilePictureMutation, { data, loading, error }] = useAddUserProfilePictureMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddUserProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<AddUserProfilePictureMutation, AddUserProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserProfilePictureMutation, AddUserProfilePictureMutationVariables>(AddUserProfilePictureDocument, options);
      }
export type AddUserProfilePictureMutationHookResult = ReturnType<typeof useAddUserProfilePictureMutation>;
export type AddUserProfilePictureMutationResult = Apollo.MutationResult<AddUserProfilePictureMutation>;
export type AddUserProfilePictureMutationOptions = Apollo.BaseMutationOptions<AddUserProfilePictureMutation, AddUserProfilePictureMutationVariables>;
export const VerifyUserEmailDocument = gql`
    mutation verifyUserEmail($code: String) {
  verifyUserEmail(input: {code: $code}) {
    id
  }
}
    `;
export type VerifyUserEmailMutationFn = Apollo.MutationFunction<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>;

/**
 * __useVerifyUserEmailMutation__
 *
 * To run a mutation, you first call `useVerifyUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserEmailMutation, { data, loading, error }] = useVerifyUserEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyUserEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>(VerifyUserEmailDocument, options);
      }
export type VerifyUserEmailMutationHookResult = ReturnType<typeof useVerifyUserEmailMutation>;
export type VerifyUserEmailMutationResult = Apollo.MutationResult<VerifyUserEmailMutation>;
export type VerifyUserEmailMutationOptions = Apollo.BaseMutationOptions<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>;
export const SetUserPrivateFieldsDocument = gql`
    mutation setUserPrivateFields($fields: [PrivateFieldInput]) {
  setUserPrivateFields(input: {fields: $fields}) {
    ...OwnedUser
  }
}
    ${OwnedUserFragmentDoc}`;
export type SetUserPrivateFieldsMutationFn = Apollo.MutationFunction<SetUserPrivateFieldsMutation, SetUserPrivateFieldsMutationVariables>;

/**
 * __useSetUserPrivateFieldsMutation__
 *
 * To run a mutation, you first call `useSetUserPrivateFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPrivateFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPrivateFieldsMutation, { data, loading, error }] = useSetUserPrivateFieldsMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useSetUserPrivateFieldsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPrivateFieldsMutation, SetUserPrivateFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPrivateFieldsMutation, SetUserPrivateFieldsMutationVariables>(SetUserPrivateFieldsDocument, options);
      }
export type SetUserPrivateFieldsMutationHookResult = ReturnType<typeof useSetUserPrivateFieldsMutation>;
export type SetUserPrivateFieldsMutationResult = Apollo.MutationResult<SetUserPrivateFieldsMutation>;
export type SetUserPrivateFieldsMutationOptions = Apollo.BaseMutationOptions<SetUserPrivateFieldsMutation, SetUserPrivateFieldsMutationVariables>;
export const ListWodBookEntriesDocument = gql`
    query listWodBookEntries($limit: Int, $offset: Int) {
  listWodBookEntries(input: {limit: $limit, offset: $offset}) {
    total
    limit
    offset
    result {
      id
      exercise {
        name
        blocks {
          content
          type
          image {
            url
          }
          order
        }
        expectedScores {
          type
          sort
        }
      }
      scoreEntries {
        id
        date
        scores {
          value
          type
        }
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useListWodBookEntriesQuery__
 *
 * To run a query within a React component, call `useListWodBookEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWodBookEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWodBookEntriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListWodBookEntriesQuery(baseOptions?: Apollo.QueryHookOptions<ListWodBookEntriesQuery, ListWodBookEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWodBookEntriesQuery, ListWodBookEntriesQueryVariables>(ListWodBookEntriesDocument, options);
      }
export function useListWodBookEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWodBookEntriesQuery, ListWodBookEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWodBookEntriesQuery, ListWodBookEntriesQueryVariables>(ListWodBookEntriesDocument, options);
        }
export type ListWodBookEntriesQueryHookResult = ReturnType<typeof useListWodBookEntriesQuery>;
export type ListWodBookEntriesLazyQueryHookResult = ReturnType<typeof useListWodBookEntriesLazyQuery>;
export type ListWodBookEntriesQueryResult = Apollo.QueryResult<ListWodBookEntriesQuery, ListWodBookEntriesQueryVariables>;
export const ShowWodBookEntryDocument = gql`
    query showWodBookEntry($entryId: ID!) {
  showWodBookEntry(input: {entryId: $entryId}) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;

/**
 * __useShowWodBookEntryQuery__
 *
 * To run a query within a React component, call `useShowWodBookEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowWodBookEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowWodBookEntryQuery({
 *   variables: {
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useShowWodBookEntryQuery(baseOptions: Apollo.QueryHookOptions<ShowWodBookEntryQuery, ShowWodBookEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowWodBookEntryQuery, ShowWodBookEntryQueryVariables>(ShowWodBookEntryDocument, options);
      }
export function useShowWodBookEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowWodBookEntryQuery, ShowWodBookEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowWodBookEntryQuery, ShowWodBookEntryQueryVariables>(ShowWodBookEntryDocument, options);
        }
export type ShowWodBookEntryQueryHookResult = ReturnType<typeof useShowWodBookEntryQuery>;
export type ShowWodBookEntryLazyQueryHookResult = ReturnType<typeof useShowWodBookEntryLazyQuery>;
export type ShowWodBookEntryQueryResult = Apollo.QueryResult<ShowWodBookEntryQuery, ShowWodBookEntryQueryVariables>;
export const ListPublicWodCategoriesDocument = gql`
    query listPublicWodCategories($limit: Int, $offset: Int) {
  listPublicWodCategories(input: {limit: $limit, offset: $offset}) {
    limit
    offset
    total
    result {
      id
      createdAt
      updatedAt
      published
      translations {
        lang
        name
      }
    }
  }
}
    `;

/**
 * __useListPublicWodCategoriesQuery__
 *
 * To run a query within a React component, call `useListPublicWodCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicWodCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicWodCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPublicWodCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>(ListPublicWodCategoriesDocument, options);
      }
export function useListPublicWodCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>(ListPublicWodCategoriesDocument, options);
        }
export type ListPublicWodCategoriesQueryHookResult = ReturnType<typeof useListPublicWodCategoriesQuery>;
export type ListPublicWodCategoriesLazyQueryHookResult = ReturnType<typeof useListPublicWodCategoriesLazyQuery>;
export type ListPublicWodCategoriesQueryResult = Apollo.QueryResult<ListPublicWodCategoriesQuery, ListPublicWodCategoriesQueryVariables>;
export const ListPublicWodsDocument = gql`
    query listPublicWods($categoryId: ID, $limit: Int, $offset: Int) {
  listPublicWods(input: {categoryId: $categoryId, limit: $limit, offset: $offset}) {
    limit
    offset
    total
    result {
      id
      createdAt
      published
      category {
        translations {
          lang
          name
        }
      }
      exercise {
        expectedScores {
          type
          sort
        }
        nameTranslations {
          lang
          name
        }
      }
      latestUserScores {
        limit
        offset
        total
        result {
          date
          id
          scores {
            type
            value
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListPublicWodsQuery__
 *
 * To run a query within a React component, call `useListPublicWodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicWodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicWodsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListPublicWodsQuery(baseOptions?: Apollo.QueryHookOptions<ListPublicWodsQuery, ListPublicWodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPublicWodsQuery, ListPublicWodsQueryVariables>(ListPublicWodsDocument, options);
      }
export function useListPublicWodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPublicWodsQuery, ListPublicWodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPublicWodsQuery, ListPublicWodsQueryVariables>(ListPublicWodsDocument, options);
        }
export type ListPublicWodsQueryHookResult = ReturnType<typeof useListPublicWodsQuery>;
export type ListPublicWodsLazyQueryHookResult = ReturnType<typeof useListPublicWodsLazyQuery>;
export type ListPublicWodsQueryResult = Apollo.QueryResult<ListPublicWodsQuery, ListPublicWodsQueryVariables>;
export const ShowPublicWodDocument = gql`
    query showPublicWod($wodId: ID!) {
  showPublicWod(input: {wodId: $wodId}) {
    id
    createdAt
    published
    category {
      translations {
        lang
        name
      }
    }
    exercise {
      blockTranslations {
        blocks {
          content
          elements
          id
          image {
            url
          }
          order
          type
        }
        lang
      }
      expectedScores {
        type
        sort
      }
      chronoConfiguration {
        ... on AhapChronoConfiguration {
          ahaps {
            timeLimit
            restTime
          }
          type
        }
        ... on AmrapChronoConfiguration {
          roundConfiguration {
            number
            restTime
            timeLimit
          }
          setConfiguration {
            number
            restTime
          }
          type
        }
        ... on EmomChronoConfiguration {
          emoms {
            everyTime
            restTime
            roundsNumber
            whileTime
          }
          type
        }
        ... on ForTimeChronoConfiguration {
          type
          timeLimit
          setConfiguration {
            number
            restTime
          }
        }
        ... on TabataChronoConfiguration {
          type
          roundConfiguration {
            number
            restTime
            timeLimit
          }
          setConfiguration {
            number
            restTime
          }
        }
      }
      nameTranslations {
        lang
        name
      }
    }
    latestUserScores {
      limit
      offset
      total
      result {
        date
        id
        scores {
          type
          value
        }
      }
    }
  }
}
    `;

/**
 * __useShowPublicWodQuery__
 *
 * To run a query within a React component, call `useShowPublicWodQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowPublicWodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowPublicWodQuery({
 *   variables: {
 *      wodId: // value for 'wodId'
 *   },
 * });
 */
export function useShowPublicWodQuery(baseOptions: Apollo.QueryHookOptions<ShowPublicWodQuery, ShowPublicWodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowPublicWodQuery, ShowPublicWodQueryVariables>(ShowPublicWodDocument, options);
      }
export function useShowPublicWodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowPublicWodQuery, ShowPublicWodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowPublicWodQuery, ShowPublicWodQueryVariables>(ShowPublicWodDocument, options);
        }
export type ShowPublicWodQueryHookResult = ReturnType<typeof useShowPublicWodQuery>;
export type ShowPublicWodLazyQueryHookResult = ReturnType<typeof useShowPublicWodLazyQuery>;
export type ShowPublicWodQueryResult = Apollo.QueryResult<ShowPublicWodQuery, ShowPublicWodQueryVariables>;
export const SaveSessionExerciseToWodBookDocument = gql`
    mutation saveSessionExerciseToWodBook($exerciseId: ID!, $sessionId: ID!, $title: String) {
  saveSessionExerciseToWodBook(
    input: {exerciseId: $exerciseId, sessionId: $sessionId, title: $title}
  ) {
    id
    createdAt
    updatedAt
    exercise {
      name
      blocks {
        order
        type
        content
        image {
          url
        }
      }
    }
    createdAt
    updatedAt
    scoreEntries {
      id
      date
      scores {
        value
        type
      }
    }
  }
}
    `;
export type SaveSessionExerciseToWodBookMutationFn = Apollo.MutationFunction<SaveSessionExerciseToWodBookMutation, SaveSessionExerciseToWodBookMutationVariables>;

/**
 * __useSaveSessionExerciseToWodBookMutation__
 *
 * To run a mutation, you first call `useSaveSessionExerciseToWodBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSessionExerciseToWodBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSessionExerciseToWodBookMutation, { data, loading, error }] = useSaveSessionExerciseToWodBookMutation({
 *   variables: {
 *      exerciseId: // value for 'exerciseId'
 *      sessionId: // value for 'sessionId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useSaveSessionExerciseToWodBookMutation(baseOptions?: Apollo.MutationHookOptions<SaveSessionExerciseToWodBookMutation, SaveSessionExerciseToWodBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSessionExerciseToWodBookMutation, SaveSessionExerciseToWodBookMutationVariables>(SaveSessionExerciseToWodBookDocument, options);
      }
export type SaveSessionExerciseToWodBookMutationHookResult = ReturnType<typeof useSaveSessionExerciseToWodBookMutation>;
export type SaveSessionExerciseToWodBookMutationResult = Apollo.MutationResult<SaveSessionExerciseToWodBookMutation>;
export type SaveSessionExerciseToWodBookMutationOptions = Apollo.BaseMutationOptions<SaveSessionExerciseToWodBookMutation, SaveSessionExerciseToWodBookMutationVariables>;
export const DeleteWodBookEntryDocument = gql`
    mutation deleteWodBookEntry($id: ID!) {
  deleteWodBookEntry(input: {id: $id})
}
    `;
export type DeleteWodBookEntryMutationFn = Apollo.MutationFunction<DeleteWodBookEntryMutation, DeleteWodBookEntryMutationVariables>;

/**
 * __useDeleteWodBookEntryMutation__
 *
 * To run a mutation, you first call `useDeleteWodBookEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWodBookEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWodBookEntryMutation, { data, loading, error }] = useDeleteWodBookEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWodBookEntryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWodBookEntryMutation, DeleteWodBookEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWodBookEntryMutation, DeleteWodBookEntryMutationVariables>(DeleteWodBookEntryDocument, options);
      }
export type DeleteWodBookEntryMutationHookResult = ReturnType<typeof useDeleteWodBookEntryMutation>;
export type DeleteWodBookEntryMutationResult = Apollo.MutationResult<DeleteWodBookEntryMutation>;
export type DeleteWodBookEntryMutationOptions = Apollo.BaseMutationOptions<DeleteWodBookEntryMutation, DeleteWodBookEntryMutationVariables>;
export const AddWodBookEntryScoresDocument = gql`
    mutation addWodBookEntryScores($id: ID!, $scores: [WodBookEntryScoreInput], $date: Date) {
  addWodBookEntryScores(input: {id: $id, scores: $scores, date: $date}) {
    id
    scoreEntries {
      id
      date
      scores {
        value
        type
      }
    }
  }
}
    `;
export type AddWodBookEntryScoresMutationFn = Apollo.MutationFunction<AddWodBookEntryScoresMutation, AddWodBookEntryScoresMutationVariables>;

/**
 * __useAddWodBookEntryScoresMutation__
 *
 * To run a mutation, you first call `useAddWodBookEntryScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWodBookEntryScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWodBookEntryScoresMutation, { data, loading, error }] = useAddWodBookEntryScoresMutation({
 *   variables: {
 *      id: // value for 'id'
 *      scores: // value for 'scores'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddWodBookEntryScoresMutation(baseOptions?: Apollo.MutationHookOptions<AddWodBookEntryScoresMutation, AddWodBookEntryScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWodBookEntryScoresMutation, AddWodBookEntryScoresMutationVariables>(AddWodBookEntryScoresDocument, options);
      }
export type AddWodBookEntryScoresMutationHookResult = ReturnType<typeof useAddWodBookEntryScoresMutation>;
export type AddWodBookEntryScoresMutationResult = Apollo.MutationResult<AddWodBookEntryScoresMutation>;
export type AddWodBookEntryScoresMutationOptions = Apollo.BaseMutationOptions<AddWodBookEntryScoresMutation, AddWodBookEntryScoresMutationVariables>;
export const DeleteWodBookEntryScoresDocument = gql`
    mutation deleteWodBookEntryScores($scoreId: ID!, $entryId: ID!) {
  deleteWodBookEntryScores(input: {scoreId: $scoreId, entryId: $entryId}) {
    id
    scoreEntries {
      id
      date
      scores {
        value
        type
      }
    }
  }
}
    `;
export type DeleteWodBookEntryScoresMutationFn = Apollo.MutationFunction<DeleteWodBookEntryScoresMutation, DeleteWodBookEntryScoresMutationVariables>;

/**
 * __useDeleteWodBookEntryScoresMutation__
 *
 * To run a mutation, you first call `useDeleteWodBookEntryScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWodBookEntryScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWodBookEntryScoresMutation, { data, loading, error }] = useDeleteWodBookEntryScoresMutation({
 *   variables: {
 *      scoreId: // value for 'scoreId'
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useDeleteWodBookEntryScoresMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWodBookEntryScoresMutation, DeleteWodBookEntryScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWodBookEntryScoresMutation, DeleteWodBookEntryScoresMutationVariables>(DeleteWodBookEntryScoresDocument, options);
      }
export type DeleteWodBookEntryScoresMutationHookResult = ReturnType<typeof useDeleteWodBookEntryScoresMutation>;
export type DeleteWodBookEntryScoresMutationResult = Apollo.MutationResult<DeleteWodBookEntryScoresMutation>;
export type DeleteWodBookEntryScoresMutationOptions = Apollo.BaseMutationOptions<DeleteWodBookEntryScoresMutation, DeleteWodBookEntryScoresMutationVariables>;
export const CreateWodBookEntryDocument = gql`
    mutation createWodBookEntry($exerciseName: String!) {
  createWodBookEntry(input: {exerciseName: $exerciseName}) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type CreateWodBookEntryMutationFn = Apollo.MutationFunction<CreateWodBookEntryMutation, CreateWodBookEntryMutationVariables>;

/**
 * __useCreateWodBookEntryMutation__
 *
 * To run a mutation, you first call `useCreateWodBookEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWodBookEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWodBookEntryMutation, { data, loading, error }] = useCreateWodBookEntryMutation({
 *   variables: {
 *      exerciseName: // value for 'exerciseName'
 *   },
 * });
 */
export function useCreateWodBookEntryMutation(baseOptions?: Apollo.MutationHookOptions<CreateWodBookEntryMutation, CreateWodBookEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWodBookEntryMutation, CreateWodBookEntryMutationVariables>(CreateWodBookEntryDocument, options);
      }
export type CreateWodBookEntryMutationHookResult = ReturnType<typeof useCreateWodBookEntryMutation>;
export type CreateWodBookEntryMutationResult = Apollo.MutationResult<CreateWodBookEntryMutation>;
export type CreateWodBookEntryMutationOptions = Apollo.BaseMutationOptions<CreateWodBookEntryMutation, CreateWodBookEntryMutationVariables>;
export const UpdateWodBookEntryDocument = gql`
    mutation updateWodBookEntry($entryId: ID!, $exerciseName: String!) {
  updateWodBookEntry(input: {entryId: $entryId, exerciseName: $exerciseName}) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type UpdateWodBookEntryMutationFn = Apollo.MutationFunction<UpdateWodBookEntryMutation, UpdateWodBookEntryMutationVariables>;

/**
 * __useUpdateWodBookEntryMutation__
 *
 * To run a mutation, you first call `useUpdateWodBookEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWodBookEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWodBookEntryMutation, { data, loading, error }] = useUpdateWodBookEntryMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      exerciseName: // value for 'exerciseName'
 *   },
 * });
 */
export function useUpdateWodBookEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWodBookEntryMutation, UpdateWodBookEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWodBookEntryMutation, UpdateWodBookEntryMutationVariables>(UpdateWodBookEntryDocument, options);
      }
export type UpdateWodBookEntryMutationHookResult = ReturnType<typeof useUpdateWodBookEntryMutation>;
export type UpdateWodBookEntryMutationResult = Apollo.MutationResult<UpdateWodBookEntryMutation>;
export type UpdateWodBookEntryMutationOptions = Apollo.BaseMutationOptions<UpdateWodBookEntryMutation, UpdateWodBookEntryMutationVariables>;
export const AddWodBookEntryBlockDocument = gql`
    mutation addWodBookEntryBlock($content: String, $elements: [String], $entryId: ID!, $image: Upload, $type: SessionExerciseBlockType) {
  addWodBookEntryBlock(
    input: {content: $content, elements: $elements, entryId: $entryId, image: $image, type: $type}
  ) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type AddWodBookEntryBlockMutationFn = Apollo.MutationFunction<AddWodBookEntryBlockMutation, AddWodBookEntryBlockMutationVariables>;

/**
 * __useAddWodBookEntryBlockMutation__
 *
 * To run a mutation, you first call `useAddWodBookEntryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWodBookEntryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWodBookEntryBlockMutation, { data, loading, error }] = useAddWodBookEntryBlockMutation({
 *   variables: {
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      entryId: // value for 'entryId'
 *      image: // value for 'image'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddWodBookEntryBlockMutation(baseOptions?: Apollo.MutationHookOptions<AddWodBookEntryBlockMutation, AddWodBookEntryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWodBookEntryBlockMutation, AddWodBookEntryBlockMutationVariables>(AddWodBookEntryBlockDocument, options);
      }
export type AddWodBookEntryBlockMutationHookResult = ReturnType<typeof useAddWodBookEntryBlockMutation>;
export type AddWodBookEntryBlockMutationResult = Apollo.MutationResult<AddWodBookEntryBlockMutation>;
export type AddWodBookEntryBlockMutationOptions = Apollo.BaseMutationOptions<AddWodBookEntryBlockMutation, AddWodBookEntryBlockMutationVariables>;
export const UpdateWodBookEntryBlockDocument = gql`
    mutation updateWodBookEntryBlock($blockId: ID!, $content: String, $elements: [String], $entryId: ID!, $image: Upload, $type: SessionExerciseBlockType) {
  updateWodBookEntryBlock(
    input: {blockId: $blockId, content: $content, elements: $elements, entryId: $entryId, image: $image, type: $type}
  ) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type UpdateWodBookEntryBlockMutationFn = Apollo.MutationFunction<UpdateWodBookEntryBlockMutation, UpdateWodBookEntryBlockMutationVariables>;

/**
 * __useUpdateWodBookEntryBlockMutation__
 *
 * To run a mutation, you first call `useUpdateWodBookEntryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWodBookEntryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWodBookEntryBlockMutation, { data, loading, error }] = useUpdateWodBookEntryBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      content: // value for 'content'
 *      elements: // value for 'elements'
 *      entryId: // value for 'entryId'
 *      image: // value for 'image'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateWodBookEntryBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWodBookEntryBlockMutation, UpdateWodBookEntryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWodBookEntryBlockMutation, UpdateWodBookEntryBlockMutationVariables>(UpdateWodBookEntryBlockDocument, options);
      }
export type UpdateWodBookEntryBlockMutationHookResult = ReturnType<typeof useUpdateWodBookEntryBlockMutation>;
export type UpdateWodBookEntryBlockMutationResult = Apollo.MutationResult<UpdateWodBookEntryBlockMutation>;
export type UpdateWodBookEntryBlockMutationOptions = Apollo.BaseMutationOptions<UpdateWodBookEntryBlockMutation, UpdateWodBookEntryBlockMutationVariables>;
export const DeleteWodBookEntryBlockDocument = gql`
    mutation deleteWodBookEntryBlock($blockId: ID!, $entryId: ID!) {
  deleteWodBookEntryBlock(input: {blockId: $blockId, entryId: $entryId})
}
    `;
export type DeleteWodBookEntryBlockMutationFn = Apollo.MutationFunction<DeleteWodBookEntryBlockMutation, DeleteWodBookEntryBlockMutationVariables>;

/**
 * __useDeleteWodBookEntryBlockMutation__
 *
 * To run a mutation, you first call `useDeleteWodBookEntryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWodBookEntryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWodBookEntryBlockMutation, { data, loading, error }] = useDeleteWodBookEntryBlockMutation({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useDeleteWodBookEntryBlockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWodBookEntryBlockMutation, DeleteWodBookEntryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWodBookEntryBlockMutation, DeleteWodBookEntryBlockMutationVariables>(DeleteWodBookEntryBlockDocument, options);
      }
export type DeleteWodBookEntryBlockMutationHookResult = ReturnType<typeof useDeleteWodBookEntryBlockMutation>;
export type DeleteWodBookEntryBlockMutationResult = Apollo.MutationResult<DeleteWodBookEntryBlockMutation>;
export type DeleteWodBookEntryBlockMutationOptions = Apollo.BaseMutationOptions<DeleteWodBookEntryBlockMutation, DeleteWodBookEntryBlockMutationVariables>;
export const SetWodBookEntryExpectedScoresDocument = gql`
    mutation setWodBookEntryExpectedScores($entryId: ID!, $expectedScores: [ExpectedScoreInput]) {
  setWodBookEntryExpectedScores(
    input: {entryId: $entryId, expectedScores: $expectedScores}
  ) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type SetWodBookEntryExpectedScoresMutationFn = Apollo.MutationFunction<SetWodBookEntryExpectedScoresMutation, SetWodBookEntryExpectedScoresMutationVariables>;

/**
 * __useSetWodBookEntryExpectedScoresMutation__
 *
 * To run a mutation, you first call `useSetWodBookEntryExpectedScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWodBookEntryExpectedScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWodBookEntryExpectedScoresMutation, { data, loading, error }] = useSetWodBookEntryExpectedScoresMutation({
 *   variables: {
 *      entryId: // value for 'entryId'
 *      expectedScores: // value for 'expectedScores'
 *   },
 * });
 */
export function useSetWodBookEntryExpectedScoresMutation(baseOptions?: Apollo.MutationHookOptions<SetWodBookEntryExpectedScoresMutation, SetWodBookEntryExpectedScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWodBookEntryExpectedScoresMutation, SetWodBookEntryExpectedScoresMutationVariables>(SetWodBookEntryExpectedScoresDocument, options);
      }
export type SetWodBookEntryExpectedScoresMutationHookResult = ReturnType<typeof useSetWodBookEntryExpectedScoresMutation>;
export type SetWodBookEntryExpectedScoresMutationResult = Apollo.MutationResult<SetWodBookEntryExpectedScoresMutation>;
export type SetWodBookEntryExpectedScoresMutationOptions = Apollo.BaseMutationOptions<SetWodBookEntryExpectedScoresMutation, SetWodBookEntryExpectedScoresMutationVariables>;
export const AddPublicWodScoresDocument = gql`
    mutation addPublicWodScores($wodId: ID!, $scores: [PublicWodScoreInput], $date: Date) {
  addPublicWodScores(input: {wodId: $wodId, scores: $scores, date: $date}) {
    id
    date
    scores {
      type
      value
    }
  }
}
    `;
export type AddPublicWodScoresMutationFn = Apollo.MutationFunction<AddPublicWodScoresMutation, AddPublicWodScoresMutationVariables>;

/**
 * __useAddPublicWodScoresMutation__
 *
 * To run a mutation, you first call `useAddPublicWodScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublicWodScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublicWodScoresMutation, { data, loading, error }] = useAddPublicWodScoresMutation({
 *   variables: {
 *      wodId: // value for 'wodId'
 *      scores: // value for 'scores'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useAddPublicWodScoresMutation(baseOptions?: Apollo.MutationHookOptions<AddPublicWodScoresMutation, AddPublicWodScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPublicWodScoresMutation, AddPublicWodScoresMutationVariables>(AddPublicWodScoresDocument, options);
      }
export type AddPublicWodScoresMutationHookResult = ReturnType<typeof useAddPublicWodScoresMutation>;
export type AddPublicWodScoresMutationResult = Apollo.MutationResult<AddPublicWodScoresMutation>;
export type AddPublicWodScoresMutationOptions = Apollo.BaseMutationOptions<AddPublicWodScoresMutation, AddPublicWodScoresMutationVariables>;
export const DeletePublicWodScoresDocument = gql`
    mutation deletePublicWodScores($scoreEntryId: ID!) {
  deletePublicWodScores(input: {scoreEntryId: $scoreEntryId})
}
    `;
export type DeletePublicWodScoresMutationFn = Apollo.MutationFunction<DeletePublicWodScoresMutation, DeletePublicWodScoresMutationVariables>;

/**
 * __useDeletePublicWodScoresMutation__
 *
 * To run a mutation, you first call `useDeletePublicWodScoresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicWodScoresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicWodScoresMutation, { data, loading, error }] = useDeletePublicWodScoresMutation({
 *   variables: {
 *      scoreEntryId: // value for 'scoreEntryId'
 *   },
 * });
 */
export function useDeletePublicWodScoresMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicWodScoresMutation, DeletePublicWodScoresMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicWodScoresMutation, DeletePublicWodScoresMutationVariables>(DeletePublicWodScoresDocument, options);
      }
export type DeletePublicWodScoresMutationHookResult = ReturnType<typeof useDeletePublicWodScoresMutation>;
export type DeletePublicWodScoresMutationResult = Apollo.MutationResult<DeletePublicWodScoresMutation>;
export type DeletePublicWodScoresMutationOptions = Apollo.BaseMutationOptions<DeletePublicWodScoresMutation, DeletePublicWodScoresMutationVariables>;
export const UpdateWodBookEntryBlocksOrderDocument = gql`
    mutation updateWodBookEntryBlocksOrder($blockOrders: [BlockOrderInput], $entryId: ID!) {
  updateWodBookEntryBlocksOrder(
    input: {blockOrders: $blockOrders, entryId: $entryId}
  ) {
    ...WodBookEntry
  }
}
    ${WodBookEntryFragmentDoc}`;
export type UpdateWodBookEntryBlocksOrderMutationFn = Apollo.MutationFunction<UpdateWodBookEntryBlocksOrderMutation, UpdateWodBookEntryBlocksOrderMutationVariables>;

/**
 * __useUpdateWodBookEntryBlocksOrderMutation__
 *
 * To run a mutation, you first call `useUpdateWodBookEntryBlocksOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWodBookEntryBlocksOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWodBookEntryBlocksOrderMutation, { data, loading, error }] = useUpdateWodBookEntryBlocksOrderMutation({
 *   variables: {
 *      blockOrders: // value for 'blockOrders'
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useUpdateWodBookEntryBlocksOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWodBookEntryBlocksOrderMutation, UpdateWodBookEntryBlocksOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWodBookEntryBlocksOrderMutation, UpdateWodBookEntryBlocksOrderMutationVariables>(UpdateWodBookEntryBlocksOrderDocument, options);
      }
export type UpdateWodBookEntryBlocksOrderMutationHookResult = ReturnType<typeof useUpdateWodBookEntryBlocksOrderMutation>;
export type UpdateWodBookEntryBlocksOrderMutationResult = Apollo.MutationResult<UpdateWodBookEntryBlocksOrderMutation>;
export type UpdateWodBookEntryBlocksOrderMutationOptions = Apollo.BaseMutationOptions<UpdateWodBookEntryBlocksOrderMutation, UpdateWodBookEntryBlocksOrderMutationVariables>;