import { useContext, useEffect, useState } from 'react'
import { Play } from '../../../main/Icons'
import { TimerConfigDataContext } from '../contexts/TimerConfigDataContext'
import { useHistory } from 'react-router-dom';
import './TimerPreStart.scss'
import { TimerContext } from '../contexts/TimerContext';
import { CHRONO_ACTIVITIES, CHRONO_SCREEN } from '../../../../config/type';
import { useTranslation } from 'react-i18next';
import { secToString } from '../../../../utils/chrono';

const TimerPreStart = ({setPreStart} : any) => {
	const {t} = useTranslation()
	const [phrase, setPhrase] = useState<any>([])
	const {timerConfig} = useContext(TimerConfigDataContext)


	//Phrase generation
	useEffect(() => {
		const texts = []

		//AMRAP
		if(timerConfig.type === CHRONO_ACTIVITIES[0].name){
			let restTime = timerConfig.setConfiguration.restTime
			let number = timerConfig.setConfiguration.number === 0 ? 1 : timerConfig.setConfiguration.number
			let timeLimit = timerConfig.roundConfiguration.timeLimit

			texts.push(t('chrono:totalTime') + ' : ' + secToString(restTime * number + timeLimit * number))
			if(number !== 1 || restTime !== 0) texts.unshift(t('chrono:sets') + ' : ' + number)
		}

		//EMOM
		else if(timerConfig.type === CHRONO_ACTIVITIES[1].name){
			const emomConfig = {
				number : timerConfig?.emoms.length,
				rounds : function() {
					let total = 0
					timerConfig?.emoms.forEach((emom : any) => {total += emom.roundsNumber})
					return total
				},
				totalTime: function() {
					let total = 0
					timerConfig?.emoms.forEach((emom : any) => {
						total += emom.everyTime * emom.roundsNumber + emom.restTime
					})
					return total
				}
			}

			if(emomConfig.number > 1) texts.push('EMOMS : ' + emomConfig.number)
			texts.push(t('chrono:rounds') + ' : ' + emomConfig.rounds())
			texts.push(t('chrono:totalTime') + ' : ' + secToString(emomConfig.totalTime()))
		}

		//FORTIME
		else if(timerConfig.type === CHRONO_ACTIVITIES[2].name){
			
			if(timerConfig.timeLimit !== 0){
				if(timerConfig.setConfiguration.number > 0){
					texts.push(t('chrono:sets') + ' : ' + timerConfig.setConfiguration.number)
				}

				let rounds = timerConfig.setConfiguration.number === 0 ? 1 : timerConfig.setConfiguration.number
				let restTime = timerConfig.setConfiguration.restTime === 0 ? 1 : timerConfig.setConfiguration.restTime
				let totalTime = rounds * (timerConfig.timeLimit * restTime)

				texts.push(t('chrono:totalTime') + ' : ' + secToString(totalTime))
			}
		}

		//TABATA
		else if(timerConfig.type === CHRONO_ACTIVITIES[3].name){
			let timeSet = (timerConfig.roundConfiguration.timeLimit * timerConfig.roundConfiguration.number) + (timerConfig.roundConfiguration.number - 1) * timerConfig.roundConfiguration.restTime
			texts.push(t('chrono:rounds') + ' : ' + timerConfig.roundConfiguration.number)
			
			if(timerConfig.setConfiguration.number > 0){
				timeSet = timeSet * timerConfig.setConfiguration.number + (timerConfig.setConfiguration.number - 1) * timerConfig.setConfiguration.restTime
				texts.push(t('chrono:sets') + ' : ' + timerConfig.setConfiguration.number)
			}

			texts.push(t('chrono:totalTime') + ' : ' + secToString(timeSet))
		}

		//AHAP
		else if(timerConfig.type === CHRONO_ACTIVITIES[4].name){
			if(timerConfig.ahaps.length > 0){
				texts.push('AHAP' + ' : ' + timerConfig.ahaps.length)
			}

			let totalTime = 0
			timerConfig.ahaps.forEach((ahap : any) => {
				totalTime += ahap.timeLimit + ahap.restTime
			})

			texts.push(t('chrono:totalTime') + ' : ' + secToString(totalTime))

		}

		setPhrase(texts)



	}, [timerConfig])

	

	return (
		<div className="timerPreStart">

			<h1 className="weightSemiBold">
				{timerConfig.type}
			</h1>

			<button>
				<div className="circle" onClick={() => setPreStart(false)}>
					<Play />
				</div>
			</button>

			<div className="details">

				{
					phrase.map((phraseU : string, i :number) => (
						<div className="phrase titleXL weightMedium" key={i}>
							{phraseU}
						</div>
					))
				}

			</div>
			
		</div>
	)
}

export default TimerPreStart
