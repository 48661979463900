import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { ListProgramUsersDocument, useRemoveProgramUserMutation } from '../../../../generated/gql';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import UserCard from '../../../common/UserCard';
import Button from '../../../common/Button';
import { Send, X } from '../../../main/Icons';
import Modal from '../../../common/Modal';
import { capitalizeName } from '../../../../utils/string';
import update from 'immutability-helper';

import './ProgListMemberLine.scss';
import Loader from '../../../main/Loader';
import { useHistory } from 'react-router-dom';

const ProgListMemberLine = ({ userData, programId, userRole, dataProgram }: any) => {
	const { t } = useTranslation();
	const [removeModal, setRemoveModal] = useState(false);
	const history = useHistory();

	const [removeProgramUser, { data, error, loading, refetch }]: any = useRemoveProgramUserMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: ListProgramUsersDocument,
				variables: {
					id: programId,
				},
			});

			if (queryData?.listProgramUsers) {
				const index = queryData?.listProgramUsers?.result.findIndex(
					(u: any) => u?.profile?.id === data?.removeProgramUser?.userId
				);

				if (index === -1) return;

				cache.writeQuery({
					query: ListProgramUsersDocument,
					variables: {
						id: programId,
					},
					data: {
						listProgramUsers: update(queryData.listProgramUsers, {
							result: {
								$splice: [[index, 1]],
							},
							total: {
								$apply: (x: number) => x - 1,
							},
						}),
					},
				});
			}
		},
	});
	useErrorModal({ error, onRetry: () => refetch() });

	const onRemove = useCallback(() => {
		removeProgramUser({
			variables: {
				userId: userData?.profile?.id,
				programId,
			},
		}).then(() => {
			setRemoveModal(false);
		});
	}, [removeProgramUser, userData?.profile?.id, programId]);

	return (
		<>
			<div className="memberLine">
				<div className="left">
					<UserCard
						key={userData.profile.id}
						data={userData.profile}
						imgSize={42}
						classNameName="textMedium weightMedium"
						classNameNickname="textSmall weightMedium"
					/>
				</div>
				<div className="right">
					{userRole === 'coach' && dataProgram?.price === 0 && (
						<Button
							buttonClass="btnRedLine btnCentered"
							ButtonIcon={X}
							onClick={() => {
								setRemoveModal(true);
							}}
						/>
					)}
					<Button
						buttonClass="btnGreenLine btnCentered"
						ButtonIcon={Send}
						onClick={() => history.push('/chat/' + userData.profile.id)}
					/>
				</div>
			</div>

			<Modal visible={removeModal} setVisible={setRemoveModal}>
				<div className="removeMemberConfirmation">
					<h1 className="titleL weightMedium">{t('programs:removeUserTitle')}</h1>
					<p className="textSmall weightMedium">
						{t('programs:removeUser1')}
						<strong>
							{' ' + capitalizeName(userData.profile.firstName) + ' ' + capitalizeName(userData.profile.lastName) + ' '}
						</strong>
						{t('programs:removeUser2')}
					</p>
					{loading && <Loader size={80} />}
					<div className="actions">
						<button
							className="textNormal weightMedium"
							onClick={() => {
								setRemoveModal(false);
							}}
						>
							{t('commons:cancel')}
						</button>
						<button
							className="textNormal weightMedium"
							onClick={() => {
								onRemove();
							}}
						>
							{t('commons:delete')}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ProgListMemberLine;
