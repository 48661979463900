import { AtSign, EyeOff, ArrowRight, Eye } from '../main/Icons';
import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import SocialButtons from './SocialButtons';
import Button from '../common/Button';
import TextInput from '../common/Form/TextInput';

import hustleGreen from '../../assets/img/hustle_up/hustle_green.png';
import './LoginForm.scss';

type Inputs = {
	email: string;
	password: string;
};

const LoginForm = ({ onLogin }: any) => {
	const [loading, setLoading] = useState(false);
	const [showPass, setShowPass] = useState(false);
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		email: Yup.string().trim().lowercase().required(t('validation:requiredField')).email(t('validation:emailWrong')),
		password: Yup.string()
			.required(t('validation:requiredField'))
			.min(6, t('validation:passwordMinLength'))
			.max(20, t('validation:passwordMaxLength'))
			.matches(/^(?=.*?[A-Z])(?=.*[a-z])(?=.*?[0-9]).*$/, t('validation:passwordMustContain')),
	});

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = useCallback(
		(data: { email: string; password: string }) => {
			setLoading(true);
			Auth.signIn(data.email, data.password)
				.then(() => {
					console.log('[AUTH] COGNITO LOGIN SUCCESS');
					onLogin();
				})
				.catch((e) => {
					console.error(e);
					setError('email', {
						type: 'validation',
						message: t([`errors:${e.code}`, 'errors:default']),
					});
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[onLogin, setError, setLoading, t]
	);

	const onPressSocialButton = useCallback((provider: CognitoHostedUIIdentityProvider) => {
		Auth.federatedSignIn({ provider })
			.catch((e) => console.error('[AUTH] SOCIAL LOGIN ERROR', e))
			.then(() => {
				/*onLogin();*/
				console.log('[AUTH] SOCIAL LOGIN SUCCESS');
			});
	}, []);

	return (
		<div className="loginForm loginLeftContainer">
			<header className="headerHustleUp">
				<img src={hustleGreen} alt="Hustle Up logo" />

				<h1 className="titleXXXL weightBold">{t('auth:welcomeback')}</h1>
				<p className="textMedium weightMedium">{t('auth:connectToApp')}</p>
			</header>

			<form className="formContainer" onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="email"
					placeholder={t('commons:email')}
					type="text"
					Icon={AtSign}
				/>

				<TextInput
					required
					errors={errors}
					register={register}
					name="password"
					placeholder={t('commons:password')}
					type={showPass ? 'text' : 'password'}
					onIconPress={() => setShowPass(!showPass)}
					Icon={showPass ? Eye : EyeOff}
				/>

				<div className="actions">
					<div className="forgetPassword">
						<Link to="/forget-password" className="textSmall weightMedium">
							{t('auth:forgotPassword')}
						</Link>
					</div>

					<div className="formValidationFrontPage">
						<div className="left">
							<Button buttonText={t('commons:login')} buttonClass="btnGreen textMedium weightBold" onClick={() => {}} />
						</div>

						<div className="right">
							<p className="textSmall weightMedium">{t('auth:noMemberYet')}</p>
							<Link to="/register">
								<p className="textSmall weightMedium">{t('commons:register')}</p>
								<ArrowRight />
							</Link>
						</div>
					</div>
				</div>
			</form>

			<SocialButtons onPressSocialButton={onPressSocialButton} />
		</div>
	);
};

export default LoginForm;
