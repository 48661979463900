export const SEARCH_TYPE = {
	USER: 'user',
	PROGRAM: 'program',
};

export const PROG_NAVBAR = {
	HOME: 'user',
	CALENDAR: 'calendar',
	MEMBER: 'member',
	SETTINGS: 'settings',
};

export const MENU_LIST = {
	PROFILE: 'profile',
	LANG: 'lang',
	PRIVACY: 'privacy',
	SUPPORT: 'support',
	PAYMENT: 'payment',
	ABOUT: 'about',
	CGU: 'CGU',
	CONNECT: 'connect',
};

export const PROG_TYPE = {
	INDIVIDUAL: 'INDIVIDUAL',
	GROUP: 'GROUP',
	SPECIFIC: 'SPECIFIC',
};

export const PROG_CONFIDENTIALITY = {
	PRIVATE: 'PRIVATE',
	PUBLIC: 'PUBLIC',
};

export const PROG_PRICEING = {
	FREE: 'FREE',
	PAYING: 'PAYING',
};

export const COMMENT_TYPE = {
	SESSION: 'SESSION',
};

export const EXPECTED_SCORES = {
	CALORIES: 'CALORIES',
	DISTANCE: 'DISTANCE',
	DURATION: 'DURATION',
	REPETITIONS: 'REPETITIONS',
	LOAD: 'LOAD',
};

export const WODTYPE = {
	PUBLIC: 'PUBLIC',
	PRIVATE: 'PRIVATE',
};

export const MESSSAGE_INPUT_TYPE = {
	CHAT: 'CHAT',
	COMMENT: 'COMMENT',
};

export const CHRONO_ACTIVITIES = [
	{ name: 'AMRAP' },
	{ name: 'EMOM' },
	{ name: 'FORTIME' },
	{ name: 'TABATA' },
	{ name: 'AHAP' },
];

export enum CHRONO_SCREEN {
	CONFIG,
	APP,
}

export enum CHRONO_CONGIG_INPUT_SHOW {
	TIME,
	NUMBER,
}

export enum CHRONO_CONFIG_CONTEXT {
	START,
	CONFIG,
}

export enum CHRONO_UNITS {
	MIN,
	SEC,
	ROUND,
}

export enum CHRONO_SETTYPE {
	SINGLE,
	MULTIPLE,
}

export enum CHRONO_TYPE {
	COUNTDOWN,
	TIMER,
	TIMERINFINITE,
	PAUSE,
	SETPAUSE,
}
