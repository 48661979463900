import React, { useEffect, useState } from 'react';
import { CHRONO_ACTIVITIES } from '../../../../config/type';

export const TimerConfigDataContext = React.createContext({} as any);

const TimerConfigDataContextProvider = ({ children, states }: any) => {
	const [timerConfig, setTimerConfig] = useState<any>(states.chronoConfiguration);
	const [chronoConfigurationData, setChronoConfigurationData] = useState<any>(states.chronoConfigurationData);

	console.log(states)
	return (
		<TimerConfigDataContext.Provider
			value={{
				timerConfig,
				setTimerConfig,
				chronoConfigurationData
			}}
		>
			{children}
		</TimerConfigDataContext.Provider>
	);
};

export default TimerConfigDataContextProvider;
