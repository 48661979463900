import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Save } from '../../../../main/Icons';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';
import { useAddEmomChronoConfigurationMutation } from '../../../../../generated/gql';
import { useErrorModal } from '../../../../../hooks/useErrorModal';
import { useSelector } from 'react-redux';
import { SystemState } from '../../../../../store/SystemReducer';
import { TimerConfigContext } from '../../contexts/TimerConfigContext';

const SaveEmomButton = () => {
	const { t } = useTranslation();
	const { timerConfig } = useContext(TimerConfigDataContext);
	const {setModalVisible} = useContext(TimerConfigContext)
	const [addEmomChronoConfiguration, { error, loading }] = useAddEmomChronoConfigurationMutation();
	useErrorModal({ error });
	const saveChronoConfigData = useSelector((state: SystemState) => state.saveChronoConfigData);

	const onClick = () => {
		if (loading || !saveChronoConfigData) return;

		addEmomChronoConfiguration({
			variables: {
				sessionId: saveChronoConfigData?.sessionId,
				exerciseId: saveChronoConfigData?.exerciseId,
				emoms: timerConfig?.emoms,
			},
		})
			.then((r) => {
				console.log('addEmomChronoConfiguration', r);
				setModalVisible(false)
				/*setTimeout(() => {
					window?.location?.reload();
				}, 300);*/
			})
			.catch((e) => console.log('e', e));
	};

	return (
		<button className="timerSaveConfigButton titleXXL weightMedium" onClick={onClick}>
			<Save /> {t('commons:save')} EMOM
		</button>
	);
};

export default SaveEmomButton;
