import { ArrowRight, ZoomIn } from '../../../main/Icons';
import { useTranslation } from 'react-i18next';
import WidgetBase from '../../../common/WidgetBase';
import WidgetTitle from '../../../common/WidgetTitle';
import Button from '../../../common/Button';

import ProgramLine from '../../../pages/profile/programs/ProgramLine';

import './ProgramsFollow.scss';
import EmptyMessage from '../../../common/EmptyMessage';
import { useHistory } from 'react-router-dom';
import { focusNavbarSearch } from '../../../../store/actions';
import { useDispatch } from 'react-redux';

const ProgramsFollow = ({ className, data, isSelfProfile }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<WidgetBase className={className}>
			<div className="programsFollowWidgetWrapper">
				{(data?.followedPrograms?.length > 0 || !isSelfProfile) && (
					<WidgetTitle
						title={t('profile:widgetTitle:programsFollowed')}
						ButtonIcon={isSelfProfile ? ArrowRight : null}
						buttonClass="btnLarge btnGrey btnCentered"
						onClick={() => history.push('/program')}
					/>
				)}

				{data?.followedPrograms?.length === 0 && (
					<div className="noProgramFollow">
						{isSelfProfile ? (
							<>
								<div className="title">
									<div className="icon">
										<ZoomIn />
									</div>

									<div className="titleText weightBold titleXL">{t('profile:widgetProgramsFollow:title')}</div>
								</div>

								<p className="textMedium weightMedium">{t('profile:widgetProgramsFollow:findCoachText')}</p>

								<div className="actions">
									{/*	<Button
										onClick={() => dispatch(focusNavbarSearch(true))}
										buttonText={t('profile:widgetAction:findCoach')}
										buttonClass="btnGreyContrast textNormal weightMedium"
									/>*/}

									<Button
										onClick={() => dispatch(focusNavbarSearch(true))}
										buttonText={t('profile:widgetAction:findProgram')}
										buttonClass="btnGreen textNormal weightMedium"
									/>
								</div>
							</>
						) : (
							<EmptyMessage message={t('profile:widgetProgramsFollow:noData')} />
						)}
					</div>
				)}

				<div className="programsList">
					{data?.followedPrograms?.map((program: any) => (
						<ProgramLine key={program.id} data={program} type="followed" />
					))}
				</div>
			</div>
		</WidgetBase>
	);
};

export default ProgramsFollow;
