import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { toggleNavbarSearch, focusNavbarSearch } from '../../../store/actions';
import { SystemState } from '../../../store/SystemReducer';

import NavbarSearchModal from './NavbarSearchModal';
import NavbarSearchOptions from './NavbarSearchOptions';

import './NavbarSearch.scss';
import { SEARCH_TYPE } from '../../../config/type';

type Inputs = {
	search: string;
};

const NavbarSearch = () => {
	const { t } = useTranslation();
	const { register, watch } = useForm<Inputs>();
	const isMedium = useMediaQuery({ query: '(min-width: 800px)' });
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
	const location = useLocation();

	const [searchType, setSearchType] = useState(SEARCH_TYPE.PROGRAM);
	const [searchInputStyle, setSearchInputStyle] = useState({
		opacity: 0,
		zIndex: 5,
	});
	const [searchIconStyle, setSearchIconStyle] = useState({
		zIndex: 4,
	});
	const [modalView, setModalView] = useState(false);

	const dispatch = useDispatch();

	const navbarSearchToggled = useSelector(
		(state: SystemState) => state.navbarSearchToggled
	);
	const navbarSearchFocus = useSelector(
		(state: SystemState) => state.navbarSearchFocus
	);

	const searchIcon = useRef<SVGSVGElement>(null);
	const navbarSearch = useRef<HTMLDivElement>(null);

	//FOCUS SUR L'INPUT
	useEffect(() => {
		if (navbarSearchFocus) {
			dispatch(toggleNavbarSearch(true));
			dispatch(focusNavbarSearch(false));
			setModalView(true);
			document.getElementById('navbarSearchInput')?.focus();
		}
	}, [navbarSearchFocus]);

	//GESTION AFFICHAGE MODAL/SEARCH BAR
	useEffect(() => {
		searchToogle(navbarSearchToggled);
	}, [navbarSearchToggled]); // eslint-disable-line

	useEffect(() => {
		if (isDesktop) {
			dispatch(toggleNavbarSearch(true));
		} else {
			dispatch(toggleNavbarSearch(false));
			setModalView(false);
		}
	}, [isDesktop, watch, location]);

	useEffect(() => {
		if (isMedium && navbarSearch.current) {
			if (navbarSearchToggled) {
				navbarSearch.current.style.width = '240px';
			} else {
				navbarSearch.current.style.width = '20px';
			}
		} else if (!isMedium && navbarSearch.current) {
			if (navbarSearchToggled) {
				navbarSearch.current.style.width = '200px';
			} else {
				navbarSearch.current.style.width = '16px';
			}
		}
	}, [isMedium, navbarSearchToggled]);

	const handleClick = () => {
		//Deploiement de la navbar
		dispatch(toggleNavbarSearch(true));
		setModalView(true);
	};

	const searchToogle = (navbarSearchToggled: boolean) => {
		setSearchInputStyle({
			opacity: navbarSearchToggled ? 1 : 0,
			zIndex: navbarSearchToggled ? 4 : 5,
		});
		setSearchIconStyle({
			zIndex: navbarSearchToggled ? 5 : 4,
		});

		if (navbarSearch.current && searchIcon.current) {
			if (isMedium) {
				navbarSearch.current.style.width = navbarSearchToggled
					? '240px'
					: '20px';
			} else {
				navbarSearch.current.style.width = navbarSearchToggled
					? '200px'
					: '16px';
			}
			searchIcon.current.style.marginLeft = navbarSearchToggled
				? '10px'
				: '0px';
		}
	};

	//GESTION CLICK EXTERIEUR
	useEffect(() => {
		window.addEventListener('click', handleClickOutsideSearch);

		return () => {
			window.removeEventListener('click', handleClickOutsideSearch);
		};
	}, [isDesktop]); //eslint-disable-line react-hooks/exhaustive-deps

	//Detection du click en dehors de la barre de recherche et de la fenêtre
	const handleClickOutsideSearch = (e: any) => {
		let searchElements = false;

		let endLoop = false;
		let element = e.target;

		while (!endLoop) {
			if (element.parentElement) {
				element = element.parentElement;
				if (element.classList) {
					if (
						element.classList[0] === 'navbarSearchModal' ||
						element.classList[0] === 'navbarSearch' ||
						element.classList[0] === 'navbarSearchOptions'
					) {
						searchElements = true;
					}
				}
			} else {
				endLoop = true;
			}
		}

		if (!searchElements) {
			if (!isDesktop) {
				dispatch(toggleNavbarSearch(false));
				// setSearchDisplay(false);
			}
			setModalView(false);
		}
	};

	//CLOSE SEARCH MODAL WHEN CHANGE PAGE
	useEffect(() => {
		if (!isDesktop) {
			dispatch(toggleNavbarSearch(false));
		}

		setModalView(false);
	}, [location]);

	return (
		<>
			<div className="navbarSearch" ref={navbarSearch}>
				<input
					{...register('search')}
					onClick={handleClick}
					type="text"
					placeholder={t('commons:search')}
					style={searchInputStyle}
					className="textNormal"
					id="navbarSearchInput"
					autoComplete="off"
				/>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					strokeWidth="2"
					viewBox="0 0 24 24"
					className="searchButton"
					style={searchIconStyle}
					ref={searchIcon}
				>
					<circle cx="11" cy="11" r="8" />
					<path d="M21 21l-4-4" />
				</svg>
			</div>

			{modalView && (
				<NavbarSearchModal
					searchType={searchType}
					setSearchType={setSearchType}
					searchInputValue={watch('search')}
					modalView={modalView}
				/>
			)}
		</>
	);
};

export default NavbarSearch;
