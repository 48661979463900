import './ModalTimer.scss';
import React, { useState, useEffect } from 'react';
import type { BodyScrollOptions } from 'body-scroll-lock';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isIOS } from 'react-device-detect';
import clickOutside from '../../../../../../utils/clickOutside';
import Timer from '../../../../timer/Timer';
import { useDispatch } from 'react-redux';
import { setChatChannelId, setSaveChronoConfigData } from '../../../../../../store/actions';

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const options: BodyScrollOptions = {
	reserveScrollBarGap: true,
};



const ModalTimer = ({ visible, setVisible, sessionId, exerciseId, chronoConfiguration }: any) => {
	const [state, setState] = useState(visible ? VISIBLE : HIDDEN);
	const classNameBackground = state === VISIBLE ? 'modalTimer' : 'modalTimer modalTimerOut';
	const classNameModalWindow = state === VISIBLE ? 'modalTimerWindow' : 'modalTimerWindow modalTimerWindowOut';
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			setSaveChronoConfigData({
				sessionId,
				exerciseId,
			})
		);
	}, [dispatch, exerciseId, sessionId]);

	

	useEffect(() => {
		
		if (!visible) {
			setState(LEAVING);
		} else {
			setState((s) => (s === HIDDEN ? ENTERING : VISIBLE));
			window.addEventListener('click', handleClickOutside);
			return () => {
				window.removeEventListener('click', handleClickOutside);
			};
		}
	}, [visible]);

	useEffect(() => {
		if (state === LEAVING) {
			const timer = setTimeout(() => {
				setState(HIDDEN);
			}, 200);

			return () => {
				clearTimeout(timer);
			};
		} else if (state === ENTERING) {
			let repaint = document.body.offsetHeight;
			setState(VISIBLE);
		}

		//Body scroll disable
		let scrollElem: any = document.querySelector('.modalTimerWindow');
		if (state === HIDDEN) {
			// enableBodyScroll(scrollElem)
			clearAllBodyScrollLocks();
		} else {
			if (!isIOS) disableBodyScroll(scrollElem, options);
		}
	}, [state]);

	//Detection du click en dehors de la barre de recherche et de la fenêtre
	const handleClickOutside = (e: any) => {
		if (clickOutside(e, ['modalTimerWindow', 'closeButton'])) {
			setVisible(false);
		}
	};

	if (state === HIDDEN) return null;

	return (
		<div className={classNameBackground}>
			<div className={classNameModalWindow}>
				<Timer chronoConfiguration={chronoConfiguration} setModalVisible={setVisible} />
			</div>
		</div>
	);
};

export default ModalTimer;
