import { useCallback, useEffect, useState } from 'react'; // eslint-disable-line
import { useListProgramsLazyQuery } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';

import './SearchListPrograms.scss';
import ProgCard from '../../common/ProgCard';
import { useTranslation } from 'react-i18next';
import Loader from '../../main/Loader';

const SearchListPrograms = ({ searchQuery, onPress }: any) => {
	// const [loadingMore, setLoadingMore] = useState(false);
	const { t } = useTranslation();
	const [
		getProgramsList,
		{ data, error, loading, refetch, fetchMore }, // eslint-disable-line
	] = useListProgramsLazyQuery({
		fetchPolicy: 'network-only',
	});
	useErrorModal({ error, onRetry: () => refetch });

	/*const fetchMoreUsers = useCallback(() => {
        if (data?.listUsers?.result?.length === data?.listUsers?.total) {
            return;
        }
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.listUsers.result.length,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
                if (!fetchMoreResult) return prev;
                return {
                    listUsers: {
                        ...fetchMoreResult.listUsers,
                        result: [
                            ...prev.listUsers.result,
                            ...fetchMoreResult.listUsers.result,
                        ],
                    },
                };
            },
        }).finally(() => {
            setLoadingMore(false);
        });
    }, [data]);*/

	useEffect(() => {
		getProgramsList();
	}, [getProgramsList]);

	const getProg = useCallback(async () => {
		await getProgramsList({
			variables: {
				filter: searchQuery.trim(),
			},
		});
	}, [getProgramsList, searchQuery]);

	useEffect(() => {
		if (searchQuery !== '') getProg().catch((e) => console.error(e));
		else getProgramsList();
	}, [getProg, getProgramsList, searchQuery]);

	if (loading) return <Loader size={80} />;

	return (
		<div className="searchListProg">
			{
				// @ts-ignore
				data?.listPrograms?.total > 0 ? (
					// @ts-ignore
					data?.listPrograms?.result.map((prog: any) => {
						return (
							<ProgCard
								key={prog.id}
								data={prog}
								imgSize={36}
								sizeTitle="textMedium weightMedium"
								sizeInfos="textSmall weightMedium"
							/>
						);
					})
				) : (
					<div>
						<p>{t('errors:noProgFound')}</p>
					</div>
				)
			}
		</div>
	);
};

export default SearchListPrograms;
