import Resizer from 'react-image-file-resizer';

const scoreTypesUnit = {
	LOAD: 'kg',
	DURATION: 'seconds',
	CALORIES: 'cal',
	REPETITIONS: 'reps',
	DISTANCE: 'm',
};

export const formatScore = (unit, value) => {
	unit = scoreTypesUnit[unit];
	if (unit === scoreTypesUnit.DURATION) {
		let secs = value;
		let minutes = Math.floor(secs / 60);
		secs = secs % 60;
		let hours = Math.floor(minutes / 60);
		minutes = minutes % 60;

		if (hours > 0 && minutes > 0 && secs > 0) {
			return `${hours}h ${minutes}min ${secs}s`;
		}
		if (hours > 0 && minutes > 0) {
			return `${hours}h ${minutes}min`;
		}
		if (minutes > 0 && secs > 0) {
			return `${minutes}min ${secs}s`;
		}
		if (hours > 0) {
			return `${hours}h`;
		}
		if (minutes > 0) {
			return `${minutes}min`;
		}
		if (secs > 0) {
			return `${secs}s`;
		}
	}
	if (unit === scoreTypesUnit.DISTANCE) {
		if (value > 1000) {
			return `${value / 1000} km`;
		} else {
			return `${value} m`;
		}
	}
	if (unit === scoreTypesUnit.LOAD) {
		if (value < 1) {
			return `${value * 1000} g`;
		} else {
			return `${value} kg`;
		}
	}
	if (unit === scoreTypesUnit.CALORIES) {
		if (value > 1000) {
			return `${value / 1000} kcal`;
		} else {
			return `${value} cal`;
		}
	}
	return `${value} ${unit}`;
};

export const computeProgramNote = (points, notesNumber) => {
	if (points > 0 && notesNumber > 0) return (points / notesNumber).toFixed(1);

	return null;
};

export const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			1000,
			1000,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			'blob'
		);
	});

export const timeToString = (time) => new Date(time).toISOString().split('T')[0];

export const capitalizeFirstLetter = (string) => {
	return string[0].toUpperCase() + string.slice(1);
};

export function arrayMoveMutable(array, fromIndex, toIndex) {
	const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

		const [item] = array.splice(fromIndex, 1);
		array.splice(endIndex, 0, item);
	}
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
	array = [...array];
	arrayMoveMutable(array, fromIndex, toIndex);
	return array;
}

export const getUnitForScoreType = (scoreType) => {
	return scoreTypesUnit[scoreType];
};
