import './ProgramAdd.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, HelpCircle, Lock, Pen, User, Users } from '../../../components/main/Icons';
import ProgramAddInfos from '../../../components/pages/programs/ProgramAdd/ProgramAddInfos';
import Modal from '../../../components/common/Modal';
import { PROG_TYPE, PROG_CONFIDENTIALITY, PROG_PRICEING } from '../../../config/type';
import Button from '../../../components/common/Button';
import { useCreateAccountLinkMutation } from '../../../generated/gql';
import { useErrorModal } from '../../../hooks/useErrorModal';
import useUser from '../../../hooks/user/useUser';

const ProgramAdd = () => {
	const [step, setStep] = useState(0);
	const [choices, setChoices] = useState<any>([
		{
			category: 'type',
			value: null,
		},
		{
			category: 'confidentiality',
			value: null,
		},
		{
			category: 'price',
			value: null,
		},
	]);
	const { t } = useTranslation();

	return (
		<div id="programCreate">
			<h1 className="pageTitle titleXXL weightBold">{t('programs:programAdd:title')}</h1>
			<div className="createSteps">
				<ProgramAddStep
					componentStep={0}
					step={step}
					setStep={setStep}
					title={t('programs:programAdd:stepTitlePrice')}
					choices={choices}
				>
					<ProgramPrice setChoices={setChoices} choices={choices} setStep={setStep} />
				</ProgramAddStep>
				<ProgramAddStep
					componentStep={1}
					step={step}
					setStep={setStep}
					title={t('programs:programAdd:stepTitleType')}
					choices={choices}
				>
					<ProgramType setChoices={setChoices} choices={choices} setStep={setStep} />
				</ProgramAddStep>
				<ProgramAddStep
					componentStep={2}
					step={step}
					setStep={setStep}
					title={t('programs:programAdd:stepTitleConfidentiality')}
					choices={choices}
				>
					<ProgramConfidentiality setChoices={setChoices} choices={choices} setStep={setStep} />
				</ProgramAddStep>
				<ProgramAddStep
					componentStep={3}
					step={step}
					setStep={setStep}
					title={t('programs:programAdd:stepTitleInfos')}
					choices={choices}
				>
					<ProgramAddInfos progConfig={choices} />
				</ProgramAddStep>
			</div>
			{/*<Notification
				type="load"
				text="Programme enregistré"
				subtext="Redirection vers Nom du programme..."
				visible={finish}
				setVisible={setFinish}
			/>*/}

			{/* <Notification type="error" text="Programme enregistré" subtext="Redirection vers Nom du programme..." /> */}
		</div>
	);
};

const ProgramAddStep = ({ children, componentStep, step, setStep, title, choices }: any) => {
	const { t } = useTranslation();
	const [helpModal, setHelpModal] = useState(false);
	const handleClick = () => {
		setStep(componentStep);
	};

	return (
		<div className="programAddStep">
			<div className="title" onClick={() => {}}>
				<div
					className="stepNumber textNormal weightSemiBold"
					style={{
						background: componentStep !== step ? '#E6EAF4' : '',
					}}
				>
					{componentStep}
				</div>
				<h1 className="titleL weightMedium">{title}</h1>

				{componentStep === 2 && step === 2 && (
					<>
						<div
							className="help"
							onClick={() => {
								setHelpModal(true);
							}}
						>
							<HelpCircle />
						</div>
						<Modal visible={helpModal} setVisible={setHelpModal}>
							<div className="helpModal">{t('programs:programAdd:helpModal')}</div>
						</Modal>
					</>
				)}
			</div>

			{((choices[2].value && componentStep === 0) ||
				(choices[0].value && componentStep === 1) ||
				(componentStep === 2 && choices[1].value !== null)) && (
				<div className="subtitle titleL weightBold" onClick={handleClick}>
					{componentStep === 1 && choices[0].value === PROG_TYPE.INDIVIDUAL
						? t('programs:programAdd:choiceIndividualTitle')
						: componentStep === 1 && choices[0].value === PROG_TYPE.GROUP
						? t('programs:programAdd:choiceGroupTitle')
						: ''}

					{componentStep === 2 && choices[1].value === PROG_CONFIDENTIALITY.PUBLIC
						? t('programs:programAdd:choicePublicTitle')
						: componentStep === 2 && choices[1].value === PROG_CONFIDENTIALITY.PRIVATE
						? t('programs:programAdd:choicePrivateTitle')
						: ''}

					{componentStep === 0 && choices[2].value === PROG_PRICEING.FREE
						? t('programs:programAdd:choiceFreeTitle')
						: componentStep === 0 && choices[2].value === PROG_PRICEING.PAYING
						? t('programs:programAdd:choicePayingTitle')
						: ''}
					<Pen />
				</div>
			)}

			<div className="contentStep" aria-expanded={componentStep === step}>
				<div className="decoration">
					<div className="line" />
				</div>
				<div className="contentStepWrapper">{children}</div>
			</div>
		</div>
	);
};

const ProgramType = ({ setChoices, choices, setStep }: any) => {
	const { t } = useTranslation();

	const handleClick = (choice: any) => {
		const newState = [...choices];
		newState[0].value = choice;
		setChoices(newState);
		setStep(2);
	};

	return (
		<div className="programStepType programStep">
			<ChoiceButton
				Icon={User}
				iconClass="iconGreen"
				title={t('programs:programAdd:choiceIndividualTitle')}
				texts={t('programs:programAdd:choiceIndividualP')}
				onClick={() => {
					handleClick(PROG_TYPE.INDIVIDUAL);
				}}
			/>
			<ChoiceButton
				Icon={Users}
				iconClass="iconBlue"
				title={t('programs:programAdd:choiceGroupTitle')}
				texts={t('programs:programAdd:choiceGroupP')}
				onClick={() => {
					handleClick(PROG_TYPE.GROUP);
				}}
			/>
		</div>
	);
};

const ProgramPrice = ({ setChoices, choices, setStep }: any) => {
	const { t } = useTranslation();
	const { user } = useUser();
	const [visible, setVisible] = useState<any>(false);
	const [createAccountLink, { loading, error }] = useCreateAccountLinkMutation();
	useErrorModal({ error });

	const createStripeConnectAccount = () => {
		createAccountLink({
			variables: {
				returnUrl: process.env.REACT_APP_DOMAIN_NAME + 'program/add',
			},
		}).then((r) => {
			console.log('createAccountLink r', r);
			if (r?.data?.createAccountLink?.url) window.location.replace(r?.data?.createAccountLink?.url);
		});
	};

	const handleClick = (choice: any) => {
		const newState = [...choices];
		newState[2].value = choice;

		if (newState[2].value === PROG_PRICEING.PAYING && !user?.validated_stripe_connect_account) {
			console.log('payant click but no stripe account');
			setVisible(true);
		} else {
			setChoices(newState);
			setStep(1);
		}
	};

	return (
		<div className="programStepType programStep">
			<ChoiceButton
				Icon={User}
				iconClass="iconGreen"
				title={t('programs:programAdd:choiceFreeTitle')}
				texts={t('programs:programAdd:choiceFreeP')}
				onClick={() => {
					handleClick(PROG_PRICEING.FREE);
				}}
			/>
			<ChoiceButton
				Icon={Users}
				iconClass="iconBlue"
				title={t('programs:programAdd:choicePayingTitle')}
				texts={t('programs:programAdd:choicePayingP')}
				onClick={() => {
					handleClick(PROG_PRICEING.PAYING);
				}}
			/>
			<Modal visible={visible} setVisible={setVisible}>
				<div className="modalSession">
					<h1 className="weightBold">{t('programs:programAdd:choicePayingTitleModal')}</h1>
					<ul>
						<li className="textSmall weightMedium bodyText1">{t('programs:programAdd:choicePayingBodyModal1')}</li>
						<li className="textMedium weightMedium bodyText1">{t('programs:programAdd:choicePayingBodyModal2')}</li>
						<li className="textMedium weightMedium bodyText1">{t('programs:programAdd:choicePayingBodyModal3')}</li>
					</ul>
					<div className="actions">
						<Button
							loading={loading}
							buttonText={t('programs:successModalBtn')}
							buttonClass="btnGreen textNormal weightMedium"
							onClick={() => {
								console.log('open stripe connect account');
								createStripeConnectAccount();
							}}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
};

const ProgramConfidentiality = ({ setChoices, choices, setStep }: any) => {
	const { t } = useTranslation();

	const handleClick = (choice: any) => {
		const newState = [...choices];
		newState[1].value = choice;
		setChoices(newState);
		setStep(3);
	};

	return (
		<div className="programStepConfident programStep">
			<ChoiceButton
				Icon={Eye}
				iconClass="iconBlue"
				title={t('programs:programAdd:choicePublicTitle')}
				texts={t('programs:programAdd:choicePublicP')}
				onClick={() => {
					handleClick(PROG_CONFIDENTIALITY.PUBLIC);
				}}
			/>
			<ChoiceButton
				Icon={Lock}
				iconClass="iconGrey"
				title={t('programs:programAdd:choicePrivateTitle')}
				texts={t('programs:programAdd:choicePrivateP')}
				onClick={() => {
					handleClick(PROG_CONFIDENTIALITY.PRIVATE);
				}}
			/>
		</div>
	);
};

const ChoiceButton = ({ Icon, iconClass, title, texts, onClick }: any) => {
	return (
		<div className="choiceButton" onClick={onClick}>
			<div className="title">
				<div className={`iconWrapper ${iconClass}`}>
					<Icon />
				</div>
				<h1 className="titleL weightMedium">{title}</h1>
			</div>

			<p className="textSmall weightMedium">{texts}</p>
		</div>
	);
};

export default ProgramAdd;
