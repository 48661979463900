import './CalendarProgramFilter.scss';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarDatesContext } from '../contexts/CalendarDatesContext';
import { Check, ChevronDown } from '../../../main/Icons';
import { CalendarContext } from '../contexts/CalendarContext';

const CalendarProgramFilter = () => {

	return (
		<div className="calendarMainSidebarFilters">
			<ProgramFilter owner={true} />
			<ProgramFilter owner={false} />
		</div>
	);
};

interface ProgramFilterProps {
	owner: boolean;
}

const ProgramFilter = ({ owner }: ProgramFilterProps) => {
	const { t } = useTranslation();
	const { programList } = useContext(CalendarDatesContext);
	const { me } = useContext(CalendarContext);
	const [visible, setVisible] = useState<boolean>(true);
	const programListRef = useRef<any>();

	const handleClick = () => {
		const programListRefCurrent = programListRef?.current;

		if (visible) {
			programListRefCurrent.style.opacity = 0;
			programListRefCurrent.style.maxHeight = 0;
			setVisible(false);
		} else {
			programListRefCurrent.style.opacity = 1;
			programListRefCurrent.style.maxHeight = '1200px';
			setVisible(true);
		}
	};

	return (
		<div className="programFilter noselect">
			<div className="titleBar" onClick={handleClick}>
				<div className="title titleL weightMedium">
					{owner
						? t('profile:widgetTitle:programsCreated')
						: t('profile:widgetTitle:programsFollowed')}
				</div>

				<div
					className={`
						showButton
						${visible ? '' : 'showButtonReverse'}
					`}
				>
					<ChevronDown />
				</div>
			</div>

			<div className="programList" ref={programListRef}>
				{programList?.map((program: any, index: number) => {
					if (owner) {
						if (program?.creator?.id === me?.user?.id && owner) {
							return <Filter key={index} program={program} owner={owner} />;
						}
					} else {
						if (program?.creator?.id !== me?.user?.id) {
							return <Filter key={index} program={program} owner={owner} />;
						}
					}
				})}
			</div>
		</div>
	);
};

interface FilterProps {
	program: any;
	owner: boolean;
}

const Filter = ({ program, owner }: FilterProps) => {
	const { programList, setProgramList } = useContext(CalendarDatesContext);

	const handleClick = () => {
		const programListUpdate = [...programList];
		const programIndex = programListUpdate.indexOf(program);

		if (programIndex >= 0) {
			programListUpdate[programIndex].visible = !programListUpdate[programIndex]
				.visible;
		}
		setTimeout(() => {
			setProgramList(programListUpdate);
		}, 100);
	};

	return (
		<div className="filter" onClick={handleClick}>
			<div
				className={`
					checkbox
					${owner ? 'checkboxOwner' : 'checkboxMember'}
					${program.visible ? '' : 'checkboxDisable'}
				`}
			>
				{program.visible && <Check />}
			</div>

			<div className="name textNormal weightMedium">{program.name}</div>
		</div>
	);
};

export default React.memo(CalendarProgramFilter);
