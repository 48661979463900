import './CalendarSessionLeaderboardTemplate.scss';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ScoreValueType, useListSessionExerciseScoreRanksLazyQuery } from '../../../../../generated/gql';
import { CalendarSessionContext } from '../../contexts/CalendarSessionContext';
import { ChevronLeft } from 'react-feather';
import { X, Plus, ChevronDown, Award, ChevronUp } from '../../../../main/Icons';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/Button';
import { useMediaQuery } from 'react-responsive';
import { UserIcon } from '../../../../main/UserIcon';
import moment from 'moment';
import CalendarSessionLeaderboardUserFeedback from './CalendarSessionLeaderboardUserFeedback';
import Modal from '../../../../common/Modal';
import ModalAddScore from './ModalAddScore';

interface CalendarSessionLeaderboardTemplateProps {
	dataExercise: any;
	sessionId: any;
	setLeaderboardVisible: React.Dispatch<React.SetStateAction<boolean>>;
	leaderboardVisible: boolean;
}

const CalendarSessionLeaderboardTemplate = ({
	dataExercise,
	sessionId,
	setLeaderboardVisible,
	leaderboardVisible,
}: CalendarSessionLeaderboardTemplateProps) => {
	const { t } = useTranslation();
	const { dataSession } = useContext(CalendarSessionContext);
	const [addLeaderboardScoreVisible, setAddLeaderboardScoreVisible] = useState(false);
	const is440 = useMediaQuery({ query: '(min-width: 440px)' });
	const is800 = useMediaQuery({ query: '(min-width: 800px)' });

	const [selectedScore, setSelectedScore] = useState(dataExercise?.expectedScores[0]?.type);

	const [
		listSessionExerciseScoreRanks,
		{ loading, data, error, refetch },
	] = useListSessionExerciseScoreRanksLazyQuery();

	useEffect(() => {
		if (leaderboardVisible) {
			listSessionExerciseScoreRanks({
				variables: {
					exerciseId: dataExercise?.id,
					sessionId,
					type: selectedScore,
				},
			});
		}
	}, [dataExercise?.id, selectedScore, sessionId, leaderboardVisible]);

	const onScoreAdded = useCallback(() => {
		if (refetch) refetch().catch((e) => console.error(e));
	}, [refetch]);

	return (
		<>
			<div className="calendarSessionLeaderboardTemplate">
				<header className="navbar">
					<div className="left">
						<button className="textMedium weightMedium" onClick={() => setLeaderboardVisible(false)}>
							<ChevronLeft />
							{dataSession?.showProgramSession?.name}
						</button>
					</div>
					<div className="right">
						<button onClick={() => setLeaderboardVisible(false)}>
							<X />
						</button>
					</div>
				</header>

				<div className="exerciseInfos">
					<div className="left">
						<h1 className="titleXXL weightBold">{t('calendar:leaderboard')}</h1>
					</div>
					<div className="right">
						{dataExercise?.userScores.length === 0 && (
							<Button
								buttonClass={`
									btnGreen titleL weightMedium
									${is440 ? 'btnDuo' : 'btnCentered btnLarge'}
								`}
								ButtonIcon={Plus}
								buttonText={is440 && t('session:addScore')}
								onClick={() => {
									setAddLeaderboardScoreVisible(true);
								}}
							/>
						)}
					</div>
				</div>

				<div className="actions">
					{dataExercise?.expectedScores.map((catScore: any, index: number) => (
						<ScoreCatButton
							name={t('session:' + catScore.type.toLowerCase())}
							selectedScore={selectedScore}
							setSelectedScore={setSelectedScore}
							type={catScore.type}
							key={index}
						/>
					))}
				</div>

				<section className="scoreTable">
					<div className="titleBar">
						<div className="position">
							<Award />
						</div>
						<div className="adherants textMedium weightMedium">{t('programs:details:members')}</div>

						{is800 && (
							<>
								<div className="pseudo textNormal weightMedium">{t('formik:nickname')}</div>
								<div className="score textNormal weightMedium">{t('session:score')}</div>
							</>
						)}
					</div>

					<div className="scoreList">
						{data?.listSessionExerciseScoreRanks?.total === 0 && (
							<div className="noScore">
								<p className="textGrey textMedium weightMedium">{t('session:noScore')}</p>
							</div>
						)}
						{data?.listSessionExerciseScoreRanks?.result?.map((score: any, index: number) => (
							<ScoreLine key={index} score={score} selectedScore={selectedScore} exerciseId={dataExercise?.id} />
						))}
					</div>
				</section>
			</div>

			<Modal visible={addLeaderboardScoreVisible} setVisible={setAddLeaderboardScoreVisible}>
				<ModalAddScore
					sessionId={sessionId}
					exerciseId={dataExercise?.id}
					visible={addLeaderboardScoreVisible}
					setVisible={setAddLeaderboardScoreVisible}
					scoreExpected={dataExercise?.expectedScores}
					type={selectedScore}
					onScoreAdded={onScoreAdded}
				/>
			</Modal>
		</>
	);
};

const ScoreCatButton = ({ name, selectedScore, setSelectedScore, type }: any) => {
	return (
		<button
			className={`
				btn btnGreyContrast textSmall weightMedium noselect
				${selectedScore === type ? 'btnBlue' : 'btnGreyContrast'}
			`}
			onClick={() => setSelectedScore(type)}
		>
			{name}
		</button>
	);
};

const ScoreLine = ({ score, selectedScore, exerciseId }: any) => {
	const [scoreUnit, setScoreUnit] = useState<any>();
	const [showFeedback, setShowFeedback] = useState<any>(false);

	useEffect(() => {
		let unit: string = '';
		if (selectedScore === ScoreValueType.Calories) {
			unit = 'kcal';
		} else if (selectedScore === ScoreValueType.Distance) {
			unit = 'm';
		} else if (selectedScore === ScoreValueType.Load) {
			unit = 'kg';
		} else if (selectedScore === ScoreValueType.Repetitions) {
			unit = 'reps';
		}

		setScoreUnit(unit);
	}, [setScoreUnit, selectedScore]);

	return (
		<>
			<div className="scoreLine">
				<div className="left">
					<div className="position textMedium weightBold">{score.position}</div>

					<div className="infos">
						<div className="adherants">
							<UserIcon
								url={score?.member.profile_picture?.url}
								text={score?.member?.firstName?.charAt(0) + score?.member?.lastName?.charAt(0)}
								size={18}
							/>

							<h1 className="textSmall weightMedium">{score?.member?.lastName + ' ' + score?.member?.firstName}</h1>
						</div>
						<div className="pseudo textSmall weightMedium">@{score?.member?.nickname}</div>
						<div className="score textSmall weightMedium">
							{selectedScore !== ScoreValueType.Duration ? (
								<div>{score.score + ' ' + scoreUnit}</div>
							) : (
								<div>{moment.utc(score.score * 1000).format('mm [min] ss [sec]')}</div>
							)}
						</div>
					</div>
				</div>
				{/*
		TODO
		<div className="right">
					<button onClick={() => setShowFeedback((feedback: any) => !feedback)} />
					{showFeedback ? <ChevronDown /> : <ChevronUp />}
				</div>*/}
			</div>
			{showFeedback && <CalendarSessionLeaderboardUserFeedback userId={score?.member?.id} exerciseId={exerciseId} />}
		</>
	);
};

export default CalendarSessionLeaderboardTemplate;
