import WidgetTitle from '../../../common/WidgetTitle';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useErrorModal } from '../../../../hooks/useErrorModal';
import { useEffect, useState } from 'react';
import { usePrismicApollo } from '../../../../api/prismic/usePrismicApollo';
import { ALL_TIPS_AND_TRICKS } from '../../../../api/prismic/tipsAndTricks/queries';
import { i18nLangToPrismic } from '../../../../api/prismic/utils';
import Loader from '../../../../components/main/Loader';

import './News.scss';

import NewsCard from './NewsCard';

const News = () => {
	const { i18n, t } = useTranslation();
	const client = usePrismicApollo();
	const { error, loading, data, refetch, fetchMore } = useQuery(ALL_TIPS_AND_TRICKS, {
		client,
		variables: {
			lang: i18nLangToPrismic(i18n.language),
			tags_in: ['production'],
			last: 30,
			sortBy: 'meta_lastPublicationDate_DESC',
		},
	});
	// @ts-ignore
	useErrorModal({ error, retry: () => refetch() });

	if (loading) {
		return <Loader size={80} />;
	}

	return (
		<div className="newsWrapper">
			<WidgetTitle title={t('home:newsTitle')} />

			<div className="newsList">
				{data?.allTipsandtrickss?.edges?.map((data: any, index: any) => (
					<NewsCard key={index} data={data} />
				))}
			</div>
		</div>
	);
};

export default News;
