import './ProgramEditInfos.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pen, X } from '../../../main/Icons';
import TextInput from '../../../common/Form/TextInput';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Textarea from '../../../common/Form/Textarea';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import defaultProgImg from '../../../../assets/img/icon_hustle_up_small.png';
import { MeDocument, useAddProgramAvatarMutation, useUpdateProgramMutation } from '../../../../generated/gql';
import { useHistory } from 'react-router-dom';
import { capitalizeName } from '../../../../utils/string';
import { resizeFile } from '../../../../utils/helpers';
import update from 'immutability-helper';
import { Keywords } from '../Keyworlds';
import { useErrorModal } from '../../../../hooks/useErrorModal';

type Inputs = {
	name: string;
	description: string;
	usersLimit: number;
	duration: number;
	keyword: string;
};

const ProgramEditInfos = ({ progConfig }: any) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [shouldSumbit, setShouldSubmit] = useState<any>(false);
	const [keywords, setKeywords] = useState<any>(progConfig?.tags || []);

	const validationSchema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.required(t('formik:requiredField'))
			.min(2, t('formik:progNameValidation'))
			.max(40, t('formik:progNameValidation')),
		description: Yup.string()
			.trim()
			.required(t('formik:requiredField'))
			.max(3000, t('formik:progDescriptionValidation')),
		/*usersLimit: Yup.number()
			.min(0, t('formik:min0'))
			.max(100000000, t('formik:max')),*/
		/*.integer(t('formik:invalideNumber')),*/
		price: Yup.number().min(0, t('formik:min0')).max(10000, t('formik:max10000')).integer(t('formik:invalideNumber')),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			name: capitalizeName(progConfig?.name) as string,
			description: progConfig?.description as string,
		},
	});

	const [
		updateProgram,
		{ data, error, loading }, // eslint-disable-line
	] = useUpdateProgramMutation({
		update(cache, { data }) {
			const queryData: any = cache.readQuery({
				query: MeDocument,
			});

			if (!data) {
				console.error('QUERY ME NO DATA');
				return;
			}

			cache.writeQuery({
				query: MeDocument,
				data: {
					me: update(queryData?.me, {
						ownedPrograms: {
							$push: [data?.updateProgram],
						},
					}),
				},
			});
		},
	});

	const onSubmit = async (values: any, e: any) => {
		if (!shouldSumbit) return;

		try {
			if (values.usersLimit === '' || values.usersLimit === undefined) values.usersLimit = 0;

			const val = validationSchema.cast(values);

			let progConfigData = {
				id: progConfig?.id,
				description: val.description,
				/*avatar: null,*/
				duration: 0,
				name: val.name,
				//price: 0, //parseFloat(values.price),
				tags: keywords,
				//usersLimit: val.usersLimit,
				open: false,
			};

			const prog = await updateProgram({
				variables: progConfigData,
			});

			setTimeout(() => {
				history.push('/program/' + prog?.data?.updateProgram?.id);
			}, 1000);
		} catch (e) {
			console.error('handleSubmit ', JSON.stringify(e));
		}
	};

	return (
		<div className="programStepInfos programStep">
			<ProgramAvatar progConfig={progConfig} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<TextInput
					required
					errors={errors}
					register={register}
					name="name"
					placeholder={t('programs:programAdd:programName')}
					type="text"
				/>

				<Textarea errors={errors} register={register} name="description" placeholder={t('formik:description')} />

				{/*{progConfig[0]?.value === PROG_TYPE.GROUP && (
					<>
						<TextInput
							// required
							errors={errors}
							register={register}
							name="usersLimit"
							placeholder={t('programs:programAdd:maxMember')}
							type="number"
						/>
						<p className="textSmall weightMedium helper">
							{t('programs:userMaxHelper')}
						</p>
					</>
				)}*/}

				{/*<TextInput
					// required
					errors={errors}
					register={register}
					name="duration"
					placeholder={t('programs:programAdd:duration')}
					type="number"
				/>*/}
				<Keywords keywords={keywords} setKeywords={setKeywords} />
				<div className="actions">
					<Button
						loading={loading}
						buttonClass={'textNormal weightMedium btnGreen'}
						buttonText={t('commons:finish')}
						onClick={() => {
							setShouldSubmit(true);
						}}
					/>
				</div>
			</form>
		</div>
	);
};

const ProgramAvatar = ({ progConfig }: any) => {
	const [programImg, setProgramImg] = useState<any>({ previewImage: progConfig?.avatar?.url } || null);
	const { t } = useTranslation();
	const [
		addProgramAvatar,
		{ error, loading }, // eslint-disable-line
	] = useAddProgramAvatarMutation();
	useErrorModal({ error });

	const selectFile = async (e: any) => {
		if (loading) return;

		setProgramImg({
			currentFile: e.target.files[0],
			previewImage: URL.createObjectURL(e.target.files[0]),
		});

		try {
			const image = await resizeFile(e.target.files[0]);

			addProgramAvatar({
				variables: {
					file: image,
					programId: progConfig?.id,
				},
			})
				.then((r) => console.log('r', r))
				.catch((e) => console.log('e', e));
		} catch (e) {
			console.error('resize error', e);
		}
	};

	return (
		<div className="logoProgram">
			<div className="preview">
				<img src={programImg?.previewImage ? programImg.previewImage : defaultProgImg} alt="Program Avatar" />
			</div>
			<div className="actions textNormal weightMedium">
				<label htmlFor="imgUpload" className="imgUploadLabel">
					<Pen />
					{t('programs:programAdd:updateLogo')}
					<input id="imgUpload" type="file" accept="image/*" onChange={selectFile} />
				</label>
			</div>
		</div>
	);
};

export default ProgramEditInfos;
