import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { CHRONO_ACTIVITIES, CHRONO_TYPE } from '../../../../../config/type';
import { secToString } from '../../../../../utils/chrono';
import { Clipboard, Maximize, PauseCircle, StopCircle } from '../../../../main/Icons';
import { TimerConfigDataContext } from '../../contexts/TimerConfigDataContext';
import './TimerChronoApp.scss';
import TimerChronoAppExerciseModal from './TimerChronoAppExerciseModal';
import TimerChronoAppPauseModal from './TimerChronoAppPauseModal';
import TimerEndScreen from './TimerEndScreen';

const TimerChronoApp = () => {
	const { t } = useTranslation();

	const isMobileLandscape = useMediaQuery({ query: '(max-height: 770px)' });
	const isMobileLandscapeWidth = useMediaQuery({ query: '(min-width: 640px)' });

	//DIFFERENT PHASES
	const [chronoType, setChronoType] = useState<any>();
	const chronoTypeRef = useRef(chronoType);
	chronoTypeRef.current = chronoType;

	const [chronoEnd, setChronoEnd] = useState(false);
	const chronoEndRef = useRef(chronoEnd);
	chronoEndRef.current = chronoEnd;

	//PAUSE
	const [pause, setPause] = useState<any>(false);
	const pauseRef = useRef(pause);
	pauseRef.current = pause;

	//CLOSE
	const [close, setClose] = useState<any>(false);
	const closeRef = useRef(close);
	closeRef.current = close;

	//START COUNTDOWN OF 10SEC
	const [countdownValue, setCountdownValue] = useState<any>(2);

	const { timerConfig, chronoConfigurationData } = useContext(TimerConfigDataContext);
	const [timerTimeline, setTimerTimeline] = useState<any>([]);
	const [timerTimelinePosition, setTimerTimelinePosition] = useState(0);
	const [timerValue, setTimerValue] = useState<any>();
	const [timerTotalValue, setTimerTotalValue] = useState(0);
	const [chronoChange, setChronoChange] = useState<boolean>(false);

	const [upDown, setUpDown] = useState<boolean>(true); //True = UP False = Down
	const [timerValueUp, setTimerValueUp] = useState<any>(1);

	//TIMER PROGRESSION
	const [timerProgression, setTimerProgression] = useState<any>({
		sets: 0,
		global: 1,
	});

	//LAPS SYSTEM
	const [laps, setLaps] = useState<any>([]);

	//FULLSCREEN
	const [fullScreen, setFullScreen] = useState<boolean>(false);

	//EXERCISE MODAL
	const [exerciseModal, setExerciseModal] = useState<boolean>(false);

	useEffect(() => {
		//Set timer timeline
		const timerTimelineArr = [];
		//AMRAP
		if (timerConfig.type === CHRONO_ACTIVITIES[0].name) {
			let rounds = timerConfig.setConfiguration.number === 0 ? 1 : timerConfig.setConfiguration.number;
			for (let i = 0; i < rounds; i++) {
				timerTimelineArr.push({
					time: timerConfig.roundConfiguration.timeLimit,
					type: CHRONO_TYPE.TIMER,
				});
				if (i + 1 < rounds) {
					timerTimelineArr.push({
						time: timerConfig.setConfiguration.restTime,
						type: CHRONO_TYPE.SETPAUSE,
					});
				}
			}
		}
		//EMOM
		else if (timerConfig.type === CHRONO_ACTIVITIES[1].name) {
			timerConfig.emoms.forEach((emom: any, index: number) => {
				if (index !== 0 && timerConfig.emoms[index].restTime !== 0) {
					timerTimelineArr.push({
						time: timerConfig.emoms[index].restTime,
						type: CHRONO_TYPE.SETPAUSE,
					});
				}
				for (let i = 0; i < timerConfig.emoms[index].roundsNumber; i++) {
					timerTimelineArr.push({
						time: timerConfig.emoms[index].everyTime,
						type: CHRONO_TYPE.TIMER,
					});
				}
			});
		}
		//FORTIME
		else if (timerConfig.type === CHRONO_ACTIVITIES[2].name) {
			//Infinite timer
			if (timerConfig.timeLimit === 0) {
				timerTimelineArr.push({
					time: 0,
					type: CHRONO_TYPE.TIMERINFINITE,
				});
			} else {
				let nbRounds = timerConfig.setConfiguration.number === 0 ? 1 : timerConfig.setConfiguration.number;
				for (let i = 0; i < nbRounds; i++) {
					timerTimelineArr.push({
						time: timerConfig.timeLimit,
						type: CHRONO_TYPE.TIMER,
					});
					if (i !== nbRounds - 1) {
						timerTimelineArr.push({
							time: timerConfig.setConfiguration.restTime,
							type: CHRONO_TYPE.SETPAUSE,
						});
					}
				}
			}
		}
		//TABATA
		else if (timerConfig.type === CHRONO_ACTIVITIES[3].name) {
			let nbSets = timerConfig.setConfiguration.number !== 0 ? timerConfig.setConfiguration.number : 1;
			let nbRounds = timerConfig.roundConfiguration.number;

			for (let i = 0; i < nbSets; i++) {
				if (i !== 0) {
					timerTimelineArr.push({
						time: timerConfig.setConfiguration.restTime,
						type: CHRONO_TYPE.PAUSE,
					});
				}
				for (let j = 0; j < nbRounds; j++) {
					timerTimelineArr.push({
						time: timerConfig.roundConfiguration.timeLimit,
						type: CHRONO_TYPE.TIMER,
					});
					if (j !== nbRounds - 1) {
						timerTimelineArr.push({
							time: timerConfig.roundConfiguration.restTime,
							type: CHRONO_TYPE.SETPAUSE,
						});
					}
				}
			}
		}
		//AHAP
		else if (timerConfig.type === CHRONO_ACTIVITIES[4].name) {
			timerConfig.ahaps.forEach((ahap: any, index: number) => {
				if (index !== 0 && timerConfig.ahaps[index].restTime !== 0) {
					timerTimelineArr.push({
						time: timerConfig.ahaps[index].restTime,
						type: CHRONO_TYPE.SETPAUSE,
					});
				}
				timerTimelineArr.push({
					time: ahap.timeLimit,
					type: CHRONO_TYPE.TIMER,
				});
			});
		}

		setTimerTimeline(timerTimelineArr);

		//Start Countdown
		setChronoType(CHRONO_TYPE.COUNTDOWN);
		setChronoChange(!chronoChange);

		return () => {
			setClose(true);
		};
	}, []);

	//TIMER LAUNCHER
	useEffect(() => {
		if (chronoType === CHRONO_TYPE.COUNTDOWN && !pause) {
			countdownApp();
		}

		if (
			(chronoType === CHRONO_TYPE.TIMER ||
				chronoType === CHRONO_TYPE.TIMERINFINITE ||
				chronoType === CHRONO_TYPE.SETPAUSE ||
				chronoType === CHRONO_TYPE.PAUSE) &&
			!pause &&
			!chronoEnd
		) {
			timerLap();
			timerApp();
		}
	}, [chronoChange, pause, chronoEnd]);

	//START COUNTDOWN OF 10SEC
	const countdownApp = () => {
		const countdownRun = setInterval(() => {
			if (closeRef.current) {
				clearInterval(countdownRun);
			} else {
				setCountdownValue((o: any) => {
					if (pauseRef.current || chronoEndRef.current) {
						clearInterval(countdownRun);
						return o;
					}

					if (o <= 1) {
						clearInterval(countdownRun);
						setTimeout(() => {
							setTimerValue(timerTimeline[timerTimelinePosition].time);
							setChronoType(timerTimeline[timerTimelinePosition].type);
							setChronoChange(!chronoChange);
							setTimerValueUp(1);
						}, 1000);
						return 'GO';
					} else {
						return o - 1;
					}
				});
			}
		}, 1000);
	};

	//TIMER APP
	const timerApp = () => {
		//INFINITE TIMER
		if (chronoType === CHRONO_TYPE.TIMERINFINITE) {
			setUpDown(true);
			const timerRun = setInterval(() => {
				if (closeRef.current) {
					clearInterval(timerRun);
				} else {
					setTimerValue((o: any) => {
						if (pauseRef.current || chronoEndRef.current) {
							clearInterval(timerRun);
							setTimerValueUp((oldState: any) => oldState);
							return o;
						}
						return o - 1;
					});
				}
			}, 1000);
			// NORMAL TIMER
		} else {
			const timerRun = setInterval(() => {
				if (closeRef.current) {
					clearInterval(timerRun);
				} else {
					setTimerValue((o: any) => {
						if (pauseRef.current || chronoEndRef.current) {
							clearInterval(timerRun);
							setTimerValueUp((oldState: any) => oldState);
							return o;
						}

						if (o === 1) {
							clearInterval(timerRun);

							if (timerTimelinePosition + 1 < timerTimeline.length) {
								// setTimeout(() => {
								setTimerTimelinePosition((o) => o + 1);
								setTimerValue(timerTimeline[timerTimelinePosition + 1].time);
								setChronoType(timerTimeline[timerTimelinePosition + 1].type);
								setChronoChange(!chronoChange);
								// }, 1000)
								return o;
							} else {
								setChronoEnd(true);
							}
						} else {
							return o - 1;
						}
					});
				}
			}, 1000);
		}
	};

	//UP/DOWN
	useEffect(() => {
		let timerUpValue = 1;
		if (chronoType === CHRONO_TYPE.TIMERINFINITE) {
			setTimerValueUp((o: any) => o + 1);
		} else {
			if (timerTimeline.length >= 1) {
				timerUpValue = timerTimeline[timerTimelinePosition].time - timerValue + 1;
			}
			setTimerValueUp(timerUpValue);
		}
		setTimerTotalValue((o: any) => o + 1);
	}, [timerValue]);

	//LAPS SYSTEM
	const lapsAdd = () => {
		setLaps((o: any) => {
			const u = [...o];
			let lastLapEnd = u.length === 0 ? 0 : u[u.length - 1].start;
			u.push({
				start: timerValueUp,
				time: timerValueUp - lastLapEnd,
			});
			return u;
		});
	};

	const timerLap = () => {
		//AMRAP
		if (timerConfig.type === CHRONO_ACTIVITIES[0].name) {
			if (chronoType === 1 && timerConfig.setConfiguration.number > 1) {
				setTimerProgression((o: any) => {
					const u = { ...o };
					u.sets += 1;
					return u;
				});
			}
		}
		//EMOM
		else if (timerConfig.type === CHRONO_ACTIVITIES[1].name) {
			setTimerProgression((o: any) => {
				const u = { ...o };
				if (chronoType === 1) {
					u.sets += 1;
				} else if (chronoType === 4) {
					u.global += 1;
				}
				return u;
			});
		}
		//FORTIME
		else if (timerConfig.type === CHRONO_ACTIVITIES[2].name) {
			if (chronoType === 1) {
				setTimerProgression((o: any) => {
					const u = { ...o };
					u.sets += 1;
					return u;
				});
			}
		}
		//TABATA
		else if (timerConfig.type === CHRONO_ACTIVITIES[3].name) {
			setTimerProgression((o: any) => {
				const u = { ...o };
				if (chronoType === 1) {
					u.sets += 1;
				} else if (chronoType === 3) {
					u.global += 1;
				}
				return u;
			});
		}
		//AHAP
		else if (timerConfig.type === CHRONO_ACTIVITIES[4].name) {
			setTimerProgression((o: any) => {
				const u = { ...o };
				if (chronoType === 1 && timerTimelinePosition !== 0) {
					u.global += 1;
				}
				return u;
			});
		}
	};

	//FULLSCREEN MODE
	const handleFullScreen = () => {
		if(!fullScreen){
			if (document.querySelector('.timerApp')?.requestFullscreen) {
				document.querySelector('.timerApp')?.requestFullscreen();
			}
			setFullScreen(true)
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			}
			setFullScreen(false)
		}
	};

	return (
		<div className="timerChronoApp">
			<div className="infos">
				<div className="infosText">
					{/* AMRAP */}
					{timerConfig.type === CHRONO_ACTIVITIES[0].name && (
						<>
							<h1>{laps.length + ' ' + t('chrono:laps')}</h1>

							{timerConfig.setConfiguration.number > 1 && (
								<h1>{t('chrono:set') + ' : ' + timerProgression.sets + '/' + timerConfig.setConfiguration.number}</h1>
							)}
						</>
					)}
					{/* EMOM */}
					{timerConfig.type === CHRONO_ACTIVITIES[1].name && (
						<>
							<h1>{timerProgression.sets + '/' + timerConfig.emoms[timerProgression.global - 1].roundsNumber}</h1>

							{timerConfig.emoms.length > 1 && <h1>EMOM {timerProgression.global + '/' + timerConfig.emoms.length}</h1>}
						</>
					)}
					{/* FORTIME */}
					{timerConfig.type === CHRONO_ACTIVITIES[2].name && (
						<>
							<h1>{laps.length + ' ' + t('chrono:laps')}</h1>

							{timerConfig.setConfiguration.number > 1 && (
								<h1>{t('chrono:set') + ' ' + timerProgression.global + '/' + timerConfig.setConfiguration.number}</h1>
							)}
						</>
					)}

					{/* TABATA */}
					{timerConfig.type === CHRONO_ACTIVITIES[3].name && (
						<>
							<h1>{timerProgression.sets + '/' + timerConfig.roundConfiguration.number}</h1>

							{timerConfig.setConfiguration.number > 1 && (
								<h1>{t('chrono:set') + ' ' + timerProgression.global + '/' + timerConfig.setConfiguration.number}</h1>
							)}
						</>
					)}

					{/* AHAP */}
					{timerConfig.type === CHRONO_ACTIVITIES[4].name && (
						<>
							{timerConfig.ahaps.length > 1 && (
								<h1>{'AHAP' + ' ' + timerProgression.global + '/' + timerConfig.ahaps.length}</h1>
							)}
						</>
					)}
				</div>
			</div>

			<div className="timer">
				{chronoType === CHRONO_TYPE.COUNTDOWN && (
					<h1 className="countdownPrestart timerValue">
						{countdownValue < 10 && typeof countdownValue === 'number' ? '0' + countdownValue : countdownValue}
					</h1>
				)}

				{(chronoType === CHRONO_TYPE.TIMER ||
					chronoType === CHRONO_TYPE.TIMERINFINITE ||
					chronoType === CHRONO_TYPE.PAUSE ||
					chronoType === CHRONO_TYPE.SETPAUSE) && (
					<h1
						className={`
							timerValue
							${chronoType === CHRONO_TYPE.PAUSE && 'timerValuePause'}
							${chronoType === CHRONO_TYPE.SETPAUSE && 'timerValueSetPause'}
							${timerValue < 4 && chronoType !== CHRONO_TYPE.TIMERINFINITE && 'timerValueEnd'}
						`}
					>
						{secToString(upDown ? timerValueUp : timerValue)}
					</h1>
				)}
			</div>

			<div className="actions">
				{/* <button onClick={() => setPause(!pause)}>Pause</button>
				<button onClick={() => setUpDown(!upDown)}>UP/DOWN</button> */}

				<div className="top">
					{
						/* LAPS */
						chronoType !== CHRONO_TYPE.COUNTDOWN &&
							(timerConfig.type === CHRONO_ACTIVITIES[0].name || timerConfig.type === CHRONO_ACTIVITIES[2].name) && (
								<>
									{laps.length > 0 && (
										<h1 className="titleXXL weightMedium">
											{t('chrono:lastLap')} : {secToString(laps[laps.length - 1].time)}
										</h1>
									)}

									{(!isMobileLandscape || !isMobileLandscapeWidth) && (
										<button className="btnTimer weightBold" onClick={lapsAdd}>
											{t('chrono:addLap')}
										</button>
									)}
								</>
							)
					}
				</div>

				<div className="bottom">
					<div className="left">
						{chronoType !== CHRONO_TYPE.TIMERINFINITE && chronoType !== CHRONO_TYPE.COUNTDOWN && (
							<>
								<button
									className={`
									btnTimer weightBold
									${upDown && 'btnTimerDesactive'}
								`}
									onClick={() => setUpDown(true)}
								>
									{t('chrono:UP')}
								</button>

								<button
									className={`
									btnTimer weightBold
									${!upDown && 'btnTimerDesactive'}
								`}
									onClick={() => setUpDown(false)}
								>
									{t('chrono:DOWN')}
								</button>
							</>
						)}
					</div>

					<div className="center">
						<button className="btnTimer btnStop btnStopPause" onClick={() => setChronoEnd(true)}>
							<StopCircle />
						</button>

						{isMobileLandscape &&
							isMobileLandscapeWidth &&
							chronoType !== CHRONO_TYPE.COUNTDOWN &&
							(timerConfig.type === CHRONO_ACTIVITIES[0].name || timerConfig.type === CHRONO_ACTIVITIES[2].name) && (
								<button className="btnTimer weightBold" onClick={lapsAdd}>
									{t('chrono:addLap')}
								</button>
							)}

						<button className="btnTimer btnPause btnStopPause" onClick={() => setPause(!pause)}>
							<PauseCircle />
						</button>
					</div>

					<div className="right">
						<button className="btnTimer btnMaximize" onClick={handleFullScreen}>
							<Maximize />
						</button>
					</div>
				</div>


				{
					chronoConfigurationData?.blocks?.length > 0 && 
						<>
							<div className="execiseTextButton" onClick={() => {setExerciseModal(true)}}>
								<Clipboard />
							</div>

							<TimerChronoAppExerciseModal visible={exerciseModal} setVisible={setExerciseModal} />
						</>

					
				}


				<TimerChronoAppPauseModal visible={pause} setVisible={setPause} setChronoEnd={setChronoEnd} />

				{chronoEnd && <TimerEndScreen timerTotalValue={timerTotalValue} laps={laps} nameCat={timerConfig.type} />}
			</div>
		</div>
	);
};

export default TimerChronoApp;
