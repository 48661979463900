import React, { useState } from 'react';
import { CHRONO_SCREEN } from '../../../../config/type';

export const TimerContext = React.createContext({} as any);

const TimerContextProvider = ({children, states} : any) => {

	return (
		<TimerContext.Provider
			value={{
				...states,
			}}
		>
			{children}
		</TimerContext.Provider>
	)

}

export default TimerContextProvider
