import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

import LoginForm from '../../components/auth/LoginForm';

import './LoginPage.scss';

export const LoginPage = () => {
	const history = useHistory();
	const height = use100vh();
	const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });

	const onLogin = useCallback(() => {
		history.go(0);
	}, [history]);

	return (
		<div
			id="loginPage"
			className="loginLayoutWrapper"
			style={{
				minHeight: height ? height : '100vh',
			}}
		>
			<div className="leftLoginWrapper">
				<LoginForm onLogin={onLogin} />
			</div>

			{isDesktop && <div className="rightLoginWrapper" />}
		</div>
	);
};
