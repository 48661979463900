import MenuItem from '../MenuItem';
import './DesktopSidebar.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { Auth } from 'aws-amplify';

const DesktopSidebar = ({ menuList, setMenuIndex, menuIndex }: any) => {
	const { t } = useTranslation();

	const history = useHistory();

	const onLogout = useCallback(() => {
		Auth.signOut().then(() => {
			history.go(0);
		});
	}, [history]);

	return (
		<div className="DesktopSidebar">
			<div className="selected titleXL weightBold">{t('commons:settings')}</div>

			<div className="menuContainer">
				{menuList.map((menuItem: any) => (
					<MenuItem
						key={menuItem.id}
						data={menuItem}
						menuIndex={menuIndex}
						setMenuIndex={setMenuIndex}
					/>
				))}
			</div>

			<div className="separator" />

			<div className="disconnect titleL weightMedium noselect">
				<button onClick={() => onLogout()}>{t('commons:disconnection')}</button>
			</div>
		</div>
	);
};

export default DesktopSidebar;
