import './Day.scss';
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import moment from 'moment';
import SessionLine from './SessionLine';
import { CalendarContext } from '../contexts/CalendarContext';
import { DateShowFormat } from '../_types';
import { CalendarDatesContext } from '../contexts/CalendarDatesContext';
import { Add } from '../../../main/Icons';
import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../common/Form/TextInput';
import ModalSessionAdd from './ModalSessionAdd';

interface DayProps {
	setSessionDragging: React.Dispatch<any>;
	dateUnique: any;
	me: any;
}

const Day = ({ dateUnique, me, setSessionDragging }: DayProps) => {
	const { t } = useTranslation();
	const {
		setDateSelected,
		dateShowFormat,
		programOwner,
		programPage,
		modalAddSession,
		setModalAddSession,
	} = useContext(CalendarContext);

	const { programList } = useContext(CalendarDatesContext);
	const dayCellule = useRef<any>(null);
	const dayCelluleWrapper = useRef<HTMLDivElement>(null);
	const [celluleHeight, setCelluleHeight] = useState<number>(
		dateShowFormat === DateShowFormat.MONTH ? 140 : 400
	);

	const activeColor = dateUnique.state.selected ? '#50B4EE' : '#4C5771';

	// Fix Height of cellule
	const resizeCellule = useCallback(() => {
		let heightCellule = dateShowFormat === DateShowFormat.MONTH ? 140 : 400;

		if (dayCelluleWrapper.current) {
			if (dayCelluleWrapper.current.scrollHeight > heightCellule) {
				if (dateUnique.state.selected) {
					setCelluleHeight(dayCelluleWrapper.current.scrollHeight - 14);
				} else {
					setCelluleHeight(dayCelluleWrapper.current.scrollHeight);
				}
			}
		}
	}, [dateUnique.state.selected, dateShowFormat]);

	useEffect(() => {
		resizeCellule();
	});

	useEffect(() => {
		window.addEventListener('resize', resizeCellule);
		return () => {
			window.removeEventListener('resize', resizeCellule);
		};
	}, [resizeCellule]);

	// Drag and Drop
	useEffect(() => {
		const dragover = (e: Event) => e.preventDefault();
		const dayCelluleRef = dayCellule.current;
		dayCelluleRef?.addEventListener('dragover', dragover, false);
		return () => {
			dayCelluleRef?.removeEventListener('dragover', dragover, false);
		};
	}, []);

	//Change selected day
	const handleClick = (e: any) => {
		//Detect if click on session
		let sessionClick = false;
		let endLoop = false;
		let element = e.target;

		while (!endLoop) {
			if (element.parentElement) {
				for (let i = 0; i < element.classList.length; i++) {
					if (
						element.classList[i] === 'sessionLineCalendarMonth' ||
						element.classList[i] === 'sessionLineCalendarWeek'
					)
						sessionClick = true;
				}
				element = element.parentElement;
			} else {
				endLoop = true;
			}
		}

		if (!sessionClick) {
			setDateSelected(dateUnique.dateMoment);
		}
	};

	return (
		<td
			id={'day' + moment(dateUnique.dateMoment).format('DDMMYYYY')}
			ref={dayCellule}
			className={`
				dayCellule
				${
					dateShowFormat === DateShowFormat.MONTH
						? 'dayCelluleMonth'
						: 'dayCelluleWeek'
				}
			`}
			style={{
				height: celluleHeight + 'px',
			}}
			onClick={handleClick}
		>
			<div
				className={`
					dayCelluleWrapper
					${dateUnique.state.selected && 'dayCelluleWrapperSelected'}
				`}
				ref={dayCelluleWrapper}
			>
				{programPage && programOwner && dateUnique.state.selected && (
					<div 
						className={dateShowFormat === DateShowFormat.MONTH ? "btnAddSessionWrapperMonth" : "btnAddSessionWrapper"}	
					>
						<Button
							buttonClass="buttonAddSession btnCentered btnRound btnGreen"
							ButtonIcon={Add}
							onClick={() => {
								setModalAddSession(true);
							}}
						/>
					</div>
				)}

				{dateShowFormat === DateShowFormat.MONTH && (
					<div
						className={`
							title textSmall weightMedium
							${
								moment(dateUnique.dateMoment).format('DD/MM/YYYY') ===
									moment().format('DD/MM/YYYY') && 'titleToday'
							}
						`}
						style={{
							color: dateUnique.state.active ? activeColor : '#909fc4',
						}}
					>
						{moment(dateUnique.dateMoment).format('DD')}
					</div>
				)}

				{dateUnique?.sessions.length > 0 && (
					<div className="sessionsListe">
						{dateUnique?.sessions.map((session: any, index: number) => {
							return programList?.map((program: any, index2: number) => {
								if (program.id === session.program.id) {
									if (program.visible) {
										return (
											<SessionLine
												key={index.toString() + index2.toString()}
												data={session}
												me={me}
												setSessionDragging={setSessionDragging}
											/>
										);
									}
								}
								return null;
							});
						})}
					</div>
				)}
			</div>
		</td>
	);
};

export default Day;
