import './CheckboxScore.scss';
import React, { useState } from 'react';
import { Check } from '../../../../../main/Icons';

interface CheckboxScoreProps {
	name: string;
	value: boolean;
	onScoresSelected: any;
	index: number;
	setScoresSelected: any;
}

const CheckboxScore = ({ name, value, onScoresSelected, index, setScoresSelected }: CheckboxScoreProps) => {
	const handleClick = async () => {
		await setScoresSelected((s: any) => {
			const newState = [...s];
			newState[index].value = !value;
			return newState;
		});
		onScoresSelected(index, value);
	};

	return (
		<div className="checkboxScore textSmall weightMedium noselect" onClick={handleClick}>
			<div className="checkbox">{value && <Check />}</div>
			{name}
		</div>
	);
};

export default CheckboxScore;
