import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { User, FileText, Lock, Headphones, CreditCard, HelpCircle, Price } from '../../components/main/Icons';
import { CGU } from '../unauthenticated/CguPage';
import MobileMenu from '../../components/pages/settings/MobileMenu/MobileMenu';
import Profile from '../../components/pages/settings/Profile/Profile';
import Language from '../../components/pages/settings/Language/Language';
import DesktopSidebar from '../../components/pages/settings/DesktopSidebar/DesktopSidebar';
import useUser from '../../hooks/user/useUser';
import Privacy from '../../components/pages/settings/Privacy/Privacy';
import Support from '../../components/pages/settings/Support/Support';
import Payment from '../../components/pages/settings/Payment/Payment';
import About from '../../components/pages/settings/About/About';
import { MENU_LIST } from '../../config/type';
import { useLocation } from 'react-router-dom';

import './SettingsPage.scss';
import Connect from '../../components/pages/settings/Connect/Connect';

export const SettingsPage = () => {
	const { t } = useTranslation();
	const { user } = useUser();
	const location = useLocation();

	const menuList = [
		{
			id: MENU_LIST.ABOUT,
			text: t('settings:menu:about'),
			Icon: HelpCircle,
		},
		{
			id: MENU_LIST.PROFILE,
			text: t('settings:menu:profile'),
			Icon: User,
		},
		{
			id: MENU_LIST.LANG,
			text: t('settings:menu:lang'),
			Icon: FileText,
		},
		//TODO
		/*{
			id: MENU_LIST.PRIVACY,
			text: t('settings:menu:privacy'),
			Icon: Lock,
		},*/
		{
			id: MENU_LIST.CGU,
			text: t('settings:menu:cgu'),
			Icon: Lock,
		},
		{
			id: MENU_LIST.SUPPORT,
			text: t('settings:menu:support'),
			Icon: Headphones,
		},
		{
			id: MENU_LIST.PAYMENT,
			text: t('settings:menu:payment'),
			Icon: CreditCard,
		},
		{
			id: MENU_LIST.CONNECT,
			text: t('settings:menu:connect'),
			Icon: Price,
		},
	];
	const [menuIndex, setMenuIndex] = useState<String>(MENU_LIST.ABOUT);
	const isDesktop = useMediaQuery({ query: '(min-width: 800px)' });

	useEffect(() => {
		// @ts-ignore
		if (location?.state && location?.state[0] === 'PROFILE') {
			setMenuIndex(MENU_LIST.PROFILE);
		}
	}, [location]);

	return (
		<div id="settingsPage">
			{!isDesktop ? (
				<MobileMenu menuList={menuList} menuIndex={menuIndex} setMenuIndex={setMenuIndex} />
			) : (
				<DesktopSidebar menuList={menuList} menuIndex={menuIndex} setMenuIndex={setMenuIndex} />
			)}
			{menuIndex === MENU_LIST.PROFILE && user && <Profile user={user} />}
			{menuIndex === MENU_LIST.LANG && <Language />}
			{/*{menuIndex === MENU_LIST.PRIVACY && <Privacy />}*/}
			{menuIndex === MENU_LIST.CGU && <CGU />}
			{menuIndex === MENU_LIST.SUPPORT && <Support />}
			{menuIndex === MENU_LIST.PAYMENT && <Payment />}
			{menuIndex === MENU_LIST.CONNECT && <Connect />}
			{menuIndex === MENU_LIST.ABOUT && <About />}
		</div>
	);
};
