import { SYSTEM_STORE_ACTIONS } from './SystemReducer';

export const setErrorModal = (
	data: {
		title: string;
		body: string;
		onRetry?: () => void;
		onClose?: () => void;
	} | null
) => {
	return {
		type: SYSTEM_STORE_ACTIONS.SET_ERROR_MODAL,
		value: data,
	};
};

export const toggleSidebar = (value: boolean) => {
	return {
		type: SYSTEM_STORE_ACTIONS.TOGGLE_SIDEBAR,
		value,
	};
};

export const toggleNavbarSearch = (value: boolean) => {
	return {
		type: SYSTEM_STORE_ACTIONS.TOGGLE_NAVBARSEARCH,
		value,
	};
};

export const focusNavbarSearch = (value: boolean) => {
	return {
		type: SYSTEM_STORE_ACTIONS.TOGGLE_NAVBARSEARCHFOCUS,
		value,
	};
};

export const setChatChannelId = (value: boolean) => {
	return {
		type: SYSTEM_STORE_ACTIONS.SET_CHAT_CHANNEL_ID,
		value,
	};
};

export const setSaveChronoConfigData = (value: object) => {
	return {
		type: SYSTEM_STORE_ACTIONS.SET_SAVE_CHRONO_CONFIG_DATA,
		value,
	};
};
