export const French = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M171 424H9c-5 0-9-4-9-9V97c0-5 4-9 9-9h162v336z"
				fill="#41479b"
			/>
			<path fill="#f5f5f5" d="M171 88h170v336H171z" />
			<path
				d="M503 424H341V88h162c5 0 9 4 9 9v318c0 5-4 9-9 9z"
				fill="#ff4b55"
			/>
		</svg>
	);
};

export const English = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path
				d="M474 88H38c-21 0-38 17-38 39v258c0 22 17 39 38 39h436c21 0 38-17 38-39V127c0-22-17-39-38-39z"
				fill="#41479b"
			/>
			<path
				d="M511 120c-3-18-18-32-37-32h-10L300 195V88h-88v107L48 88H38c-19 0-34 14-37 32l139 92H0v88h140L1 392c3 18 18 32 37 32h10l164-107v107h88V317l164 107h10c19 0 34-14 37-32l-139-92h140v-88H372l139-92z"
				fill="#f5f5f5"
			/>
			<g fill="#ff4b55">
				<path d="M282 88h-52v142H0v52h230v142h52V282h230v-52H282z" />
				<path d="M25 421l186-121h-32L9 410c4 5 10 9 16 11zM346 300h-32l181 117c5-3 9-7 12-13L346 300zM4 109l158 103h32L15 96c-4 3-8 8-11 13zM333 212l170-111c-5-4-10-8-16-10L300 212h33z" />
			</g>
		</svg>
	);
};
